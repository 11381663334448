import {listBallot} from "./GetBallotGame";
import {listBallotBingoLimit} from "./ListBallotBingoLimit";

export function getListBallotToValidateNumberComponent(game_mode: string, bingo_limit: number) {
    switch (game_mode) {
        case 'col_1':
            return getListCol1(bingo_limit);
        case 'col_2':
            return getListCol2( bingo_limit);
        case 'col_3':
            return getListCol3(bingo_limit);
        case 'col_4':
            return getListCol4(bingo_limit);
        case 'col_5':
            return getListCol5(bingo_limit);
        case 'corners':
            return getListCorners(bingo_limit);
        case 'shape_X':
            return getListShapeX(bingo_limit);
        case 'diagonal':
            return getListShapeX(bingo_limit);
        case 'anti_diagonal':
            return getListShapeX(bingo_limit);
        case 'row_3':
            return getListShapeX(bingo_limit);
        default:
            return getListDefault(bingo_limit);
    }
}

function getListCol1(bingo_limit: number) {
    if (bingo_limit === 25) {
        return listBallot(1, 5);
    }
    if (bingo_limit === 50) {
        return listBallot(1, 10);
    }
    if (bingo_limit === 75) {
        return listBallot(1, 15);
    }
    if (bingo_limit === 95 || bingo_limit === 99) {
        return listBallot(1, 19);
    }
}
function getListCol2(bingo_limit: number) {
    if (bingo_limit === 25) {
        return listBallot(6, 10);
    }
    if (bingo_limit === 50) {
        return listBallot(11, 20);
    }
    if (bingo_limit === 75) {
        return listBallot(16, 30);
    }
    if (bingo_limit === 95) {
        return listBallot(20, 38);
    }
    if (bingo_limit === 99) {
        return listBallot(20, 39);
    }
}
function getListCol3(bingo_limit: number) {
    if (bingo_limit === 25) {
        return listBallot(11, 15);
    }
    if (bingo_limit === 50) {
        return listBallot(21, 30);
    }
    if (bingo_limit === 75) {
        return listBallot(31, 45);
    }
    if (bingo_limit === 95) {
        return listBallot(39, 57);
    }
    if (bingo_limit === 99) {
        return listBallot(40, 59);
    }
}
function getListCol4(bingo_limit: number) {
    if (bingo_limit === 25) {
        return listBallot(16, 20);
    }
    if (bingo_limit === 50) {
        return listBallot(31, 40);
    }
    if (bingo_limit === 75) {
        return listBallot(46, 60);
    }
    if (bingo_limit === 95) {
        return listBallot(58, 76);
    }
    if (bingo_limit === 99) {
        return listBallot(60, 79);
    }
}
function getListCol5(bingo_limit: number) {
    if (bingo_limit === 25) {
        return listBallot(21, 25);
    }
    if (bingo_limit === 50) {
        return listBallot(41, 50);
    }
    if (bingo_limit === 75) {
        return listBallot(61, 75);
    }
    if (bingo_limit === 95) {
        return listBallot(77, 95);
    }
    if (bingo_limit === 99) {
        return listBallot(80, 99);
    }
}
function getListCorners(bingo_limit: number) {
    if (bingo_limit === 25) {
        const list1 = listBallot(1, 5);
        const list2 = listBallot(21, 25);
        return list1.concat(list2);
    }
    if (bingo_limit === 50) {
        const list1 = listBallot(1, 10);
        const list2 = listBallot(41, 50);
        return list1.concat(list2);
    }
    if (bingo_limit === 75) {
        const list1 = listBallot(1, 15);
        const list2 = listBallot(61, 75);
        return list1.concat(list2);
    }
    if (bingo_limit === 95) {
        const list1 = listBallot(1, 19);
        const list2 = listBallot(77, 95);
        return list1.concat(list2);
    }
    if (bingo_limit === 99) {
        const list1 = listBallot(1, 19);
        const list2 = listBallot(80, 99);
        return list1.concat(list2);
    }
}
function getListShapeX(bingo_limit: number) {
    if (bingo_limit === 25) {
        const list1 = listBallot(1, 10);
        const list2 = listBallot(16, 25);
        return list1.concat(list2);
    }
    if (bingo_limit === 50) {
        const list1 = listBallot(1, 20);
        const list2 = listBallot(31, 50);
        return list1.concat(list2);
    }
    if (bingo_limit === 75) {
        const list1 = listBallot(1, 15);
        const list2 = listBallot(61, 75);
        return list1.concat(list2);
    }
    if (bingo_limit === 95) {
        const list1 = listBallot(1, 38);
        const list2 = listBallot(58, 95);
        return list1.concat(list2);
    }
    if (bingo_limit === 99) {
        const list1 = listBallot(1, 39);
        const list2 = listBallot(60, 99);
        return list1.concat(list2);
    }
}
function getListDefault(bingo_limit: number) {
    return listBallotBingoLimit(bingo_limit);
}