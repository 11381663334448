import {OptionsAdminStyles} from "./OptionsAdminStyles"
import Option from "../Option/Option";
import {optionsComponentData} from "../../../shared/Utils/OptionsComponentData";
import {useHistory} from "react-router-dom";
import CreateSession from "../CreateSession/CreateSession";
import {useAppDispatch, useAppSelector} from "../../../shared/Utils/Hooks";
import {closeCreateSession, openCreateSession, openUserInfo} from "../../../store/ActionsAdmin/ModalsActions";
import React from "react";
import {useThemeContext} from "../../../shared/Hooks/ThemeContextProvider";
import AddBulkUserInCreateSession from "../AddBulkUserInCreateSession/AddBulkUserInCreateSession";

export default function OptionsAdmin(): JSX.Element {

    const dispatch = useAppDispatch();
    const history = useHistory();
    const themeActive = useThemeContext();
    const showModalCreateSession = useAppSelector((state) => state.modalState.showModalCreateSession);
    const showModalAddBulkUsersInCreateSession = useAppSelector((state) => state.modalState.showModalAddBulkUsersInCreateSession);

    function handleClick(id:number) {
        console.log(id);
        if (id === 1) {
            history.push('/admin/user-management');
            dispatch(openUserInfo(false));
            dispatch(closeCreateSession());
        }
        if (id === 2) {
            dispatch(openCreateSession());
            dispatch(openUserInfo(false));
        }
        if (id === 3) {
            history.push('/admin/configuration-options');
            dispatch(openUserInfo(false));
        }
    }

    return (
    <OptionsAdminStyles>
        <div className="container-img">
            <img
                className="optionsAdminTitle"
                alt="optionsAdminTitle"
                src={themeActive.theme?.logo}
            />
        </div>
        <div className="container-options">
            {optionsComponentData && optionsComponentData.map((option) => (
                <Option click={handleClick} key={option.id} id={option.id} img={option.img} text={option.text}/>
            ))}
        </div>
        {showModalCreateSession &&
            <div className="modal-create-session">
                <CreateSession/>
            </div>
        }
        {showModalAddBulkUsersInCreateSession &&
            <div className="modal-create-session-bulk-users">
                <AddBulkUserInCreateSession/>
            </div>
        }
    </OptionsAdminStyles>
    );
}
