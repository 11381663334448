import styled from "styled-components";

export const ButtonReloadGameStyled = styled.div`
    position: absolute;
    bottom: 1vw;
    right: 3vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    cursor: pointer;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        /* Define la rotación inicial */
        100% {
            transform: rotate(360deg);
        }
        /* Define la rotación final */
    }
    
    .icon-reload {
         width: 4vw;
    }
    .icon-reload-animation {
        width: 3.5vw;
        animation: spin 4s linear infinite;
    }
`
