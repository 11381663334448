import styled from "styled-components";

export const NavStyled = styled.div`
  display: flex;
  align-items: center;
  width: 95%;
  margin: 0 auto;
  
  .link {
    margin: 0;
    text-align: left;
    letter-spacing: 0;
    color: #303030;
    opacity: 1;
    font-size: 1.5vw;
    font-family: Poppins-SemiBold, sans-serif;
    font-weight: 600;
    cursor: pointer;
  }
  .link-active {
    margin: 0;
    text-align: left;
    letter-spacing: 0;
    color: #9545FF;
    opacity: 1;
    font-size: 1.5vw;
    cursor: pointer;
    border-bottom: 4px solid #9545FF;
    padding-bottom: .3vw;
    font-family: Poppins-SemiBold, sans-serif;
    font-weight: 600;
  }
  .container-links {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 3vw;
    width: 100%;
  }
`
