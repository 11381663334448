import {CreateFinishedGameDataModel} from "../../domain/models/CreateFinishedGameDataModel";
import {
    CLEAN_SINGLE_FINISHED_GAME,
    GET_SINGLE_FINISHED_GAME,
    GET_SINGLE_FINISHED_GAME_FAILED,
    GET_SINGLE_FINISHED_GAME_SUCCESS
} from "../TypesAdmin/FinishedGameTypes";
import {GetFinishedGameDbModel} from "../../domain/models/GetFinishedGameDbModel";

interface IStateFinishedGame {
    singleFinishedGame: GetFinishedGameDbModel | null;
    isLoadingSingleFinishedGame: boolean;
}

const initialStateFinishedGame: IStateFinishedGame = {
    singleFinishedGame: null,
    isLoadingSingleFinishedGame: false,
}

const FinishedGameReducer = function (state = initialStateFinishedGame, action: { type: any; payload: any }): IStateFinishedGame {
    switch (action.type) {
        case GET_SINGLE_FINISHED_GAME:
            return {
                isLoadingSingleFinishedGame: action.payload,
                singleFinishedGame: state.singleFinishedGame,
            }
        case GET_SINGLE_FINISHED_GAME_SUCCESS:
            return {
                isLoadingSingleFinishedGame: false,
                singleFinishedGame: action.payload,
            }
        case GET_SINGLE_FINISHED_GAME_FAILED:
            return {
                isLoadingSingleFinishedGame: false,
                singleFinishedGame: action.payload,
            }
        case CLEAN_SINGLE_FINISHED_GAME:
            return {
                isLoadingSingleFinishedGame: state.isLoadingSingleFinishedGame,
                singleFinishedGame: action.payload,
            }
        default:
            return state;
    }
}
export default FinishedGameReducer;
