import {
    GET_ALL_SESSIONS_ACTIVE_END_USER_FAILED,
    GET_ALL_SESSIONS_ACTIVE_END_USER_SUCCESS,
    GET_SINGLE_SESSION_END_USER,
    GET_SINGLE_SESSION_END_USER_FAILED,
    GET_SINGLE_SESSION_END_USER_SUCCESS
} from "../TypesAdmin/SessionEndUserTypes";
import {SessionModel} from "../../domain/models/SessionModel";


interface IStateSessionEndUser {
    allSessionsEndUser: SessionModel[];
    isLoadingSingleSessionEndUser: boolean;
    singleSessionEndUser: SessionModel | null;
    message: string;
    error: string;
}
const initialStateSessionEndUser: IStateSessionEndUser = {
    allSessionsEndUser: [],
    isLoadingSingleSessionEndUser: false,
    singleSessionEndUser: null,
    message: '',
    error: ''
}
const SessionEndUserReducer = function (state = initialStateSessionEndUser, action: {type:any; payload:any}):IStateSessionEndUser {
    switch (action.type) {
        case GET_ALL_SESSIONS_ACTIVE_END_USER_SUCCESS:
            return {
                allSessionsEndUser: action.payload,
                isLoadingSingleSessionEndUser: state.isLoadingSingleSessionEndUser,
                singleSessionEndUser: state.singleSessionEndUser,
                message: '',
                error: ''
            }
        case GET_ALL_SESSIONS_ACTIVE_END_USER_FAILED:
            return {
                allSessionsEndUser: state.allSessionsEndUser,
                isLoadingSingleSessionEndUser: state.isLoadingSingleSessionEndUser,
                singleSessionEndUser: state.singleSessionEndUser,
                message: '',
                error: action.payload
            }
        case GET_SINGLE_SESSION_END_USER:
            return {
                allSessionsEndUser: state.allSessionsEndUser,
                isLoadingSingleSessionEndUser: action.payload,
                singleSessionEndUser: state.singleSessionEndUser,
                message: '',
                error: ''
            }
        case GET_SINGLE_SESSION_END_USER_SUCCESS:
            return {
                allSessionsEndUser: state.allSessionsEndUser,
                isLoadingSingleSessionEndUser: false,
                singleSessionEndUser: action.payload,
                message: '',
                error: ''
            }
        case GET_SINGLE_SESSION_END_USER_FAILED:
            return {
                allSessionsEndUser: state.allSessionsEndUser,
                isLoadingSingleSessionEndUser: false,
                singleSessionEndUser: state.singleSessionEndUser,
                message: '',
                error: action.payload
            }
        default:
            return state;
    }
}
export default SessionEndUserReducer;