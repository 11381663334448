import {SessionGamesStyled} from "./SessionGamesStyled";
import React, {useEffect, useState} from "react";
import GameState from "../GameState/GameState";
import {useAppDispatch, useAppSelector} from "../../../../admin/shared/Utils/Hooks";
import {getSingleGameEndUser} from "../../../../admin/store/ActionsAdmin/GameEndUserActions";
import Loading from "../../../../admin/presentation/Components/Loading/Loading";
import {getGameInfo} from "../../../../admin/store/ActionsAdmin/BingosActions";
import {getCardsAws} from "../../../../admin/store/ActionsAdmin/CardActions";
import {IParams} from "../../../../admin/presentation/Components/ShowSession/ShowSession";
import {useParams} from "react-router-dom";
import {getSingleSessionEndUser} from "../../../../admin/store/ActionsAdmin/SessionEndUserActions";


export default function SessionGames(): JSX.Element {

    const id: IParams = useParams();
    const dispatch = useAppDispatch();
    const singleSessionEndUser = useAppSelector((state) => state.sessionEndUserState.singleSessionEndUser);
    const userEndActive = useAppSelector((state) => state.userState.userEndActive);
    const [gameIdSelected, setGameIdSelected] = useState(0);
    const [isLoadingSingleGame, setIsLoadingSingleGame] = useState(false);
    const [isListVisible, setIsListVisible] = useState(false);

    const toggleListVisibility = () => {
        if (singleSessionEndUser && singleSessionEndUser.games.length > 1) {
            setIsListVisible(!isListVisible);
        }
    };


    function handleGameIdSelected(id: number) {
        setIsListVisible(false);
        setIsLoadingSingleGame(true);
        setGameIdSelected(id);
        if (singleSessionEndUser && userEndActive) {
            dispatch(getSingleGameEndUser(id)).then(() => {
                dispatch(getGameInfo(id)).then(() => {
                    dispatch(getCardsAws(singleSessionEndUser.id.toString(), userEndActive.phone)).then(() => {
                        setIsLoadingSingleGame(false);
                    });
                });
            });
        }
    }

    useEffect(() => {
        console.log(gameIdSelected);
        console.log(id.id);
    }, [gameIdSelected, id]);

    useEffect(() => {
        if (singleSessionEndUser && singleSessionEndUser.games.length !== 0) {
            console.log(singleSessionEndUser)
            setGameIdSelected(singleSessionEndUser.games[0].id);
            dispatch(getSingleGameEndUser(singleSessionEndUser.games[0].id)).then(() => {
            });
        }
    }, []);

    useEffect(() => {
        const timerGamesOfSingleSession = setInterval(() => {
            dispatch(getSingleSessionEndUser(parseInt(id.id))).then(() => {});
        }, 5000);
        return () => clearInterval(timerGamesOfSingleSession);
    }, []);

    return (
        <SessionGamesStyled>
            <div className="sidebar-header">
                <div className="container-circle-img">
                    <img
                        className="game-img"
                        alt="game-img"
                        // src="/img/imgUser/imgGame/Rectángulo%20343.png"
                        src={singleSessionEndUser?.main_image}
                    />
                </div>
                <span className="text-header">{singleSessionEndUser?.session_name}</span>
            </div>
            <div className="sidebar-content_modal">
                <div className="container-description">
                    <span className="text-description">
                        {singleSessionEndUser && singleSessionEndUser.games.length === 0 ? 'No hay juego por el momento' : 'Juegos en esta sesión'}
                    </span>
                </div>
                <div className="game-state-container">
                    {singleSessionEndUser?.games && singleSessionEndUser.games.map((game) => (
                        <GameState
                            id={game.id}
                            idSelected={game.id === gameIdSelected}
                            nameGame={game.bingo_name}
                            statusGame={game.status}
                            handleGameIdSelected={(id) => handleGameIdSelected(id)}
                        />
                    ))}
                </div>
                <div className="game-state-container-mobile">
                    <div className="selected-game">
                        {singleSessionEndUser?.games &&
                            singleSessionEndUser.games
                                .filter((game) => game.id === gameIdSelected)
                                .map((game) => (
                                    <GameState
                                        key={game.id}
                                        id={game.id}
                                        idSelected={game.id === gameIdSelected}
                                        nameGame={game.bingo_name}
                                        statusGame={game.status}
                                        handleGameIdSelected={(id) => handleGameIdSelected(id)}
                                    />
                                ))}
                    </div>
                    <button className="toggle-games-button" onClick={toggleListVisibility}>
                        <img src="/img/imgUser/mobile/Dropdown-mobile.png" alt="Dropdown Icon" />
                    </button>
                    {isListVisible && (
                        <div className="dropdown-content">
                            {singleSessionEndUser?.games &&
                                singleSessionEndUser.games
                                    .filter((game) => game.id !== gameIdSelected)
                                    .map((game) => (
                                        <div className="icon-state"
                                            key={game.id}
                                            onClick={() => handleGameIdSelected(game.id)}
                                        >
                                            {game.status === 'end' &&
                                                <img
                                                    className="game-icon"
                                                    alt="game-finish"
                                                    src='/img/imgUser/imgGame/game-finish.png'
                                                />
                                            }
                                            {game.status === 'inactive' &&
                                                <img
                                                    className="game-icon"
                                                    alt="game-finish"
                                                    src='/img/imgUser/imgGame/game-programmed.png'
                                                />
                                            }
                                            {game.status === 'active' &&
                                                <img
                                                    className="game-icon"
                                                    alt="lanch-ballot"
                                                    src="/img/newImage/in-game.png"
                                                />
                                            }
                                            {game.bingo_name}
                                        </div>
                                    ))}
                        </div>
                    )}
                </div>
            </div>
            {isLoadingSingleGame &&
                <div className="loadingSingleGame">
                <Loading/>
                </div>
            }
        </SessionGamesStyled>
    );
}
