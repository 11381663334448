import styled from "styled-components";
import {Device} from "../../../shared/Utils/devices";

interface INotHaveAccountStylesProps {
    button_color:string;
    button_color_shadow:string;
}
export const SignInStyles = styled.div<INotHaveAccountStylesProps>`
  display: flex;
  width: 100%;
  height: 95%;
  

  .form-login {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 1vw;
    width: 95%;
    height: 95%;

    .title-form {
      width: 85%;
      text-align: center;
      font-size: 2.5vw;
      font-weight: 900;
      font-family: Anton-Regular, sans-serif;
      letter-spacing: 0;
      color: #2E304E;
      opacity: 1;
    }

    .container-text-2 {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 85%;

      .text-2 {
        width: 50%;
        text-align: center;
        letter-spacing: 0;
        color: #2E304E;
        opacity: 1;
        font-size: 1.3vw;
      }
    }

    .container-input {
      width: 85%;

      input {
        width: 100%;
        padding: 1.5vw;
        outline: none;
        border: 2px solid #4E5175;
        border-radius: 47px;
        opacity: 0.6;
        font-size: 1.5vw;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
        font-style: italic;
      }

      input::placeholder {
        font-size: 1.5vw;
        text-align: start;
        text-transform: revert;
        letter-spacing: 0;
        color: #53577C;
        opacity: 1;
      }

      .input-validate-code {
        text-align: center;
      }
    }

    .not-have-account {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 85%;

      .not-have-account-text {
        text-align: center;
        letter-spacing: 0;
        color: #424144;
        opacity: 1;
        font-size: 1.4vw;
        cursor: pointer;
      }
    }

    .recaptcha {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 85%;
    }

    .container-input-check {
      width: 85%;
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 4vw;

      .input-check {
        display: flex;
        align-items: center;
        justify-content: start;
        gap: .5vw;

        input[type="checkbox"] {
          appearance: none;
          background-color: #fff;
          margin-top: 0.2vw;
          font: inherit;
          color: currentColor;
          width: 1.15em;
          height: 1.15em;
          border: .1em solid #AEAEAE;
          border-radius: .30em;
          transform: translateY(-0.075em);
          display: grid;
          place-content: center;
        }

        .form-control + .form-control {
          margin-top: 1em;
        }

        input[type="checkbox"]::before {
          content: "";
          width: 0.66em;
          height: 0.68em;
          transform: scale(0);
          transition: 120ms transform ease-in-out;
          box-shadow: inset 1em 1em var(--form-control-color);
          background-color: #4E4495;
          border-radius: .3vw;
        }

        input[type="checkbox"]:checked::before {
          transform: scale(1);
        }

        label {
          display: flex;
          font-size: 1vw;
          text-align: center;
          letter-spacing: 0;
          color: #424144;
          opacity: 1;
          gap: 1vw;
        }

      }

      .error-session {
        font-size: 1vw;
        text-align: left;
        letter-spacing: 0;
        color: #424144;
        opacity: 1;
      }

      .register {
        font-size: 1vw;
        text-align: left;
        letter-spacing: 0;
        color: #424144;
        opacity: 1;
        cursor: pointer;
      }
    }

    .container-button {
      width: 85%;
      display: flex;
      align-items: center;
      justify-content: center;

      .button-form {
        outline: none;
        border: none;
        background: ${({button_color}) => button_color};
        border-radius: 37px;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 17vw;
        height: 3.5vw;
        text-align: center;
        letter-spacing: 0;
        color: #FFFFFF;
        font-size: 2vw;
        transition: box-shadow 0.3s ease;
        cursor: pointer;
        font-family: Anton, sans-serif;
        font-weight: 400;
      }

      .button-form:hover {
        box-shadow: ${({button_color_shadow: button_color_shadow}) => button_color_shadow};
      }

      .button-form-register {
        background: ${({button_color: button_color}) => button_color};
        border-radius: 37px;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16vw;
        height: 3.5vw;
        text-align: center;
        letter-spacing: 0;
        color: #FFFFFF;
        font-size: 2vw;
        transition: box-shadow 0.3s ease;
        cursor: pointer;
        outline: none;
        border: none;
      }

      .button-form-register:hover {
        box-shadow: ${({button_color_shadow: button_color_shadow}) => button_color_shadow};
      }

      .button-form-waiting {
        font-size: 2vw;
      }
    }

    .container-error-massages {
      display: flex;
      width: 85%;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 1vw;
      color: red;
    }

  }
  @media ${Device.desktopL} {
    display: flex;
    width: 100%;
    height: 95%;

    .form-login {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      gap: 1vw;
      width: 95%;
      height: 95%;

      .title-form {
        font-size: 2.2vw;
        font-family: Anton-Regular, sans-serif;
        font-weight: 900;

      }

      .container-text-2 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 85%;

        .text-2 {
          width: 50%;
          text-align: center;
          letter-spacing: 0;
          color: #2E304E;
          opacity: 1;
          font-size: 1.5vw;
        }
      }

      .container-input {
        width: 85%;

        input {
          width: 100%;
          font-size: 2vw;
          height: 3vh;
        }

        input::placeholder {
          font-size: 2vw;
        }

        .input-validate-code {
          text-align: center;
        }
      }

      .not-have-account {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 85%;

        .not-have-account-text {
          text-align: center;
          letter-spacing: 0;
          color: #424144;
          opacity: 1;
          font-size: 1.4vw;
          cursor: pointer;
        }
      }

      .recaptcha {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 85%;
      }

      .container-input-check {
        width: 85%;
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 4vw;

        .input-check {
          display: flex;
          align-items: center;
          justify-content: start;
          gap: .5vw;

          input[type="checkbox"] {
            appearance: none;
            background-color: #fff;
            margin: 0;
            font: inherit;
            color: currentColor;
            width: 1.15em;
            height: 1.15em;
            border: .1em solid #AEAEAE;
            border-radius: .30em;
            transform: translateY(-0.075em);
            display: grid;
            place-content: center;
          }

          .form-control + .form-control {
            margin-top: 1em;
          }

          input[type="checkbox"]::before {
            content: "";
            width: 0.66em;
            height: 0.68em;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em var(--form-control-color);
            background-color: #4E4495;
            border-radius: .3vw;
          }

          input[type="checkbox"]:checked::before {
            transform: scale(1);
          }

          label {
            font-size: 1vw;
            text-align: left;
            letter-spacing: 0;
            color: #424144;
            opacity: 1;
          }

        }

        .error-session {
          font-size: 1vw;
          text-align: left;
          letter-spacing: 0;
          color: #424144;
          opacity: 1;
        }

        .register {
          font-size: 1vw;
          text-align: left;
          letter-spacing: 0;
          color: #424144;
          opacity: 1;
          cursor: pointer;
        }
      }

      .container-button {
        justify-content: left;
        margin-top: 2vh;

        .button-form {
          width: 18vw;
          height: 4vw;
          font-size: 2.2vw;

        }

        .button-form-waiting {
          font-size: 2.2vw;
        }
      }

      .container-error-massages {
        font-size: 1.5vw;

      }

    }
  }
  
  @media ${Device.desktop} {
    display: flex;
    width: 100%;
    height: 95%;

    .form-login {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      gap: 1vw;
      width: 95%;
      height: 95%;

      .title-form {
        font-size: 2.2vw;
        font-family: Anton-Regular, sans-serif;
        font-weight: 900;

      }

      .container-text-2 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 85%;

        .text-2 {
          width: 50%;
          text-align: center;
          letter-spacing: 0;
          color: #2E304E;
          opacity: 1;
          font-size: 1.5vw;
        }
      }

      .container-input {
        width: 85%;

        input {
          width: 100%;
          font-size: 2vw;
          height: 3vh;
        }

        input::placeholder {
          font-size: 2vw;
        }

        .input-validate-code {
          text-align: center;
        }
      }

      .not-have-account {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 85%;

        .not-have-account-text {
          text-align: center;
          letter-spacing: 0;
          color: #424144;
          opacity: 1;
          font-size: 1.4vw;
          cursor: pointer;
        }
      }

      .recaptcha {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 85%;
      }

      .container-input-check {
        width: 85%;
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 4vw;

        .input-check {
          display: flex;
          align-items: center;
          justify-content: start;
          gap: .5vw;

          input[type="checkbox"] {
            appearance: none;
            background-color: #fff;
            margin: 0;
            font: inherit;
            color: currentColor;
            width: 1.15em;
            height: 1.15em;
            border: .1em solid #AEAEAE;
            border-radius: .30em;
            transform: translateY(-0.075em);
            display: grid;
            place-content: center;
          }

          .form-control + .form-control {
            margin-top: 1em;
          }

          input[type="checkbox"]::before {
            content: "";
            width: 0.66em;
            height: 0.68em;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em var(--form-control-color);
            background-color: #4E4495;
            border-radius: .3vw;
          }

          input[type="checkbox"]:checked::before {
            transform: scale(1);
          }

          label {
            font-size: 1vw;
            text-align: left;
            letter-spacing: 0;
            color: #424144;
            opacity: 1;
          }

        }

        .error-session {
          font-size: 1vw;
          text-align: left;
          letter-spacing: 0;
          color: #424144;
          opacity: 1;
        }

        .register {
          font-size: 1vw;
          text-align: left;
          letter-spacing: 0;
          color: #424144;
          opacity: 1;
          cursor: pointer;
        }
      }

      .container-button {
        justify-content: left;
        margin-top: 2vh;

        .button-form {
          width: 18vw;
          height: 4vw;
          font-size: 2.2vw;

        }
        
        .button-form-waiting {
          font-size: 2.2vw;
        }
      }

      .container-error-massages {
        font-size: 1.5vw;

      }

    }
  }
  
  @media ${Device.laptopL} {
    display: flex;
    width: 100%;
    height: 95%;

    .form-login {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      gap: 1vw;
      width: 95%;
      height: 95%;

      .title-form {
        font-size: 2.2vw;
        font-family: Anton-Regular, sans-serif;
        font-weight: 900;

      }

      .container-text-2 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 85%;

        .text-2 {
          width: 50%;
          text-align: center;
          letter-spacing: 0;
          color: #2E304E;
          opacity: 1;
          font-size: 1.5vw;
        }
      }

      .container-input {
        width: 85%;

        input {
          width: 100%;
          font-size: 2vw;
          height: 3vh;
        }

        input::placeholder {
          font-size: 2vw;
        }

        .input-validate-code {
          text-align: center;
        }
      }

      .not-have-account {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 85%;

        .not-have-account-text {
          text-align: center;
          letter-spacing: 0;
          color: #424144;
          opacity: 1;
          font-size: 1.4vw;
          cursor: pointer;
        }
      }

      .recaptcha {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 85%;
      }

      .container-input-check {
        width: 85%;
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 4vw;

        .input-check {
          display: flex;
          align-items: center;
          justify-content: start;
          gap: .5vw;

          input[type="checkbox"] {
            appearance: none;
            background-color: #fff;
            margin: 0;
            font: inherit;
            color: currentColor;
            width: 1.15em;
            height: 1.15em;
            border: .1em solid #AEAEAE;
            border-radius: .30em;
            transform: translateY(-0.075em);
            display: grid;
            place-content: center;
          }

          .form-control + .form-control {
            margin-top: 1em;
          }

          input[type="checkbox"]::before {
            content: "";
            width: 0.66em;
            height: 0.68em;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em var(--form-control-color);
            background-color: #4E4495;
            border-radius: .3vw;
          }

          input[type="checkbox"]:checked::before {
            transform: scale(1);
          }

          label {
            font-size: 1vw;
            text-align: left;
            letter-spacing: 0;
            color: #424144;
            opacity: 1;
          }

        }

        .error-session {
          font-size: 1vw;
          text-align: left;
          letter-spacing: 0;
          color: #424144;
          opacity: 1;
        }

        .register {
          font-size: 1vw;
          text-align: left;
          letter-spacing: 0;
          color: #424144;
          opacity: 1;
          cursor: pointer;
        }
      }

      .container-button {
        justify-content: left;
        margin-top: 2vh;

        .button-form {
          width: 18vw;
          height: 4vw;
          font-size: 2.2vw;

        }

        .button-form-register {
          background: ${({button_color: button_color}) => button_color};
          border-radius: 37px;
          opacity: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 16vw;
          height: 3.5vw;
          text-align: center;
          letter-spacing: 0;
          color: #FFFFFF;
          font-size: 1.5vw;
          transition: box-shadow 0.3s ease;
          cursor: pointer;
          border: none;
        }


        .button-form-waiting {
          font-size: 2.2vw;
        }
      }

      .container-error-massages {
        font-size: 1.5vw;

      }

    }
  }
  
  @media ${Device.laptop} {
    display: flex;
    width: 100%;
    height: 95%;

    .form-login {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      gap: 1vw;
      width: 95%;
      height: 95%;

      .title-form {
        font-size: 2.5vw;
        font-family: Anton-Regular, sans-serif;
        font-weight: 900;

      }

      .container-text-2 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 85%;

        .text-2 {
          width: 50%;
          text-align: center;
          letter-spacing: 0;
          color: #2E304E;
          opacity: 1;
          font-size: 1.5vw;
        }
      }

      .container-input {
        width: 95%;

        input {
          width: 80%;
          font-size: 1.6vw;
          height: 1.5vh;
        }

        input::placeholder {
          font-size: 1.5vw;
        }

        .input-validate-code {
          text-align: center;
        }
      }

      .not-have-account {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 85%;

        .not-have-account-text {
          text-align: center;
          letter-spacing: 0;
          color: #424144;
          opacity: 1;
          font-size: 1.4vw;
          cursor: pointer;
        }
      }

      .recaptcha {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 85%;
      }

      .container-input-check {
        width: 85%;
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 4vw;

        .input-check {
          display: flex;
          align-items: center;
          justify-content: start;
          gap: .5vw;

          input[type="checkbox"] {
            appearance: none;
            background-color: #fff;
            margin-top: 0.2vw;
            font: inherit;
            color: currentColor;
            width: 1.15em;
            height: 1.15em;
            border: .1em solid #AEAEAE;
            border-radius: .30em;
            transform: translateY(-0.075em);
            display: grid;
            place-content: center;
          }

          .form-control + .form-control {
            margin-top: 1em;
            
          }

          input[type="checkbox"]::before {
            content: "";
            width: 0.66em;
            height: 0.68em;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em var(--form-control-color);
            background-color: #4E4495;
            border-radius: .3vw;
          }

          input[type="checkbox"]:checked::before {
            transform: scale(1);
          }

          label {
            font-size: 1vw;
            text-align: left;
            letter-spacing: 0;
            color: #424144;
            opacity: 1;
            gap: .3vw;
          }

        }

        .error-session {
          font-size: 1vw;
          text-align: left;
          letter-spacing: 0;
          color: #424144;
          opacity: 1;
        }

        .register {
          font-size: 1vw;
          text-align: left;
          letter-spacing: 0;
          color: #424144;
          opacity: 1;
          cursor: pointer;
        }
      }

      .container-button {
        justify-content: left;
        margin-top: 2vh;

        .button-form {
          width: 12vw;
          height: 3.5vw;
          font-size: 1.8vw;

        }

        .button-form-register {
          background: ${({button_color: button_color}) => button_color};
          border-radius: 37px;
          opacity: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 16vw;
          height: 3.5vw;
          text-align: center;
          letter-spacing: 0;
          color: #FFFFFF;
          font-size: 2vw;
          transition: box-shadow 0.3s ease;
          cursor: pointer;
          border: none;
        }


        .button-form-waiting {
          font-size: 1.8vw;
        }
      }

      .container-error-massages {
        font-size: 1.3vw;

      }

    }
  }
  
  @media ${Device.tablet} {
    display: flex;
    width: 100%;
    height: 95%;
    
    .form-login {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      gap: 1vw;
      width: 95%;
      height: 95%;

      .title-form {
        font-size: 3.5vw;
        font-family: Anton-Regular, sans-serif;
        font-weight: 900;
        
      }

      .container-text-2 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 85%;

        .text-2 {
          width: 50%;
          text-align: center;
          letter-spacing: 0;
          color: #2E304E;
          opacity: 1;
          font-size: 1.8vw;
        }
      }

      .container-input {
        width: 85%;

        input {
          width: 100%;
          font-size: 2vw;
          height: 4vh;
        }

        input::placeholder {
          font-size: 2.3vw;
        }

        .input-validate-code {
          text-align: center;
        }
      }

      .not-have-account {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 85%;

        .not-have-account-text {
          text-align: center;
          letter-spacing: 0;
          color: #424144;
          opacity: 1;
          font-size: 1.4vw;
          cursor: pointer;
        }
      }

      .recaptcha {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 85%;
      }

      .container-input-check {
        width: 85%;
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 4vw;

        .input-check {
          display: flex;
          align-items: center;
          justify-content: start;
          gap: .5vw;

          input[type="checkbox"] {
            appearance: none;
            background-color: #fff;
            margin: 0;
            font: inherit;
            color: currentColor;
            width: 1.15em;
            height: 1.15em;
            border: .1em solid #AEAEAE;
            border-radius: .30em;
            transform: translateY(-0.075em);
            display: grid;
            place-content: center;
          }

          .form-control + .form-control {
            margin-top: 1em;
          }

          input[type="checkbox"]::before {
            content: "";
            width: 0.66em;
            height: 0.68em;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em var(--form-control-color);
            background-color: #4E4495;
            border-radius: .3vw;
          }

          input[type="checkbox"]:checked::before {
            transform: scale(1);
          }

          label {
            font-size: 1vw;
            text-align: left;
            letter-spacing: 0;
            color: #424144;
            opacity: 1;
          }

        }

        .error-session {
          font-size: 1vw;
          text-align: left;
          letter-spacing: 0;
          color: #424144;
          opacity: 1;
        }

        .register {
          font-size: 1vw;
          text-align: left;
          letter-spacing: 0;
          color: #424144;
          opacity: 1;
          cursor: pointer;
        }
      }

      .container-button {
        justify-content: left;
        margin-top: 2vh;

        .button-form {
          width: 20vw;
          height: 5vw;
          font-size: 2.7vw;
          
        }
        
        .button-form-register {
          background: ${({button_color: button_color}) => button_color};
          border-radius: 37px;
          opacity: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 16vw;
          height: 3.5vw;
          text-align: center;
          letter-spacing: 0;
          color: #FFFFFF;
          font-size: 2vw;
          transition: box-shadow 0.3s ease;
          cursor: pointer;
          border: none;
        }
        

        .button-form-waiting {
          font-size: 2.8vw;
        }
      }

      .container-error-massages {
        font-size: 1.8vw;
        
      }

    }
  }
  
  @media ${Device.mobileL} {
    display: flex;
    width: 100%;
    height: 95%;

    .form-login {
      align-items: center;
      justify-content: start;
      width: 100%;
      height: 95%;

      .title-form {
        font-size: 4.5vw;
        font-family: Anton-Regular, sans-serif;
        font-weight: 900;
      }

      .container-text-2 {
        width: 90%;

        .text-2 {
          width: 60%;
          font-size: 3.5vw;
        }
      }

      .container-input {
        width: 70%;
        margin-bottom: .5vh;
        

        input {
          width: 95%;
          height: 4vh;
          padding: 1.5vw 0 1.5vw 4.5vw;
          font-size: 5vw;
        }

        input::placeholder {
          font-size: 4vw;
        }

        .input-validate-code {
          text-align: center;
        }
      }

      .not-have-account {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 85%;

        .not-have-account-text {
          text-align: center;
          letter-spacing: 0;
          color: #424144;
          opacity: 1;
          font-size: 1.4vw;
          cursor: pointer;
        }
      }

      .recaptcha {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 85%;
        margin-top: 2vh;
      }

      .container-input-check {
        width: 85%;
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 4vw;
        margin-top: 2vh;

        .input-check {
          display: flex;
          align-items: center;
          justify-content: start;
          gap: 2vw;
          margin-left: 5vh;

          input[type="checkbox"] {
            appearance: none;
            background-color: #fff;
            margin: 0;
            font: inherit;
            color: currentColor;
            width: 1.5em;
            height: 1.5em;
            border: .1em solid #AEAEAE;
            border-radius: .30em;
            transform: translateY(-0.075em);
            display: grid;
            place-content: center;
          }

          .form-control + .form-control {
            margin-top: 1em;
          }

          input[type="checkbox"]::before {
            content: "";
            width: 0.89em;
            height: 0.89em;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em var(--form-control-color);
            background-color: #4E4495;
            border-radius: .3vw;
          }

          input[type="checkbox"]:checked::before {
            transform: scale(1);
          }

          label {
            font-size: 3.5vw;
            text-align: left;
            letter-spacing: 0;
            color: #424144;
            opacity: 1;
          }

        }

        .error-session {
          font-size: 1vw;
          text-align: left;
          letter-spacing: 0;
          color: #424144;
          opacity: 1;
        }

        .register {
          font-size: 1vw;
          text-align: left;
          letter-spacing: 0;
          color: #424144;
          opacity: 1;
          cursor: pointer;
        }
      }

      .container-button {
        margin-bottom: 2vh;
        margin-top: 1vh;

        .button-form {
          width: 68vw;
          height: 6vh;
          font-size: 5.2vw;
          padding: .5vw 0 .5vw 0 ;
          
        }

        .button-form-waiting {
          font-size: 5.2vw;
        }
      }

      .container-error-massages {
        font-size: 3.5vw;
      }

    }

  }
  
  @media ${Device.mobileM} {
    display: flex;
    width: 100%;
    height: 95%;

    .form-login {
      align-items: center;
      justify-content: start;
      width: 100%;
      height: 95%;

      .title-form {
        font-size: 6vw;
        font-family: Anton-Regular, sans-serif;
        font-weight: 900;
      }

      .container-text-2 {
        width: 90%;

        .text-2 {
          width: 60%;
          font-size: 3.5vw;
        }
      }

      .container-input {
        width: 70%;
        margin-bottom: .5vh;

        input {
          width: 95%;
          height: 4vh;
          padding: 1.5vw 0 1.5vw 4.5vw;
          font-size: 5vw;
        }

        input::placeholder {
          font-size: 3.5vw;
        }

        .input-validate-code {
          text-align: center;
        }
      }

      .not-have-account {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 85%;

        .not-have-account-text {
          text-align: center;
          letter-spacing: 0;
          color: #424144;
          opacity: 1;
          font-size: 1.4vw;
          cursor: pointer;
        }
      }

      .recaptcha {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 85%;
        margin-top: 2vh;
      }

      .container-input-check {
        width: 85%;
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 4vw;
        margin-top: 2vh;

        .input-check {
          display: flex;
          align-items: center;
          justify-content: start;
          gap: 2vw;
          margin-left: 8vw;

          input[type="checkbox"] {
            appearance: none;
            background-color: #fff;
            font: inherit;
            color: currentColor;
            width: 1.5em;
            height: 1.5em;
            border: .1em solid #AEAEAE;
            border-radius: .30em;
            transform: translateY(-0.075em);
            display: grid;
            place-content: center;
            
          }

          .form-control + .form-control {
            margin-top: 1em;
          }

          input[type="checkbox"]::before {
            content: "";
            width: 0.89em;
            height: 0.89em;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em var(--form-control-color);
            background-color: #4E4495;
            border-radius: .3vw;
          }

          input[type="checkbox"]:checked::before {
            transform: scale(1);
          }

          label {
            font-size: 3.5vw;
            text-align: left;
            letter-spacing: 0;
            color: #424144;
            opacity: 1;
          }

        }

        .error-session {
          font-size: 1vw;
          text-align: left;
          letter-spacing: 0;
          color: #424144;
          opacity: 1;
        }

        .register {
          font-size: 1vw;
          text-align: left;
          letter-spacing: 0;
          color: #424144;
          opacity: 1;
          cursor: pointer;
        }
      }

      .container-button {
        margin-top: 1%;

        .button-form {
          width: 68vw;
          height: 6vh;
          font-size: 5.2vw;
          padding: .5vw 0 .5vw 0 ;
        }

        .button-form-waiting {
          font-size: 5.2vw;
        }
      }

      .container-error-massages {
        font-size: 3.5vw;
      }

    }

  }

  @media ${Device.mobileS} {
    display: flex;
    width: 100%;
    height: 95%;

    .form-login {
      align-items: center;
      justify-content: start;
      width: 100%;
      height: 95%;

      .title-form {
        font-size: 6vw;
        font-family: Anton-Regular, sans-serif;
        font-weight: 900;
      }

      .container-text-2 {
        width: 90%;

        .text-2 {
          width: 60%;
          font-size: 3.5vw;
        }
      }

      .container-input {
        width: 70%;
        margin-bottom: .5vh;

        input {
          width: 95%;
          height: 4vh;
          padding: 2.4vw 0 2.4vw 4.5vw;
          font-size: 5vw;
        }

        input::placeholder {
          font-size: 4vw;
        }

        .input-validate-code {
          text-align: center;
        }
      }

      .not-have-account {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 85%;

        .not-have-account-text {
          text-align: center;
          letter-spacing: 0;
          color: #424144;
          opacity: 1;
          font-size: 1.4vw;
          cursor: pointer;
        }
      }

      .recaptcha {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 85%;
        margin-top: 2vh;
      }

      .container-input-check {
        width: 85%;
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 4vw;
        margin-top: 2vh;

        .input-check {
          display: flex;
          align-items: center;
          justify-content: start;
          gap: 2vw;
          margin-left: 7vw;
          

          input[type="checkbox"] {
            appearance: none;
            background-color: #fff;
            margin: 0;
            font: inherit;
            color: currentColor;
            width: 1.6em;
            height: 1.6em;
            border: .1em solid #AEAEAE;
            border-radius: .30em;
            transform: translateY(-0.075em);
            display: grid;
            place-content: center;
          }

          .form-control + .form-control {
            margin-top: 1em;
          }

          input[type="checkbox"]::before {
            content: "";
            width: 0.89em;
            height: 0.89em;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em var(--form-control-color);
            background-color: #4E4495;
            border-radius: .3vw;
          }

          input[type="checkbox"]:checked::before {
            transform: scale(1);
          }

          label {
            font-size: 3.5vw;
            text-align: left;
            letter-spacing: 0;
            color: #424144;
            opacity: 1;
          }

        }

        .error-session {
          font-size: 1vw;
          text-align: left;
          letter-spacing: 0;
          color: #424144;
          opacity: 1;
        }

        .register {
          font-size: 1vw;
          text-align: left;
          letter-spacing: 0;
          color: #424144;
          opacity: 1;
          cursor: pointer;
        }
      }

      .container-button {
        margin-top: 4%;

        .button-form {
          width: 68vw;
          height: 5vh;
          font-size: 5.5vw;
        }
        
        .button-form-waiting {
          font-size: 6vw;
        }
      }

      .container-error-massages {
        font-size: 3.2vw;
      }

    }
    
  }
`
