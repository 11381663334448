export function validateImgLogoConf(imgLogo:string, loginColor:string, buttonColor:string, buttonShadow:string, iconCenterLogin:string, iconBackgroundGame:string, iconBackgroundInfo:string, iconBackgroundWinners:string) {
    if (imgLogo === '') {
        return 'La imágen del logo es requerida';
    }
    if (loginColor === '') {
        return 'El fondo del login es requerido';
    }
    if (buttonColor === '') {
        return 'El color del botón es requerido';
    }
    if (buttonShadow === '') {
        return 'El color del sombreado es requerido';
    }
    if (iconCenterLogin === '') {
        return 'El icono central del login es requerido';
    }
    if (iconBackgroundGame === '') {
        return 'El fondo del showSession es requerido';
    }
    if (iconBackgroundInfo === '') {
        return 'El fondo del showInfo es requerido';
    }
    if (iconBackgroundWinners === '') {
        return 'El fondo del showWinners es requerido';
    }
    return '';
}