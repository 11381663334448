import styled from "styled-components";

interface ISessionInfoBingoStylesProps {
    background_info_game: string;
}

export const SessionInfoBingoStyles = styled.div<ISessionInfoBingoStylesProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: ${({background_info_game}) => `url(${background_info_game})`};
    background-repeat: no-repeat;
    background-size: 100%;
    height: 8.3vw;
    margin: 3vw auto 1vw;
    width: 98%;

    .img-opacity {
        //position: relative;
        display: flex;
        align-items: center;
        justify-content: start;
        width: 97%;
        //height: 8.5vw;
        //background-image: url('/img/newImage/img-fondo-info-game-opacity.svg');
        //background-repeat: no-repeat;
        //background-size: cover;

        .container-game-mode {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 10%;
            //height: 100%;
        }

        .info-bingo {
            //position: absolute;
            //bottom: 2vw;
            //left: 13vw;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: .5vw;

            .container-img-game {
                width: 17vw;
                height: 8.3vw;
                display: flex;
                align-items: center;
                justify-content: center;
                /* border-radius: 100%; */
                /* border: 4px solid #FFFFFF; */
                overflow: hidden;

                .image-card-session-big {
                    width: 100%;
                    height: 100%;   
                }
            }

            .container-info-game {
                display: flex;
                flex-direction: column;
                align-items: start;
                justify-content: start;
                margin-left: 2vw;
                //height: 9vw;

                .name-game {
                    font-family: RoundedMplus1c-ExtraBold, sans-serif;
                    font-weight: 100;
                    font-size: 3vw;
                    text-align: left;
                    letter-spacing: -1.52px;
                    color: #FFFFFF;
                    opacity: 1;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    width: 40vw;
                    overflow: hidden;
                    line-height: 1.3;
                    //-webkit-text-stroke: 3px #681BE0;
                }

                .preze-game {
                    font-family: RoundedMplus1c-ExtraBold, sans-serif;
                    font-weight: 100;
                    font-size: 3vw;
                    text-align: left;
                    letter-spacing: -1.49px;
                    color: #FFFFFF;
                    opacity: 1;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    width: 50vw;
                    overflow: hidden;
                    line-height: 1.3;
                    //-webkit-text-stroke: 3px #6571E3;
                }
            }
        }
    }
`