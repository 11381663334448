import {CardBingoStyled} from "./CardBingoStyled";
import {useEffect, useState} from "react";
import {useAppSelector} from "../../../../admin/shared/Utils/Hooks";
import CircularNumberGamer from "../CircularNumberGamer/CircularNumberGamer";

interface ICardBingoProps {
    array1: string[];
    array2: string[];
    array3: string[];
    array4: string[];
    array5: string[];
    serial: string;
    bingo_header: string;
}

export default function CardBingo({
                                      array1,
                                      array2,
                                      array3,
                                      array4,
                                      array5,
                                      serial,
                                      bingo_header,
                                  }: ICardBingoProps): JSX.Element {

    const gameInfo = useAppSelector((state) => state.bingoState.gameInfo);
    const [wordFirst, setWordFirst] = useState('');
    const [wordSecond, setWordSecond] = useState('');
    const [wordThird, setWordThird] = useState('');
    const [wordFourth, setWordFourth] = useState('');
    const [wordFifth, setWordFifth] = useState('');
    const [listBallots, setListBallots] = useState<string[]>([]);

    useEffect(() => {
        const word = bingo_header.toUpperCase();
        console.log(word);
        setWordFirst(word.charAt(0));
        setWordSecond(word.charAt(1));
        setWordThird(word.charAt(2));
        setWordFourth(word.charAt(3));
        setWordFifth(word.charAt(4));
    }, [bingo_header]);
    useEffect(() => {
        if (gameInfo) {
            selectedListBallots(gameInfo.listBallotPlayed);
        }
    }, [gameInfo]);

    function selectedListBallots(list: any[]) {
        const dataList: any[] = [];
        list.map((ballot) => {
            dataList.push(ballot[2]);
        });
        setListBallots(dataList);
    }

    function isValidFormat(content:string) {
        // Utilizando una expresión regular para verificar el formato "*~37~*"
        const regex = /^\*\~\d+\~\*$/;
        return regex.test(content);
    }

    function extractNumberFromFormat(content: string) {
        // Utilizando una expresión regular para extraer el número de un formato "*~37~*"
        const regex = /^\*\~(\d+)\~\*$/;
        const match = content.match(regex);
        return match ? match[1] : content;
    }

    function ballotSelectedGamer(array: any[] | undefined, ballot: string) {
        console.log(array);
        const ballotFormat = extractNumberFromFormat(ballot);
        console.log(ballotFormat);
        if (array) {
            return !array.map((ballotInfo) => ballotInfo[2]).find(element => element === ballotFormat);
        }
        return false;
    }

    useEffect(() => {
        console.log('este es el game info que necesito.................');
        console.log(gameInfo);
        const tales = getModeDescription('any_row');
        console.log(tales);
    }, [gameInfo]);

    function getModeDescription(gameMode: string | undefined): string {
        if (!gameMode) {
            return 'No';
        }
        switch (gameMode) {
            case 'any_row':
            case 'all_card':
            case 'any_col':
            case 'col_1':
            case 'col_2':
            case 'col_3':
            case 'col_4':
            case 'col_5':
                return 'all';
            case 'row_1':
            case 'row_2':
            case 'row_3':
            case 'row_4':
            case 'row_5':
                return gameMode;
            case 'diagonal':
            case 'anti_diagonal':
            case 'shape_X':
            case 'shape_L':
            case 'corners':
                return gameMode;
            default:
                return 'No';
        }
    }

    return (
        <CardBingoStyled>
            <div className="container-serial">
                Serial: {serial}
            </div>
            {getModeDescription('any_row') === 'all' &&
                <div className="container-number-bingo">
                    <span className="number">{wordFirst}</span>
                    <span className="number">{wordSecond}</span>
                    <span className="number">{wordThird}</span>
                    <span className="number">{wordFourth}</span>
                    <span className="number">{wordFifth}</span>
                    {array1.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#FF3A4F" : "transparent"}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array2.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "transparent" : "transparent"}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array3.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "transparent" : "transparent"}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array4.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "transparent" : "transparent"}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array5.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "transparent" : "transparent"}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                </div>
            }
            {getModeDescription('any_row') === 'row_1' &&
                <div className="container-number-bingo">
                    <span className="number">{wordFirst}</span>
                    <span className="number">{wordSecond}</span>
                    <span className="number">{wordThird}</span>
                    <span className="number">{wordFourth}</span>
                    <span className="number">{wordFifth}</span>
                    {array1.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "transparent" : "transparent"}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array2.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "transparent" : "transparent"}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array3.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "transparent" : "transparent"}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array4.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "transparent" : "transparent"}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array5.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "transparent" : "transparent"}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                </div>
            }
            {getModeDescription('any_row') === 'row_2' &&
                <div className="container-number-bingo">
                    <span className="number">{wordFirst}</span>
                    <span className="number">{wordSecond}</span>
                    <span className="number">{wordThird}</span>
                    <span className="number">{wordFourth}</span>
                    <span className="number">{wordFifth}</span>
                    {array1.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "transparent" : "transparent"}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array2.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "transparent" : "transparent"}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array3.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "transparent" : "transparent"}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array4.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "transparent" : "transparent"}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array5.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "transparent" : "transparent"}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                </div>
            }
            {getModeDescription('any_row') === 'row_3' &&
                <div className="container-number-bingo">
                    <span className="number">{wordFirst}</span>
                    <span className="number">{wordSecond}</span>
                    <span className="number">{wordThird}</span>
                    <span className="number">{wordFourth}</span>
                    <span className="number">{wordFifth}</span>
                    {array1.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "transparent" : "transparent"}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array2.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "transparent" : "transparent"}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array3.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "transparent" : "transparent"}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array4.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "transparent" : "transparent"}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array5.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "transparent" : "transparent"}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                </div>
            }
            {getModeDescription('any_row') === 'row_4' &&
                <div className="container-number-bingo">
                    <span className="number">{wordFirst}</span>
                    <span className="number">{wordSecond}</span>
                    <span className="number">{wordThird}</span>
                    <span className="number">{wordFourth}</span>
                    <span className="number">{wordFifth}</span>
                    {array1.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "transparent" : "transparent"}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array2.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "transparent" : "transparent"}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array3.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "transparent" : "transparent"}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array4.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "transparent" : "transparent"}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array5.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "transparent" : "transparent"}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                </div>
            }
            {getModeDescription('any_row') === 'row_5' &&
                <div className="container-number-bingo">
                    <span className="number">{wordFirst}</span>
                    <span className="number">{wordSecond}</span>
                    <span className="number">{wordThird}</span>
                    <span className="number">{wordFourth}</span>
                    <span className="number">{wordFifth}</span>
                    {array1.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "transparent" : "transparent"}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array2.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "transparent" : "transparent"}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array3.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "transparent" : "transparent"}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array4.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "transparent" : "transparent"}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array5.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "transparent" : "transparent"}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                </div>
            }
            {getModeDescription('any_row') === 'diagonal' &&
                <div className="container-number-bingo">
                    <span className="number">{wordFirst}</span>
                    <span className="number">{wordSecond}</span>
                    <span className="number">{wordThird}</span>
                    <span className="number">{wordFourth}</span>
                    <span className="number">{wordFifth}</span>
                    {array1.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                // background={gameInfo && gameInfo?.ballots.map((ballotInfo: any[]) => ballotInfo[2]).find((element: any) => element === extractNumberFromFormat(array1[0])) ? "#FF3A4F" : "transparent"}
                                background={listBallots && index === 0 && listBallots.includes(extractNumberFromFormat(number)) ? "#FF3A4F" : 'transparent'}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array2.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={listBallots && index === 1 && listBallots.includes(extractNumberFromFormat(number)) ? "#FF3A4F" : 'transparent'}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array3.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={listBallots && index === 2 && listBallots.includes(extractNumberFromFormat(number)) ? "#FF3A4F" : 'transparent'}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array4.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={listBallots && index === 3 && listBallots.includes(extractNumberFromFormat(number)) ? "#FF3A4F" : 'transparent'}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array5.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={listBallots && index === 4 && listBallots.includes(extractNumberFromFormat(number)) ? "#FF3A4F" : 'transparent'}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                </div>
            }
            {getModeDescription('any_row') === 'anti_diagonal' &&
                <div className="container-number-bingo">
                    <span className="number">{wordFirst}</span>
                    <span className="number">{wordSecond}</span>
                    <span className="number">{wordThird}</span>
                    <span className="number">{wordFourth}</span>
                    <span className="number">{wordFifth}</span>
                    {array1.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                // background={gameInfo && gameInfo?.ballots.map((ballotInfo: any[]) => ballotInfo[2]).find((element: any) => element === extractNumberFromFormat(array1[0])) ? "#FF3A4F" : "transparent"}
                                background={listBallots && index === 4 && listBallots.includes(extractNumberFromFormat(number)) ? "#FF3A4F" : 'transparent'}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array2.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={listBallots && index === 3 && listBallots.includes(extractNumberFromFormat(number)) ? "#FF3A4F" : 'transparent'}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array3.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={listBallots && index === 2 && listBallots.includes(extractNumberFromFormat(number)) ? "#FF3A4F" : 'transparent'}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array4.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={listBallots && index === 1 && listBallots.includes(extractNumberFromFormat(number)) ? "#FF3A4F" : 'transparent'}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array5.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={listBallots && index === 0 && listBallots.includes(extractNumberFromFormat(number)) ? "#FF3A4F" : 'transparent'}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                </div>
            }
            {getModeDescription('any_row') === 'shape_X' &&
                <div className="container-number-bingo">
                    <span className="number">{wordFirst}</span>
                    <span className="number">{wordSecond}</span>
                    <span className="number">{wordThird}</span>
                    <span className="number">{wordFourth}</span>
                    <span className="number">{wordFifth}</span>
                    {array1.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                // background={gameInfo && gameInfo?.ballots.map((ballotInfo: any[]) => ballotInfo[2]).find((element: any) => element === extractNumberFromFormat(array1[0])) ? "#FF3A4F" : "transparent"}
                                background={(index === 0 || index === 4) && listBallots && listBallots.includes(extractNumberFromFormat(number)) ? "#FF3A4F" : 'transparent'}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array2.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={(index === 1 || index === 3) && listBallots && listBallots.includes(extractNumberFromFormat(number)) ? "#FF3A4F" : 'transparent'}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array3.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={listBallots && index === 2 && listBallots.includes(extractNumberFromFormat(number)) ? "#FF3A4F" : 'transparent'}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array4.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={(index === 1 || index === 3) && listBallots && listBallots.includes(extractNumberFromFormat(number)) ? "#FF3A4F" : 'transparent'}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array5.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={(index === 0 || index === 4) && listBallots && listBallots.includes(extractNumberFromFormat(number)) ? "#FF3A4F" : 'transparent'}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                </div>
            }
            {getModeDescription('any_row') === 'shape_L' &&
                <div className="container-number-bingo">
                    <span className="number">{wordFirst}</span>
                    <span className="number">{wordSecond}</span>
                    <span className="number">{wordThird}</span>
                    <span className="number">{wordFourth}</span>
                    <span className="number">{wordFifth}</span>
                    {array1.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                // background={gameInfo && gameInfo?.ballots.map((ballotInfo: any[]) => ballotInfo[2]).find((element: any) => element === extractNumberFromFormat(array1[0])) ? "#FF3A4F" : "transparent"}
                                background={listBallots && index === 0 && listBallots.includes(extractNumberFromFormat(number)) ? "#FF3A4F" : 'transparent'}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array2.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={listBallots && index === 0 && listBallots.includes(extractNumberFromFormat(number)) ? "#FF3A4F" : 'transparent'}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array3.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={listBallots && index === 0 && listBallots.includes(extractNumberFromFormat(number)) ? "#FF3A4F" : 'transparent'}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array4.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={listBallots && index === 0 && listBallots.includes(extractNumberFromFormat(number)) ? "#FF3A4F" : 'transparent'}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array5.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#FF3A4F" : "transparent"}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                </div>
            }
            {getModeDescription('any_row') === 'corners' &&
                <div className="container-number-bingo">
                    <span className="number">{wordFirst}</span>
                    <span className="number">{wordSecond}</span>
                    <span className="number">{wordThird}</span>
                    <span className="number">{wordFourth}</span>
                    <span className="number">{wordFifth}</span>
                    {array1.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                // background={gameInfo && gameInfo?.ballots.map((ballotInfo: any[]) => ballotInfo[2]).find((element: any) => element === extractNumberFromFormat(array1[0])) ? "#FF3A4F" : "transparent"}
                                background={(index === 0 || index === 4) && listBallots && listBallots.includes(extractNumberFromFormat(number)) ? "#FF3A4F" : 'transparent'}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array2.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={'transparent'}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array3.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={'transparent'}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array4.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={'transparent'}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array5.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={(index === 0 || index === 4) && listBallots && listBallots.includes(extractNumberFromFormat(number)) ? "#FF3A4F" : 'transparent'}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                </div>
            }
            {getModeDescription('any_row') === 'No' &&
                <div className="container-number-bingo">
                    <span className="number">{wordFirst}</span>
                    <span className="number">{wordSecond}</span>
                    <span className="number">{wordThird}</span>
                    <span className="number">{wordFourth}</span>
                    <span className="number">{wordFifth}</span>
                    {array1.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={"transparent"}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array2.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={"transparent"}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array3.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={"transparent"}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array4.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={"transparent"}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                    {array5.map((number, index) => (
                        <div className={`number`} key={index}>
                            <CircularNumberGamer
                                position="unset"
                                width={1.1}
                                height={.9}
                                background={"transparent"}
                                border={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "none" : "none"}
                                color={gameInfo && gameInfo?.listBallotPlayed.map((ballotInfo) => ballotInfo).find((element: any) => element == extractNumberFromFormat(number)) ? "#ffffff" : "#ffffff"}
                                font_size={.65}
                                number_selected={extractNumberFromFormat(number)}
                            />
                        </div>
                    ))}
                </div>
            }
        </CardBingoStyled>
    );
}