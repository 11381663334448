import {HomeStyled} from "./HomeStyled";
import Header from "../../Components/Header/Header";
import {useThemeContext} from "../../../../admin/shared/Hooks/ThemeContextProvider";
import {Redirect, Route, Switch} from "react-router-dom";
import Footer from "../../Components/Footer/Footer";
import HomeSessionsUser from "../../Components/HomeSessionsUser/HomeSessionsUser";
import HomeGamesUser from "../../Components/HomeGamesUser/HomeGamesUser";

export default function Home():JSX.Element {

    const themeActive = useThemeContext();

    return (
        <HomeStyled
            background_game={themeActive.theme ? themeActive.theme.background_game : ''}
        >
            <Header/>
            <Switch>
                <Route path="/user/home" children={<HomeSessionsUser/>}/>
                <Route path="/user/session/:id" children={<HomeGamesUser/>}/>
                <Redirect to="/user/home"/>
            </Switch>
            <Footer/>
        </HomeStyled>
    );
}
