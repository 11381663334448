import styled from "styled-components";
import {Device} from "../../../shared/Utils/devices";

export const HomeGamesUserStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
    margin-top: 2vw;

    .sidebar-content {
        width: 27vw;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        gap: 0.5vw;

        .container-img {
            display: flex;
            width: 100%;
            justify-content: start;
            align-items: start;

            .return-icon {
                width: 1.8vw;
                margin-left: 1vw;
                cursor: pointer;
            }
        }

        .modal-user-session-info {
            position: absolute;
            top: 3vw;
            right: 3vw;
            width: 14vw;
            height: 21vw;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 3px 4px 1px #0000004D;
            border-radius: 25px;
            opacity: 1;
            margin-bottom: 1vw;
            z-index: 999999999999999;
        }

    }

    .game-content {
        width: 73vw;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        gap: 0.5vw;
        margin-bottom: 5vw;

        .container-games-cards {
            display: flex;
            flex-direction: column;
            width: 66vw;
            border: .3vw solid #ffffff;
            border-radius: 1.5vw;

            .container-games-cards-int {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 65.9vw;
                height: 8.4vw;
                border: .2vw solid #ffffff;
                border-radius: 1vw;

                .container-int-background {
                    position: absolute;
                    display: flex;
                    flex-direction: column;
                    width: 64vw;
                    height: 8.3vw;

                    .img-background {
                        position: absolute;
                        top: 0;
                        width: 100%;
                        height: 100%;
                    }

                    .container-info-game {
                        font-size: 2.5vw;
                        font-weight: bold;
                        color: #fff;
                        z-index: 1;
                        margin-left: 1.5vw;
                        margin-bottom: 1.8vw;
                        width: 50vw;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }

                    .container-info-game-mobile {
                        display: none;
                    }
                }

                .container-mode-game {
                    position: absolute;
                    right: 7vw;
                    bottom: 1vw;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 4vw;
                    height: 4vw;
                }
            }

            .container-cards {
                background: #b68ff260;
                width: 64vw;
                height: 34vw;
                margin: 1.5vw auto;
                border-radius: 1vw;
                box-sizing: border-box;
                padding: 1vw 1vw 2vw 2.5vw;
            }
        }

        .container-social-networks {
            display: flex;
            align-items: center;
            justify-content: start;
            width: 100%;
            box-sizing: border-box;
            padding-left: 5vw;
            gap: 2vw;
            display: none;

            .live-img {
                width: 10vw;
            }

            .social-networks {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 1vw;

                .img-telegram-whatsApp {
                    width: 2.5vw;
                }
            }
        }
    }
    
    @media ${Device.tablet} {
        flex-direction: column;

        .sidebar-content {
            width: 90%;
            margin-top: 4vw;

            .container-img {
                display: none;
            }

            .modal-user-session-info {
                position: absolute;
                top: 16vw;
                right: 2vw;
                width: 57vw;
                height: 48vw;
            }
        }

        .game-content {
            width: 85%;
            margin-bottom: 62vw;

            .container-games-cards {
                width: 90vw;
                height: unset;
                border: 1vw solid #ffffff;
                border-radius: 5vw;
                margin-top: -47vw;

                .container-games-cards-int {
                    height: 24vw;
                    border: 1vw solid #ffffff;
                    border-radius: 5vw;
                    right: 1vw;
                    bottom: 1vw;
                    width: 89.9vw;

                    .container-int-background {
                        width: 84vw;
                        height: 24vw;

                        .container-info-game {
                            display: none;
                        }

                        .container-info-game-mobile {
                            display: flex;
                            flex-direction: column;
                            box-sizing: border-box;
                            padding: 1vw 0 0 3vw;
                            z-index: 1;

                            .name-game {
                                font-size: 7vw;
                                font-weight: bold;
                                color: #ffffff;
                            }

                            .prize-game {
                                font-size: 4vw;
                                font-weight: bold;
                                color: #ffffff;
                            }
                        }
                    }

                    .container-mode-game {
                        right: 11vw;
                        bottom: 5vh;
                    }
                }

                .container-cards {
                    height: 83vw;
                    width: 86vw;
                }
            }

            .container-social-networks {
                display: flex;
                align-items: center;
                justify-content: start;
                width: 100%;
                box-sizing: border-box;
                padding-left: 5vw;
                gap: 2vw;
                display: none;

                .live-img {
                    width: 18vw;
                    bottom: 50vh;
                    left: 42vw;
                    position: relative;
                }

                .social-networks {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 1vw;
                    position: absolute;
                    top: 90vh;
                    right: 16vw;

                    .img-telegram-whatsApp {
                        width: 8vw;
                    }
                }
            }
        }
    }

    @media ${Device.mobileL} {
        flex-direction: column;

        .sidebar-content {
            width: 90%;
            margin-top: 4vw;

            .container-img {
                display: none;
            }

            .modal-user-session-info {
                position: absolute;
                top: 16vw;
                right: 2vw;
                width: 57vw;
                height: 48vw;
            }
        }

        .game-content {
            width: 85%;
            margin-bottom: 62vw;

            .container-games-cards {
                width: 90vw;
                height: unset;
                border: 1vw solid #ffffff;
                border-radius: 5vw;
                margin-top: -47vw;

                .container-games-cards-int {
                    height: 24vw;
                    border: 1vw solid #ffffff;
                    border-radius: 5vw;
                    right: 1vw;
                    bottom: 1vw;
                    width: 89.9vw;

                    .container-int-background {
                        width: 84vw;
                        height: 24vw;

                        .container-info-game {
                            display: none;
                        }

                        .container-info-game-mobile {
                            display: flex;
                            flex-direction: column;
                            box-sizing: border-box;
                            padding: 1vw 0 0 3vw;
                            z-index: 1;

                            .name-game {
                                font-size: 7vw;
                                font-weight: bold;
                                color: #ffffff;
                            }

                            .prize-game {
                                font-size: 4vw;
                                font-weight: bold;
                                color: #ffffff;
                            }
                        }
                    }

                    .container-mode-game {
                        right: 11vw;
                        bottom: 5vh;
                    }
                }

                .container-cards {
                    height: 83vw;
                    width: 86vw;
                }
            }

            .container-social-networks {
                display: flex;
                align-items: center;
                justify-content: start;
                width: 100%;
                box-sizing: border-box;
                padding-left: 5vw;
                gap: 2vw;
                display: none;

                .live-img {
                    width: 18vw;
                    bottom: 50vh;
                    left: 42vw;
                    position: relative;
                }

                .social-networks {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 1vw;
                    position: absolute;
                    top: 90vh;
                    right: 16vw;

                    .img-telegram-whatsApp {
                        width: 8vw;
                    }
                }
            }
        }
    }
    @media ${Device.mobileM} {
        flex-direction: column;

        .sidebar-content {
            width: 90%;
            margin-top: 4vw;

            .container-img {
                display: none;
            }

            .modal-user-session-info {
                position: absolute;
                top: 16vw;
                right: 2vw;
                width: 57vw;
                height: 48vw;
            }
        }

        .game-content {
            width: 85%;
            margin-bottom: 62vw;

            .container-games-cards {
                width: 90vw;
                height: unset;
                border: 1vw solid #ffffff;
                border-radius: 5vw;
                margin-top: -47vw;

                .container-games-cards-int {
                    height: 24vw;
                    border: 1vw solid #ffffff;
                    border-radius: 5vw;
                    right: 1vw;
                    bottom: 1vw;
                    width: 89.9vw;

                    .container-int-background {
                        width: 84vw;
                        height: 24vw;

                        .container-info-game {
                            display: none;
                        }

                        .container-info-game-mobile {
                            display: flex;
                            flex-direction: column;
                            box-sizing: border-box;
                            padding: 1vw 0 0 3vw;
                            z-index: 1;

                            .name-game {
                                font-size: 7vw;
                                font-weight: bold;
                                color: #ffffff;
                            }

                            .prize-game {
                                font-size: 4vw;
                                font-weight: bold;
                                color: #ffffff;
                            }
                        }
                    }

                    .container-mode-game {
                        right: 11vw;
                        bottom: 5vh;
                    }
                }

                .container-cards {
                    height: 83vw;
                    width: 86vw;
                }
            }

            .container-social-networks {
                display: flex;
                align-items: center;
                justify-content: start;
                width: 100%;
                box-sizing: border-box;
                padding-left: 5vw;
                gap: 2vw;
                display: none;

                .live-img {
                    width: 18vw;
                    bottom: 50vh;
                    left: 42vw;
                    position: relative;
                }

                .social-networks {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 1vw;
                    position: absolute;
                    top: 90vh;
                    right: 16vw;

                    .img-telegram-whatsApp {
                        width: 8vw;
                    }
                }
            }
        }
    }
    @media ${Device.mobileS} {
        flex-direction: column;

        .sidebar-content {
            width: 90%;
            margin-top: 4vw;

            .container-img {
                display: none;
            }

            .modal-user-session-info {
                position: absolute;
                top: 16vw;
                right: 2vw;
                width: 57vw;
                height: 48vw;
            }
        }

        .game-content {
            width: 85%;
            margin-bottom: 62vw;

            .container-games-cards {
                width: 90vw;
                height: unset;
                border: 1vw solid #ffffff;
                border-radius: 5vw;
                margin-top: -47vw;

                .container-games-cards-int {
                    height: 24vw;
                    border: 1vw solid #ffffff;
                    border-radius: 5vw;
                    right: 1vw;
                    bottom: 1vw;
                    width: 89.9vw;

                    .container-int-background {
                        width: 84vw;
                        height: 24vw;

                        .container-info-game {
                            display: none;
                        }

                        .container-info-game-mobile {
                            display: flex;
                            flex-direction: column;
                            box-sizing: border-box;
                            padding: 1vw 0 0 3vw;
                            z-index: 1;

                            .name-game {
                                font-size: 7vw;
                                font-weight: bold;
                                color: #ffffff;
                            }

                            .prize-game {
                                font-size: 4vw;
                                font-weight: bold;
                                color: #ffffff;
                            }
                        }
                    }

                    .container-mode-game {
                        right: 11vw;
                        bottom: 5vh;
                    }
                }

                .container-cards {
                    height: 83vw;
                    width: 86vw;
                }
            }

            .container-social-networks {
                display: flex;
                align-items: center;
                justify-content: start;
                width: 100%;
                box-sizing: border-box;
                padding-left: 5vw;
                gap: 2vw;
                display: none;

                .live-img {
                    width: 18vw;
                    bottom: 50vh;
                    left: 42vw;
                    position: relative;
                }

                .social-networks {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 1vw;
                    position: absolute;
                    top: 90vh;
                    right: 16vw;

                    .img-telegram-whatsApp {
                        width: 8vw;
                    }
                }
            }
        }
    }

`
