import axios from "axios";
import {
    CLEAN_SEARCH_ALL_USERS,
    CLEAN_USERS_SESSION_REL,
    CLEAN_USERS_WINNERS_EXPORT,
    CREATE_USER,
    CREATE_USER_FAILED,
    CREATE_USER_LOCAL,
    CREATE_USER_LOCAL_FAILED,
    CREATE_USER_LOCAL_SUCCESS,
    CREATE_USER_REL_ACCOUNT_AND_SESSION,
    CREATE_USER_REL_ACCOUNT_AND_SESSION_FAILED,
    CREATE_USER_REL_ACCOUNT_AND_SESSION_SUCCESS,
    CREATE_USER_SUCCESS,
    CREATE_USERS_AND_USER_SESSION_REL,
    CREATE_USERS_AND_USER_SESSION_REL_FAILED,
    CREATE_USERS_AND_USER_SESSION_REL_SUCCESS,
    DELETE_USER,
    DELETE_USER_FAILED,
    DELETE_USER_SUCCESS,
    GET_END_USER_ACTIVE,
    GET_END_USER_ACTIVE_FAILED,
    GET_END_USER_ACTIVE_SUCCESS,
    GET_USERS_REL_SESSION_AND_CARDS,
    GET_USERS_REL_SESSION_AND_CARDS_FAILED,
    GET_USERS_REL_SESSION_AND_CARDS_SUCCESS,
    GET_USERS_SESSION_REL,
    GET_USERS_SESSION_REL_FAILED,
    GET_USERS_SESSION_REL_SUCCESS,
    GET_USERS_WINNERS_EXPORT,
    GET_USERS_WINNERS_EXPORT_FAILED,
    GET_USERS_WINNERS_EXPORT_SUCCESS,
    SEARCH_ALL_USERS,
    SEARCH_ALL_USERS_FAILED,
    SEARCH_ALL_USERS_SUCCESS
} from "../TypesAdmin/UserType";
import {CreateUserModel} from "../../domain/models/CreateUserModel";
import {myDBDomain} from "../../shared/Utils/TestingApi/MyDBDomain";
import {UserActiveModel} from "../../../user/domain/models/UserActiveModel";
import {alertSuccess} from "../../shared/Utils/AlertSuccess";
import {alertError} from "../../shared/Utils/AlertError";
import {UsersWinnersToExportModel} from "../../domain/models/UsersWinnersToExportModel";
import {UsersRelSessionAndCardsModel} from "../../domain/models/UsersRelSessionAndCardsModel";
import {UsersRelSessionAndCardsShowModel} from "../../domain/models/UsersRelSessionAndCardsShowModel";
import {handleGetUsersRelSessionAndCards} from "../../shared/Utils/HandleGetUsersRelSessionAndCards";


export const createUsers = (data: CreateUserModel) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/user/create`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };
    dispatch({
        type: CREATE_USER,
        payload: true
    });
    await axios.request(config)
        .then((response) => {
            console.log(response.data);
            dispatch({
                type: CREATE_USER_SUCCESS,
                payload: response.data.message
            });
        })
        .catch((error) => {
            console.log(error);
            dispatch({
                type: CREATE_USER_FAILED,
                payload: error.response.data.error
            });
        });
}
export const createUserLocal = (data: CreateUserModel) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/user/create-local`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };
    dispatch({
        type: CREATE_USER_LOCAL,
        payload: true
    });
    await axios.request(config)
        .then((response) => {
            console.log(response.data);
            dispatch({
                type: CREATE_USER_LOCAL_SUCCESS,
                payload: response.data.message
            });
        })
        .catch((error) => {
            console.log(error);
            dispatch({
                type: CREATE_USER_LOCAL_FAILED,
                payload: error.response.data.error
            });
        });
}
export const createUserRelAccountAndSession = (dataUser: any) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    console.log(dataUser);
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/user/createUserRelAccountRelSession`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: dataUser
    };
    dispatch({
        type: CREATE_USER_REL_ACCOUNT_AND_SESSION,
        payload: true
    });
    await axios.request(config)
        .then((response) => {
            console.log(response.data);
            dispatch({
                type: CREATE_USER_REL_ACCOUNT_AND_SESSION_SUCCESS,
                payload: response.data.message
            });
            alertSuccess(response.data.message);
        })
        .catch((error) => {
            console.log(error);
            dispatch({
                type: CREATE_USER_REL_ACCOUNT_AND_SESSION_FAILED,
                payload: error.response.data.error
            });
            alertError(error.response.data.error);
        });
}
export const deleteUsers = (id: number) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    let config = {
        method: 'delete',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/user/delete/${id}`,
        headers: {}
    };
    dispatch({
        type: DELETE_USER,
        payload: true
    });
    await axios.request(config)
        .then((response) => {
            console.log(response.data);
            dispatch({
                type: DELETE_USER_SUCCESS,
                payload: response.data.message
            });
        })
        .catch((error) => {
            console.log(error);
            dispatch({
                type: DELETE_USER_FAILED,
                payload: error.response.data.error
            });
        });
}
export const getAllUsersSearch = (searchName: string, account_id: number) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/user/get-search?account_id=${account_id}&searchName=${searchName}`,
        headers: {}
    };
    dispatch({
        type: SEARCH_ALL_USERS,
        payload: true
    });
    await axios.request(config)
        .then((response) => {
            console.log(response.data);
            dispatch({
                type: SEARCH_ALL_USERS_SUCCESS,
                payload: response.data.data
            });
        })
        .catch((error) => {
            console.log(error);
            dispatch({
                type: SEARCH_ALL_USERS_FAILED,
                payload: error.response.data.error
            });
        });
}
export const cleanAllUsersSearch = () => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: CLEAN_SEARCH_ALL_USERS,
        payload: []
    });
}
export const getUsersSessionRel = (searchName: string, session_id: number) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/user/get-search-usersRel?searchName=${searchName}&session_id=${session_id}`,
        headers: {}
    };
    dispatch({
        type: GET_USERS_SESSION_REL,
        payload: true
    });
    await axios.request(config)
        .then((response) => {
            console.log(response.data);
            dispatch({
                type: GET_USERS_SESSION_REL_SUCCESS,
                payload: response.data.data
            });
        })
        .catch((error) => {
            console.log(error);
            dispatch({
                type: GET_USERS_SESSION_REL_FAILED,
                payload: error.response.data.error
            });
        });
}
export const cleanUsersSessionRel = () => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: CLEAN_USERS_SESSION_REL,
        payload: []
    });
}
export const getCreateUsersAndUserSessionRel = (data: any) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/user/create-user-and-user-session-rel`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };
    dispatch({
        type: CREATE_USERS_AND_USER_SESSION_REL,
        payload: true
    });
    await axios.request(config)
        .then((response) => {
            console.log(response.data);
            dispatch({
                type: CREATE_USERS_AND_USER_SESSION_REL_SUCCESS,
                payload: response.data
            });
            // alertSuccess('Se han creado los usuarios con éxito');
        })
        .catch((error) => {
            console.log(error);
            dispatch({
                type: CREATE_USERS_AND_USER_SESSION_REL_FAILED,
                payload: error
            });
            alertError('Hubo un error al crear usuarios');
        });
}
export const getUsersRelSessionAndCards = (session_id: number) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/user/get-user-for-session-id/${session_id}`,
        headers: {
            'Content-Type': 'application/json'
        },
    };
    dispatch({
        type: GET_USERS_REL_SESSION_AND_CARDS,
        payload: true
    });
    await axios.request(config)
        .then((response) => {
            console.log(response.data.data);
            const dataList: UsersRelSessionAndCardsModel[] = response.data.data;
            const newList: UsersRelSessionAndCardsShowModel[] = handleGetUsersRelSessionAndCards(dataList);
            console.log(newList);
            dispatch({
                type: GET_USERS_REL_SESSION_AND_CARDS_SUCCESS,
                payload: newList
            });
        })
        .catch((error) => {
            console.log(error);
            dispatch({
                type: GET_USERS_REL_SESSION_AND_CARDS_FAILED,
                payload: error.response.data.error
            });
        });
}
export const deleteUserSessionRelAndCards = (dataDelete: { session_id: number, user_id: number }) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/user/deleteUserSessionRelAndCards?session_id=${dataDelete.session_id}&user_id=${dataDelete.user_id}`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: dataDelete
    };
    await axios.request(config)
        .then((response) => {
            console.log(response.data.data);
        })
        .catch((error) => {
            console.log(error);
            alertError(error.response.data.error);
        });
}
export const getUsersWinnersExport = (serials: string[], session_id: string, name_game: string, game_mode: string) => async (dispatch: (arg0: { type: string; payload: any;   }) => void) => {
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/player/getPlayerForCardIdAndSessionId?session_id=${session_id}&name_game=${name_game}&game_mode=${game_mode}`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: {serials}
    };
    dispatch({
        type: GET_USERS_WINNERS_EXPORT,
        payload: true
    });
    await axios.request(config)
        .then((response) => {
            const userWinnerToExport: UsersWinnersToExportModel = response.data.data;
            console.log('lista de usuarios para exportar');
            console.log(userWinnerToExport);
            dispatch({
                type: GET_USERS_WINNERS_EXPORT_SUCCESS,
                payload: userWinnerToExport
            });
        })
        .catch((error) => {
            console.log(error);
            alertError(error.response.data.error);
            dispatch({
                type: GET_USERS_WINNERS_EXPORT_FAILED,
                payload: []
            });
        });
}
export const cleanUsersWinnersExport = () => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: CLEAN_USERS_WINNERS_EXPORT,
        payload: []
    });
}


// end user

export const getUserActive = (id: number) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/user/get-end-user-active/${id}`,
        headers: {}
    };
    dispatch({
        type: GET_END_USER_ACTIVE,
        payload: true
    });
    await axios.request(config)
        .then((response) => {
            const userActive: UserActiveModel = response.data.data;
            console.log(userActive);
            dispatch({
                type: GET_END_USER_ACTIVE_SUCCESS,
                payload: userActive
            });
        })
        .catch((error) => {
            console.log(error);
            dispatch({
                type: GET_END_USER_ACTIVE_FAILED,
                payload: error.response.data.error
            });
        });

}