import {TestStyles} from "./TestStyles";
import FormAccount from "./FormAccoun/FormAccount";


export default function Test():JSX.Element {

    return (
        <TestStyles>
            <h1>Crear Cuenta</h1>
            <FormAccount/>
        </TestStyles>
    );
}