import styled from "styled-components";

export const EditSessionStyles = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  
  .container-title {
    display: flex;
    align-items: start;
    justify-content: space-between;
    width: 100%;
    margin-top: 1vw;
    
    .title {
      text-align: center;
      letter-spacing: 0;
      color: #2E304E;
      opacity: 1;
      font-size: 2.1vw;
      font-weight: bold;
      margin-left: 2.5vw;
    }
    .close {
      text-align: center;
      letter-spacing: 0;
      color: #2E304E;
      opacity: 1;
      font-size: 2vw;
      font-weight: bold;
      margin-right: 2vw;
      cursor: pointer;
    }
  }
  .form {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    
    .container-img-inputs {
      display: flex;
      width: 100%;
      height: 40%;
      
      .container-img {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25%;
        height: 100%;
        
        .input-img-small {
          padding: 3vw 1vw;
          cursor: pointer;
          width: 5rem;
          display: none;
        }
        .circle-img {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          border: 2px solid #2E304E;
          width: 45%;
          height: 45%;
          cursor: pointer;
          
          .options-admin-add-image {
            width: 4.2vw;
          }
          
          .error-img {
            display: block;
            position: absolute;
            top: 9vw;
            text-align: center;
            letter-spacing: 0;
            color: red;
            opacity: 1;
            font-size: .7vw;
            font-weight: 500;
            width: 7vw;
          }
        }
        .info-add {
          display: block;
          position: absolute;
          top: 9vw;
          text-align: center;
          letter-spacing: 0;
          color: #606060;
          opacity: 1;
          font-size: .7vw;
          font-weight: 500;
          width: 7vw;
        }
      }
      .container-inputs {
        display: flex;
        flex-direction: column;
        gap: .8vw;
        width: 75%;
        height: 100%;
        margin-top: 3vw;
        
        .container-input {
          display: flex;
          flex-direction: column;
          width: 100%;

          .error {
            color: red;
            font-size: .7vw;
          }
          .input-session-name {
            text-align: left;
            letter-spacing: 0;
            color: #C4C4C4;
            opacity: 1;
            font-size: .9vw;
            width: 90%;
            outline: none;
            border: none;
            border-bottom: 1px solid #C4C4C4;
          }
          .input-session-name::placeholder {
            color: #C4C4C4;
            font-size: .9vw;
          }
        }
        .container-input-row {
          display: flex;
          width: 100%;
          gap: 1.6vw;
          .container-column {
            display: flex;
            flex-direction: column;
            width: 100%;

            .session-limit {
              text-align: left;
              letter-spacing: 0;
              color: #C4C4C4;
              opacity: 1;
              font-size: .9vw;
              width: 80%;
              outline: none;
              border: none;
              border-bottom: 1px solid #C4C4C4;
            }
            .bingo-value {
              text-align: left;
              letter-spacing: 0;
              color: #C4C4C4;
              opacity: 1;
              font-size: .9vw;
              width: 80%;
              outline: none;
              border: none;
              border-bottom: 1px solid #C4C4C4;
            }
            .bingo-value::placeholder {
              color: #C4C4C4;
              font-size: .9vw;
            }
            .card-value {
              text-align: left;
              letter-spacing: 0;
              color: #C4C4C4;
              opacity: 1;
              font-size: .9vw;
              width: 80%;
              outline: none;
              border: none;
              border-bottom: 1px solid #C4C4C4;
            }
            .card-value::placeholder {
              color: #C4C4C4;
              font-size: .9vw;
            }
            .prize-card {
              text-align: left;
              letter-spacing: 0;
              color: #C4C4C4;
              opacity: 1;
              font-size: .9vw;
              width: 80%;
              outline: none;
              border: none;
              border-bottom: 1px solid #C4C4C4;
            }
            .prize-card::placeholder {
              color: #C4C4C4;
              font-size: .9vw;
            }
            .date1 {
              text-align: left;
              letter-spacing: 0;
              color: #C4C4C4;
              opacity: 1;
              font-size: .9vw;
              width: 80%;
              outline: none;
              border: none;
              border-bottom: 1px solid #C4C4C4;
            }
            .date2 {
              text-align: left;
              letter-spacing: 0;
              color: #C4C4C4;
              opacity: 1;
              font-size: .9vw;
              width: 80%;
              outline: none;
              border: none;
              border-bottom: 1px solid #C4C4C4;
            }
          }
          .error {
            color: red;
            font-size: .7vw;
          }
        }
      }
      
    }
    .container-banner-img {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 7vw;
      
      .banner-img {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 3px solid #000000;
        border-radius: 14px;
        opacity: 1;
        width: 90%;
        height: 90%;
        margin-top: 5vw;
        
        .input-img-banner {
          padding: 3vw 1vw;
          cursor: pointer;
          width: 5rem;
          display: none;
        }
        .options-admin-add-image-banner {
          width: 5vw;
          cursor: pointer;
        }
      }
    }
    .container-text-info-input-banner {
      display: flex;
      align-items: center;
      justify-content: end;
      text-align: center;
      letter-spacing: 0;
      color: #606060;
      opacity: 1;
      margin-top: 2.4vw;
      width: 95%;
      font-size: .7vw;
    }
    
    .error-img-banner {
      display: flex;
      align-items: center;
      justify-content: end;
      text-align: center;
      letter-spacing: 0;
      color: red;
      opacity: 1;
      margin-top: 2.4vw;
      width: 95%;
      font-size: .7vw;
    }
    .container-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 2vw;
      
      .button-form {
        background: transparent linear-gradient(90deg, #9545FF 0%, #C491FC 100%) 0% 0% no-repeat padding-box;
        border-radius: 37px;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 17vw;
        height: 3.5vw;
        text-align: center;
        letter-spacing: 0;
        color: #FFFFFF;
        font-size: 2vw;
        transition: box-shadow 0.3s ease;
        cursor: pointer;
        outline: none;
        border: none;
        font-family: Anton, sans-serif;
        font-weight: 400;
      }
      .button-form:hover {
        box-shadow: 0px 0px 10px rgba(149, 69, 255, 0.7), 0px 0px 20px rgba(196, 145, 252, 0.7);
      }
    }
  }
`
