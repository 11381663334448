import styled from "styled-components";
import {Device} from "../../../shared/Utils/devices";

export const WinnersStyled = styled.div`
    margin-top: 1vw;
    
    .container-sup-winner {
        width: 22vw;
        height: 14vw;
        background-image: url("/img/newImage/img-content-sup-winner.svg");
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;

        .container-inf-winner {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: start;
            width: 22vw;
            height: 14vw;
            background-image: url("/img/newImage/img-content-inf-winner.svg");
            background-repeat: no-repeat;
            background-size: cover;

            .header-card-winner {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: .1vw solid #FFFFFF;
                width: 19vw;
                padding-top: 1vw;

                .players {
                    font-family: Alata-Regular, sans-serif;
                    font-weight: 400;
                    font-size: 1vw;
                    text-align: left;
                    letter-spacing: 0;
                    color: #FFFFFF;
                    opacity: 1;
                    width: 7vw;
                }

                .cards {
                    font-family: Alata-Regular, sans-serif;
                    font-weight: 400;
                    font-size: .9vw;
                    text-align: left;
                    letter-spacing: 0;
                    color: #FFFFFF;
                    opacity: 1;
                }

                .winners {
                    font-family: Alata-Regular, sans-serif;
                    font-weight: 400;
                    font-size: .9vw;
                    text-align: left;
                    letter-spacing: 0;
                    color: #FFFFFF;
                    opacity: 1;
                }
            }

            .body-card-winner {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: start;
                gap: .5vw;
                width: 20vw;
                height: 9vw;
                margin-top: .5vw;
                overflow-y: scroll;
                direction: rtl;

                .card-winners {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: start;
                    gap: .5vw;
                    width: 18vw;
                    height: 9vw;
                    margin-top: .5vw;
                    direction: ltr;

                    .content-winner {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 18vw;

                        .name-winner {
                            font-family: RoundedMplus1c-Regular, sans-serif;
                            font-weight: 400;
                            font-size: .9vw;
                            text-align: left;
                            letter-spacing: 0;
                            color: #FFFFFF;
                            opacity: 1;
                            width: 30%;

                        }

                        .container-serial {
                            color: #FFFFFF;
                            font-size: .9vw;
                            font-family: RoundedMplus1c-Regular, sans-serif;
                            font-weight: 400;
                            margin-right: 2vw;
                        }

                        .start-winner {
                            width: 1vw;
                        }
                    }
                }
            }

            .body-card-winner::-webkit-scrollbar {
                width: 0.4vw;
                height: 0.2vw;
            }

            .body-card-winner::-webkit-scrollbar-track {
                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                border-radius: 5vw;
                background: transparent;
                opacity: 1;


            }

            .body-card-winner::-webkit-scrollbar-thumb {
                background-color: #FFFFFF;
                outline: 1px solid slategrey;

            }
        }
    }

    @media ${Device.tablet} {
        position: relative;
        top: 120vw;

        .container-sup-winner {
            width: 78vw;
            height: 50vw;

            .container-inf-winner {
                width: 78vw;
                height: 50vw;

                .header-card-winner {
                    width: 95%;
                    padding: .5vw 1vw;
                    margin-top: 2vw;
                    box-sizing: border-box;

                    .players {
                        font-size: 3.8vw;
                        margin-bottom: 1vw;
                        width: 30%;
                        margin-left: 4vw;

                    }

                    .cards {
                        font-size: 3.8vw;
                        margin-bottom: 1vw;
                    }

                    .winners {
                        font-size: 3.8vw;
                        margin-bottom: 1vw;
                        margin-right: 2vw;
                    }
                }

                .body-card-winner {
                    height: 35vw;
                    width: 70vw;

                    .card-winners {
                        width: 95%;

                        .content-winner {
                            width: 64vw;

                            .name-winner {
                                font-size: 3.5vw;
                            }

                            .container-serial {
                                font-size: 3.5vw;
                                margin-right: 12vw;
                            }

                            .start-winner {
                                width: 3.5vw;
                            }
                        }
                    }
                }

                .body-card-winner::-webkit-scrollbar {
                    width: 0.4vw;
                    height: 0.2vw;
                }

                .body-card-winner::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                    border-radius: 5vw;
                    background: transparent;
                    opacity: 1;


                }

                .body-card-winner::-webkit-scrollbar-thumb {
                    background-color: #FFFFFF;
                }
            }
        }
    }
    
    @media ${Device.mobileL} {
        position: relative;
        top: 120vw;

        .container-sup-winner {
            width: 78vw;
            height: 50vw;

            .container-inf-winner {
                width: 78vw;
                height: 50vw;

                .header-card-winner {
                    width: 95%;
                    padding: .5vw 1vw;
                    margin-top: 2vw;
                    box-sizing: border-box;

                    .players {
                        font-size: 3.8vw;
                        margin-bottom: 1vw;
                        width: 30%;
                        margin-left: 4vw;

                    }

                    .cards {
                        font-size: 3.8vw;
                        margin-bottom: 1vw;
                    }

                    .winners {
                        font-size: 3.8vw;
                        margin-bottom: 1vw;
                        margin-right: 2vw;
                    }
                }

                .body-card-winner {
                    height: 35vw;
                    width: 70vw;

                    .card-winners {
                        width: 95%;

                        .content-winner {
                            width: 64vw;

                            .name-winner {
                                font-size: 3.5vw;
                            }

                            .container-serial {
                                font-size: 3.5vw;
                                margin-right: 12vw;
                            }

                            .start-winner {
                                width: 3.5vw;
                            }
                        }
                    }
                }

                .body-card-winner::-webkit-scrollbar {
                    width: 0.4vw;
                    height: 0.2vw;
                }

                .body-card-winner::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                    border-radius: 5vw;
                    background: transparent;
                    opacity: 1;


                }

                .body-card-winner::-webkit-scrollbar-thumb {
                    background-color: #FFFFFF;
                }
            }
        }
    }
    @media ${Device.mobileM} {
        position: relative;
        top: 120vw;

        .container-sup-winner {
            width: 78vw;
            height: 50vw;

            .container-inf-winner {
                width: 78vw;
                height: 50vw;

                .header-card-winner {
                    width: 95%;
                    padding: .5vw 1vw;
                    margin-top: 2vw;
                    box-sizing: border-box;

                    .players {
                        font-size: 3.8vw;
                        margin-bottom: 1vw;
                        width: 30%;
                        margin-left: 4vw;

                    }

                    .cards {
                        font-size: 3.8vw;
                        margin-bottom: 1vw;
                    }

                    .winners {
                        font-size: 3.8vw;
                        margin-bottom: 1vw;
                        margin-right: 2vw;
                    }
                }

                .body-card-winner {
                    height: 35vw;
                    width: 70vw;

                    .card-winners {
                        width: 95%;

                        .content-winner {
                            width: 64vw;

                            .name-winner {
                                font-size: 3.5vw;
                            }

                            .container-serial {
                                font-size: 3.5vw;
                                margin-right: 12vw;
                            }

                            .start-winner {
                                width: 3.5vw;
                            }
                        }
                    }
                }

                .body-card-winner::-webkit-scrollbar {
                    width: 0.4vw;
                    height: 0.2vw;
                }

                .body-card-winner::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                    border-radius: 5vw;
                    background: transparent;
                    opacity: 1;


                }

                .body-card-winner::-webkit-scrollbar-thumb {
                    background-color: #FFFFFF;
                }
            }
        }
    }
    @media ${Device.mobileS} {
        position: relative;
        top: 120vw;

        .container-sup-winner {
            width: 78vw;
            height: 50vw;

            .container-inf-winner {
                width: 78vw;
                height: 50vw;

                .header-card-winner {
                    width: 95%;
                    padding: .5vw 1vw;
                    margin-top: 2vw;
                    box-sizing: border-box;

                    .players {
                        font-size: 3.8vw;
                        margin-bottom: 1vw;
                        width: 30%;
                        margin-left: 4vw;

                    }

                    .cards {
                        font-size: 3.8vw;
                        margin-bottom: 1vw;
                    }

                    .winners {
                        font-size: 3.8vw;
                        margin-bottom: 1vw;
                        margin-right: 2vw;
                    }
                }

                .body-card-winner {
                    height: 35vw;
                    width: 70vw;

                    .card-winners {
                        width: 95%;

                        .content-winner {
                            width: 64vw;

                            .name-winner {
                                font-size: 3.5vw;
                            }

                            .container-serial {
                                font-size: 3.5vw;
                                margin-right: 12vw;
                            }

                            .start-winner {
                                width: 3.5vw;
                            }
                        }
                    }
                }

                .body-card-winner::-webkit-scrollbar {
                    width: 0.4vw;
                    height: 0.2vw;
                }

                .body-card-winner::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                    border-radius: 5vw;
                    background: transparent;
                    opacity: 1;


                }

                .body-card-winner::-webkit-scrollbar-thumb {
                    background-color: #FFFFFF;
                }
            }
        }
    }
`
