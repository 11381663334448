export function getListBallotPlayed(listBallot: any[]) {
    const newList: string[] = [];
    if (listBallot.length > 0) {
        for (const data of listBallot) {
            newList.push(data.number);
        }
        return newList;
    }else {
        return newList;
    }
}