import styled from "styled-components";

export const CurrentGameDataStyles = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .container-title {
        display: flex;
        align-items: start;
        justify-content: space-between;
        width: 100%;
        margin-top: 1vw;
        margin-bottom: 2vw;

        .title {
            text-align: center;
            letter-spacing: 0;
            color: #2E304E;
            opacity: 1;
            font-size: 2.1vw;
            font-weight: 600;
            margin-left: 2.5vw;
            font-family: Poppins-SemiBold, sans-serif;
        }

        .close {
            text-align: center;
            letter-spacing: 0;
            color: #2E304E;
            opacity: 1;
            font-size: 2vw;
            font-weight: bold;
            margin-right: 2vw;
            cursor: pointer;
        }
    }

    .content-document {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        gap: .1vw;
        width: 85%;
        height: 28vw;
        margin: 0 auto;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 0.5vw; /* Ancho del scrollbar */
        }

        &::-webkit-scrollbar-track {
            background-color: #CCCFDE;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #00000038;
            border-radius: 10px;
            height: 3px;
        }
        
        .container-name-session {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: start;
            gap: .6vw;
            width: 40vw;
            
            .title {
                font-size: 1vw;
                font-weight: bold;
            }
            .data {
                font-size: 1.2vw;
            }
        }
    }
    
    .text-create-user {
        cursor: pointer;
        letter-spacing: 0;
        color: #ffffff;
        opacity: 1;
        font-size: 1.2vw;
        font-family: Poppins-Light, sans-serif;
        font-weight: 300;
        background: #9933ff;
        padding: .2vw .6vw;
        border-radius: .4vw;
        width: 8vw;
        text-align: center;
        margin: 2vw auto 0;
    }
`
