import {NumberModel} from "../../domain/models/NumberModel";

export const dataNumber: NumberModel[] = [
    {
        'number': "00",
        'color': "#D7D7D7",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #D7D7D7",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },

    {
        'number': "01",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "02",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "03",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "04",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "05",
        'color': "#FFFFFF",
        'background': "#FD5000",
        'border': "none",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "06",
        'color': "#FFFFFF",
        'background': "#FD5000",
        'border': "none",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "07",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "08",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "09",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "10",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "11",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "12",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "13",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "14",
        'color': "#FFFFFF",
        'background': "#FD5000",
        'border': "none",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "15",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "16",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "17",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "18",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "19",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "20",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "21",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "22",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "23",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "24",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "25",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "26",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "27",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "28",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "29",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "30",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "31",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "32",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "33",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "34",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "35",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "36",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "37",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "38",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "39",
        'color': "#FFFFFF",
        'background': "#FD5000",
        'border': "none",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "40",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "41",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "42",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "43",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "44",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "45",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "46",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "47",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "48",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "49",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "50",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "51",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "52",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "53",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "54",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "55",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "56",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "57",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "58",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "59",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "60",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "61",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "62",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "63",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "64",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "65",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "66",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "67",
        'color': "#FFFFFF",
        'background': "#FD5000",
        'border': "none",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "68",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "69",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "70",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "71",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "72",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "73",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "74",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "75",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "76",
        'color': "#D7D7D7",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #D7D7D7",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "77",
        'color': "#D7D7D7",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #D7D7D7",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "78",
        'color': "#D7D7D7",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #D7D7D7",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "79",
        'color': "#D7D7D7",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #D7D7D7",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "80",
        'color': "#D7D7D7",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #D7D7D7",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "81",
        'color': "#D7D7D7",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #D7D7D7",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "82",
        'color': "#D7D7D7",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #D7D7D7",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "83",
        'color': "#D7D7D7",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #D7D7D7",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "84",
        'color': "#D7D7D7",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #D7D7D7",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "85",
        'color': "#D7D7D7",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #D7D7D7",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "86",
        'color': "#D7D7D7",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #D7D7D7",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "87",
        'color': "#D7D7D7",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #D7D7D7",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "88",
        'color': "#D7D7D7",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #D7D7D7",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "89",
        'color': "#D7D7D7",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #D7D7D7",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "90",
        'color': "#D7D7D7",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #D7D7D7",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "91",
        'color': "#D7D7D7",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #D7D7D7",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "92",
        'color': "#D7D7D7",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #D7D7D7",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "93",
        'color': "#D7D7D7",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #D7D7D7",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "94",
        'color': "#D7D7D7",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #D7D7D7",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "95",
        'color': "#D7D7D7",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #D7D7D7",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "96",
        'color': "#D7D7D7",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #D7D7D7",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "97",
        'color': "#D7D7D7",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #D7D7D7",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "98",
        'color': "#D7D7D7",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #D7D7D7",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "99",
        'color': "#D7D7D7",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #D7D7D7",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },

];
export const dataNumber25: NumberModel[] = [
    {
        'number': "01",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "02",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "03",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "04",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "05",
        'color': "#FFFFFF",
        'background': "#FD5000",
        'border': "none",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "06",
        'color': "#FFFFFF",
        'background': "#FD5000",
        'border': "none",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "07",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "08",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "09",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "10",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "11",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "12",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "13",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "14",
        'color': "#FFFFFF",
        'background': "#FD5000",
        'border': "none",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "15",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "16",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "17",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "18",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "19",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "20",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "21",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "22",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "23",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "24",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "25",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
];
export const dataNumber50: NumberModel[] = [
    {
        'number': "01",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "02",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "03",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "04",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "05",
        'color': "#FFFFFF",
        'background': "#FD5000",
        'border': "none",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "06",
        'color': "#FFFFFF",
        'background': "#FD5000",
        'border': "none",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "07",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "08",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "09",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "10",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "11",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "12",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "13",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "14",
        'color': "#FFFFFF",
        'background': "#FD5000",
        'border': "none",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "15",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "16",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "17",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "18",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "19",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "20",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "21",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "22",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "23",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "24",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "25",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "26",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "27",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "28",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "29",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "30",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "31",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "32",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "33",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "34",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "35",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "36",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "37",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "38",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "39",
        'color': "#FFFFFF",
        'background': "#FD5000",
        'border': "none",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "40",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "41",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "42",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "43",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "44",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "45",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "46",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "47",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "48",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "49",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "50",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
];
export const dataNumber75: NumberModel[] = [
    {
        'number': "01",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "02",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "03",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "04",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "05",
        'color': "#FFFFFF",
        'background': "#FD5000",
        'border': "none",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "06",
        'color': "#FFFFFF",
        'background': "#FD5000",
        'border': "none",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "07",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "08",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "09",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "10",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "11",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "12",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "13",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "14",
        'color': "#FFFFFF",
        'background': "#FD5000",
        'border': "none",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "15",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "16",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "17",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "18",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "19",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "20",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "21",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "22",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "23",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "24",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "25",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "26",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "27",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "28",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "29",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "30",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "31",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "32",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "33",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "34",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "35",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "36",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "37",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "38",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "39",
        'color': "#FFFFFF",
        'background': "#FD5000",
        'border': "none",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "40",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "41",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "42",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "43",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "44",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "45",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "46",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "47",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "48",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "49",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "50",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "51",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "52",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "53",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "54",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "55",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "56",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "57",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "58",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "59",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "60",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "61",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "62",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "63",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "64",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "65",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "66",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "67",
        'color': "#FFFFFF",
        'background': "#FD5000",
        'border': "none",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "68",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "69",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "70",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "71",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "72",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "73",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "74",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "75",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
];
export const dataNumber95: NumberModel[] = [
    {
        'number': "01",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "02",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "03",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "04",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "05",
        'color': "#FFFFFF",
        'background': "#FD5000",
        'border': "none",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "06",
        'color': "#FFFFFF",
        'background': "#FD5000",
        'border': "none",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "07",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "08",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "09",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "10",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "11",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "12",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "13",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "14",
        'color': "#FFFFFF",
        'background': "#FD5000",
        'border': "none",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "15",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "16",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "17",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "18",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "19",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "20",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "21",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "22",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "23",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "24",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "25",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "26",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "27",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "28",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "29",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "30",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "31",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "32",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "33",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "34",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "35",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "36",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "37",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "38",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "39",
        'color': "#FFFFFF",
        'background': "#FD5000",
        'border': "none",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "40",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "41",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "42",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "43",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "44",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "45",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "46",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "47",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "48",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "49",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "50",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "51",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "52",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "53",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "54",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "55",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "56",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "57",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "58",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "59",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "60",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "61",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "62",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "63",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "64",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "65",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "66",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "67",
        'color': "#FFFFFF",
        'background': "#FD5000",
        'border': "none",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "68",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "69",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "70",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "71",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "72",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "73",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "74",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "75",
        'color': "#878787",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #878787",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "76",
        'color': "#D7D7D7",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #D7D7D7",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "77",
        'color': "#D7D7D7",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #D7D7D7",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "78",
        'color': "#D7D7D7",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #D7D7D7",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "79",
        'color': "#D7D7D7",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #D7D7D7",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "80",
        'color': "#D7D7D7",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #D7D7D7",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "81",
        'color': "#D7D7D7",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #D7D7D7",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "82",
        'color': "#D7D7D7",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #D7D7D7",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "83",
        'color': "#D7D7D7",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #D7D7D7",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "84",
        'color': "#D7D7D7",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #D7D7D7",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "85",
        'color': "#D7D7D7",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #D7D7D7",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "86",
        'color': "#D7D7D7",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #D7D7D7",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "87",
        'color': "#D7D7D7",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #D7D7D7",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "88",
        'color': "#D7D7D7",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #D7D7D7",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "89",
        'color': "#D7D7D7",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #D7D7D7",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "90",
        'color': "#D7D7D7",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #D7D7D7",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "91",
        'color': "#D7D7D7",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #D7D7D7",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "92",
        'color': "#D7D7D7",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #D7D7D7",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "93",
        'color': "#D7D7D7",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #D7D7D7",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "94",
        'color': "#D7D7D7",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #D7D7D7",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
    {
        'number': "95",
        'color': "#D7D7D7",
        'background': "transparent",
        'border': " 0.4817756414413452px solid #D7D7D7",
        'dataInfo': {
            "type": "Aleatorio",
            "hour": "20/08/23 15:35",
            "user": "Daniel Robayo"
        }
    },
];
