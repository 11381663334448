import {UsersSessionStyles} from "./UsersSessionStyles";
import {useAppDispatch, useAppSelector} from "../../../shared/Utils/Hooks";
import React, {useEffect, useState} from "react";
import {
    openAndCloseModalUsersRelSessionAndCards,
    openAndCloseModalUsersSession
} from "../../../store/ActionsAdmin/ModalsActions";
import {
    cleanAllUsersSearch,
    cleanUsersSessionRel, deleteUserSessionRelAndCards,
    getAllUsersSearch, getUsersRelSessionAndCards,
    getUsersSessionRel
} from "../../../store/ActionsAdmin/UserActions";
import {alertInfo} from "../../../shared/Utils/AlertInfo";
import Loading from "../Loading/Loading";
import {getSingleSessionDatabase} from "../../../store/ActionsAdmin/SessionActions";
import {getListOfList} from "../../../shared/Utils/GetListOfList";
import {LuLoader} from "react-icons/lu";

export default function UsersSession(): JSX.Element {

    let timeoutUserSessionRel: string | number | NodeJS.Timeout | undefined;
    const allUsersSessionRelSearch = useAppSelector((state) => state.userState.allUsersSessionRelSearch);
    const isLoadingAllUsersSessionRelSearch = useAppSelector((state) => state.userState.isLoadingAllUsersSessionRelSearch);
    const dispatch = useAppDispatch();
    const singleSessionDatabase = useAppSelector((state) => state.sessionState.singleSessionDatabase);
    const isLoadingUsersRelSessionAndCards = useAppSelector((state) => state.userState.isLoadingUsersRelSessionAndCards);
    const [listCheckSelected, setListCheckSelected] = useState<number[]>([]);
    const [checkAllUsers, setCheckAllUsers] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [isLoadingDeleteUserRelSession, setIsLoadingDeleteUserRelSession] = useState<boolean>(false);
    const [counterUserDelete, setCounterUserDelete] = useState<number>(0);
    const [wordSearchUserSessionRel, setWordSearchUserSessionRel] = useState<string>('');

    function handleCloseModal() {
        dispatch(openAndCloseModalUsersSession(false));
    }

    function handleOpenModalUsersRelSessionAndCards() {
        if (singleSessionDatabase) {
            dispatch(getUsersRelSessionAndCards(singleSessionDatabase.id)).then(() => {
                dispatch(openAndCloseModalUsersRelSessionAndCards(true));
            });
        }

    }

    function handleCheckSelected(checked: boolean, id: number) {
        if (checked) {
            setListCheckSelected([...listCheckSelected, id]);
            setError('');
        } else {
            const newListCheckSelected = listCheckSelected.filter(user_id => user_id !== id);
            setListCheckSelected(newListCheckSelected);
        }
    }

    useEffect(() => {
        console.log(listCheckSelected);
    }, [listCheckSelected]);

    function handleDeleteUserRelSessionAndCards() {
        if (singleSessionDatabase && listCheckSelected.length > 0) {
            setIsLoadingDeleteUserRelSession(true);
            const listOfListIds = getListOfList(listCheckSelected, 1);
            console.log('lista de listas para eliminar user rel session');
            console.log(listOfListIds);
            let index = 0;
            const deleteNextUserRelSessionAndCards = () => {
                if (index < listOfListIds.length) {
                    const listTenIds = listCheckSelected[index];
                    const dataDelete = {
                        session_id: singleSessionDatabase.id,
                        user_id: listTenIds
                    };
                    console.log('objeto para eliminar user rel session');
                    console.log(dataDelete)
                    dispatch(deleteUserSessionRelAndCards(dataDelete)).then(() => {
                        index++;
                        setError('Usuarios eliminados con éxito');
                        setCounterUserDelete((value) => value + 1);
                        setTimeout(deleteNextUserRelSessionAndCards, 2000);
                    });
                } else {
                    dispatch(getSingleSessionDatabase(singleSessionDatabase.id)).then(() => {
                        setListCheckSelected([]);
                        setCheckAllUsers(false);
                        setIsLoadingDeleteUserRelSession(false);
                    });
                }
            };
            deleteNextUserRelSessionAndCards();
        } else {
            setError('Debes seleccionar un usuario para eliminar...');
        }
    }

    function handleCheckAllUsers(checked: boolean) {
        setCheckAllUsers(checked);
        console.log(checked);
        if (checked) {
            if (singleSessionDatabase) {
                singleSessionDatabase.users.map((user) => {
                    setListCheckSelected((prevId) => [...prevId, user.id]);
                });
            }
        } else {
            setListCheckSelected([]);
        }
    }

    function handleSearchUsersSessionRel(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (singleSessionDatabase) {
            if (wordSearchUserSessionRel !== '') {
                dispatch(getUsersSessionRel(wordSearchUserSessionRel, singleSessionDatabase.id)).then(() => {
                });
            }
        }
    }

    function handleSetWordSearchUserSessionRel(valueSearch: string) {
        console.log(valueSearch);
        if (valueSearch !== '') {
            setWordSearchUserSessionRel(valueSearch);
        } else {
            setWordSearchUserSessionRel(valueSearch);
            dispatch(cleanUsersSessionRel());
        }
    }

    function handleCleanInputSearch() {
        setWordSearchUserSessionRel('');
        dispatch(cleanUsersSessionRel());
    }

    return (
        <UsersSessionStyles>
            <div className="container-title">
                <span className="title">Relacion Usuarios</span>
                <button
                    disabled={isLoadingUsersRelSessionAndCards}
                    onClick={handleOpenModalUsersRelSessionAndCards}
                    className="text-see-users"
                >
                    {!isLoadingUsersRelSessionAndCards ? 'Ver usuarios' : 'Cagando...'}
                </button>
                <span onClick={handleCloseModal} className="close">x</span>
            </div>
            <form onSubmit={(event) => handleSearchUsersSessionRel(event)} className="container-search">
                <input
                    type="search"
                    className="search-users"
                    placeholder="Buscar usuarios"
                    value={wordSearchUserSessionRel}
                    onChange={(event) => handleSetWordSearchUserSessionRel(event.target.value)}
                />
                {allUsersSessionRelSearch.length === 0
                    ?
                    <button className="text-create-user" type="submit">
                        {isLoadingAllUsersSessionRelSearch
                            ?
                            <LuLoader className="icon-loading"/>
                            :
                            'Buscar'
                        }
                    </button>
                    :
                    <button
                        className="text-create-user"
                        type="button"
                        onClick={handleCleanInputSearch}
                    >
                        Limpiar
                    </button>
                }
            </form>
            <div className="container-title-data">
                <div className="container-name-checkBox">
                    <input
                        checked={checkAllUsers}
                        type="checkbox"
                        onChange={(event) => handleCheckAllUsers(event.target.checked)}
                    />
                    <span className="text-name">Nombre</span>
                </div>
                <span onClick={handleDeleteUserRelSessionAndCards} className="text-create-user">Eliminar usuarios</span>
                <span className="text-phone">Celular</span>
            </div>
            {allUsersSessionRelSearch.length === 0
                ?
                <div className="container-scroll">
                    {singleSessionDatabase && singleSessionDatabase.users.map((user) => (
                        <div key={user.id} className="container-data">
                            <div className="container-user-checkBox">
                                <input
                                    type="checkbox"
                                    checked={listCheckSelected.includes(user.id)}
                                    onChange={(event) => handleCheckSelected(event.target.checked, user.id)}
                                />
                                <span className="name">{user.name}</span>
                            </div>
                            <span className="phone">{user.phone}</span>
                        </div>
                    ))}
                </div> :
                <div className="container-scroll">
                    {allUsersSessionRelSearch.map((user) => (
                        <div key={user.id} className="container-data">
                            <span className="name">{user.name}</span>
                            <span className="phone">{user.phone}</span>
                        </div>
                    ))}
                </div>
            }
            <span className="error">{`${error} ${counterUserDelete}`}</span>
            {isLoadingDeleteUserRelSession &&
                <Loading/>
            }
        </UsersSessionStyles>
    );
}
