import {ConfirmLoginOTPStyles} from "./ConfirmLoginOTPStyles"
import React, {useState} from "react";
import {themeDefault} from "../../../shared/Utils/ThemeDefault";
import {useForm} from "react-hook-form";
import {useAppDispatch, useAppSelector} from "../../../shared/Utils/Hooks";
import {history} from "../../../../../App";
import {alertError} from "../../../shared/Utils/AlertError";
import {
    openAndCloseModalConfirmOTP,
    openAndCloseModalRegister,
    openAndCloseModalSignIn
} from "../../../store/ActionsAdmin/ModalsActions";
import {getNumberPhoneLogin} from "../../../store/ActionsAdmin/AuthUserActions";
import {validateCodeVerification} from "../../../shared/Utils/ValidateCodeVerification";
import {getSessionStorageObj} from "../../../shared/Utils/GetSessionStorageObject";

export interface confirmLoginModel {
    code: string;
}

export default function ConfirmLoginOTP(): JSX.Element {

    const {register, handleSubmit,  setValue, formState:{errors}} = useForm<confirmLoginModel>();
    const dispatch = useAppDispatch();
    const confirmationResultFirebase = useAppSelector((state) => state.authUserState.confirmationResultFirebase);
    const [isLoadingConfirmOTP, setIsLoadingConfirmOTP] = useState<boolean>(false);

    async function handleConfirmOTM(data: confirmLoginModel) {
        console.log(data);
        console.log(confirmationResultFirebase);
        setIsLoadingConfirmOTP(true);
        if (confirmationResultFirebase) {
            try {
                const res = await confirmationResultFirebase.confirm(data.code);
                const user = getSessionStorageObj('user');
                if (user.user_type === 'admin') {
                    setIsLoadingConfirmOTP(false);
                    dispatch(openAndCloseModalSignIn(true));
                    dispatch(openAndCloseModalConfirmOTP(false));
                    dispatch(openAndCloseModalRegister(false));
                    dispatch(getNumberPhoneLogin(''));
                    history.push("/admin/options");
                    return;
                }
                if (user.user_type === 'user') {
                    setIsLoadingConfirmOTP(false);
                    dispatch(openAndCloseModalSignIn(true));
                    dispatch(openAndCloseModalConfirmOTP(false));
                    dispatch(openAndCloseModalRegister(false));
                    dispatch(getNumberPhoneLogin(''));
                    // window.location.href = "https://web.whatsapp.com/send?phone=3138102286";
                    history.push("/user");
                    return;
                }
                console.log(res);
            } catch (error) {
                console.error(error);
                alertError('Código inválido');
                setIsLoadingConfirmOTP(false);
            }
        }
    }

    const submitFormConfirmOTM = (data: confirmLoginModel) => handleConfirmOTM(data);


    return (
        <ConfirmLoginOTPStyles
            button_color={themeDefault.button_color}
            button_color_shadow={themeDefault.button_color_shadow}
        >
            <form onSubmit={handleSubmit(submitFormConfirmOTM)} className="form-login">
                <div className="title-form">
                    Inicia sesión
                </div>
                <div className="container-text-2">
                        <span className="text-2">
                            Ingresa el código que recibiste
                            como mensaje de texto:
                        </span>
                </div>
                <div className="container-input">
                    <input
                        placeholder="Codigo verificacion"
                        className="input-validate-code"
                        maxLength={10}
                        type="text"
                        {...register('code', {
                            validate: validateCodeVerification
                        })}
                    />
                </div>
                {errors.code?.type === 'validate' &&
                    <div className="container-error-otp">
                        {errors.code.message}
                    </div>
                }
                <div className="container-button">
                    <button
                        disabled={isLoadingConfirmOTP}
                        type="submit"
                        className="button-form"
                    >
                        {!isLoadingConfirmOTP ? 'Entrar' : 'Cargando...'}

                    </button>
                </div>
            </form>
        </ConfirmLoginOTPStyles>
    );
}
