import {EditSessionStyles} from "./EditSessionStyles";
import {useForm} from "react-hook-form";
import {CreateSessionModel} from "../../../domain/models/CreateSessionModel";
import {useAppDispatch, useAppSelector} from "../../../shared/Utils/Hooks";
import {useEffect, useRef, useState} from "react";
import {closeCreateSession, closeEditSession} from "../../../store/ActionsAdmin/ModalsActions";
import {EditSessionModel} from "../../../domain/models/EditSessionModel";
import {
    editSession,
    getAllSessionsActive,
    getAllSessionsInactive,
    getSingleSessionDatabase
} from "../../../store/ActionsAdmin/SessionActions";
import {alertSuccess} from "../../../shared/Utils/AlertSuccess";
import {bingoNameValidate} from "../../../shared/Utils/ValidationformCreateBingo";
import {
    accumulatedValue,
    cardLimit,
    cardPrice, dateEditSession, getTodayDate, sessionDescription,
    sessionLimit,
    sessionName, ValidateCurrentDate
} from "../../../shared/Utils/ValidationformCreateSession";
import {handleDateNow} from "../../../shared/Utils/DateNow";
import {uploadFile} from "../../../../../Firebase/Firebase";
import {alertError} from "../../../shared/Utils/AlertError";

export default function EditSession(): JSX.Element {

    const dispatch = useAppDispatch();
    const {register, handleSubmit, reset, formState: {errors}} = useForm<CreateSessionModel>();
    const singleSessionDatabase = useAppSelector((state) => state.sessionState.singleSessionDatabase);
    const domainActive = useAppSelector((state) => state.accountState.domainActive);
    const EditingSession = useAppSelector((state) => state.sessionState.EditingSession);
    const offsetSessionInactive = useAppSelector((state) => state.sessionState.offsetSessionInactive);
    const offsetSessionActive = useAppSelector((state) => state.sessionState.offsetSessionActive);
    const fileInputImgSmall = useRef<HTMLInputElement | null>(null);
    const fileInputImgBanner = useRef<HTMLInputElement | null>(null);
    const [img, setImg] = useState<string|undefined>(singleSessionDatabase?.main_image);
    const [imgBanner, setImgBanner] = useState<string|undefined>(singleSessionDatabase?.banner_image);
    const [errorImgEditSession, setErrorImgEditSession] = useState('');
    const [errorImgBannerEditSession, setErrorImgBannerEditSession] = useState('');

    function handleEditSession(data: CreateSessionModel) {
        console.log(data);
        if (img === null) {
            setErrorImgEditSession('Imagen es requerida');
            return;
        }

        if (imgBanner === null) {
            setErrorImgBannerEditSession('Imagen es requerida');
            return;
        }
        if (singleSessionDatabase && img && imgBanner) {
            const dataEdit: EditSessionModel = {
                scheduled_date: data.scheduled_date,
                main_image: img,
                banner_image: imgBanner,
            }
            if (domainActive) {
                dispatch(editSession(dataEdit, singleSessionDatabase.id)).then(r => {
                    dispatch(getSingleSessionDatabase(singleSessionDatabase.id)).then(() => {
                        dispatch(getAllSessionsActive(domainActive.id, offsetSessionActive)).then(() => {});
                        dispatch(getAllSessionsInactive(domainActive.id, offsetSessionInactive)).then(() => {});
                        alertSuccess('Sesión editada con éxito');
                        reset();
                        dispatch(closeEditSession());
                    });
                });
            }
        }
    }

    const onSubmit = (data: CreateSessionModel) => handleEditSession(data);

    function handleClickImgSmall() {
        if (fileInputImgSmall.current) {
            fileInputImgSmall.current?.click()
        }
    }
    function handleClickImgBanner() {
        if (fileInputImgBanner.current) {
            fileInputImgBanner.current?.click()
        }
    }

    async function handleMainImageEdit(e:any) {
        console.log(e.target.files[0].size);
        if (e.target.files[0].size < 100000) {
            const fileMinImage = e.target.files[0];
            console.log(fileMinImage);
            if (fileMinImage && img) {
                const fileFirebase = await uploadFile(fileMinImage, img);
                console.log(fileFirebase);
                setErrorImgEditSession('');
                setImg(fileFirebase);
            }
        } else {
            setErrorImgEditSession('La imagen no puede superar los 100K');
        }
    }

    useEffect(() => {
        console.log(img)
    },[img]);

    async function handleBannerImageEdit(e:any) {
        console.log(e.target.files[0].size);
        if (e.target.files[0].size < 300000) {
            const fileBannerImage = e.target.files[0];
            console.log(fileBannerImage);
            if (fileBannerImage && imgBanner) {
                const fileFirebase = await uploadFile(fileBannerImage, imgBanner);
                setErrorImgBannerEditSession('');
                setImgBanner(fileFirebase);
            }
        }else {
            setErrorImgBannerEditSession('La imagen no puede superar los 300K');
        }
    }

    function handleCloseModal() {
        dispatch(closeEditSession());
    }


    return (
        <EditSessionStyles>
            <div className="container-title">
                <span className="title">Editar Sesión</span>
                <span onClick={handleCloseModal} className="close">x</span>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="form">
                <div className="container-img-inputs">
                    <div className="container-img">
                        <input
                            ref={fileInputImgSmall}
                            type="file"
                            accept="image/*"
                            className="input-img-small"
                            onChange={handleMainImageEdit}
                        />
                        <div onClick={handleClickImgSmall} className="circle-img">
                            <img
                                className="options-admin-add-image"
                                alt="optionsAdminAddImage"
                                src={img ? img : ''}
                            />
                        </div>
                        {errorImgEditSession !== '' &&
                            <span className="error-img">{errorImgEditSession}</span>
                        }
                        {errorImgEditSession === '' &&
                            <span className="info-add">Agregar/editar miniatura</span>
                        }
                    </div>
                    <div className="container-inputs">
                        <div className="container-input">
                            <input
                                className="input-session-name"
                                type="text"
                                disabled={true}
                                placeholder="Campo no editable"
                            />
                            {/*{errors.session_name?.type === 'validate' && <span className='error'>{errors.session_name.message}</span>}*/}
                        </div>
                        <div className="container-input-row">
                            <div className="container-column">
                                <select
                                    // defaultValue={singleSessionDatabase?.bingo_limit}
                                    className="session-limit"
                                    disabled={true}
                                >
                                    <option value=''>Campo no editable</option>
                                    <option value={singleSessionDatabase?.bingo_limit}>{singleSessionDatabase?.bingo_limit}</option>

                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="75">75</option>
                                    <option value="99">99</option>
                                </select>
                                {/*{errors.bingo_limit?.type === 'validate' && <span className='error'>{errors.bingo_limit.message}</span>}*/}
                            </div>
                            <div className="container-column">
                                <input
                                    className="bingo-value"
                                    type="text"
                                    disabled={true}
                                    placeholder="Campo no editable"
                                />
                                {/*{errors.card_price?.type === 'validate' && <span className='error'>{errors.card_price.message}</span>}*/}
                            </div>
                        </div>
                        <div className="container-input-row">
                            <div className="container-column">
                                <input
                                    className="card-value"
                                    type="text"
                                    disabled={true}
                                    placeholder="Campo no editable"
                                />
                                {/*{errors.card_limit?.type === 'validate' && <span className='error'>{errors.card_limit.message}</span>}*/}
                            </div>
                            <div className="container-column">
                                <input
                                    className="prize-card"
                                    type="text"
                                    disabled={true}
                                    placeholder="Campo no editable"
                                />
                                {/*{errors.prize_pool?.type === 'validate' && <span className='error'>{errors.prize_pool.message}</span>}*/}
                            </div>
                        </div>
                        <div className="container-input-row">
                            <div className="container-column">
                                <input
                                    className="card-value"
                                    type="text"
                                    disabled={true}
                                    placeholder="Campo no editable"
                                />
                                {/*{errors.session_info?.type === 'validate' && <span className='error'>{errors.session_info.message}</span>}*/}
                            </div>
                            <div className="container-column">
                                <input
                                    {...register('scheduled_date', {
                                        validate: dateEditSession
                                    })}
                                    defaultValue={singleSessionDatabase?.scheduled_date}
                                    className="date2"
                                    type="date"
                                    placeholder="Editar valor del Bingo"
                                />
                                {errors.scheduled_date?.type === 'validate' && <span className='error'>{errors.scheduled_date.message}</span>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-banner-img">
                    <div className="banner-img">
                        <input
                            ref={fileInputImgBanner}
                            type="file"
                            accept="image/*"
                            className="input-img-banner"
                            onChange={handleBannerImageEdit}
                        />
                        <img
                            onClick={handleClickImgBanner}
                            className="options-admin-add-image-banner"
                            alt="optionsAdminAddImage"
                            src={imgBanner}
                        />
                    </div>
                </div>
                <div className="container-text-info-input-banner">
                    {errorImgBannerEditSession !== '' &&
                        <span className="error-img-banner">{errorImgBannerEditSession}</span>
                    }
                    {errorImgBannerEditSession === '' &&
                        <span className="info-add">Agregar/editar miniatura</span>
                    }
                </div>
                <div className="container-button">
                    <button disabled={EditingSession} type="submit" className="button-form">{!EditingSession ? 'Editar Sesión' : 'Editando...'}</button>
                </div>
            </form>
        </EditSessionStyles>
    );
}
