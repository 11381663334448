import {LoginStyles} from "./LoginStyles";
import {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../../shared/Utils/Hooks";
import {getAccountActive} from "../../../store/ActionsAdmin/AccountActions";
import {themeDefault} from "../../../shared/Utils/ThemeDefault";
import SignIn from "../../Components/SignIn/SignIn";
import RegisterUser from "../../Components/RegisterUser/RegisterUser";
import ConfirmLoginOTP from "../../Components/ConfirmLoginOTP/ConfirmLoginOTP";
import {useThemeContext} from "../../../shared/Hooks/ThemeContextProvider";
import {getLocalStorageObj} from "../../../shared/Utils/GetLocalStorageObj";
import {ThemeDefaultModel} from "../../../domain/models/ThemeDefaultModel";



export default function Login(): JSX.Element {

    const themeActive = useThemeContext();
    const dispatch = useAppDispatch();
    const domain = window.location.origin;
    const domainActive = useAppSelector((state) => state.accountState.domainActive);
    const showModalSignIn = useAppSelector((state) => state.modalState.showModalSignIn);
    const showModalConfirmOTP = useAppSelector((state) => state.modalState.showModalConfirmOTP);
    const showModalRegister = useAppSelector((state) => state.modalState.showModalRegister);

    useEffect(() => {
        dispatch(getAccountActive({domain: domain})).then(r => {});
    }, [domain]);

    useEffect(() => {
        const themeSelect: ThemeDefaultModel | null = getLocalStorageObj('theme');
        if (themeSelect === null) {
            themeActive.toggleTheme(themeDefault);
        } else {
            themeActive.toggleTheme(themeSelect);
        }
    }, [domainActive]);

    return (
        <LoginStyles
            backgroundLogin={themeActive.theme ? themeActive.theme.background_login : ''}
            buttonColor={themeActive.theme ? themeActive.theme.button_color : ''}
            buttonColorShadow={themeActive.theme ? themeActive.theme.button_color_shadow : ''}
        >
            <div className="container-info-login">
                <div className="container-path-info-login">
                    <div className="container-icon">
                        <img className="icon-login" alt="img-login" src={themeActive.theme ? themeActive.theme.logo : ''}/>
                    </div>
                    <div className="container-title">
                        <h1 className="title">
                            Prepárate para ganar en el bingo
                        </h1>
                    </div>
                    <div className="container-text">
                        <span className="text">
                            Encuentra toda la información de nuestros
                            eventos y premiaciones visitando nuestras
                            redes sociales
                        </span>
                    </div>
                    <div className="container-button">
                        <div className="button">Conocer más</div>
                    </div>
                </div>
                <div className="icon-center">
                    <img className="icon-elipse-gray" alt="Elipse-gray" src={themeDefault.icon_center_login}/>
                </div>
            </div>
            <div className="container-form-login">
                {/*<NotHaveAccount/>*/}
                {showModalSignIn &&
                    <SignIn/>
                }
                {showModalRegister &&
                    <RegisterUser/>
                }
                {showModalConfirmOTP &&
                    <ConfirmLoginOTP/>
                }
                <footer className="footer-login">
                    <span>&copy;</span>2023 powered by knot
                </footer>
            </div>

        </LoginStyles>
    );
}
