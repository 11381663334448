import styled from "styled-components";
import {Device} from "../../../shared/Utils/devices";

export const GameModeStyles = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    .game-mode {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        line-height: 2;
        width: 39%;

        .img-game-mode {
            width: 3vw;
        }

        .name-game-mode {
            font-family: RoundedMplus1c-ExtraBold, sans-serif;
            font-weight: 800;
            font-size: .8vw;
            text-align: center;
            letter-spacing: -0.57px;
            color: #FFFFFF;
            opacity: 1;
        }
    }

    @media ${Device.tablet} {
        .game-mode {
            .img-game-mode {
                width: 6vw;
            }

            .name-game-mode {
                font-size: 2.8vw;
            }
        }
    }
    
    @media ${Device.mobileL} {
        .game-mode {
            .img-game-mode {
                width: 6vw;
            }

            .name-game-mode {
                font-size: 2.8vw;
            }
        }
    }
    @media ${Device.mobileM} {
        .game-mode {
            .img-game-mode {
                width: 6vw;
            }

            .name-game-mode {
                font-size: 2.8vw;
            }
        }
    }
    @media ${Device.mobileS} {
        .game-mode {
            .img-game-mode {
                width: 6vw;
            }

            .name-game-mode {
                font-size: 2.8vw;
            }
        }
    }
`
