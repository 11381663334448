import {myDBDomain} from "../../shared/Utils/TestingApi/MyDBDomain";
import axios from "axios";
import {
    GET_ALL_SESSIONS_ACTIVE_END_USER_SUCCESS,
    GET_SINGLE_SESSION_END_USER, GET_SINGLE_SESSION_END_USER_FAILED,
    GET_SINGLE_SESSION_END_USER_SUCCESS
} from "../TypesAdmin/SessionEndUserTypes";
import {SessionModel} from "../../../user/domain/models/SessionModel";

export const getAllSessionsEndUser = (account_id: number, offset: number) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    const limit: number = 100;
    console.log('este es el offset active..........');
    console.log(offset);
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/session/get-active-end-user/${account_id}?page=${offset}&size=${limit}`,
        headers: {
            'Content-Type': 'application/json'
        },
    };
    await axios.request(config)
        .then((response) => {
            const dataSession: SessionModel = response.data.data;
            console.log(dataSession);
            dispatch({
                type: GET_ALL_SESSIONS_ACTIVE_END_USER_SUCCESS,
                payload: dataSession
            });
        })
        .catch((error) => {
            console.log(error);
            dispatch({
                type: GET_ALL_SESSIONS_ACTIVE_END_USER_SUCCESS,
                payload: error.response.data.error
            });
        });
}
export const getSingleSessionEndUser = (session_id: number) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/session/get-session-end-user/${session_id}`,
        headers: {
            'Content-Type': 'application/json'
        },
    };
    dispatch({
        type: GET_SINGLE_SESSION_END_USER,
        payload: true
    });
    await axios.request(config)
        .then((response) => {
            const dataSingleSession: SessionModel = response.data.data;
            console.log(dataSingleSession);
            dispatch({
                type: GET_SINGLE_SESSION_END_USER_SUCCESS,
                payload: response.data.data
            });
        })
        .catch((error) => {
            console.log(error);
            dispatch({
                type: GET_SINGLE_SESSION_END_USER_FAILED,
                payload: error.response.data.error
            });
        });
}