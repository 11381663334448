export interface IOptions {
    id:number;
    img:string;
    text:string;
}

export const optionsComponentData: IOptions[] = [
    {
        'id': 1,
        'img': '/img/newImage/optionsAdmin/optionsAdminUserManagement.svg',
        'text': 'Gestión de Usuarios'
    },
    {
        'id': 2,
        'img': '/img/newImage/optionsAdmin/optionsAdminNewSession.svg',
        'text': 'Nueva Sesión'
    },
    {
        'id': 3,
        'img': '/img/newImage/optionsAdmin/optionsAdminConfiguration.svg',
        'text': 'Configuración'
    },
];