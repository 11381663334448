import {IS_LOADING_START_GAME_FALSE, IS_LOADING_START_GAME_TRUE} from "../TypesAdmin/SocketTypes";

interface IStateSocket {
    isLoadingStartGame: boolean;
}

const initialStateSocket: IStateSocket = {
    isLoadingStartGame: false,
}

const SocketReducer = function (state = initialStateSocket, action: { type: any; payload: any }): IStateSocket {
    switch (action.type) {
        case IS_LOADING_START_GAME_TRUE:
            return {
                isLoadingStartGame: action.payload
            }
        case IS_LOADING_START_GAME_FALSE:
            return {
                isLoadingStartGame: action.payload
            }
        default:
            return state;
    }
}
export default SocketReducer;
