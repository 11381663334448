import {FormAccountStyles} from "./FormAccountStyles";

export default function FormAccount():JSX.Element {

    return (
        <FormAccountStyles>
            <form>
                <span>Dominio: </span>
                <input type="text" placeholder="Dominio"/>
                <br/>
                <span>imagen del logo: </span>
                <input type="file" placeholder="logo"/>
                <br/>
                <span>color de fondo del login: </span>
                <input type="text" placeholder="color de fondo del login"/>
                <br/>
                <span>imagen del centro del login: </span>
                <input type="file" placeholder="imagen del centro del login"/>
                <br/>
                <span>fuente 1: </span>
                <input type="text" placeholder="fuente poppinsSemiBold"/>
                <br/>
                <span>fuente 2: </span>
                <input type="text" placeholder="fuente poppinsLight"/>
                <br/>
                <span>fuente 3: </span>
                <input type="text" placeholder="fuente poppinsExtraLightItalic"/>
                <br/>
                <span>fuente 4: </span>
                <input type="text" placeholder="fuente poppinsRegular"/>
                <br/>
                <span>fuente 5: </span>
                <input type="text" placeholder="fuente antonRegular"/>
                <br/>
                <span>fuente 6: </span>
                <input type="text" placeholder="fuente roundedMPlus1cExtraBold"/>
                <br/>
                <span>fuente 7: </span>
                <input type="text" placeholder="fuente alataRegular"/>
                <br/>
                <span>fuente 8: </span>
                <input type="text" placeholder="fuente allerDisplayRegular"/>
                <br/>
                <span>color de los botones: </span>
                <input type="text" placeholder="color de los botones"/>
                <br/>
                <span>sombra de los botones: </span>
                <input type="text" placeholder="sobra de los botones"/>
                <br/>
                <span>imagen fondo del coponente de juego: </span>
                <input type="file" placeholder="imagen de fondo del juego"/>
                <br/>
                <span>imagen fonde de informacion del juego: </span>
                <input type="file" placeholder="imagen de fondo de la informacion de juego"/>
                <br/>
                <span>imagen de fondo de las balotas del juego: </span>
                <input type="file" placeholder="imagen de fondo de las balotas del juego"/>
                <br/>
                <span>imagen de fondo de los ganadores de juego: </span>
                <input type="file" placeholder="imagen de fondo de los ganadores de juego"/>
                <br/>
                <span>imagen lanzar balota: </span>
                <input type="file" placeholder="imagen lanzar balota"/>
                <br/>
                <span>imagen lanzando balota: </span>
                <input type="file" placeholder="imagen lanzando balota"/>
                <br/>
                <span>imagen ultima balota: </span>
                <input type="file" placeholder="imagen ultima balota"/>
                <br/>
                <br/>
                <button>Crear</button>
            </form>
        </FormAccountStyles>
    );
}