import axios from "axios";
import {myDBDomain} from "../../shared/Utils/TestingApi/MyDBDomain";
import {
    CARDS_WINNER_WITH_USER_AND_GAME,
    CARDS_WINNER_WITH_USER_AND_GAME_FAILED,
    CARDS_WINNER_WITH_USER_AND_GAME_SUCCESS,
    COUNT_CARDS_BY_BINGO_LIMIT,
    GET_CARDS_AWS,
    GET_CARDS_AWS_FAILED,
    GET_CARDS_AWS_SUCCESS,
    GET_CARDS_COUNT_BY_SESSION_ID,
    GET_CARDS_COUNT_BY_SESSION_ID_FAILED,
    GET_CARDS_COUNT_BY_SESSION_ID_SUCCESS
} from "../TypesAdmin/CardTypes";
import {CardsWinnerWithUserAndGamePostModel} from "../../domain/models/CardsWinnerWithUserAndGamePostModel";

export const getCardsAws = (session_id: string, phone: string) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    const data = {
        session_id: session_id,
        phone: phone
    }
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/card/get-cards`,
        headers: {
            'Content-Type': 'application/json'
        },
        data : data
    };
    dispatch({
        type: GET_CARDS_AWS,
        payload: true
    });
    await axios.request(config)
        .then((response) => {
            console.log(response.data);
            dispatch({
                type: GET_CARDS_AWS_SUCCESS,
                payload: response.data.data[0]
            });
        })
        .catch((error) => {
            console.log(error);
            dispatch({
                type: GET_CARDS_AWS_FAILED,
                payload: error.response.data.error
            });
        });
}
export const getCardSCountBySessionId = (session_id: number) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/player/getCountPlayersForSessionId/${session_id}`,
        headers: { }
    };
    dispatch({
        type: GET_CARDS_COUNT_BY_SESSION_ID,
        payload: true
    });
    await axios.request(config)
        .then((response) => {
            console.log('contador de players o cartones en juego...............');
            dispatch({
                type: GET_CARDS_COUNT_BY_SESSION_ID_SUCCESS,
                payload: response.data.data
            });
        })
        .catch((error) => {
            console.log(error);
            dispatch({
                type: GET_CARDS_COUNT_BY_SESSION_ID_FAILED,
                payload: error.response.data.error
            });
        });
}
export const getCardsWinnerWithUserAndGame = (data: CardsWinnerWithUserAndGamePostModel) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/card/get-cards-winners`,
        headers: { },
        data: data
    };
    dispatch({
        type: CARDS_WINNER_WITH_USER_AND_GAME,
        payload: true
    });
    await axios.request(config)
        .then((response) => {
            console.log(response.data.data);
            dispatch({
                type: CARDS_WINNER_WITH_USER_AND_GAME_SUCCESS,
                payload: response.data.data
            });
        })
        .catch((error) => {
            console.log(error);
            dispatch({
                type: CARDS_WINNER_WITH_USER_AND_GAME_FAILED,
                payload: error.response.data.error
            });
        });
}
export const getCountCardsByBingoLimit = (bingo_limit: number) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/card/countCardsByBingoLimit?bingo_limit=${bingo_limit}`,
        headers: { }
    };
    await axios.request(config)
        .then((response) => {
            dispatch({
                type: COUNT_CARDS_BY_BINGO_LIMIT,
                payload: response.data.data
            });
        })
        .catch((error) => {
            console.log(error.response.data);
        });
}