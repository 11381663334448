import {ConfigurationOptionsStyles} from "./ConfigurationOptionsStyles"
import React, {useEffect, useState} from "react";
import {useThemeContext} from "../../../shared/Hooks/ThemeContextProvider";
import {ThemeDefaultModel} from "../../../domain/models/ThemeDefaultModel";
import {getLocalStorageObj} from "../../../shared/Utils/GetLocalStorageObj";
import ConfigurationThemeForm from "../ConfigurationThemeForm/ConfigurationThemeForm";
import {useAppDispatch, useAppSelector} from "../../../shared/Utils/Hooks";
import {changeSessionSelected} from "../../../store/ActionsAdmin/SessionActions";
import {openAndCloseModalCreateUser, openUserInfo} from "../../../store/ActionsAdmin/ModalsActions";
import {useHistory} from "react-router-dom";
import {setLocalStorageObj} from "../../../shared/Utils/SetLocalStorageObj";
import {themeDefault} from "../../../shared/Utils/ThemeDefault";


export default function ConfigurationOptions(): JSX.Element {

    const dispatch = useAppDispatch();
    const history = useHistory();
    const themeActive = useThemeContext();
    const themeSelect: ThemeDefaultModel | null = getLocalStorageObj('theme');
    const domainActive = useAppSelector((state) => state.accountState.domainActive);
    const [createdTheme, setCreatedTheme] = useState<boolean>(false);

    useEffect(() => {
        console.log(themeActive);
        console.log(themeSelect);
    }, [themeActive, themeSelect]);

    useEffect(() => {
        console.log(domainActive?.account_config.logo);
    }, []);

    function handleShowForm() {
        setCreatedTheme(!createdTheme);
    }

    function redirectHome() {
        history.push('/admin/options');
        dispatch(changeSessionSelected(undefined));
        dispatch(openUserInfo(false));
        dispatch(openAndCloseModalCreateUser(false));
    }

    function handleActiveTheme() {
        if (domainActive && domainActive.account_config) {
            setLocalStorageObj(domainActive.account_config, 'theme');
            themeActive.toggleTheme(domainActive.account_config);
        }
    }

    function handleDeactivateTheme() {
        localStorage.removeItem('theme');
        themeActive.toggleTheme(themeDefault);
    }

    return (
        <ConfigurationOptionsStyles
            buttonColor={themeActive.theme ? themeActive.theme.button_color : ''}
            buttonColorShadow={themeActive.theme ? themeActive.theme.button_color_shadow : ''}
        >
            <div className="user-management-container">
                <div className="icon-back-container">
                    <img onClick={redirectHome} className="icon-back" alt="back"
                         src="/img/newImage/optionsAdmin/icon-back.png"/>
                </div>
            </div>
            <h1 className="title-configuration">Configuraciones</h1>
            {domainActive?.account_config.logo === '' &&
                <div className="container-buttons">
                    <p className="text-buttons">
                        Crear configuración del tema
                    </p>
                    <button className="buttons" onClick={handleShowForm}>{!createdTheme ? 'Configurar' : 'Cerrar'}</button>
                    {createdTheme &&
                        <ConfigurationThemeForm setCreatedTheme={() => setCreatedTheme(false)}/>
                    }
                </div>
            }
            {domainActive?.account_config.logo !== '' &&
                <div className="container-buttons">
                    <p className="text-buttons">
                        Editar configuración del tema
                    </p>
                    <button className="buttons" onClick={handleShowForm}>{!createdTheme ? 'Configurar' : 'Cerrar'}</button>
                    {createdTheme &&
                        <ConfigurationThemeForm setCreatedTheme={() => setCreatedTheme(false)}/>
                    }
                </div>
            }

            {!themeSelect && domainActive?.account_config.logo !== '' &&
                <div className="container-switch">
                    <span>Ya se creo una configuración.</span>
                    <button onClick={handleActiveTheme} className="buttons">Activar</button>
                </div>
            }
            {themeSelect && domainActive?.account_config.logo !== '' &&
                <div className="container-switch">
                    <span>Ya se creo una configuración.</span>
                    <button onClick={handleDeactivateTheme} className="buttons">Desactivar</button>
                </div>
            }
        </ConfigurationOptionsStyles>
    );
}
