import styled from "styled-components";

export const OptionStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: .5vw;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 2vw;
  opacity: 1;
  width: 14%;
  height: 12vw;
  cursor: pointer;
  
  .optionsAdminUserManagement-img1 {
    width: 4.5vw;
  }
  .optionsAdminUserManagement-img2 {
    width: 3vw;
  }
  .optionsAdminUserManagement-img3 {
    width: 3.5vw;
  }
  
  .text-option {
    display: flex;
    text-align: center;
    letter-spacing: 0;
    color: #2E304E;
    opacity: 1;
    font-size: 1.2vw;
    font-family: Poppins-SemiBold, sans-serif;
    font-weight: 600;
    line-height: 1;
    height: 2.5vw;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .text-option-new-width {
    width: 66%;
  }
`
