import {ListAllBingosModel} from "../../domain/models/ListAllBingosModel";
import {
    CLEAN_ALL_BINGOS, CLEAN_LAST_BALLOT,
    CLEAN_SINGLE_BINGO,
    CREATE_BINGO,
    CREATE_BINGO_FAILED,
    CREATE_BINGO_SUCCESS,
    DELETE_BINGO,
    DELETE_BINGO_FAILED,
    DELETE_BINGO_SUCCESS,
    EDIT_BINGO,
    EDIT_BINGO_FAILED,
    EDIT_BINGO_SUCCESS,
    GET_ALL_BINGOS,
    GET_ALL_BINGOS_FAILED,
    GET_ALL_BINGOS_SUCCESS, GET_GAME_INFO, GET_GAME_INFO_FAILED, GET_GAME_INFO_SUCCESS, GET_SHOW_WINNERS,
    GET_SINGLE_BINGO,
    GET_SINGLE_BINGO_FAILED,
    GET_SINGLE_BINGO_SUCCESS,
    LAUNCH_BALLOT, LAUNCH_BALLOT_FAILED,
    LAUNCH_BALLOT_SUCCESS,
    START_GAME,
    START_GAME_SUCCESS, UPDATE_LAST_BALLOT
} from "../TypesAdmin/BingosType";
import {GameInfoModel} from "../../domain/models/GameInfoModel";
import {
    GET_SINGLE_GAME_END_USER,
    GET_SINGLE_GAME_END_USER_FAILED,
    GET_SINGLE_GAME_END_USER_SUCCESS
} from "../TypesAdmin/GameEndUserTypes";

interface IStateGameEndUser {
    singleGameEndUser:ListAllBingosModel | null;
    loadingSingleGameEndUser:boolean;
    message: string;
    error: string;
}
const initialStateGameEndUser: IStateGameEndUser = {
    singleGameEndUser: null,
    loadingSingleGameEndUser:false,
    message: '',
    error: ''
}

const GameEndUserReducer = function (state = initialStateGameEndUser, action: {type:any; payload:any}):IStateGameEndUser {
    switch (action.type) {
        case GET_SINGLE_GAME_END_USER:
            return {
                singleGameEndUser: state.singleGameEndUser,
                loadingSingleGameEndUser: action.payload,
                message: '',
                error: ''
            };
        case GET_SINGLE_GAME_END_USER_SUCCESS:
            return {
                singleGameEndUser: action.payload,
                loadingSingleGameEndUser: false,
                message: '',
                error: ''
            };
        case GET_SINGLE_GAME_END_USER_FAILED:
            return {
                singleGameEndUser:state.singleGameEndUser,
                loadingSingleGameEndUser: false,
                message: '',
                error: action.payload
            };
        default:
            return state;
    }
}
export default GameEndUserReducer;
