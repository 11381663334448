import {ListAllBingosModel} from "../../domain/models/ListAllBingosModel";
import {
    ALL_BALLOTS_PLAYED,
    ALL_BALLOTS_PLAYED_IN_LAUNCH_BALLOT,
    CHANGE_IS_AUTOMATIC_LAUNCH,
    CHANGE_LAUNCHING_BALLOT_FALSE,
    CHANGE_LAUNCHING_BALLOT_TRUE,
    CHANGE_PLAY_LAUNCH_BALLOT_AUTO,
    CHANGE_STARTING_GAME,
    CLEAN_ALL_BINGOS,
    CLEAN_LAST_BALLOT,
    CLEAN_SINGLE_BINGO,
    CLEAR_DEBUG_MESSAGES,
    COUNT_LAUNCH_BALLOT,
    CREATE_BINGO,
    CREATE_BINGO_FAILED,
    CREATE_BINGO_SUCCESS,
    CREATE_IMAGE,
    CREATE_IMAGE_FAILED,
    CREATE_IMAGE_SUCCESS,
    DEBUG_MESSAGES,
    DEBUG_MESSAGES_FAILED,
    DEBUG_MESSAGES_SUCCESS,
    DELETE_BINGO,
    DELETE_BINGO_FAILED,
    DELETE_BINGO_SUCCESS,
    DELETE_IMAGE,
    DELETE_IMAGE_FAILED,
    DELETE_IMAGE_SUCCESS,
    EDIT_BINGO,
    EDIT_BINGO_FAILED,
    EDIT_BINGO_SUCCESS,
    FINALIZED_PLAY_LAUNCH_BALLOT_AUTO,
    GET_ALL_BINGOS,
    GET_ALL_BINGOS_FAILED,
    GET_ALL_BINGOS_SUCCESS,
    GET_ALL_DEBUG_MESSAGES_DATA_BASE,
    GET_ALL_DEBUG_MESSAGES_DATA_BASE_FAILED,
    GET_ALL_DEBUG_MESSAGES_DATA_BASE_SUCCESS,
    GET_ALL_IMAGES,
    GET_ALL_IMAGES_FAILED,
    GET_ALL_IMAGES_SUCCESS,
    GET_GAME_INFO,
    GET_GAME_INFO_FAILED,
    GET_GAME_INFO_SUCCESS,
    GET_SHOW_WINNERS,
    GET_SINGLE_BINGO,
    GET_SINGLE_BINGO_FAILED,
    GET_SINGLE_BINGO_SUCCESS,
    LAUNCH_BALLOT,
    LAUNCH_BALLOT_FAILED,
    LAUNCH_BALLOT_SUCCESS, RESET_LAUNCHING_BALLOT,
    SELECTED_CLICK_ADD_IMAGE,
    SELECTED_IMAGE_BANNER_CREATE_SESSION,
    SELECTED_IMAGE_MAIN_CREATE_GAME,
    SELECTED_IMAGE_MAIN_CREATE_SESSION,
    START_GAME,
    START_GAME_SUCCESS,
    SWITCH_DEBUG_MESSAGES,
    SWITCH_DEBUG_MESSAGES_SOCKET, UPDATE_GAME_INFO,
    UPDATE_LAST_BALLOT,
    UPDATE_LAST_BALLOT_LAUNCH,
    UPDATE_STATE_SINGLE_BINGO
} from "../TypesAdmin/BingosType";
import {GameInfoModel} from "../../domain/models/GameInfoModel";
import {SelectedClickAddImageType} from "../../domain/models/SelectedClickAddImage";
import {GameStorageDataModel} from "../../domain/models/GameStorageDataModel";

interface IStateBingo {
    allBingos:ListAllBingosModel[];
    loadingAllBingos:boolean;
    startingGame:boolean;
    singleBingo:ListAllBingosModel | null;
    loadingSingleBingo:boolean;
    createBingo:string;
    DeletingBingo:boolean;
    loadingCreateBingo:boolean;
    launchingBallot:boolean;
    startedGame:boolean;
    message:string;
    blockedLaunch:boolean;
    loadingEditBingo:boolean;
    lastBallot:string;
    showWinners: any[];
    gameInfo: GameStorageDataModel | null;
    loadingGameInfo: boolean;
    updateLaunchBallotNumber: string;
    allBallotsPlayed: any[];
    countLaunchBallot: number;
    playLaunchBallotAuto: boolean;
    isAutomaticLaunch: boolean;
    selectedImageMainCreateSession: string;
    selectedImageBannerCreateSession: string;
    selectedImageMainCreateGame: string;
    selectedClickAddImage: SelectedClickAddImageType;
    allImagesStorage: string[];
    isLoadingCreateImage: boolean;
    isLoadingAllImages: boolean;
    isLoadingDeleteImage: boolean;
    switchDebugMessageData: boolean;
    isLoadingAllDebugMessages: boolean;
    allDebugMessages: string[];
    isLoadingDebugMessages: boolean;
}
const initialStateBingo: IStateBingo = {
    allBingos: [],
    loadingAllBingos:false,
    singleBingo: null,
    loadingSingleBingo:false,
    createBingo:'',
    DeletingBingo:false,
    loadingCreateBingo:false,
    launchingBallot:false,
    startedGame:false,
    message:'',
    blockedLaunch:false,
    startingGame:false,
    loadingEditBingo:false,
    lastBallot: '',
    showWinners: [],
    gameInfo: null,
    loadingGameInfo: false,
    updateLaunchBallotNumber: '',
    allBallotsPlayed: [],
    countLaunchBallot: 0,
    playLaunchBallotAuto: false,
    isAutomaticLaunch: false,
    selectedImageMainCreateSession: '',
    selectedImageBannerCreateSession: '',
    selectedImageMainCreateGame: '',
    selectedClickAddImage: '',
    allImagesStorage: [],
    isLoadingCreateImage: false,
    isLoadingAllImages: false,
    isLoadingDeleteImage: false,
    switchDebugMessageData: false,
    isLoadingAllDebugMessages: false,
    allDebugMessages: [],
    isLoadingDebugMessages: false,
}

const BingoReducer = function (state = initialStateBingo, action: {type:any; payload:any}):IStateBingo {
    switch (action.type) {
        case GET_ALL_BINGOS:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                launchingBallot: state.launchingBallot,
                allBingos: state.allBingos,
                loadingAllBingos:true,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case GET_ALL_BINGOS_SUCCESS:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: action.payload,
                loadingAllBingos:false,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                launchingBallot: state.launchingBallot,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case GET_ALL_BINGOS_FAILED:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:false,
                singleBingo:null,
                loadingSingleBingo:state.loadingSingleBingo,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                launchingBallot: state.launchingBallot,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:action.payload
            };
        case GET_SINGLE_BINGO:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:true,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                launchingBallot: state.launchingBallot,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case GET_SINGLE_BINGO_SUCCESS:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos: state.loadingAllBingos,
                singleBingo:action.payload,
                loadingSingleBingo:false,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                launchingBallot: state.launchingBallot,
                startedGame: state.startedGame,
                loadingEditBingo: state.loadingEditBingo,
                lastBallot: state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case GET_SINGLE_BINGO_FAILED:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:false,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                launchingBallot: state.launchingBallot,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:"Error al cargar el bingo"
            };
        case CREATE_BINGO:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:true,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case CREATE_BINGO_SUCCESS:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:action.payload,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:false,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case CREATE_BINGO_FAILED:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:false,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:action.payload
            };
        case DELETE_BINGO:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:action.payload,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            }
        case DELETE_BINGO_SUCCESS:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:false,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:action.payload
            }
        case DELETE_BINGO_FAILED:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:false,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:action.payload
            }
        case LAUNCH_BALLOT:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: true,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case LAUNCH_BALLOT_SUCCESS:
            return {
                startingGame: state.startingGame,
                blockedLaunch: false,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: false,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: action.payload,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case LAUNCH_BALLOT_FAILED:
            return {
                startingGame: state.startingGame,
                blockedLaunch: true,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: false,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:action.payload
            };
        case START_GAME:
            return {
                startingGame: true,
                blockedLaunch: false,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:true,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case START_GAME_SUCCESS:
            return {
                startingGame: false,
                blockedLaunch: false,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:true,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case EDIT_BINGO:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:action.payload,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case EDIT_BINGO_SUCCESS:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:false,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case EDIT_BINGO_FAILED:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:false,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:action.payload
            };
        case CLEAN_SINGLE_BINGO:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:action.payload,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case CLEAN_ALL_BINGOS:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: action.payload,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case UPDATE_LAST_BALLOT:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:action.payload,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case CLEAN_LAST_BALLOT:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:action.payload,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case GET_GAME_INFO:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: action.payload,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case GET_GAME_INFO_SUCCESS:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: action.payload,
                loadingGameInfo: false,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case UPDATE_GAME_INFO:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: action.payload,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case GET_GAME_INFO_FAILED:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: false,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message: action.payload
            };
        case GET_SHOW_WINNERS:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: action.payload,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message: ''
            };
        case UPDATE_LAST_BALLOT_LAUNCH:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:action.payload,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case ALL_BALLOTS_PLAYED:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: action.payload,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case ALL_BALLOTS_PLAYED_IN_LAUNCH_BALLOT:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: action.payload,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case CHANGE_STARTING_GAME:
            return {
                startingGame: action.payload,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame: state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case UPDATE_STATE_SINGLE_BINGO:
            return {
                startingGame: false,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo: action.payload,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame: state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case CHANGE_LAUNCHING_BALLOT_TRUE:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: action.payload,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case CHANGE_LAUNCHING_BALLOT_FALSE:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: action.payload,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case COUNT_LAUNCH_BALLOT:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot + action.payload,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case CHANGE_PLAY_LAUNCH_BALLOT_AUTO:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: action.payload,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case FINALIZED_PLAY_LAUNCH_BALLOT_AUTO:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: action.payload,
                isAutomaticLaunch: false,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case CHANGE_IS_AUTOMATIC_LAUNCH:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: action.payload,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case SELECTED_IMAGE_MAIN_CREATE_SESSION:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: action.payload,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case SELECTED_IMAGE_BANNER_CREATE_SESSION:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: action.payload,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case SELECTED_IMAGE_MAIN_CREATE_GAME:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: action.payload,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case SELECTED_CLICK_ADD_IMAGE:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: action.payload,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case CREATE_IMAGE:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: action.payload,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case CREATE_IMAGE_SUCCESS:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: action.payload,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case CREATE_IMAGE_FAILED:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: action.payload,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case GET_ALL_IMAGES:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: action.payload,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case GET_ALL_IMAGES_SUCCESS:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: action.payload,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: false,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case GET_ALL_IMAGES_FAILED:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: false,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case DELETE_IMAGE:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: true,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case DELETE_IMAGE_SUCCESS:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: action.payload,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case DELETE_IMAGE_FAILED:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: false,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case SWITCH_DEBUG_MESSAGES:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: action.payload,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case SWITCH_DEBUG_MESSAGES_SOCKET:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: action.payload,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case DEBUG_MESSAGES:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: action.payload,
                message:''
            };
        case DEBUG_MESSAGES_SUCCESS:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: [...state.allDebugMessages, action.payload],
                isLoadingDebugMessages: false,
                message:''
            };
        case DEBUG_MESSAGES_FAILED:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: false,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: [...state.allDebugMessages, action.payload],
                isLoadingDebugMessages: false,
                message:''
            };
        case CLEAR_DEBUG_MESSAGES:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: action.payload,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case GET_ALL_DEBUG_MESSAGES_DATA_BASE:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: action.payload,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case GET_ALL_DEBUG_MESSAGES_DATA_BASE_SUCCESS:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: false,
                allDebugMessages: action.payload,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case GET_ALL_DEBUG_MESSAGES_DATA_BASE_FAILED:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: state.launchingBallot,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: false,
                allDebugMessages: action.payload,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        case RESET_LAUNCHING_BALLOT:
            return {
                startingGame: state.startingGame,
                blockedLaunch: state.blockedLaunch,
                allBingos: state.allBingos,
                loadingAllBingos:state.loadingAllBingos,
                singleBingo:state.singleBingo,
                loadingSingleBingo:state.loadingSingleBingo,
                launchingBallot: action.payload,
                createBingo:state.createBingo,
                DeletingBingo:state.DeletingBingo,
                loadingCreateBingo:state.loadingCreateBingo,
                startedGame:state.startedGame,
                loadingEditBingo:state.loadingEditBingo,
                lastBallot:state.lastBallot,
                showWinners: state.showWinners,
                gameInfo: state.gameInfo,
                loadingGameInfo: state.loadingGameInfo,
                updateLaunchBallotNumber: state.updateLaunchBallotNumber,
                allBallotsPlayed: state.allBallotsPlayed,
                countLaunchBallot: state.countLaunchBallot,
                playLaunchBallotAuto: state.playLaunchBallotAuto,
                isAutomaticLaunch: state.isAutomaticLaunch,
                selectedImageMainCreateSession: state.selectedImageMainCreateSession,
                selectedImageBannerCreateSession: state.selectedImageBannerCreateSession,
                selectedImageMainCreateGame: state.selectedImageMainCreateGame,
                selectedClickAddImage: state.selectedClickAddImage,
                allImagesStorage: state.allImagesStorage,
                isLoadingCreateImage: state.isLoadingCreateImage,
                isLoadingAllImages: state.isLoadingAllImages,
                isLoadingDeleteImage: state.isLoadingDeleteImage,
                switchDebugMessageData: state.switchDebugMessageData,
                isLoadingAllDebugMessages: state.isLoadingAllDebugMessages,
                allDebugMessages: state.allDebugMessages,
                isLoadingDebugMessages: state.isLoadingDebugMessages,
                message:''
            };
        default:
            return state;
    }
}
export default BingoReducer;
