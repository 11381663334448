import {CHANGE_OPTION_NAV, CHANGE_SELECTED_OPTIONS, UPDATE_SELECTED_OPTIONS} from "../TypesAdmin/SelectedOptionTypes";

interface IStateSelectedOption {
    selectedOption:string;
    optionNav:string;
}
const initialStateSelectedOption:IStateSelectedOption = {
    selectedOption: 'semi',
    optionNav: 'games',
}
const selectedOptionReducer = function (state = initialStateSelectedOption, action: { type:any; payload:any }): IStateSelectedOption {
    switch (action.type) {
        case CHANGE_SELECTED_OPTIONS:
            return {
                selectedOption:action.payload,
                optionNav:state.optionNav,
            };
        case UPDATE_SELECTED_OPTIONS:
            return {
                selectedOption: action.payload,
                optionNav:state.optionNav,
            };
        case CHANGE_OPTION_NAV:
            return {
                selectedOption: state.selectedOption,
                optionNav:action.payload,
            };
        default:
            return state;
    }
}
export default selectedOptionReducer;
