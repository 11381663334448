import {ContentCardBingoStyled} from "./ContentCardBingoStyled";
import CardBingo from "../CardBingo/CardBingo";
import {useAppSelector} from "../../../../admin/shared/Utils/Hooks";
import {useEffect} from "react";

export default function ContentCardBingo(): JSX.Element {

    const allCardsUserSession = useAppSelector((state) => state.cardsState.allCardsUserSession);
    const singleSessionEndUser = useAppSelector((state) => state.sessionEndUserState.singleSessionEndUser);

    useEffect(() => {
        console.log(allCardsUserSession);
    }, []);

    return (
        <ContentCardBingoStyled>
            {allCardsUserSession && allCardsUserSession.cards.map((card, index) => (
                <CardBingo
                    key={index}
                    array1={card.numbers[0]}
                    array2={card.numbers[1]}
                    array3={card.numbers[2]}
                    array4={card.numbers[3]}
                    array5={card.numbers[4]}
                    serial={card.card_id}
                    bingo_header={singleSessionEndUser ? singleSessionEndUser.bingo_header : ''}
                />
            ))}
        </ContentCardBingoStyled>
    );
}