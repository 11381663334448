import styled from "styled-components";

export const ShowSessionGamesStyled = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: start;
    gap: 4vw;
    overflow-y: scroll;
    width: 96%;
    height: 30vw;
    margin: 3vw 0;
    box-sizing: border-box;
    padding: 1vw;

    &::-webkit-scrollbar {
        width: 1.2vw; /* Ancho del scrollbar */
    }

    &::-webkit-scrollbar-track {
        background-color: #CCCFDE;
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background: #00000038;
        border-radius: 5px;
        width: 1vw;
    }

    .modal-user-info {
        position: absolute;
        top: 3vw;
        left: 12vw;
        width: 24vw;
        height: 21vw;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 3px 4px 1px #0000004D;
        border-radius: 25px;
        opacity: 1;
        margin-bottom: 1vw;
        z-index: 999999999999999;
    }
`