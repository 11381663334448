import styled from "styled-components";

export const SessionStyles = styled.div`
  display: flex;
  width: 100%;
  
  .container {
    display: flex;
    gap: .5vw;
    width: 94%;
    height: 2.5vw;
    cursor: pointer;
    padding: 0.3vw 0.5vw;
    
    .user-image {
      width: 2.5vw;
      border-radius: 50%;
    }
    .container-info-session {
      width: 100%;
      display: flex;
      flex-direction: column;

      .container-name-edit {
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .container-icons-sidebar {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: .5vw;

          .icon-delete {
            font-size: 0.9vw;
            cursor: pointer;

            .ended {
              opacity: 0.24;
            }
            .white {
              color: #ffffff;
            }
          }
        }
        
        .title-session {
          text-align: left;
          letter-spacing: 0;
          color: #2E304E;
          opacity: 1;
          font-size: .8vw;
          font-family: Poppins-SemiBold, sans-serif;
          font-weight: 600;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 10vw;
          overflow: hidden;
        }
        .ended {
          opacity: 0.24;
        }
        .white {
          color: #ffffff;
        }
        .container-edit {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          cursor: pointer;

          .options-admin-img-edit-2 {
            width: .6vw;
          }
          .options-admin-img-edit-1 {
            position: absolute;
            bottom: .2vw;
            left: .25vw;
            width: .48vw;
          }
          .white {
            fill: #ffffff;
          }
        }
      }
      .info-session {
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 0.2vw;

        .info-text {
          line-height: 1;
          text-align: left;
          letter-spacing: 0;
          color: #2E304E;
          opacity: 1;
          font-size: .6vw;
          font-family: Poppins-SemiBold, sans-serif;
          font-weight: 300;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 8vw;
          overflow: hidden;
        }
        .ended {
          opacity: 0.24;
        }
        .white {
          color: #ffffff;
        }
        .container-icon-people {
          display: flex;
          align-items: center;
          gap: .5vw;

          .people-play {
            width: 1vw;
          }
          .count-people {
            text-align: left;
            letter-spacing: 0;
            color: #2E304E;
            opacity: 1;
            font-size: .6vw;
            font-family: Poppins-Light, sans-serif;
            font-weight: 300;
          }
          .ended {
            opacity: 0.24;
          }
          .white {
            color: #ffffff;
          }
        }
      }
    }
  }
  .active {
    background: #2C2F4F;
  }
`
