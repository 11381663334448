import styled from "styled-components";

export const NumberStyles = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    .container-header-bingo {
        position: relative;
        display: grid;
        align-content: center;
        justify-items: center;
        grid-template-columns: repeat(1, 2.8vw);
        grid-template-rows: repeat(5, 3.6vw);

        .header-text-1 {
            position: relative;
            bottom: 0vw;
            left: 0vw;
            font-family: AllerDisplay-Regular, sans-serif;
            font-weight: 400;
            font-size: 3vw;
            font-weight: bold;
            text-align: left;
            letter-spacing: 0px;
            color: #FFFFFF;
            opacity: 1;
        }

        .header-text-2 {
            position: relative;
            bottom: 0vw;
            left: 0vw;
            font-family: AllerDisplay-Regular, sans-serif;
            font-weight: 400;
            font-size: 3vw;
            font-weight: bold;
            text-align: left;
            letter-spacing: 0px;
            color: #FFFFFF;
            opacity: 1;
        }

        .header-text-3 {
            position: relative;
            bottom: 0;
            left: 0;
            font-family: AllerDisplay-Regular, sans-serif;
            font-weight: 400;
            font-size: 3vw;
            font-weight: bold;
            text-align: left;
            letter-spacing: 0px;
            color: #FFFFFF;
            opacity: 1;
        }

        .header-text-4 {
            position: relative;
            top: 0vw;
            left: 0vw;
            font-family: AllerDisplay-Regular, sans-serif;
            font-weight: 400;
            font-size: 3vw;
            font-weight: bold;
            text-align: left;
            letter-spacing: 0px;
            color: #FFFFFF;
            opacity: 1;
        }

        .header-text-5 {
            position: relative;
            top: 0vw;
            left: 0vw;
            font-family: AllerDisplay-Regular, sans-serif;
            font-weight: 400;
            font-size: 3vw;
            font-weight: bold;
            text-align: left;
            letter-spacing: 0px;
            color: #FFFFFF;
            opacity: 1;
        }
    }

    .container-container-numbers-25 {
        display: grid;
        align-content: center;
        justify-items: center;
        grid-template-columns: repeat(5, 5.5vw);
        grid-template-rows: repeat(5, 3.6vw);

        .container-numbers {
            display: flex;
            position: relative;
            align-items: center;
            justify-content: center;
            width: 3.5vw;
            height: 3vw;
            cursor: pointer;

            .number-info {
                position: absolute;
                border-color: darkgray;
                z-index: 1;
                font-size: 0.5em;
                width: 10vw;
                left: 2.3vw;
                clip-path: polygon(15% 0, 100% 0, 100% 100%, 15% 100%, 15% 75%, 0 50%, 15% 27%);
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 1px solid #E4E4E4;
                opacity: 1;
                box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);

                .close-selected-number {
                    font-size: .8vw;
                    color: black;
                    text-align: right;
                    padding-right: 0.3vw;
                    font-weight: bold;
                }

                .info {
                    display: flex;
                    flex-direction: column;
                    padding: 0.1rem 0 .2rem 1.5rem;
                }

                .text-bold {
                    font-size: 1.1em;
                    font-weight: bold;
                    text-align: center;
                    letter-spacing: 0px;
                    color: #4C4C4C;
                }

                .text {
                    font-size: 1.3em;
                    text-align: center;
                    letter-spacing: 0px;
                    color: #4C4C4C;
                }
            }
        }
    }

    .container-container-numbers-50 {
        display: grid;
        align-content: center;
        justify-items: center;
        grid-template-columns: repeat(10, 4.5vw);
        grid-template-rows: repeat(5, 3.5vw);

        .container-numbers {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 3.5vw;
            height: 3vw;
            cursor: pointer;

            .number-info {
                position: absolute;
                border-color: darkgray;
                z-index: 1;
                font-size: 0.5em;
                width: 10vw;
                left: 2.3vw;
                clip-path: polygon(15% 0, 100% 0, 100% 100%, 15% 100%, 15% 75%, 0 50%, 15% 27%);
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 1px solid #E4E4E4;
                opacity: 1;
                box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);

                .close-selected-number {
                    font-size: .8vw;
                    color: black;
                    text-align: right;
                    padding-right: 0.3vw;
                    font-weight: bold;
                }

                .info {
                    display: flex;
                    flex-direction: column;
                    padding: 0.1rem 0 .2rem 1.5rem;
                }

                .text-bold {
                    font-size: 1.1em;
                    font-weight: bold;
                    text-align: center;
                    letter-spacing: 0px;
                    color: #4C4C4C;
                }

                .text {
                    font-size: 1.3em;
                    text-align: center;
                    letter-spacing: 0px;
                    color: #4C4C4C;
                }
            }
        }
    }

    .container-container-numbers-75 {
        display: grid;
        align-content: center;
        justify-items: center;
        grid-template-columns: repeat(15, 3.8vw);
        grid-template-rows: repeat(5, 3.5vw);

        .container-numbers {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 3.5vw;
            height: 3vw;
            cursor: pointer;

            .number-info {
                position: absolute;
                border-color: darkgray;
                z-index: 1;
                font-size: 0.5em;
                width: 10vw;
                left: 2.3vw;
                clip-path: polygon(15% 0, 100% 0, 100% 100%, 15% 100%, 15% 75%, 0 50%, 15% 27%);
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 1px solid #E4E4E4;
                opacity: 1;
                box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);

                .close-selected-number {
                    font-size: .8vw;
                    color: black;
                    text-align: right;
                    padding-right: 0.3vw;
                    font-weight: bold;
                }

                .info {
                    display: flex;
                    flex-direction: column;
                    padding: 0.1rem 0 .2rem 1.5rem;
                }

                .text-bold {
                    font-size: 1.1em;
                    font-weight: bold;
                    text-align: center;
                    letter-spacing: 0px;
                    color: #4C4C4C;
                }

                .text {
                    font-size: 1.3em;
                    text-align: center;
                    letter-spacing: 0px;
                    color: #4C4C4C;
                }
            }
        }
    }

    .container-container-numbers-95 {
        display: grid;
        align-content: center;
        justify-items: center;
        grid-template-columns: repeat(19, 3.10vw);
        grid-template-rows: repeat(5, 3.5vw);

        .container-numbers {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 3vw;
            height: 3vw;
            cursor: pointer;

            .number-info {
                position: absolute;
                border-color: darkgray;
                z-index: 1;
                font-size: 0.5em;
                width: 10vw;
                left: 2.3vw;
                clip-path: polygon(15% 0, 100% 0, 100% 100%, 15% 100%, 15% 75%, 0 50%, 15% 27%);
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 1px solid #E4E4E4;
                opacity: 1;
                box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);

                .close-selected-number {
                    font-size: .8vw;
                    color: black;
                    text-align: right;
                    padding-right: 0.3vw;
                    font-weight: bold;
                }

                .info {
                    display: flex;
                    flex-direction: column;
                    padding: 1vw 0 2vw 1.5vw;
                }

                .text-bold {
                    font-size: 1.1em;
                    font-weight: bold;
                    text-align: center;
                    letter-spacing: 0px;
                    color: #4C4C4C;
                }

                .text {
                    font-size: 1.3em;
                    text-align: center;
                    letter-spacing: 0px;
                    color: #4C4C4C;
                }
            }
        }
    }

    .container-container-numbers-99 {
        display: grid;
        align-content: center;
        justify-items: center;
        grid-template-columns: repeat(20, 3.12vw);
        grid-template-rows: repeat(5, 3.5vw);

        .container-numbers {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 3vw;
            height: 3vw;
            cursor: pointer;

            .number-info {
                position: absolute;
                border-color: darkgray;
                z-index: 1;
                font-size: 0.5em;
                width: 10vw;
                left: 2.3vw;
                clip-path: polygon(15% 0, 100% 0, 100% 100%, 15% 100%, 15% 75%, 0 50%, 15% 27%);
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 1px solid #E4E4E4;
                opacity: 1;
                box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);

                .close-selected-number {
                    font-size: .8vw;
                    color: black;
                    text-align: right;
                    padding-right: 0.3vw;
                    font-weight: bold;
                }

                .info {
                    display: flex;
                    flex-direction: column;
                    padding: 1vw 0 2vw 1.5vw;
                }

                .text-bold {
                    font-size: 1.1em;
                    font-weight: bold;
                    text-align: center;
                    letter-spacing: 0px;
                    color: #4C4C4C;
                }

                .text {
                    font-size: 1.3em;
                    text-align: center;
                    letter-spacing: 0px;
                    color: #4C4C4C;
                }
            }
        }
    }

`