import styled from "styled-components";

interface IAccumulatedStylesProps {
    width:number;
    height:string | number;
    margin:number;
    cumulativePercentage: number;
    top: number;
    left: number
}
export const AccumulatedStyles = styled.div<IAccumulatedStylesProps>`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 1vw;
    background: #f7f7f8;
    width: ${({width}) => width}vw;
    height: ${({height}) => height === 'unset' ? '' : height + 'vw'};
    margin: ${({margin}) => margin}vw auto;

    .container-icon-back {
        display: flex;
        align-items: start;
        justify-content: center;
        width: 3vw;
        height: 100%;

        .icon-back {
            width: 1.5vw;
            height: 1.5vw;
            margin-top: 1vw;
            cursor: pointer;
        }
    }

    .container-img {

        .options-admin-img-ball-8-big {
            width: 4.5vw;
            height: 4.5vw;
            border-radius: 100%;
        }
    }

    .container-info {
        display: flex;
        flex-direction: column;
        width: 100%;

        .title-info {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .icon-reload {
                font-size: 1.3vw;
                font-weight: bold;
                color: #000000;
                cursor: pointer;
            }
            .icon-reload-animation {
                animation: spin 4s linear infinite;
            }
            .container-icon-delete {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: .5vw;

                .text-create-bulk-users {
                    cursor: pointer;
                    text-align: left;
                    letter-spacing: 0;
                    color: #ffffff;
                    opacity: 1;
                    font-size: .9vw;
                    font-family: Poppins-Light, sans-serif;
                    font-weight: 300;
                    background: #9933ff;
                    padding: .2vw .6vw;
                    border-radius: .4vw;
                }
                .text-create-user {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 7vw;
                    cursor: pointer;
                    text-align: left;
                    letter-spacing: 0;
                    color: #ffffff;
                    opacity: 1;
                    font-size: .9vw;
                    font-family: Poppins-Light, sans-serif;
                    font-weight: 300;
                    background: #9933ff;
                    padding: .2vw .6vw;
                    border-radius: .4vw;
                }
                .text-create-user:hover {
                    box-shadow: 0px 0px 10px rgba(149, 69, 255, 0.7), 0px 0px 20px rgba(196, 145, 252, 0.7);
                }
                .text-create-bulk-users:hover {
                    box-shadow: 0px 0px 10px rgba(149, 69, 255, 0.7), 0px 0px 20px rgba(196, 145, 252, 0.7);
                }

                .icon-delete {
                    font-size: 1.8vw;
                    cursor: pointer;
                }
            }

            .title {
                margin: 0;
                font-family: Poppins-SemiBold, sans-serif;
                font-weight: 600;
            }

            .container-edit {
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                cursor: pointer;
                margin-right: 1vw;

                .options-admin-img-edit-2 {
                    width: 1.4vw;
                }

                .options-admin-img-edit-1 {
                    position: absolute;
                    bottom: .5vw;
                    left: .6vw;
                    width: 1.1vw;
                }
            }

        }

        .container-players-cards {
            display: flex;
            gap: 2vw;
            align-items: center;
            justify-content: start;
            margin-top: 0.2vw;

            .players-cards {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: .3vw;
                cursor: pointer;

                .options-admin-img-count-players {
                    width: 1.5vw;
                }

                .count-players {
                    text-align: left;
                    letter-spacing: 0;
                    color: #303030;
                    opacity: 1;
                    font-size: 1vw;
                    font-family: Poppins-Light, sans-serif;
                    font-weight: 300;
                }

                .text-players {
                    text-align: left;
                    letter-spacing: 0;
                    color: #303030;
                    opacity: 1;
                    font-size: 1vw;
                    font-family: Poppins-Light, sans-serif;
                    font-weight: 300;
                }
            }
        }
    }

    .container-accumulated {
        width: 60%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 8px;
        margin-left: 15px;

        .p-accumulated {
            margin-right: 10px;
            font-family: Alata-Regular, sans-serif;
            font-weight: 300;
            font-size: .8vw;
            text-align: left;
            letter-spacing: 0;
            color: #545454;
            opacity: 1;
        }

        .rectangle {
            width: 30vw;
            height: 1vh;
            background: transparent linear-gradient(0deg, #631313 0%, #580F0F 10%, #3D0808 40%, #2D0303 66%, #280202 87%) 0% 0% no-repeat padding-box;
            opacity: 1;
            border-radius: 7px;
            padding: 0;
            font-size: 10.22px;
            letter-spacing: 0px;
            color: #F0FF00;

            .rectangle2 {
                width: ${({cumulativePercentage}) => cumulativePercentage + '%'};
                position: relative;
                height: 1vh;
                background: #C491FC 0% 0% no-repeat padding-box;
                opacity: 1;
                border-radius: 7px;


                .result-percentege {
                    position: absolute;
                    right: -0.8vw;
                    top: 0.5vw;
                    background-color: white;
                    color: black;
                    padding: .3vw .5vw;
                    border-radius: .5vw;
                    font-weight: bold;
                }
            }
        }
    }

    .modal-users-session {
        position: absolute;
        top: ${({top}) => top}vw;
        left: ${({left}) => left}vw;
        width: 30vw;
        height: 30vw;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 3px 4px 1px #0000004D;
        border-radius: 25px;
        opacity: 1;
        margin-bottom: 1vw;
        z-index: 9999999999;
    }

    .modal-users-rel-session-and-cards {
        position: absolute;
        top: ${({top}) => top}vw;
        left: ${({left}) => left}vw;
        width: 50vw;
        height: 30vw;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 3px 4px 1px #0000004D;
        border-radius: 25px;
        opacity: 1;
        margin-bottom: 1vw;
        z-index: 9999999999;
    }
    .modal-users-rel-account-and-session {
        position: absolute;
        top: 10vw;
        left: 33vw;
        width: 25vw;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 3px 4px 1px #0000004D;
        border-radius: 25px;
        opacity: 1;
        margin-bottom: 1vw;
        z-index: 9999999999;
    }
    .modal-current-game-data {
        position: absolute;
        top: 3.5vw;
        left: 25vw;
        width: 50vw;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 3px 4px 1px #0000004D;
        border-radius: 25px;
        opacity: 1;
        margin-bottom: 1vw;
        z-index: 9999999999;
        padding-bottom: 2vw;
    }
`
