import {CircularNumberGamerStyled} from "./CircularNumberGamerStyled";

interface ICircularNumber {
    position: string;
    width: number;
    height: number;
    background: string;
    border: string;
    color: string;
    font_size: number;
    number_selected: any;
    onClick?: () => void
}

export default function CircularNumberGamer({
                                           width,
                                           height,
                                           background,
                                           border,
                                           color,
                                           font_size,
                                           number_selected,
                                           position,
                                           onClick,
                                       }: ICircularNumber): JSX.Element {

    return (
        <CircularNumberGamerStyled
            width={width}
            height={height}
            background={background}
            border={border}
            color={color}
            font_size={font_size}
            position={position}
        >
            <div onClick={onClick}>
                {number_selected}
            </div>
        </CircularNumberGamerStyled>
    );
}