
import {
    ALL_SEARCH_SESSIONS_ACTIVE,
    ALL_SEARCH_SESSIONS_ACTIVE_FAILED,
    ALL_SEARCH_SESSIONS_ACTIVE_SUCCESS,
    ALL_SEARCH_SESSIONS_INACTIVE, ALL_SEARCH_SESSIONS_INACTIVE_FAILED, ALL_SEARCH_SESSIONS_INACTIVE_SUCCESS,
    CHANGE_CUMULATIVE_VALUE, CLEAN_ALL_SEARCH_SESSIONS_ACTIVE, CLEAN_ALL_SEARCH_SESSIONS_INACTIVE,
    CLEAN_ALL_SESSIONS,
    CLEAN_SINGLE_SESSION,
    CREATE_SESSION,
    CREATE_SESSION_FAILED,
    CREATE_SESSION_SUCCESS,
    DELETE_SESSION,
    DELETE_SESSION_FAILED,
    DELETE_SESSION_SUCCESS,
    EDIT_SESSION,
    EDIT_SESSION_FAILED,
    EDIT_SESSION_SUCCESS,
    GET_ALL_SESSIONS_ACTIVE,
    GET_ALL_SESSIONS_ACTIVE_FAILED,
    GET_ALL_SESSIONS_ACTIVE_SUCCESS,
    GET_ALL_SESSIONS_INACTIVE,
    GET_ALL_SESSIONS_INACTIVE_FAILED,
    GET_ALL_SESSIONS_INACTIVE_SUCCESS,
    GET_SINGLE_SESSION,
    GET_SINGLE_SESSION_DATABASE,
    GET_SINGLE_SESSION_DATABASE_FAILED,
    GET_SINGLE_SESSION_DATABASE_SUCCESS,
    GET_SINGLE_SESSION_FAILED,
    GET_SINGLE_SESSION_SUCCESS,
    SESSION_SELECTED, UPDATE_OFFSET_SESSION_ACTIVE, UPDATE_OFFSET_SESSION_INACTIVE,

} from "../TypesAdmin/SessionTypes";
import {Session} from "../../domain/models/CreateSessionModel";
import {SessionModel} from "../../domain/models/SessionModel";

interface IStateSessions {
    allSessionsActive:SessionModel[];
    loadingAllSessionsActive:boolean;
    allSessionsInactive:SessionModel[];
    loadingAllSessionsInactive:boolean;
    allSearchSessionsActive:SessionModel[];
    loadingAllSearchSessionsActive:boolean;
    allSearchSessionsInactive:SessionModel[];
    loadingAllSearchSessionsInactive:boolean;
    singleSession:Session | null;
    loadingSingleSession:boolean;
    EditingSession:boolean;
    DeletingSession:boolean;
    CreatingSession:boolean;
    sessionSelected: number | undefined;
    cumulativePercentage: number;
    loadingSingleSessionDatabase: boolean;
    singleSessionDatabase: SessionModel | null;
    offsetSessionActive: number;
    offsetSessionInactive: number;
    ErrorMessage:string
}
const initialStateSession: IStateSessions = {
    allSessionsActive: [],
    loadingAllSessionsActive:false,
    allSessionsInactive: [],
    loadingAllSessionsInactive: false,
    allSearchSessionsActive: [],
    loadingAllSearchSessionsActive: false,
    allSearchSessionsInactive: [],
    loadingAllSearchSessionsInactive: false,
    singleSession: null,
    loadingSingleSession:false,
    EditingSession:false,
    DeletingSession:false,
    CreatingSession:false,
    sessionSelected: undefined,
    cumulativePercentage: 0,
    loadingSingleSessionDatabase: false,
    singleSessionDatabase: null,
    offsetSessionActive: 0,
    offsetSessionInactive: 0,
    ErrorMessage:""
}

const SessionReducer = function (state = initialStateSession, action: {type:any; payload:any}):IStateSessions {
    switch (action.type) {
        case GET_ALL_SESSIONS_ACTIVE:
            return {
                allSessionsActive: state.allSessionsActive,
                loadingAllSessionsActive:true,
                allSessionsInactive: state.allSessionsInactive,
                loadingAllSessionsInactive: state.loadingAllSessionsInactive,
                allSearchSessionsActive:state.allSearchSessionsActive,
                loadingAllSearchSessionsActive: state.loadingAllSearchSessionsActive,
                allSearchSessionsInactive: state.allSearchSessionsInactive,
                loadingAllSearchSessionsInactive: state.loadingAllSearchSessionsInactive,
                singleSession: state.singleSession,
                loadingSingleSession:state.loadingSingleSession,
                EditingSession:state.EditingSession,
                DeletingSession:state.DeletingSession,
                CreatingSession:state.CreatingSession,
                sessionSelected: state.sessionSelected,
                cumulativePercentage: state.cumulativePercentage,
                loadingSingleSessionDatabase: state.loadingSingleSessionDatabase,
                singleSessionDatabase: state.singleSessionDatabase,
                offsetSessionActive: state.offsetSessionActive,
                offsetSessionInactive: state.offsetSessionInactive,
                ErrorMessage:state.ErrorMessage
            };
        case GET_ALL_SESSIONS_ACTIVE_SUCCESS:
            return {
                allSessionsActive: action.payload,
                loadingAllSessionsActive:false,
                allSessionsInactive: state.allSessionsInactive,
                loadingAllSessionsInactive: state.loadingAllSessionsInactive,allSearchSessionsActive:state.allSearchSessionsActive,
                loadingAllSearchSessionsActive: state.loadingAllSearchSessionsActive,
                allSearchSessionsInactive: state.allSearchSessionsInactive,
                loadingAllSearchSessionsInactive: state.loadingAllSearchSessionsInactive,
                singleSession: state.singleSession,
                loadingSingleSession:state.loadingSingleSession,
                EditingSession:state.EditingSession,
                DeletingSession:state.DeletingSession,
                CreatingSession:state.CreatingSession,
                sessionSelected: state.sessionSelected,
                cumulativePercentage: state.cumulativePercentage,
                loadingSingleSessionDatabase: state.loadingSingleSessionDatabase,
                singleSessionDatabase: state.singleSessionDatabase,
                offsetSessionActive: state.offsetSessionActive,
                offsetSessionInactive: state.offsetSessionInactive,
                ErrorMessage:state.ErrorMessage
            };
        case GET_ALL_SESSIONS_ACTIVE_FAILED:
            return {
                allSessionsActive: state.allSessionsActive,
                loadingAllSessionsActive:false,
                allSessionsInactive: state.allSessionsInactive,
                loadingAllSessionsInactive: state.loadingAllSessionsInactive,
                allSearchSessionsActive:state.allSearchSessionsActive,
                loadingAllSearchSessionsActive: state.loadingAllSearchSessionsActive,
                allSearchSessionsInactive: state.allSearchSessionsInactive,
                loadingAllSearchSessionsInactive: state.loadingAllSearchSessionsInactive,
                singleSession: state.singleSession,
                loadingSingleSession:state.loadingSingleSession,
                EditingSession:state.EditingSession,
                DeletingSession:state.DeletingSession,
                CreatingSession:state.CreatingSession,
                sessionSelected: state.sessionSelected,
                cumulativePercentage: state.cumulativePercentage,
                loadingSingleSessionDatabase: state.loadingSingleSessionDatabase,
                singleSessionDatabase: state.singleSessionDatabase,
                offsetSessionActive: state.offsetSessionActive,
                offsetSessionInactive: state.offsetSessionInactive,
                ErrorMessage:action.payload
            };
        case GET_ALL_SESSIONS_INACTIVE:
            return {
                allSessionsActive: state.allSessionsActive,
                loadingAllSessionsActive:state.loadingAllSessionsActive,
                allSessionsInactive: state.allSessionsInactive,
                loadingAllSessionsInactive: true,
                allSearchSessionsActive:state.allSearchSessionsActive,
                loadingAllSearchSessionsActive: state.loadingAllSearchSessionsActive,
                allSearchSessionsInactive: state.allSearchSessionsInactive,
                loadingAllSearchSessionsInactive: state.loadingAllSearchSessionsInactive,
                singleSession: state.singleSession,
                loadingSingleSession:state.loadingSingleSession,
                EditingSession:state.EditingSession,
                DeletingSession:state.DeletingSession,
                CreatingSession:state.CreatingSession,
                sessionSelected: state.sessionSelected,
                cumulativePercentage: state.cumulativePercentage,
                loadingSingleSessionDatabase: state.loadingSingleSessionDatabase,
                singleSessionDatabase: state.singleSessionDatabase,
                offsetSessionActive: state.offsetSessionActive,
                offsetSessionInactive: state.offsetSessionInactive,
                ErrorMessage:state.ErrorMessage
            };
        case GET_ALL_SESSIONS_INACTIVE_SUCCESS:
            return {
                allSessionsActive: state.allSessionsActive,
                loadingAllSessionsActive:state.loadingAllSessionsActive,
                allSessionsInactive: action.payload,
                loadingAllSessionsInactive: false,
                allSearchSessionsActive:state.allSearchSessionsActive,
                loadingAllSearchSessionsActive: state.loadingAllSearchSessionsActive,
                allSearchSessionsInactive: state.allSearchSessionsInactive,
                loadingAllSearchSessionsInactive: state.loadingAllSearchSessionsInactive,
                singleSession: state.singleSession,
                loadingSingleSession:state.loadingSingleSession,
                EditingSession:state.EditingSession,
                DeletingSession:state.DeletingSession,
                CreatingSession:state.CreatingSession,
                sessionSelected: state.sessionSelected,
                cumulativePercentage: state.cumulativePercentage,
                loadingSingleSessionDatabase: state.loadingSingleSessionDatabase,
                singleSessionDatabase: state.singleSessionDatabase,
                offsetSessionActive: state.offsetSessionActive,
                offsetSessionInactive: state.offsetSessionInactive,
                ErrorMessage:state.ErrorMessage
            };
        case GET_ALL_SESSIONS_INACTIVE_FAILED:
            return {
                allSessionsActive: state.allSessionsActive,
                loadingAllSessionsActive:state.loadingAllSessionsActive,
                allSessionsInactive: state.allSessionsInactive,
                loadingAllSessionsInactive: false,
                allSearchSessionsActive:state.allSearchSessionsActive,
                loadingAllSearchSessionsActive: state.loadingAllSearchSessionsActive,
                allSearchSessionsInactive: state.allSearchSessionsInactive,
                loadingAllSearchSessionsInactive: state.loadingAllSearchSessionsInactive,
                singleSession: state.singleSession,
                loadingSingleSession:state.loadingSingleSession,
                EditingSession:state.EditingSession,
                DeletingSession:state.DeletingSession,
                CreatingSession:state.CreatingSession,
                sessionSelected: state.sessionSelected,
                cumulativePercentage: state.cumulativePercentage,
                loadingSingleSessionDatabase: state.loadingSingleSessionDatabase,
                singleSessionDatabase: state.singleSessionDatabase,
                offsetSessionActive: state.offsetSessionActive,
                offsetSessionInactive: state.offsetSessionInactive,
                ErrorMessage:action.payload
            };
        case GET_SINGLE_SESSION:
            return {
                allSessionsActive: state.allSessionsActive,
                loadingAllSessionsActive:state.loadingAllSessionsActive,
                allSessionsInactive: state.allSessionsInactive,
                loadingAllSessionsInactive: state.loadingAllSessionsInactive,
                allSearchSessionsActive:state.allSearchSessionsActive,
                loadingAllSearchSessionsActive: state.loadingAllSearchSessionsActive,
                allSearchSessionsInactive: state.allSearchSessionsInactive,
                loadingAllSearchSessionsInactive: state.loadingAllSearchSessionsInactive,
                singleSession: state.singleSession,
                loadingSingleSession:true,
                EditingSession:state.EditingSession,
                DeletingSession:state.DeletingSession,
                CreatingSession:state.CreatingSession,
                sessionSelected: state.sessionSelected,
                cumulativePercentage: state.cumulativePercentage,
                loadingSingleSessionDatabase: state.loadingSingleSessionDatabase,
                singleSessionDatabase: state.singleSessionDatabase,
                offsetSessionActive: state.offsetSessionActive,
                offsetSessionInactive: state.offsetSessionInactive,
                ErrorMessage:state.ErrorMessage
            };
        case GET_SINGLE_SESSION_SUCCESS:
            return {
                allSessionsActive: state.allSessionsActive,
                loadingAllSessionsActive:state.loadingAllSessionsActive,
                allSessionsInactive: state.allSessionsInactive,
                loadingAllSessionsInactive: state.loadingAllSessionsInactive,
                allSearchSessionsActive:state.allSearchSessionsActive,
                loadingAllSearchSessionsActive: state.loadingAllSearchSessionsActive,
                allSearchSessionsInactive: state.allSearchSessionsInactive,
                loadingAllSearchSessionsInactive: state.loadingAllSearchSessionsInactive,
                singleSession: action.payload,
                loadingSingleSession:false,
                EditingSession:state.EditingSession,
                DeletingSession:state.DeletingSession,
                CreatingSession:state.CreatingSession,
                sessionSelected: state.sessionSelected,
                cumulativePercentage: state.cumulativePercentage,
                loadingSingleSessionDatabase: state.loadingSingleSessionDatabase,
                singleSessionDatabase: state.singleSessionDatabase,
                offsetSessionActive: state.offsetSessionActive,
                offsetSessionInactive: state.offsetSessionInactive,
                ErrorMessage:state.ErrorMessage
            };
        case GET_SINGLE_SESSION_FAILED:
            return {
                allSessionsActive: state.allSessionsActive,
                loadingAllSessionsActive:state.loadingAllSessionsActive,
                allSessionsInactive: state.allSessionsInactive,
                loadingAllSessionsInactive: state.loadingAllSessionsInactive,
                allSearchSessionsActive:state.allSearchSessionsActive,
                loadingAllSearchSessionsActive: state.loadingAllSearchSessionsActive,
                allSearchSessionsInactive: state.allSearchSessionsInactive,
                loadingAllSearchSessionsInactive: state.loadingAllSearchSessionsInactive,
                singleSession: state.singleSession,
                loadingSingleSession:false,
                EditingSession:state.EditingSession,
                DeletingSession:state.DeletingSession,
                CreatingSession:state.CreatingSession,
                sessionSelected: state.sessionSelected,
                cumulativePercentage: state.cumulativePercentage,
                loadingSingleSessionDatabase: state.loadingSingleSessionDatabase,
                singleSessionDatabase: state.singleSessionDatabase,
                offsetSessionActive: state.offsetSessionActive,
                offsetSessionInactive: state.offsetSessionInactive,
                ErrorMessage:action.payload
            };
        case CREATE_SESSION:
            return {
                allSessionsActive: state.allSessionsActive,
                loadingAllSessionsActive:state.loadingAllSessionsActive,
                allSessionsInactive: state.allSessionsInactive,
                loadingAllSessionsInactive: state.loadingAllSessionsInactive,
                allSearchSessionsActive:state.allSearchSessionsActive,
                loadingAllSearchSessionsActive: state.loadingAllSearchSessionsActive,
                allSearchSessionsInactive: state.allSearchSessionsInactive,
                loadingAllSearchSessionsInactive: state.loadingAllSearchSessionsInactive,
                singleSession: state.singleSession,
                loadingSingleSession:state.loadingSingleSession,
                EditingSession:state.EditingSession,
                DeletingSession:state.DeletingSession,
                CreatingSession:true,
                sessionSelected: state.sessionSelected,
                cumulativePercentage: state.cumulativePercentage,
                loadingSingleSessionDatabase: state.loadingSingleSessionDatabase,
                singleSessionDatabase: state.singleSessionDatabase,
                offsetSessionActive: state.offsetSessionActive,
                offsetSessionInactive: state.offsetSessionInactive,
                ErrorMessage:state.ErrorMessage
            };
        case CREATE_SESSION_SUCCESS:
            return {
                allSessionsActive: state.allSessionsActive,
                loadingAllSessionsActive:state.loadingAllSessionsActive,
                allSessionsInactive: state.allSessionsInactive,
                loadingAllSessionsInactive: state.loadingAllSessionsInactive,
                allSearchSessionsActive:state.allSearchSessionsActive,
                loadingAllSearchSessionsActive: state.loadingAllSearchSessionsActive,
                allSearchSessionsInactive: state.allSearchSessionsInactive,
                loadingAllSearchSessionsInactive: state.loadingAllSearchSessionsInactive,
                singleSession: state.singleSession,
                loadingSingleSession:state.loadingSingleSession,
                EditingSession:state.EditingSession,
                DeletingSession:state.DeletingSession,
                CreatingSession:false,
                sessionSelected: state.sessionSelected,
                cumulativePercentage: state.cumulativePercentage,
                loadingSingleSessionDatabase: state.loadingSingleSessionDatabase,
                singleSessionDatabase: state.singleSessionDatabase,
                offsetSessionActive: state.offsetSessionActive,
                offsetSessionInactive: state.offsetSessionInactive,
                ErrorMessage:state.ErrorMessage
            };
        case CREATE_SESSION_FAILED:
            return {
                allSessionsActive: state.allSessionsActive,
                loadingAllSessionsActive:state.loadingAllSessionsActive,
                allSessionsInactive: state.allSessionsInactive,
                loadingAllSessionsInactive: state.loadingAllSessionsInactive,
                allSearchSessionsActive:state.allSearchSessionsActive,
                loadingAllSearchSessionsActive: state.loadingAllSearchSessionsActive,
                allSearchSessionsInactive: state.allSearchSessionsInactive,
                loadingAllSearchSessionsInactive: state.loadingAllSearchSessionsInactive,
                singleSession: state.singleSession,
                loadingSingleSession:state.loadingSingleSession,
                EditingSession:state.EditingSession,
                DeletingSession:state.DeletingSession,
                CreatingSession:false,
                sessionSelected: state.sessionSelected,
                cumulativePercentage: state.cumulativePercentage,
                loadingSingleSessionDatabase: state.loadingSingleSessionDatabase,
                singleSessionDatabase: state.singleSessionDatabase,
                offsetSessionActive: state.offsetSessionActive,
                offsetSessionInactive: state.offsetSessionInactive,
                ErrorMessage:action.payload
            };
        case EDIT_SESSION:
            return {
                allSessionsActive: state.allSessionsActive,
                loadingAllSessionsActive:state.loadingAllSessionsActive,
                allSessionsInactive: state.allSessionsInactive,
                loadingAllSessionsInactive: state.loadingAllSessionsInactive,
                allSearchSessionsActive:state.allSearchSessionsActive,
                loadingAllSearchSessionsActive: state.loadingAllSearchSessionsActive,
                allSearchSessionsInactive: state.allSearchSessionsInactive,
                loadingAllSearchSessionsInactive: state.loadingAllSearchSessionsInactive,
                singleSession: state.singleSession,
                loadingSingleSession:state.loadingSingleSession,
                EditingSession:true,
                DeletingSession:state.DeletingSession,
                CreatingSession:state.CreatingSession,
                sessionSelected: state.sessionSelected,
                cumulativePercentage: state.cumulativePercentage,
                loadingSingleSessionDatabase: state.loadingSingleSessionDatabase,
                singleSessionDatabase: state.singleSessionDatabase,
                offsetSessionActive: state.offsetSessionActive,
                offsetSessionInactive: state.offsetSessionInactive,
                ErrorMessage:state.ErrorMessage
            };
        case EDIT_SESSION_SUCCESS:
            return {
                allSessionsActive: state.allSessionsActive,
                loadingAllSessionsActive:state.loadingAllSessionsActive,
                allSessionsInactive: state.allSessionsInactive,
                loadingAllSessionsInactive: state.loadingAllSessionsInactive,
                allSearchSessionsActive:state.allSearchSessionsActive,
                loadingAllSearchSessionsActive: state.loadingAllSearchSessionsActive,
                allSearchSessionsInactive: state.allSearchSessionsInactive,
                loadingAllSearchSessionsInactive: state.loadingAllSearchSessionsInactive,
                singleSession: state.singleSession,
                loadingSingleSession:state.loadingSingleSession,
                EditingSession:false,
                DeletingSession:state.DeletingSession,
                CreatingSession:state.CreatingSession,
                sessionSelected: state.sessionSelected,
                cumulativePercentage: state.cumulativePercentage,
                loadingSingleSessionDatabase: state.loadingSingleSessionDatabase,
                singleSessionDatabase: state.singleSessionDatabase,
                offsetSessionActive: state.offsetSessionActive,
                offsetSessionInactive: state.offsetSessionInactive,
                ErrorMessage:state.ErrorMessage
            };
        case EDIT_SESSION_FAILED:
            return {
                allSessionsActive: state.allSessionsActive,
                loadingAllSessionsActive:state.loadingAllSessionsActive,
                allSessionsInactive: state.allSessionsInactive,
                loadingAllSessionsInactive: state.loadingAllSessionsInactive,
                allSearchSessionsActive:state.allSearchSessionsActive,
                loadingAllSearchSessionsActive: state.loadingAllSearchSessionsActive,
                allSearchSessionsInactive: state.allSearchSessionsInactive,
                loadingAllSearchSessionsInactive: state.loadingAllSearchSessionsInactive,
                singleSession: state.singleSession,
                loadingSingleSession:state.loadingSingleSession,
                EditingSession:false,
                DeletingSession:state.DeletingSession,
                CreatingSession:state.CreatingSession,
                sessionSelected: state.sessionSelected,
                cumulativePercentage: state.cumulativePercentage,
                loadingSingleSessionDatabase: state.loadingSingleSessionDatabase,
                singleSessionDatabase: state.singleSessionDatabase,
                offsetSessionActive: state.offsetSessionActive,
                offsetSessionInactive: state.offsetSessionInactive,
                ErrorMessage:action.payload
            };
        case DELETE_SESSION:
            return {
                allSessionsActive: state.allSessionsActive,
                loadingAllSessionsActive:state.loadingAllSessionsActive,
                allSessionsInactive: state.allSessionsInactive,
                loadingAllSessionsInactive: state.loadingAllSessionsInactive,
                allSearchSessionsActive:state.allSearchSessionsActive,
                loadingAllSearchSessionsActive: state.loadingAllSearchSessionsActive,
                allSearchSessionsInactive: state.allSearchSessionsInactive,
                loadingAllSearchSessionsInactive: state.loadingAllSearchSessionsInactive,
                singleSession: state.singleSession,
                loadingSingleSession:state.loadingSingleSession,
                EditingSession:state.EditingSession,
                DeletingSession:true,
                CreatingSession:state.CreatingSession,
                sessionSelected: state.sessionSelected,
                cumulativePercentage: state.cumulativePercentage,
                loadingSingleSessionDatabase: state.loadingSingleSessionDatabase,
                singleSessionDatabase: state.singleSessionDatabase,
                offsetSessionActive: state.offsetSessionActive,
                offsetSessionInactive: state.offsetSessionInactive,
                ErrorMessage:state.ErrorMessage
            };
        case DELETE_SESSION_SUCCESS:
            return {
                allSessionsActive: state.allSessionsActive,
                loadingAllSessionsActive:state.loadingAllSessionsActive,
                allSessionsInactive: state.allSessionsInactive,
                loadingAllSessionsInactive: state.loadingAllSessionsInactive,
                allSearchSessionsActive:state.allSearchSessionsActive,
                loadingAllSearchSessionsActive: state.loadingAllSearchSessionsActive,
                allSearchSessionsInactive: state.allSearchSessionsInactive,
                loadingAllSearchSessionsInactive: state.loadingAllSearchSessionsInactive,
                singleSession: state.singleSession,
                loadingSingleSession:state.loadingSingleSession,
                EditingSession:state.EditingSession,
                DeletingSession:false,
                CreatingSession:state.CreatingSession,
                sessionSelected: state.sessionSelected,
                cumulativePercentage: state.cumulativePercentage,
                loadingSingleSessionDatabase: state.loadingSingleSessionDatabase,
                singleSessionDatabase: state.singleSessionDatabase,
                offsetSessionActive: state.offsetSessionActive,
                offsetSessionInactive: state.offsetSessionInactive,
                ErrorMessage:state.ErrorMessage
            };
        case DELETE_SESSION_FAILED:
            return {
                allSessionsActive: state.allSessionsActive,
                loadingAllSessionsActive:state.loadingAllSessionsActive,
                allSessionsInactive: state.allSessionsInactive,
                loadingAllSessionsInactive: state.loadingAllSessionsInactive,
                allSearchSessionsActive:state.allSearchSessionsActive,
                loadingAllSearchSessionsActive: state.loadingAllSearchSessionsActive,
                allSearchSessionsInactive: state.allSearchSessionsInactive,
                loadingAllSearchSessionsInactive: state.loadingAllSearchSessionsInactive,
                singleSession: state.singleSession,
                loadingSingleSession:state.loadingSingleSession,
                EditingSession:state.EditingSession,
                DeletingSession:false,
                CreatingSession:state.CreatingSession,
                sessionSelected: state.sessionSelected,
                cumulativePercentage: state.cumulativePercentage,
                loadingSingleSessionDatabase: state.loadingSingleSessionDatabase,
                singleSessionDatabase: state.singleSessionDatabase,
                offsetSessionActive: state.offsetSessionActive,
                offsetSessionInactive: state.offsetSessionInactive,
                ErrorMessage:action.payload
            };
        case CLEAN_SINGLE_SESSION:
            return {
                allSessionsActive: state.allSessionsActive,
                loadingAllSessionsActive:state.loadingAllSessionsActive,
                allSessionsInactive: state.allSessionsInactive,
                loadingAllSessionsInactive: state.loadingAllSessionsInactive,
                allSearchSessionsActive:state.allSearchSessionsActive,
                loadingAllSearchSessionsActive: state.loadingAllSearchSessionsActive,
                allSearchSessionsInactive: state.allSearchSessionsInactive,
                loadingAllSearchSessionsInactive: state.loadingAllSearchSessionsInactive,
                singleSession: action.payload,
                loadingSingleSession:state.loadingSingleSession,
                EditingSession:state.EditingSession,
                DeletingSession:state.DeletingSession,
                CreatingSession:state.CreatingSession,
                sessionSelected: state.sessionSelected,
                cumulativePercentage: state.cumulativePercentage,
                loadingSingleSessionDatabase: state.loadingSingleSessionDatabase,
                singleSessionDatabase: state.singleSessionDatabase,
                offsetSessionActive: state.offsetSessionActive,
                offsetSessionInactive: state.offsetSessionInactive,
                ErrorMessage:''
            };
        case CLEAN_ALL_SESSIONS:
            return {
                allSessionsActive: action.payload,
                loadingAllSessionsActive:state.loadingAllSessionsActive,
                allSessionsInactive: state.allSessionsInactive,
                loadingAllSessionsInactive: state.loadingAllSessionsInactive,
                allSearchSessionsActive:state.allSearchSessionsActive,
                loadingAllSearchSessionsActive: state.loadingAllSearchSessionsActive,
                allSearchSessionsInactive: state.allSearchSessionsInactive,
                loadingAllSearchSessionsInactive: state.loadingAllSearchSessionsInactive,
                singleSession: state.singleSession,
                loadingSingleSession:state.loadingSingleSession,
                EditingSession:state.EditingSession,
                DeletingSession:state.DeletingSession,
                CreatingSession:state.CreatingSession,
                sessionSelected: state.sessionSelected,
                cumulativePercentage: state.cumulativePercentage,
                loadingSingleSessionDatabase: state.loadingSingleSessionDatabase,
                singleSessionDatabase: state.singleSessionDatabase,
                offsetSessionActive: state.offsetSessionActive,
                offsetSessionInactive: state.offsetSessionInactive,
                ErrorMessage:''
            };
        case SESSION_SELECTED:
            return {
                allSessionsActive: state.allSessionsActive,
                loadingAllSessionsActive:state.loadingAllSessionsActive,
                allSessionsInactive: state.allSessionsInactive,
                loadingAllSessionsInactive: state.loadingAllSessionsInactive,
                allSearchSessionsActive:state.allSearchSessionsActive,
                loadingAllSearchSessionsActive: state.loadingAllSearchSessionsActive,
                allSearchSessionsInactive: state.allSearchSessionsInactive,
                loadingAllSearchSessionsInactive: state.loadingAllSearchSessionsInactive,
                singleSession: state.singleSession,
                loadingSingleSession:state.loadingSingleSession,
                EditingSession:state.EditingSession,
                DeletingSession:state.DeletingSession,
                CreatingSession:state.CreatingSession,
                sessionSelected: action.payload,
                cumulativePercentage: state.cumulativePercentage,
                loadingSingleSessionDatabase: state.loadingSingleSessionDatabase,
                singleSessionDatabase: state.singleSessionDatabase,
                offsetSessionActive: state.offsetSessionActive,
                offsetSessionInactive: state.offsetSessionInactive,
                ErrorMessage:''
            };
        case CHANGE_CUMULATIVE_VALUE:
            return {
                allSessionsActive: state.allSessionsActive,
                loadingAllSessionsActive:state.loadingAllSessionsActive,
                allSessionsInactive: state.allSessionsInactive,
                loadingAllSessionsInactive: state.loadingAllSessionsInactive,
                allSearchSessionsActive:state.allSearchSessionsActive,
                loadingAllSearchSessionsActive: state.loadingAllSearchSessionsActive,
                allSearchSessionsInactive: state.allSearchSessionsInactive,
                loadingAllSearchSessionsInactive: state.loadingAllSearchSessionsInactive,
                singleSession: state.singleSession,
                loadingSingleSession:state.loadingSingleSession,
                EditingSession:state.EditingSession,
                DeletingSession:state.DeletingSession,
                CreatingSession:state.CreatingSession,
                sessionSelected: state.sessionSelected,
                cumulativePercentage: action.payload,
                loadingSingleSessionDatabase: state.loadingSingleSessionDatabase,
                singleSessionDatabase: state.singleSessionDatabase,
                offsetSessionActive: state.offsetSessionActive,
                offsetSessionInactive: state.offsetSessionInactive,
                ErrorMessage:''
            };
        case GET_SINGLE_SESSION_DATABASE:
            return {
                allSessionsActive: state.allSessionsActive,
                loadingAllSessionsActive:state.loadingAllSessionsActive,
                allSessionsInactive: state.allSessionsInactive,
                loadingAllSessionsInactive: state.loadingAllSessionsInactive,
                allSearchSessionsActive:state.allSearchSessionsActive,
                loadingAllSearchSessionsActive: state.loadingAllSearchSessionsActive,
                allSearchSessionsInactive: state.allSearchSessionsInactive,
                loadingAllSearchSessionsInactive: state.loadingAllSearchSessionsInactive,
                singleSession: state.singleSession,
                loadingSingleSession:state.loadingSingleSession,
                EditingSession:state.EditingSession,
                DeletingSession:state.DeletingSession,
                CreatingSession:state.CreatingSession,
                sessionSelected: state.sessionSelected,
                cumulativePercentage: state.cumulativePercentage,
                loadingSingleSessionDatabase: action.payload,
                singleSessionDatabase: state.singleSessionDatabase,
                offsetSessionActive: state.offsetSessionActive,
                offsetSessionInactive: state.offsetSessionInactive,
                ErrorMessage:''
            };
        case GET_SINGLE_SESSION_DATABASE_SUCCESS:
            return {
                allSessionsActive: state.allSessionsActive,
                loadingAllSessionsActive:state.loadingAllSessionsActive,
                allSessionsInactive: state.allSessionsInactive,
                loadingAllSessionsInactive: state.loadingAllSessionsInactive,
                allSearchSessionsActive:state.allSearchSessionsActive,
                loadingAllSearchSessionsActive: state.loadingAllSearchSessionsActive,
                allSearchSessionsInactive: state.allSearchSessionsInactive,
                loadingAllSearchSessionsInactive: state.loadingAllSearchSessionsInactive,
                singleSession: state.singleSession,
                loadingSingleSession:state.loadingSingleSession,
                EditingSession:state.EditingSession,
                DeletingSession:state.DeletingSession,
                CreatingSession:state.CreatingSession,
                sessionSelected: state.sessionSelected,
                cumulativePercentage: state.cumulativePercentage,
                loadingSingleSessionDatabase: false,
                singleSessionDatabase: action.payload,
                offsetSessionActive: state.offsetSessionActive,
                offsetSessionInactive: state.offsetSessionInactive,
                ErrorMessage:''
            };
        case GET_SINGLE_SESSION_DATABASE_FAILED:
            return {
                allSessionsActive: state.allSessionsActive,
                loadingAllSessionsActive:state.loadingAllSessionsActive,
                allSessionsInactive: state.allSessionsInactive,
                loadingAllSessionsInactive: state.loadingAllSessionsInactive,
                allSearchSessionsActive:state.allSearchSessionsActive,
                loadingAllSearchSessionsActive: state.loadingAllSearchSessionsActive,
                allSearchSessionsInactive: state.allSearchSessionsInactive,
                loadingAllSearchSessionsInactive: state.loadingAllSearchSessionsInactive,
                singleSession: state.singleSession,
                loadingSingleSession:state.loadingSingleSession,
                EditingSession:state.EditingSession,
                DeletingSession:state.DeletingSession,
                CreatingSession:state.CreatingSession,
                sessionSelected: state.sessionSelected,
                cumulativePercentage: state.cumulativePercentage,
                loadingSingleSessionDatabase: false,
                singleSessionDatabase: state.singleSessionDatabase,
                offsetSessionActive: state.offsetSessionActive,
                offsetSessionInactive: state.offsetSessionInactive,
                ErrorMessage: action.payload
            };
        case ALL_SEARCH_SESSIONS_ACTIVE:
            return {
                allSessionsActive: state.allSessionsActive,
                loadingAllSessionsActive:state.loadingAllSessionsActive,
                allSessionsInactive: state.allSessionsInactive,
                loadingAllSessionsInactive: state.loadingAllSessionsInactive,
                allSearchSessionsActive:state.allSearchSessionsActive,
                loadingAllSearchSessionsActive: action.payload,
                allSearchSessionsInactive: state.allSearchSessionsInactive,
                loadingAllSearchSessionsInactive: state.loadingAllSearchSessionsInactive,
                singleSession: state.singleSession,
                loadingSingleSession:state.loadingSingleSession,
                EditingSession:state.EditingSession,
                DeletingSession:state.DeletingSession,
                CreatingSession:state.CreatingSession,
                sessionSelected: state.sessionSelected,
                cumulativePercentage: state.cumulativePercentage,
                loadingSingleSessionDatabase: state.loadingSingleSessionDatabase,
                singleSessionDatabase: state.singleSessionDatabase,
                offsetSessionActive: state.offsetSessionActive,
                offsetSessionInactive: state.offsetSessionInactive,
                ErrorMessage: state.ErrorMessage
            };
        case ALL_SEARCH_SESSIONS_ACTIVE_SUCCESS:
            return {
                allSessionsActive: state.allSessionsActive,
                loadingAllSessionsActive:state.loadingAllSessionsActive,
                allSessionsInactive: state.allSessionsInactive,
                loadingAllSessionsInactive: state.loadingAllSessionsInactive,
                allSearchSessionsActive:action.payload,
                loadingAllSearchSessionsActive: false,
                allSearchSessionsInactive: state.allSearchSessionsInactive,
                loadingAllSearchSessionsInactive: state.loadingAllSearchSessionsInactive,
                singleSession: state.singleSession,
                loadingSingleSession:state.loadingSingleSession,
                EditingSession:state.EditingSession,
                DeletingSession:state.DeletingSession,
                CreatingSession:state.CreatingSession,
                sessionSelected: state.sessionSelected,
                cumulativePercentage: state.cumulativePercentage,
                loadingSingleSessionDatabase: state.loadingSingleSessionDatabase,
                singleSessionDatabase: state.singleSessionDatabase,
                offsetSessionActive: state.offsetSessionActive,
                offsetSessionInactive: state.offsetSessionInactive,
                ErrorMessage: state.ErrorMessage
            };
        case ALL_SEARCH_SESSIONS_ACTIVE_FAILED:
            return {
                allSessionsActive: state.allSessionsActive,
                loadingAllSessionsActive:state.loadingAllSessionsActive,
                allSessionsInactive: state.allSessionsInactive,
                loadingAllSessionsInactive: state.loadingAllSessionsInactive,
                allSearchSessionsActive:state.allSearchSessionsActive,
                loadingAllSearchSessionsActive: false,
                allSearchSessionsInactive: state.allSearchSessionsInactive,
                loadingAllSearchSessionsInactive: state.loadingAllSearchSessionsInactive,
                singleSession: state.singleSession,
                loadingSingleSession:state.loadingSingleSession,
                EditingSession:state.EditingSession,
                DeletingSession:state.DeletingSession,
                CreatingSession:state.CreatingSession,
                sessionSelected: state.sessionSelected,
                cumulativePercentage: state.cumulativePercentage,
                loadingSingleSessionDatabase: state.loadingSingleSessionDatabase,
                singleSessionDatabase: state.singleSessionDatabase,
                offsetSessionActive: state.offsetSessionActive,
                offsetSessionInactive: state.offsetSessionInactive,
                ErrorMessage: action.payload
            };
        case ALL_SEARCH_SESSIONS_INACTIVE:
            return {
                allSessionsActive: state.allSessionsActive,
                loadingAllSessionsActive:state.loadingAllSessionsActive,
                allSessionsInactive: state.allSessionsInactive,
                loadingAllSessionsInactive: state.loadingAllSessionsInactive,
                allSearchSessionsActive:state.allSearchSessionsActive,
                loadingAllSearchSessionsActive: state.loadingAllSearchSessionsActive,
                allSearchSessionsInactive: state.allSearchSessionsInactive,
                loadingAllSearchSessionsInactive: action.payload,
                singleSession: state.singleSession,
                loadingSingleSession:state.loadingSingleSession,
                EditingSession:state.EditingSession,
                DeletingSession:state.DeletingSession,
                CreatingSession:state.CreatingSession,
                sessionSelected: state.sessionSelected,
                cumulativePercentage: state.cumulativePercentage,
                loadingSingleSessionDatabase: state.loadingSingleSessionDatabase,
                singleSessionDatabase: state.singleSessionDatabase,
                offsetSessionActive: state.offsetSessionActive,
                offsetSessionInactive: state.offsetSessionInactive,
                ErrorMessage: state.ErrorMessage
            };
        case ALL_SEARCH_SESSIONS_INACTIVE_SUCCESS:
            return {
                allSessionsActive: state.allSessionsActive,
                loadingAllSessionsActive:state.loadingAllSessionsActive,
                allSessionsInactive: state.allSessionsInactive,
                loadingAllSessionsInactive: state.loadingAllSessionsInactive,
                allSearchSessionsActive:state.allSearchSessionsActive,
                loadingAllSearchSessionsActive: state.loadingAllSearchSessionsActive,
                allSearchSessionsInactive:action.payload,
                loadingAllSearchSessionsInactive: false,
                singleSession: state.singleSession,
                loadingSingleSession:state.loadingSingleSession,
                EditingSession:state.EditingSession,
                DeletingSession:state.DeletingSession,
                CreatingSession:state.CreatingSession,
                sessionSelected: state.sessionSelected,
                cumulativePercentage: state.cumulativePercentage,
                loadingSingleSessionDatabase: state.loadingSingleSessionDatabase,
                singleSessionDatabase: state.singleSessionDatabase,
                offsetSessionActive: state.offsetSessionActive,
                offsetSessionInactive: state.offsetSessionInactive,
                ErrorMessage: state.ErrorMessage
            };
        case ALL_SEARCH_SESSIONS_INACTIVE_FAILED:
            return {
                allSessionsActive: state.allSessionsActive,
                loadingAllSessionsActive:state.loadingAllSessionsActive,
                allSessionsInactive: state.allSessionsInactive,
                loadingAllSessionsInactive: state.loadingAllSessionsInactive,
                allSearchSessionsActive:state.allSearchSessionsActive,
                loadingAllSearchSessionsActive: state.loadingAllSearchSessionsActive,
                allSearchSessionsInactive:state.allSearchSessionsInactive,
                loadingAllSearchSessionsInactive: false,
                singleSession: state.singleSession,
                loadingSingleSession:state.loadingSingleSession,
                EditingSession:state.EditingSession,
                DeletingSession:state.DeletingSession,
                CreatingSession:state.CreatingSession,
                sessionSelected: state.sessionSelected,
                cumulativePercentage: state.cumulativePercentage,
                loadingSingleSessionDatabase: state.loadingSingleSessionDatabase,
                singleSessionDatabase: state.singleSessionDatabase,
                offsetSessionActive: state.offsetSessionActive,
                offsetSessionInactive: state.offsetSessionInactive,
                ErrorMessage: action.payload
            };
        case CLEAN_ALL_SEARCH_SESSIONS_ACTIVE:
            return {
                allSessionsActive: state.allSessionsActive,
                loadingAllSessionsActive:state.loadingAllSessionsActive,
                allSessionsInactive: state.allSessionsInactive,
                loadingAllSessionsInactive: state.loadingAllSessionsInactive,
                allSearchSessionsActive:action.payload,
                loadingAllSearchSessionsActive: state.loadingAllSearchSessionsActive,
                allSearchSessionsInactive:state.allSearchSessionsInactive,
                loadingAllSearchSessionsInactive: state.loadingAllSearchSessionsInactive,
                singleSession: state.singleSession,
                loadingSingleSession:state.loadingSingleSession,
                EditingSession:state.EditingSession,
                DeletingSession:state.DeletingSession,
                CreatingSession:state.CreatingSession,
                sessionSelected: state.sessionSelected,
                cumulativePercentage: state.cumulativePercentage,
                loadingSingleSessionDatabase: state.loadingSingleSessionDatabase,
                singleSessionDatabase: state.singleSessionDatabase,
                offsetSessionActive: state.offsetSessionActive,
                offsetSessionInactive: state.offsetSessionInactive,
                ErrorMessage: state.ErrorMessage
            };
        case CLEAN_ALL_SEARCH_SESSIONS_INACTIVE:
            return {
                allSessionsActive: state.allSessionsActive,
                loadingAllSessionsActive:state.loadingAllSessionsActive,
                allSessionsInactive: state.allSessionsInactive,
                loadingAllSessionsInactive: state.loadingAllSessionsInactive,
                allSearchSessionsActive:state.allSearchSessionsActive,
                loadingAllSearchSessionsActive: state.loadingAllSearchSessionsActive,
                allSearchSessionsInactive:action.payload,
                loadingAllSearchSessionsInactive: state.loadingAllSearchSessionsInactive,
                singleSession: state.singleSession,
                loadingSingleSession:state.loadingSingleSession,
                EditingSession:state.EditingSession,
                DeletingSession:state.DeletingSession,
                CreatingSession:state.CreatingSession,
                sessionSelected: state.sessionSelected,
                cumulativePercentage: state.cumulativePercentage,
                loadingSingleSessionDatabase: state.loadingSingleSessionDatabase,
                singleSessionDatabase: state.singleSessionDatabase,
                offsetSessionActive: state.offsetSessionActive,
                offsetSessionInactive: state.offsetSessionInactive,
                ErrorMessage: state.ErrorMessage
            };
        case UPDATE_OFFSET_SESSION_ACTIVE:
            return {
                allSessionsActive: state.allSessionsActive,
                loadingAllSessionsActive:state.loadingAllSessionsActive,
                allSessionsInactive: state.allSessionsInactive,
                loadingAllSessionsInactive: state.loadingAllSessionsInactive,
                allSearchSessionsActive:state.allSearchSessionsActive,
                loadingAllSearchSessionsActive: state.loadingAllSearchSessionsActive,
                allSearchSessionsInactive: state.allSearchSessionsInactive,
                loadingAllSearchSessionsInactive: state.loadingAllSearchSessionsInactive,
                singleSession: state.singleSession,
                loadingSingleSession:state.loadingSingleSession,
                EditingSession:state.EditingSession,
                DeletingSession:state.DeletingSession,
                CreatingSession:state.CreatingSession,
                sessionSelected: state.sessionSelected,
                cumulativePercentage: state.cumulativePercentage,
                loadingSingleSessionDatabase: state.loadingSingleSessionDatabase,
                singleSessionDatabase: state.singleSessionDatabase,
                offsetSessionActive: action.payload,
                offsetSessionInactive: state.offsetSessionInactive,
                ErrorMessage: state.ErrorMessage
            };
        case UPDATE_OFFSET_SESSION_INACTIVE:
            return {
                allSessionsActive: state.allSessionsActive,
                loadingAllSessionsActive:state.loadingAllSessionsActive,
                allSessionsInactive: state.allSessionsInactive,
                loadingAllSessionsInactive: state.loadingAllSessionsInactive,
                allSearchSessionsActive:state.allSearchSessionsActive,
                loadingAllSearchSessionsActive: state.loadingAllSearchSessionsActive,
                allSearchSessionsInactive: state.allSearchSessionsInactive,
                loadingAllSearchSessionsInactive: state.loadingAllSearchSessionsInactive,
                singleSession: state.singleSession,
                loadingSingleSession:state.loadingSingleSession,
                EditingSession:state.EditingSession,
                DeletingSession:state.DeletingSession,
                CreatingSession:state.CreatingSession,
                sessionSelected: state.sessionSelected,
                cumulativePercentage: state.cumulativePercentage,
                loadingSingleSessionDatabase: state.loadingSingleSessionDatabase,
                singleSessionDatabase: state.singleSessionDatabase,
                offsetSessionActive: state.offsetSessionActive,
                offsetSessionInactive: action.payload,
                ErrorMessage: state.ErrorMessage
            };
        default:
            return state;
    }
}
export default SessionReducer;