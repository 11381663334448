export function validatePhoneNumber(value:string) {
    console.log(value);
    if (value === '') {
        return 'El campo del teléfono es requerido...';
    }
    if (/\D/.test(value)) {
        return 'Solo se aceptan números...';
    }
    if (value.length < 10) {
        return 'El teléfono debe tener 10 cifras...';
    } else {
        return true;
    }
}
