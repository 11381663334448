import {WelcomeMessageStyled} from "./WelcomeMessageStyled";
import {useAppSelector} from "../../../../admin/shared/Utils/Hooks";

export default function WelcomeMessage():JSX.Element {

    const allSessionsEndUser = useAppSelector((state) => state.sessionEndUserState.allSessionsEndUser);

    const userEndActive = useAppSelector((state) => state.userState.userEndActive);

    return (
        <WelcomeMessageStyled>
            <div className="text-container">
                <span className="welcome-text">Bienvendo {userEndActive?.name}</span>
                <span className="description-text">
                    {allSessionsEndUser && allSessionsEndUser.length !== 0 ? 'Elige una sala para empezar a jugar' : 'No hay salas por el momento'}
                </span>
            </div>
        </WelcomeMessageStyled>
    );
}
