import {
    EDIT_ACCOUNT_CONFIG,
    EDIT_ACCOUNT_CONFIG_FAILED,
    EDIT_ACCOUNT_CONFIG_SUCCESS
} from "../TypesAdmin/AccountConfigTypes";

interface IStateAccountConfig {
    isLoadingAccountConfig: boolean;
    messages: string;
    errors: string;
}
const initialStateAccountConfig: IStateAccountConfig = {
    isLoadingAccountConfig: false,
    messages: '',
    errors: '',
}
const AccountConfigReducer = function (state = initialStateAccountConfig, action: {type:any; payload:any}):IStateAccountConfig {
    switch (action.type) {
        case EDIT_ACCOUNT_CONFIG:
            return {
                isLoadingAccountConfig: action.payload,
                messages: '',
                errors: '',
            };
        case EDIT_ACCOUNT_CONFIG_SUCCESS:
            return {
                isLoadingAccountConfig: false,
                messages: '',
                errors: '',
            };
        case EDIT_ACCOUNT_CONFIG_FAILED:
            return {
                isLoadingAccountConfig: false,
                messages: '',
                errors: action.payload,
            };
        default:
            return state;
    }
}
export default AccountConfigReducer;