interface IGameModes {
    ref:string;
    translationRef:string;
}
export const gameModes:IGameModes[] = [
    {
        ref: 'any_row',
        translationRef: 'Cualquier fila'
    },
    {
        ref: 'all_card',
        translationRef: 'Todo el tarjetón'
    },
    {
        ref: 'any_col',
        translationRef: 'Cualquier columna'
    },
    {
        ref: 'row_1',
        translationRef: 'Primera fila'
    },
    {
        ref: 'row_2',
        translationRef: 'Segunda fila'
    },
    {
        ref: 'row_3',
        translationRef: 'Tercera fila'
    },
    {
        ref: 'row_4',
        translationRef: 'Cuarta fila'
    },
    {
        ref: 'row_5',
        translationRef: 'Quinta fila'
    },
    {
        ref: 'col_1',
        translationRef: 'Columna B'
    },
    {
        ref: 'col_2',
        translationRef: 'Columna I'
    },
    {
        ref: 'col_3',
        translationRef: 'Columna N'
    },
    {
        ref: 'col_4',
        translationRef: 'Columna G'
    },
    {
        ref: 'col_5',
        translationRef: 'Columna O'
    },
    {
        ref: 'diagonal',
        translationRef: 'Diagonal'
    },
    {
        ref: 'anti_diagonal',
        translationRef: 'Antidiagonal'
    },
    {
        ref: 'shape_X',
        translationRef: 'Forma X'
    },
    {
        ref: 'shape_L',
        translationRef: 'Forma L'
    },
    {
        ref: 'corners',
        translationRef: '4 Esquinas'
    },
]
// export const gameModes =  ['any_row', 'all_card', 'any_col', 'row_1', 'row_2', 'row_3', 'row_4',
//     'row_5', 'col_1', 'col_2', 'col_3', 'col_4', 'col_5',
//     'diagonal', 'anti_diagonal', 'shape_X', 'shape_L', 'corners'];