import axios from "axios";
import {
    CHANGE_LOADING_OTP,
    CHANGE_LOADING_REGISTER,
    CHANGE_LOADING_SIGN_IN,
    CONFIRMATION_RESULT_FIREBASE,
    GET_USER_ACTIVE,
    GET_USER_ACTIVE_FAILED,
    GET_USER_ACTIVE_SUCCESS,
    LOGOUT_SUCCESS,
    NUMBER_PHONE_LOGIN,
    VERIFY_NUMBER_PHONE,
    VERIFY_NUMBER_PHONE1, VERIFY_NUMBER_PHONE1_FAILED, VERIFY_NUMBER_PHONE1_SUCCESS,
    VERIFY_NUMBER_PHONE_FAILED,
    VERIFY_NUMBER_PHONE_SUCCESS
} from "../TypesAdmin/AuthUserType";
import {ResponseAuthModel} from "../../domain/models/PostUser";
import {myDBDomain} from "../../shared/Utils/TestingApi/MyDBDomain";
import {VerifyNumberPhoneModel, VerifyPhoneModel} from "../../domain/models/VerifyNumberPhoneModel";
import {ConfirmationResult} from "firebase/auth";
import {alertError} from "../../shared/Utils/AlertError";
import {setSessionStorageObj} from "../../shared/Utils/SetSessionStorageObject";

export const authUser = (token:string) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/auth/confirm`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        }
    };
    dispatch({
        type: GET_USER_ACTIVE,
        payload: true
    });
    await axios.request(config)
        .then((response) => {
            const responseData: ResponseAuthModel = response.data;
            console.log(response.data);
            dispatch({
                type: GET_USER_ACTIVE_SUCCESS,
                payload: responseData
            });
        })
        .catch((error) => {
            console.log(error.response.data.message);
            dispatch({
                type: GET_USER_ACTIVE_FAILED,
                payload: error.response.data.message
            });
        });
}
export const logoutPost = () => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    sessionStorage.removeItem('secret');
    dispatch({
        type: LOGOUT_SUCCESS,
        payload: null
    });
}
export const verifyNumberPhoneAndAccount = (data: VerifyNumberPhoneModel) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/user/get-for-phone-account-full`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };
    dispatch({
        type: VERIFY_NUMBER_PHONE,
        payload: true
    });
    await axios.request(config)
        .then((response) => {
            console.log('pasé la verificacion del numero de celular...................');
            console.log(response.data);
            dispatch({
                type: VERIFY_NUMBER_PHONE_SUCCESS,
                payload: false
            });
            setSessionStorageObj(response.data.data, 'user');
        })
        .catch((error) => {
            console.log('debo registrarme...........');
            console.log(error);
            if (error.response.status === 401) {
                alertError(`${error.resolver.data.error} debe registrarse `);
                dispatch({
                    type: VERIFY_NUMBER_PHONE_FAILED,
                    payload: error.resolver.data.error
                });
            }
        });
}
export const verifyNumberPhone = (data: VerifyPhoneModel) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    console.log(data);
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/user/get-for-phone`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };
    dispatch({
        type: VERIFY_NUMBER_PHONE1,
        payload: true
    });
    await axios.request(config)
        .then((response) => {
            console.log('pasé la verificacion del numero de celular...................');
            console.log(response.status);
            dispatch({
                type: VERIFY_NUMBER_PHONE1_SUCCESS,
                payload: response.status
            });
        })
        .catch((error) => {
            console.log('debo registrarme...........');
            console.log(error);
            if (error.response.status === 401) {
                alertError(`${error.resolver.data.error} debe registrarse `);
                dispatch({
                    type: VERIFY_NUMBER_PHONE1_FAILED,
                    payload: error.response.status
                });
            }
        });
}
export const getNumberPhoneLogin = (number: string) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: NUMBER_PHONE_LOGIN,
        payload: number
    });
}
export const getConfirmationResultFirebase = (confirmationFirebase: ConfirmationResult | null) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    console.log(confirmationFirebase);
    dispatch({
        type: CONFIRMATION_RESULT_FIREBASE,
        payload: confirmationFirebase
    });
}
export const changeLoadingSignIn = (action: boolean) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: CHANGE_LOADING_SIGN_IN,
        payload: action
    });
}
export const changeLoadingRegister = (action: boolean) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: CHANGE_LOADING_REGISTER,
        payload: action
    });
}
export const changeLoadingOTP = (action: boolean) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: CHANGE_LOADING_OTP,
        payload: action
    });
}
