import styled from "styled-components";
import {Device} from "../../../shared/Utils/devices";

interface ICircularNumberStyled {
    position:string;
    width:number;
    height:number;
    background:string;
    border:string;
    color:string;
    font_size:number;
}

export const CircularNumberGamerStyled = styled.div<ICircularNumberStyled>`
    position: ${({position}) => position};
    top: -1vw;
    width: ${({width}) => width + 'vw'};
    height: ${({height}) => height + 'vw'};
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({background}) => background};
    border: ${({border}) => border};
    opacity: 1;
    border-radius: 100%;
    letter-spacing: 0;
    color: ${({color}) => color};
    font-weight: bold;
    font-size: ${({font_size}) => font_size + 'vw'};
    padding-bottom: 0.2vw;

    @media ${Device.tablet} {
        width: 4.4vw;
        height: 4.4vw;
        font-size: 3vw;
    }
    
    @media ${Device.mobileL} {
        width: 4.4vw;
        height: 4.4vw;
        font-size: 3vw;
    }
    @media ${Device.mobileM} {
        width: 4.4vw;
        height: 4.4vw;
        font-size: 3vw;
    }
    @media ${Device.mobileS} {
        width: 4.4vw;
        height: 4.4vw;
        font-size: 3vw;
    }
`