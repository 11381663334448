import {CHANGE_CUMULATIVE_VALUE} from "../TypesAdmin/CumulativeType";

interface IStateCumulative {
    cumulativePercentage: number;
}
const initialStateCumulative: IStateCumulative = {
    cumulativePercentage: 0,
}
const CumulativeReducer = function (state = initialStateCumulative, action: {type:any; payload:any}):IStateCumulative {
    switch (action.type) {
        case CHANGE_CUMULATIVE_VALUE:
            return {
                cumulativePercentage: action.payload
            };
        default:
            return state
    }
}
export default CumulativeReducer;