export const GET_ALL_SESSIONS_ACTIVE = 'GET_ALL_SESSIONS_ACTIVE';
export const GET_ALL_SESSIONS_ACTIVE_SUCCESS = 'GET_ALL_SESSIONS_ACTIVE_SUCCESS';
export const GET_ALL_SESSIONS_ACTIVE_FAILED = 'GET_ALL_SESSIONS_ACTIVE_FAILED';
export const GET_ALL_SESSIONS_INACTIVE = 'GET_ALL_SESSIONS_INACTIVE';
export const GET_ALL_SESSIONS_INACTIVE_SUCCESS = 'GET_ALL_SESSIONS_INACTIVE_SUCCESS';
export const GET_ALL_SESSIONS_INACTIVE_FAILED = 'GET_ALL_SESSIONS_INACTIVE_FAILED';
export const GET_SINGLE_SESSION = 'GET_SINGLE_SESSION';
export const GET_SINGLE_SESSION_SUCCESS = 'GET_SINGLE_SESSION_SUCCESS';
export const GET_SINGLE_SESSION_FAILED = 'GET_SINGLE_SESSION_FAILED';
export const EDIT_SESSION = 'EDIT_SESSION';
export const EDIT_SESSION_SUCCESS = 'EDIT_SESSION_SUCCESS';
export const EDIT_SESSION_FAILED = 'EDIT_SESSION_FAILED';
export const DELETE_SESSION = 'DELETE_SESSION';
export const DELETE_SESSION_SUCCESS = 'DELETE_SESSION_SUCCESS';
export const DELETE_SESSION_FAILED = 'DELETE_SESSION_FAILED';
export const CREATE_SESSION = 'CREATE_SESSION';
export const CREATE_SESSION_SUCCESS = 'CREATE_SESSION_SUCCESS';
export const CREATE_SESSION_FAILED = 'CREATE_SESSION_FAILED';
export const CLEAN_SINGLE_SESSION = 'CLEAN_SINGLE_SESSION';
export const CLEAN_ALL_SESSIONS = 'CLEAN_ALL_SESSIONS';
export const SESSION_SELECTED = 'SESSION_SELECTED';
export const CHANGE_CUMULATIVE_VALUE = 'CHANGE_CUMULATIVE_VALUE';



// types database

export const GET_SINGLE_SESSION_DATABASE = 'GET_SINGLE_SESSION_DATABASE';
export const GET_SINGLE_SESSION_DATABASE_SUCCESS = 'GET_SINGLE_SESSION_DATABASE_SUCCESS';
export const GET_SINGLE_SESSION_DATABASE_FAILED = 'GET_SINGLE_SESSION_DATABASE_FAILED';
export const ALL_SEARCH_SESSIONS_ACTIVE = 'ALL_SEARCH_SESSIONS_ACTIVE';
export const ALL_SEARCH_SESSIONS_ACTIVE_SUCCESS = 'ALL_SEARCH_SESSIONS_ACTIVE_SUCCESS';
export const ALL_SEARCH_SESSIONS_ACTIVE_FAILED = 'ALL_SEARCH_SESSIONS_ACTIVE_FAILED';
export const ALL_SEARCH_SESSIONS_INACTIVE = 'ALL_SEARCH_SESSIONS_INACTIVE';
export const ALL_SEARCH_SESSIONS_INACTIVE_SUCCESS = 'ALL_SEARCH_SESSIONS_INACTIVE_SUCCESS';
export const ALL_SEARCH_SESSIONS_INACTIVE_FAILED = 'ALL_SEARCH_SESSIONS_INACTIVE_FAILED';
export const CLEAN_ALL_SEARCH_SESSIONS_ACTIVE = 'CLEAN_ALL_SEARCH_SESSIONS_ACTIVE';
export const CLEAN_ALL_SEARCH_SESSIONS_INACTIVE = 'CLEAN_ALL_SEARCH_SESSIONS_INACTIVE';
export const UPDATE_OFFSET_SESSION_ACTIVE = 'UPDATE_OFFSET_SESSION_ACTIVE';
export const UPDATE_OFFSET_SESSION_INACTIVE = 'UPDATE_OFFSET_SESSION_INACTIVE';