import styled from "styled-components";
import {Device} from "../../../shared/Utils/devices";

export const UserSessionInfoStyles = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .container-user-info {
        display: flex;
        align-items: center;
        justify-content: start;
        width: 85%;
        margin-top: 1.2vw;

        .container-user-session {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: .5vw;
            margin-right: 2vw;

            .container-icon-session {
                display: flex;
                align-items: center;
                justify-content: center;
                position: revert;
                border-radius: 100%;

                .img-user-session-circle {
                    width: 2vw;
                }

                .img-user-session-avatar {
                    width: 1vw;
                    position: absolute;
                }
            }

            .name-user {
                text-align: center;
                letter-spacing: 0;
                color: #2E304E;
                opacity: 1;
                font-size: 1vw;
                font-weight: 600;
                font-family: Poppins-SemiBold, sans-serif;
            }
        }
    }
    .container-user-link {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        gap: .5vw;
        width: 80%;
        height: 12.5vw;
        margin-top: 2.5vw;

        .container-link {
            display: flex;
            align-items: center;
            justify-content: start;
            gap: 1vw;
            width: 100%;
            cursor: pointer;

            .options-admin-img-legal-information {
                width: .8vw;
            }

            .text-link {
                text-align: left;
                letter-spacing: 0;
                color: #2E304E;
                opacity: 1;
                font-size: 1vw;
            }
        }
    }
    .container-user-logout {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        gap: .5vw;
        width: 80%;

        .container-logout {
            display: flex;
            align-items: center;
            justify-content: start;
            gap: 1vw;
            width: 100%;
            cursor: pointer;

            .options-admin-img-logout {
                width: .8vw;
            }

            .text-logout {
                text-align: left;
                letter-spacing: 0;
                color: #F45858;
                opacity: 1;
                font-size: 1vw;
            }
        }
    }

    @media ${Device.tablet} {

        .container-user-info {
            margin-top: 4.2vw;

            .container-user-session {
                gap: 2.5vw;
                margin-right: 0;

                .container-icon-session {
                    position: relative;

                    .img-user-session-circle {
                        width: 6vw;
                    }

                    .img-user-session-avatar {
                        width: 3vw;
                    }
                }
                .name-user {
                    font-size: 3vw;
                }
            }
        }
        .container-user-link {

            .container-link {
                gap: 2vw;

                .options-admin-img-legal-information {
                    width: 4vw;
                }

                .text-link {
                    font-size: 3vw;
                }
            }
        }
        .container-user-logout {
            gap: 1.5vw;
            margin-top: 14vw;

            .container-logout {

                .options-admin-img-logout {
                    width: 3vw;
                }

                .text-logout {
                    font-size: 3vw;
                }
            }
        }
    }
    
    @media ${Device.mobileL} {

        .container-user-info {
            margin-top: 4.2vw;

            .container-user-session {
                gap: 2.5vw;
                margin-right: 0;

                .container-icon-session {
                    position: relative;

                    .img-user-session-circle {
                        width: 6vw;
                    }

                    .img-user-session-avatar {
                        width: 3vw;
                    }
                }
                .name-user {
                    font-size: 3vw;
                }
            }
        }
        .container-user-link {

            .container-link {
                gap: 2vw;

                .options-admin-img-legal-information {
                    width: 4vw;
                }

                .text-link {
                    font-size: 3vw;
                }
            }
        }
        .container-user-logout {
            gap: 1.5vw;
            margin-top: 14vw;

            .container-logout {

                .options-admin-img-logout {
                    width: 3vw;
                }

                .text-logout {
                    font-size: 3vw;
                }
            }
        }
    }
    @media ${Device.mobileM} {

        .container-user-info {
            margin-top: 4.2vw;

            .container-user-session {
                gap: 2.5vw;
                margin-right: 0;

                .container-icon-session {
                    position: relative;

                    .img-user-session-circle {
                        width: 6vw;
                    }

                    .img-user-session-avatar {
                        width: 3vw;
                    }
                }
                .name-user {
                    font-size: 3vw;
                }
            }
        }
        .container-user-link {

            .container-link {
                gap: 2vw;

                .options-admin-img-legal-information {
                    width: 4vw;
                }

                .text-link {
                    font-size: 3vw;
                }
            }
        }
        .container-user-logout {
            gap: 1.5vw;
            margin-top: 14vw;

            .container-logout {

                .options-admin-img-logout {
                    width: 3vw;
                }

                .text-logout {
                    font-size: 3vw;
                }
            }
        }
    }
    @media ${Device.mobileS} {

        .container-user-info {
            margin-top: 4.2vw;

            .container-user-session {
                gap: 2.5vw;
                margin-right: 0;

                .container-icon-session {
                    position: relative;

                    .img-user-session-circle {
                        width: 6vw;
                    }

                    .img-user-session-avatar {
                        width: 3vw;
                    }
                }
                .name-user {
                    font-size: 3vw;
                }
            }
        }
        .container-user-link {

            .container-link {
                gap: 2vw;

                .options-admin-img-legal-information {
                    width: 4vw;
                }

                .text-link {
                    font-size: 3vw;
                }
            }
        }
        .container-user-logout {
            gap: 1.5vw;
            margin-top: 14vw;

            .container-logout {

                .options-admin-img-logout {
                    width: 3vw;
                }

                .text-logout {
                    font-size: 3vw;
                }
            }
        }
    }
`
