interface IChatBotNumbers {
    id: number;
    number: string;
}

export const chatBotNumbers: IChatBotNumbers[] = [
    {
        id: 1,
        number: '573138102286'
    },
    {
        id: 2,
        number: '15550765901'
    },
    {
        id: 3,
        number: '573108563043'
    },
    {
        id: 4,
        number: '573108563050'
    }
]