import {combineReducers, configureStore} from "@reduxjs/toolkit";
import AdminReducer from "../../modules/admin/store/ReducerAdmin/AdminReducer";
import UserReducer from "../../modules/user/store/ReducerUser/UserReducer";

// const MacroReducer = combineReducers({
//     adminState:AdminReducer,
//     // userState:UserReducer
// })
export const store = configureStore({
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false
    }),
    reducer: AdminReducer
});

export type AdminState = ReturnType<typeof store.getState>;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
