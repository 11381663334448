import {ShowSessionAcquireCardsStyled} from "./ShowSessionAcquireCardsStyled";
import {SetStateAction, useEffect, useState} from "react";
import QuillShowSession from "../QuillShowSession/QuillShowSession";
import {useAppDispatch, useAppSelector} from "../../../shared/Utils/Hooks";
import {editSessionAcquireCards, editSessionConfigAwards} from "../../../store/ActionsAdmin/SessionConfigActions";
import {getSingleSessionDatabase} from "../../../store/ActionsAdmin/SessionActions";
import {SessionConfigAcquireCardsModel} from "../../../domain/models/SessionConfigAcquireCardsModel";

export default function ShowSessionAcquireCards(): JSX.Element {

    const dispatch = useAppDispatch();
    const [acquireCardsContent, setAcquireCardsContent] = useState('');
    const singleSessionDatabase = useAppSelector((state) => state.sessionState.singleSessionDatabase);
    const isLoadingSessionConfigAcquireCards = useAppSelector((state) => state.sessionConfigState.isLoadingSessionConfigAcquireCards);
    const [dataAcquireCards, setDataAcquireCards] = useState<string>('');

    const acquireCardsHandleChange = (content: SetStateAction<string>) => {
        setAcquireCardsContent(content);
    };

    function handleOnClickButton() {
        console.log(acquireCardsContent)
        const acquireCardsString = JSON.stringify(acquireCardsContent);
        console.log(acquireCardsString);
        const data: SessionConfigAcquireCardsModel = {
            acquire_cards_data: acquireCardsString,
        }

        if (singleSessionDatabase) {
            dispatch(editSessionAcquireCards(data, singleSessionDatabase.session_config_id)).then(() => {
                dispatch(getSingleSessionDatabase(singleSessionDatabase.id)).then(() => {});
            });
        }
    }

    useEffect(() => {
        if (singleSessionDatabase && singleSessionDatabase.session_config.acquire_cards_data) {
            const dataAcquireCards: string | null = JSON.parse(singleSessionDatabase.session_config.acquire_cards_data);
            console.log(dataAcquireCards);
            if (dataAcquireCards !== null) {
                setDataAcquireCards(dataAcquireCards);
            } else {
                setDataAcquireCards('');
            }
        }
    }, [singleSessionDatabase]);



    return (
        <ShowSessionAcquireCardsStyled>
            <QuillShowSession
                value={dataAcquireCards}
                handleChange={acquireCardsHandleChange}
                onClickButton={handleOnClickButton}
                loading={isLoadingSessionConfigAcquireCards}
            />
        </ShowSessionAcquireCardsStyled>
    );
}
