export function validateCodeVerification(value:string) {
    if (value === '') {
        return 'Codigo verificacion requerido...'
    }
    let numberPhone:number = parseInt(value, 10);
    if (isNaN(numberPhone)) {
        return "Ingrese un numero valido..."
    }
    return true;
}
