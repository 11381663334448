import {UserAuthModel} from "../../domain/models/UserAuthModel";
import {
    CHANGE_LOADING_OTP,
    CHANGE_LOADING_SIGN_IN,
    CONFIRMATION_RESULT_FIREBASE,
    GET_USER_ACTIVE,
    GET_USER_ACTIVE_FAILED,
    GET_USER_ACTIVE_SUCCESS,
    IS_AUTHORIZED,
    IS_NOT_AUTHORIZED,
    LOGOUT_SUCCESS,
    NUMBER_PHONE_LOGIN,
    VERIFY_NUMBER_PHONE, VERIFY_NUMBER_PHONE1, VERIFY_NUMBER_PHONE1_FAILED, VERIFY_NUMBER_PHONE1_SUCCESS,
    VERIFY_NUMBER_PHONE_FAILED,
    VERIFY_NUMBER_PHONE_SUCCESS
} from "../TypesAdmin/AuthUserType";
import {cibBoost} from "@coreui/icons";
import {ConfirmationResult} from "firebase/auth";

interface IStateAuthUser {
    isAuthorized:boolean;
    isLoadingUserActive:boolean;
    isLoadingLogout:boolean;
    userActive:UserAuthModel | null;
    numberPhoneLogin: string;
    isLoadingVerifyPhone: boolean;
    isLoadingVerifyCode: boolean;
    confirmationResultFirebase: ConfirmationResult | null;
    numberPhoneIsCorrect: number;
    errors:string;
}
const initialStateAuthUser: IStateAuthUser = {
    isAuthorized: false,
    isLoadingUserActive: false,
    isLoadingLogout: false,
    userActive: null,
    numberPhoneLogin: '',
    isLoadingVerifyPhone: false,
    isLoadingVerifyCode: false,
    confirmationResultFirebase: null,
    numberPhoneIsCorrect: 0,
    errors: '',
}

const AuthUserReducer = function (state = initialStateAuthUser, action: {type:any; payload:any}):IStateAuthUser {
    switch (action.type) {
        case IS_AUTHORIZED:
            return {
                isAuthorized: action.payload,
                isLoadingUserActive: state.isLoadingUserActive,
                isLoadingLogout: state.isLoadingLogout,
                userActive: state.userActive,
                numberPhoneLogin: state.numberPhoneLogin,
                isLoadingVerifyPhone: state.isLoadingVerifyPhone,
                isLoadingVerifyCode: state.isLoadingVerifyCode,
                confirmationResultFirebase: state.confirmationResultFirebase,
                numberPhoneIsCorrect: state.numberPhoneIsCorrect,
                errors: '',
            }
        case IS_NOT_AUTHORIZED:
            return {
                isAuthorized: action.payload,
                isLoadingUserActive: state.isLoadingUserActive,
                isLoadingLogout: state.isLoadingLogout,
                userActive: state.userActive,
                numberPhoneLogin: state.numberPhoneLogin,
                isLoadingVerifyPhone: state.isLoadingVerifyPhone,
                isLoadingVerifyCode: state.isLoadingVerifyCode,
                confirmationResultFirebase: state.confirmationResultFirebase,
                numberPhoneIsCorrect: state.numberPhoneIsCorrect,
                errors: '',
            }
        case GET_USER_ACTIVE:
            return {
                isAuthorized: state.isAuthorized,
                isLoadingUserActive: action.payload,
                isLoadingLogout: state.isLoadingLogout,
                userActive: state.userActive,
                numberPhoneLogin: state.numberPhoneLogin,
                isLoadingVerifyPhone: state.isLoadingVerifyPhone,
                isLoadingVerifyCode: state.isLoadingVerifyCode,
                confirmationResultFirebase: state.confirmationResultFirebase,
                numberPhoneIsCorrect: state.numberPhoneIsCorrect,
                errors: '',
            }
        case GET_USER_ACTIVE_SUCCESS:
            return {
                isAuthorized: true,
                isLoadingUserActive: false,
                isLoadingLogout: state.isLoadingLogout,
                userActive: action.payload,
                numberPhoneLogin: state.numberPhoneLogin,
                isLoadingVerifyPhone: state.isLoadingVerifyPhone,
                isLoadingVerifyCode: state.isLoadingVerifyCode,
                confirmationResultFirebase: state.confirmationResultFirebase,
                numberPhoneIsCorrect: state.numberPhoneIsCorrect,
                errors: '',
            }
        case GET_USER_ACTIVE_FAILED:
            return {
                isAuthorized: false,
                isLoadingUserActive: false,
                isLoadingLogout: state.isLoadingLogout,
                userActive: state.userActive,
                numberPhoneLogin: state.numberPhoneLogin,
                isLoadingVerifyPhone: state.isLoadingVerifyPhone,
                isLoadingVerifyCode: state.isLoadingVerifyCode,
                confirmationResultFirebase: state.confirmationResultFirebase,
                numberPhoneIsCorrect: state.numberPhoneIsCorrect,
                errors: action.payload,
            }
        case LOGOUT_SUCCESS:
            return {
                isAuthorized: false,
                isLoadingUserActive: state.isLoadingUserActive,
                isLoadingLogout: false,
                userActive: action.payload,
                numberPhoneLogin: state.numberPhoneLogin,
                isLoadingVerifyPhone: state.isLoadingVerifyPhone,
                isLoadingVerifyCode: state.isLoadingVerifyCode,
                confirmationResultFirebase: state.confirmationResultFirebase,
                numberPhoneIsCorrect: state.numberPhoneIsCorrect,
                errors: '',
            }
        case NUMBER_PHONE_LOGIN:
            return {
                isAuthorized: state.isAuthorized,
                isLoadingUserActive: state.isLoadingUserActive,
                isLoadingLogout: state.isLoadingLogout,
                userActive: state.userActive,
                numberPhoneLogin: action.payload,
                isLoadingVerifyPhone: state.isLoadingVerifyPhone,
                isLoadingVerifyCode: state.isLoadingVerifyCode,
                confirmationResultFirebase: state.confirmationResultFirebase,
                numberPhoneIsCorrect: state.numberPhoneIsCorrect,
                errors: '',
            }
        case VERIFY_NUMBER_PHONE:
            return {
                isAuthorized: state.isAuthorized,
                isLoadingUserActive: state.isLoadingUserActive,
                isLoadingLogout: state.isLoadingLogout,
                userActive: state.userActive,
                numberPhoneLogin: state.numberPhoneLogin,
                isLoadingVerifyPhone: state.isLoadingVerifyPhone,
                isLoadingVerifyCode: state.isLoadingVerifyCode,
                confirmationResultFirebase: state.confirmationResultFirebase,
                numberPhoneIsCorrect: state.numberPhoneIsCorrect,
                errors: '',
            }
        case VERIFY_NUMBER_PHONE_SUCCESS:
            return {
                isAuthorized: state.isAuthorized,
                isLoadingUserActive: state.isLoadingUserActive,
                isLoadingLogout: state.isLoadingLogout,
                userActive: state.userActive,
                numberPhoneLogin: state.numberPhoneLogin,
                isLoadingVerifyPhone: state.isLoadingVerifyPhone,
                isLoadingVerifyCode: state.isLoadingVerifyCode,
                confirmationResultFirebase: state.confirmationResultFirebase,
                numberPhoneIsCorrect: state.numberPhoneIsCorrect,
                errors: '',
            }
        case VERIFY_NUMBER_PHONE_FAILED:
            return {
                isAuthorized: state.isAuthorized,
                isLoadingUserActive: state.isLoadingUserActive,
                isLoadingLogout: state.isLoadingLogout,
                userActive: state.userActive,
                numberPhoneLogin: state.numberPhoneLogin,
                isLoadingVerifyPhone: state.isLoadingVerifyPhone,
                isLoadingVerifyCode: state.isLoadingVerifyCode,
                confirmationResultFirebase: state.confirmationResultFirebase,
                numberPhoneIsCorrect: state.numberPhoneIsCorrect,
                errors: action.payload,
            }
        case CONFIRMATION_RESULT_FIREBASE:
            return {
                isAuthorized: state.isAuthorized,
                isLoadingUserActive: state.isLoadingUserActive,
                isLoadingLogout: state.isLoadingLogout,
                userActive: state.userActive,
                numberPhoneLogin: state.numberPhoneLogin,
                isLoadingVerifyPhone: state.isLoadingVerifyPhone,
                isLoadingVerifyCode: state.isLoadingVerifyCode,
                confirmationResultFirebase: action.payload,
                numberPhoneIsCorrect: state.numberPhoneIsCorrect,
                errors: '',
            }
        case CHANGE_LOADING_SIGN_IN:
            return {
                isAuthorized: state.isAuthorized,
                isLoadingUserActive: state.isLoadingUserActive,
                isLoadingLogout: state.isLoadingLogout,
                userActive: state.userActive,
                numberPhoneLogin: state.numberPhoneLogin,
                isLoadingVerifyPhone: action.payload,
                isLoadingVerifyCode: state.isLoadingVerifyCode,
                confirmationResultFirebase: state.confirmationResultFirebase,
                numberPhoneIsCorrect: state.numberPhoneIsCorrect,
                errors: '',
            }
        case CHANGE_LOADING_OTP:
            return {
                isAuthorized: state.isAuthorized,
                isLoadingUserActive: state.isLoadingUserActive,
                isLoadingLogout: state.isLoadingLogout,
                userActive: state.userActive,
                numberPhoneLogin: state.numberPhoneLogin,
                isLoadingVerifyPhone: state.isLoadingVerifyPhone,
                isLoadingVerifyCode: action.payload,
                confirmationResultFirebase: state.confirmationResultFirebase,
                numberPhoneIsCorrect: state.numberPhoneIsCorrect,
                errors: '',
            }
        case VERIFY_NUMBER_PHONE1:
            return {
                isAuthorized: state.isAuthorized,
                isLoadingUserActive: state.isLoadingUserActive,
                isLoadingLogout: state.isLoadingLogout,
                userActive: state.userActive,
                numberPhoneLogin: state.numberPhoneLogin,
                isLoadingVerifyPhone: state.isLoadingVerifyPhone,
                isLoadingVerifyCode: state.isLoadingVerifyCode,
                confirmationResultFirebase: state.confirmationResultFirebase,
                numberPhoneIsCorrect: state.numberPhoneIsCorrect,
                errors: '',
            }
        case VERIFY_NUMBER_PHONE1_SUCCESS:
            return {
                isAuthorized: state.isAuthorized,
                isLoadingUserActive: state.isLoadingUserActive,
                isLoadingLogout: state.isLoadingLogout,
                userActive: state.userActive,
                numberPhoneLogin: state.numberPhoneLogin,
                isLoadingVerifyPhone: state.isLoadingVerifyPhone,
                isLoadingVerifyCode: state.isLoadingVerifyCode,
                confirmationResultFirebase: state.confirmationResultFirebase,
                numberPhoneIsCorrect: action.payload,
                errors: '',
            }
        case VERIFY_NUMBER_PHONE1_FAILED:
            return {
                isAuthorized: state.isAuthorized,
                isLoadingUserActive: state.isLoadingUserActive,
                isLoadingLogout: state.isLoadingLogout,
                userActive: state.userActive,
                numberPhoneLogin: state.numberPhoneLogin,
                isLoadingVerifyPhone: state.isLoadingVerifyPhone,
                isLoadingVerifyCode: state.isLoadingVerifyCode,
                confirmationResultFirebase: state.confirmationResultFirebase,
                numberPhoneIsCorrect: action.payload,
                errors: '',
            }
        default:
            return state;
    }
}
export default AuthUserReducer;