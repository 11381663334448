import {DomainModel} from "../../domain/models/DomainModel";
import {GET_ACCOUNT_ACTIVE, GET_ACCOUNT_ACTIVE_FAILED, GET_ACCOUNT_ACTIVE_SUCCESS} from "../TypesAdmin/AccountType";

interface IStateDomain {
    isLoadingDomain: boolean;
    domainActive: DomainModel | null;
    messages: string;
    errors: string;
}
const initialStateDomain: IStateDomain = {
    isLoadingDomain: false,
    domainActive: null,
    messages: '',
    errors: '',
}
const AccountReducer = function (state = initialStateDomain, action: {type:any; payload:any}):IStateDomain {
    switch (action.type) {
        case GET_ACCOUNT_ACTIVE:
            return {
                isLoadingDomain: action.payload,
                domainActive: state.domainActive,
                messages: '',
                errors: '',
            };
        case GET_ACCOUNT_ACTIVE_SUCCESS:
            return {
                isLoadingDomain: false,
                domainActive: action.payload,
                messages: '',
                errors: '',
            };
        case GET_ACCOUNT_ACTIVE_FAILED:
            return {
                isLoadingDomain: false,
                domainActive: state.domainActive,
                messages: '',
                errors: action.payload,
            };
        default:
            return state;
    }
}
export default AccountReducer;