import styled from "styled-components";

export const AppSidebarStyles = styled.div`
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        /* Define la rotación inicial */
        100% {
            transform: rotate(360deg);
        }
        /* Define la rotación final */
    }
    
    display: flex;
    flex-direction: column;
    margin-top: 3vw;
    width: 15%;
    height: 93.4%;
    background: #EFEFF1 0% 0% no-repeat padding-box;

    .container-session-active {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        width: 100%;
        height: 48%;
        overflow: hidden;

        .formal-session {
            width: 90%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-align: left;
            margin-bottom: .8vw;
            margin-top: .6vw;

            .container-search {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                
                .active-sessions-search {
                    width: 10vw;
                    border: 2px solid transparent;
                    border-radius: 8px;
                    outline: none;
                    background-color: #f3f3f4;
                    color: #0d0c22;
                    transition: .3s ease;
                }
                .active-sessions-search::placeholder {
                    color: #9e9ea7;
                }
                .active-sessions-search:focus, .active-sessions-search:hover {
                    outline: none;
                    border-color: #9e9ea7;
                    background-color: #fff;
                    box-shadow: 0 0 0 4px rgb(234 76 137 / 10%);
                }
                .button-search {
                    background: transparent;
                    outline: none;
                    border: none;
                    border-radius: .2vw;
                    cursor: pointer;
                    padding: 0;
                }
                .icon-loading {
                    animation: spin 4s linear infinite;
                }
            }

            .view-active-sessions {
                display: flex;
                align-items: center;
                justify-content: space-between;
                left: 0.5vw;
                width: 100%;

                .formal-session-text {
                    letter-spacing: 0;
                    color: #2E304E;
                    opacity: 1;
                    font-size: 1.2vw;
                    font-family: Poppins-SemiBold, sans-serif;
                    font-weight: 600;
                }
                .icon-menu {
                    width: 1.2vw;
                    cursor: pointer;
                }
            }
        }

        .container-component {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 95%;
        }

        .container-show-more {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 90%;

            .text-show-more {
                letter-spacing: 0;
                color: #9545FF;
                opacity: 1;
                font-size: .5vw;
                cursor: pointer;
            }

            .text-show-more-inactive {
                color: darkgray;
            }
        }
    }

    .container-session-ended {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        width: 100%;
        height: 50%;


        .formal-session {
            width: 90%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-align: left;
            margin-bottom: .8vw;
            margin-top: .6vw;

            .container-search {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;

                .active-sessions-search {
                    width: 10vw;
                    border: 2px solid transparent;
                    border-radius: 8px;
                    outline: none;
                    background-color: #f3f3f4;
                    color: #0d0c22;
                    transition: .3s ease;
                }
                .active-sessions-search::placeholder {
                    color: #9e9ea7;
                }
                .active-sessions-search:focus, .active-sessions-search:hover {
                    outline: none;
                    border-color: #9e9ea7;
                    background-color: #fff;
                    box-shadow: 0 0 0 4px rgb(234 76 137 / 10%);
                }
                .button-search {
                    background: transparent;
                    outline: none;
                    border: none;
                    border-radius: .2vw;
                    cursor: pointer;
                    padding: 0;
                }
                .icon-loading {
                    animation: spin 4s linear infinite;
                }
            }

            .view-active-sessions {
                display: flex;
                align-items: center;
                justify-content: space-between;
                left: 0.5vw;
                width: 100%;

                .formal-session-text {
                    letter-spacing: 0;
                    color: #2E304E;
                    opacity: 1;
                    font-size: 1.2vw;
                    font-family: Poppins-SemiBold, sans-serif;
                    font-weight: 600;
                }
                .icon-menu {
                    width: 1.2vw;
                    cursor: pointer;
                }
            }
        }

        .container-show-more {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 90%;

            .text-show-more {
                letter-spacing: 0;
                color: #9545FF;
                opacity: 1;
                font-size: .5vw;
                cursor: pointer;
            }

            .text-show-more-inactive {
                color: darkgray;
            }
        }
    }

    .modal-create-session {
        position: absolute;
        top: 5vw;
        left: 16vw;
        width: 50vw;
        height: 34vw;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 3px 4px 1px #0000004D;
        border-radius: 25px;
        opacity: 1;
        margin-bottom: 1vw;
        z-index: 3;
    }

    .modal-user-info {
        position: absolute;
        top: 3vw;
        right: 3vw;
        width: 14vw;
        height: 21vw;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 3px 4px 1px #0000004D;
        border-radius: 25px;
        opacity: 1;
        margin-bottom: 1vw;
        z-index: 999999999999999;
    }
`
