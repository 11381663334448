import styled from "styled-components";

export const CardGameStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 20%;
  height: 29.4vw;
  
  .container-img-new-game {
    background: #F2F2F2 0% 0% no-repeat padding-box;
    opacity: 1;
    
    .icon-new-game {
      width: 6vw;
    }
  }
  .container-img {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 21vw;
    cursor: pointer;
    
    
    
    .image-card-session-big {
      width: 100%;
    }
    .game-start {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: .1vw;   
      opacity: 0.68;
      border-radius: 100%;
      width: 60%;
      height: 43%;
      
      .icon-start-game {
        width: 2.7vw;
      }
      .text {
        text-align: center;
        letter-spacing: 0;
        color: #FFFFFF;
        opacity: 1;
        font-size: 1.5vw;
        font-family: Poppins-SemiBold, sans-serif;
        font-weight: 600;
      }
    }
  }
  .container-border {
    box-shadow: 0px 0px 10px rgba(149, 69, 255, 0.7), 0px 0px 20px rgba(196, 145, 252, 0.7);
  }
  .container-border:hover {
    box-shadow: 2px 0px 20px 1px #9545FF;
    border: 2px solid rgba(255, 255, 255, 0.454);
  }
  .container-info {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    width: 100%;
    
    .name-game {
      margin: .7vw 0;
      text-align: left;
      letter-spacing: 0;
      color: #303030;
      opacity: 1;
      font-size: 2vw;
      font-family: Poppins-SemiBold, sans-serif;
      font-weight: 600;
      line-height: 1;
    }
    .prize-game {
      text-align: left;
      letter-spacing: 0;
      color: #424144;
      opacity: 1;
      font-size: 1.5vw;
      font-family: Poppins-Regular, sans-serif;
      font-weight: 400;
      line-height: 1;
    }
  }
`
