import React, {useEffect} from "react";
import {auth} from "../../../../Firebase/Firebase";
import {history} from "../../../../App";
import {getSessionStorageObj} from "../Utils/GetSessionStorageObject";

export default function IsAuthorizedAdmin({children}: React.PropsWithChildren): JSX.Element {

    // const currentUserFirebase = auth.currentUser;
    const user = getSessionStorageObj('user');

    useEffect(() => {
        // console.log(currentUserFirebase);
        if (user) {
            if (user.user_type === 'user') {
                // window.location.href = "https://web.whatsapp.com/send?phone=3138102286";
                history.push("/user");
                return;
            }
        } else {
            history.push("/login");
        }
    }, [user]);

    return (
        <>
            {children}
        </>
    );
}
