import {myDBDomain} from "./Utils/TestingApi/MyDBDomain";
import axios from "axios";
import {GameStorageDataModel} from "../domain/models/GameStorageDataModel";
import {getLastBallotTs} from "./Utils/getLastBallot.ts";
import {getListBallotPlayed} from "./Utils/getListBallotPlayed";
import {getListBallotPlayedData} from "./Utils/getListBallotPlayedData";

export async function updateGameInfoData(game_id: number) {
    console.log(game_id);
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/game/get-game-info-aws/${game_id}`,
        headers: {}
    };
    try {
        const response = await axios.request(config);
        if (response.status !== 200) {
            return false;
        }
        const gameData:GameStorageDataModel = {
            lastBallot: getLastBallotTs(JSON.parse(response.data.data.ballots)),
            listBallotPlayed: getListBallotPlayed(JSON.parse(response.data.data.ballots)),
            listBallotPlayedData: getListBallotPlayedData(JSON.parse(response.data.data.ballots)),
            showWinners: JSON.parse(response.data.data.list_winners),
        }
        return gameData
    } catch (error) {
        console.log('Error al actualizar el game_info');
        console.error(error);
        return false;
    }
}
export async function resolveGameInfoData(game_id: number) {
    const resGameInfo = await updateGameInfoData(game_id);
    if (resGameInfo === false) {
        while (true) {
            const confirmGameInfo = await updateGameInfoData(game_id);
            if (confirmGameInfo !== false) {
                return confirmGameInfo;
            }
        }
    } else {
        return resGameInfo;
    }
}