import {GameModeStyles} from "./GameModeStyles";

interface IGameMode {
    gameMode: string | undefined;
}
export default function GameMode({gameMode}:IGameMode): JSX.Element {

    return (
        <GameModeStyles>
            {gameMode && gameMode === 'any_row' &&
                <div className="game-mode">
                    <img className="img-game-mode" alt="img-game-mode" src="/img/newImage/optionsAdmin/icons-game-mode/culaquier-fila.png"/>
                    <span className="name-game-mode">Cualquier fila</span>
                </div>
            }
            {gameMode && gameMode === 'all_card' &&
                <div className="game-mode">
                    <img className="img-game-mode" alt="img-game-mode" src="/img/newImage/optionsAdmin/icons-game-mode/carton-lleno.png"/>
                    <span className="name-game-mode">Carton Lleno</span>
                </div>
            }
            {gameMode && gameMode === 'any_col' &&
                <div className="game-mode">
                    <img className="img-game-mode" alt="img-game-mode" src="/img/newImage/optionsAdmin/icons-game-mode/culaquier-columna.png"/>
                    <span className="name-game-mode">Cualquier Columna</span>
                </div>
            }
            {gameMode && gameMode === 'row_1' &&
                <div className="game-mode">
                    <img className="img-game-mode" alt="img-game-mode" src="/img/newImage/optionsAdmin/icons-game-mode/primera-fila.png"/>
                    <span className="name-game-mode">Primera Fila</span>
                </div>
            }
            {gameMode && gameMode === 'row_2' &&
                <div className="game-mode">
                    <img className="img-game-mode" alt="img-game-mode" src="/img/newImage/optionsAdmin/icons-game-mode/segunda-fila.png"/>
                    <span className="name-game-mode">Segunda Fila</span>
                </div>
            }
            {gameMode && gameMode === 'row_3' &&
                <div className="game-mode">
                    <img className="img-game-mode" alt="img-game-mode" src="/img/newImage/optionsAdmin/icons-game-mode/tercera-fila.png"/>
                    <span className="name-game-mode">Tercera Fila</span>
                </div>
            }
            {gameMode && gameMode === 'row_4' &&
                <div className="game-mode">
                    <img className="img-game-mode" alt="img-game-mode" src="/img/newImage/optionsAdmin/icons-game-mode/cuarte-fila.png"/>
                    <span className="name-game-mode">Cuarta Fila</span>
                </div>
            }
            {gameMode && gameMode === 'row_5' &&
                <div className="game-mode">
                    <img className="img-game-mode" alt="img-game-mode" src="/img/newImage/optionsAdmin/icons-game-mode/quinta-fila.png"/>
                    <span className="name-game-mode">Quinta Fila</span>
                </div>
            }
            {gameMode && gameMode === 'col_1' &&
                <div className="game-mode">
                    <img className="img-game-mode" alt="img-game-mode" src="/img/newImage/optionsAdmin/icons-game-mode/primera-columna.png"/>
                    <span className="name-game-mode">Columna B</span>
                </div>
            }
            {gameMode && gameMode === 'col_2' &&
                <div className="game-mode">
                    <img className="img-game-mode" alt="img-game-mode" src="/img/newImage/optionsAdmin/icons-game-mode/segunda-columna.png"/>
                    <span className="name-game-mode">Columna I</span>
                </div>
            }
            {gameMode && gameMode === 'col_3' &&
                <div className="game-mode">
                    <img className="img-game-mode" alt="img-game-mode" src="/img/newImage/optionsAdmin/icons-game-mode/tercera-columna.png"/>
                    <span className="name-game-mode">Columna N</span>
                </div>
            }
            {gameMode && gameMode === 'col_4' &&
                <div className="game-mode">
                    <img className="img-game-mode" alt="img-game-mode" src="/img/newImage/optionsAdmin/icons-game-mode/cuarta-columna.png"/>
                    <span className="name-game-mode">Columna G</span>
                </div>
            }
            {gameMode && gameMode === 'col_5' &&
                <div className="game-mode">
                    <img className="img-game-mode" alt="img-game-mode" src="/img/newImage/optionsAdmin/icons-game-mode/quinta-columna.png"/>
                    <span className="name-game-mode">Columna O</span>
                </div>
            }
            {gameMode && gameMode === 'diagonal' &&
                <div className="game-mode">
                    <img className="img-game-mode" alt="img-game-mode" src="/img/newImage/optionsAdmin/icons-game-mode/diag-izq-dere.png"/>
                    <span className="name-game-mode">Diagonal</span>
                </div>
            }
            {gameMode && gameMode === 'anti_diagonal' &&
                <div className="game-mode">
                    <img className="img-game-mode" alt="img-game-mode" src="/img/newImage/optionsAdmin/icons-game-mode/diag-der-izq.png"/>
                    <span className="name-game-mode">Anti diagonal</span>
                </div>
            }
            {gameMode && gameMode === 'shape_X' &&
                <div className="game-mode">
                    <img className="img-game-mode" alt="img-game-mode" src="/img/newImage/optionsAdmin/icons-game-mode/letra-X.png"/>
                    <span className="name-game-mode">En cruz</span>
                </div>
            }
            {gameMode && gameMode === 'shape_L' &&
                <div className="game-mode">
                    <img className="img-game-mode" alt="img-game-mode" src="/img/newImage/optionsAdmin/icons-game-mode/letra-L.png"/>
                    <span className="name-game-mode">En L</span>
                </div>
            }
            {gameMode && gameMode === 'corners' &&
                <div className="game-mode">
                    <img className="img-game-mode" alt="img-game-mode" src="/img/newImage/optionsAdmin/icons-game-mode/cuatro-esquinas.png"/>
                    <span className="name-game-mode">Cuatro esquinas</span>
                </div>
            }
        </GameModeStyles>
    );
}
