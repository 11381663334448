import {AccountUserRelModel, DomainGetModel} from "../../domain/models/DomainModel";
import axios from "axios";
import {GET_ACCOUNT_ACTIVE, GET_ACCOUNT_ACTIVE_FAILED, GET_ACCOUNT_ACTIVE_SUCCESS} from "../TypesAdmin/AccountType";
import {myDBDomain} from "../../shared/Utils/TestingApi/MyDBDomain";

export const getAccountActive = (data:DomainGetModel) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    console.log(data);
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/account/get-for-account`,
        headers: {
            'Content-Type': 'application/json'
        },
        data : data
    };
    dispatch({
        type: GET_ACCOUNT_ACTIVE,
        payload: true
    });
    axios.request(config)
        .then((response) => {
            console.log(response.data);
            dispatch({
                type: GET_ACCOUNT_ACTIVE_SUCCESS,
                payload: response.data.data
            });
        })
        .catch((error) => {
            console.log(error);
            dispatch({
                type: GET_ACCOUNT_ACTIVE_FAILED,
                payload: error.response.data.error
            });
        });
}
export const createAccountUserRel = (data:AccountUserRelModel) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    console.log(data);
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/accountUserRel/create`,
        headers: {
            'Content-Type': 'application/json'
        },
        data : data
    };
    axios.request(config)
        .then((response) => {
            console.log(response.data);
        })
        .catch((error) => {
            console.log(error);
        });
}