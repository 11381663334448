import axios from "axios";
import {myDBDomain} from "../../shared/Utils/TestingApi/MyDBDomain";
import {
    GET_SINGLE_GAME_END_USER,
    GET_SINGLE_GAME_END_USER_FAILED,
    GET_SINGLE_GAME_END_USER_SUCCESS
} from "../TypesAdmin/GameEndUserTypes";



export const getSingleGameEndUser = (game_id: number) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    console.log(game_id);
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/game/get-for-id-end-user/${game_id}`,
        headers: {}
    };
    dispatch({
        type: GET_SINGLE_GAME_END_USER,
        payload: true
    });
    await axios.request(config)
        .then((response) => {
            console.log(response.data.data)
            dispatch({
                type: GET_SINGLE_GAME_END_USER_SUCCESS,
                payload: response.data.data
            });
        })
        .catch((error) => {
            console.log(error);
            dispatch({
                type: GET_SINGLE_GAME_END_USER_FAILED,
                payload: error.response.data.error
            });
        });
}
