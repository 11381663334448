import styled from "styled-components";
import {Device} from "../../../shared/Utils/devices";

interface ILoginStylesProps {
    backgroundLogin: string;
    buttonColor:string;
    buttonColorShadow:string;
}

export const LoginStyles = styled.div<ILoginStylesProps>`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  
  .container-info-login {
    position: relative;
    display: flex;
    width: 55%;
    height: 100%;
    
    .container-path-info-login {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      background: ${({backgroundLogin}) => backgroundLogin};
      opacity: 1;
      clip-path: polygon(0 0, 100% 0, 52% 100%, 0% 100%);
      
      .container-icon {
        display: flex;
        align-items: end;
        justify-content: center;
        width: 70%;
        height: 20%;
        
        .icon-login {
          width: 24vw;
        }
      }
      .container-title {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60%;
        height: 30%;

        .title {
          margin: 0;
          width: 25vw;
          color: white;
          font-size: 2.5vw;
          text-align: center;
          letter-spacing: 0;
          line-height: 1.1;
          opacity: 1;
          font-family: Anton-Regular, sans-serif;
          font-weight: 600;
        }
      }
      .container-text {
        display: flex;
        align-items: start;
        justify-content: center;
        width: 60%;
        height: 13%;
        
        .text {
          width: 25vw;
          text-align: center;
          font-size: 1.2vw;
          font-weight: normal;
          color: white;
          letter-spacing: 0;
          line-height: 1;
          opacity: 1;
        }
      }
      .container-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60%;
        height: 20%;
       
        
        .button {
          font-size: 2vw;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 18vw;
          height: 4vw;
          background: ${({buttonColor}) => buttonColor};
          border-radius: 33px;
          opacity: 1;
          cursor: pointer;
          transition: box-shadow 0.3s ease;
          font-family: Anton, sans-serif;
          font-weight: 400;
        }
        .button:hover {
          box-shadow: ${({buttonColorShadow}) => buttonColorShadow};
        }
      }
    }
    .icon-center {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 8vw;
      top: 18vw;
      
      .icon-elipse-gray {
        width: 12vw;
      }
      .icon-elipse-pimk {
        position: absolute;
        width: 10vw;
      }
      .icon-arroy {
        position: absolute;
        width: 2vw;
      }
    }
  }
  .container-form-login {
    display: flex;
    align-items: end;
    flex-direction: column;
    width: 45%;
    height: 100%;
    
    .footer-login {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 5%;
    }
  }
  
  @media ${Device.desktopL} {
    .container-info-login {
      width: 100%;
      height: 100%;

      .container-path-info-login {
        clip-path: polygon(0 0, 100% 0, 52% 100%, 0% 100%);

        .container-icon {
          width: 100%;
          height: 20%;
          margin-top: 5%;

          .icon-login {
            width: 28vw;
          }
        }
        .container-title {
          width: 80%;
          height: 22%;

          .title {
            width: 56vw;
            font-size: 2.2vw;
            font-family: Anton-Regular, sans-serif;
            font-weight: 600;
          }
        }
        .container-text {
          width: 73%;
          height: 13%;

          .text {
            width: 30vw;
            font-size: 1.5vw;
          }

        }
        .container-button {
          width: 65%;
          height: 20%;

          .button {
            font-size: 2.3vw;
            width: 18vw;
            height: 4vw;
          }
        }
      }
      .icon-center {
        right: 5vw;
        top: 34vh;

        .icon-elipse-gray {
          width: 9vw;
        }
      }
    }
    .container-form-login {
      width: 100%;
      height: 100%;

      .footer-login {
        margin-bottom: 1vh;
        font-size: 1.8vw;
      }
    }
  }
  
  @media ${Device.desktop} {
    .container-info-login {
      width: 100%;
      height: 100%;

      .container-path-info-login {
        clip-path: polygon(0 0, 100% 0, 52% 100%, 0% 100%);

        .container-icon {
          width: 75%;
          height: 20%;

          .icon-login {
            width: 25vw;
          }
        }
        .container-title {
          width: 68%;
          height: 22%;

          .title {
            font-size: 2.4vw;
            font-family: Anton-Regular, sans-serif;
            font-weight: 900;
            text-align: center;
          }
        }
        .container-text {
          width: 70%;
          height: 13%;

          .text {
            width: 30vw;
            font-size: 1.5vw;
          }
        }
        .container-button {
          width: 65%;
          height: 20%;
          margin-top: 10vh;

          .button {
            font-size: 2vw;
            width: 18vw;
            height: 3.2vw;
          }
        }
      }
      .icon-center {
        right: 6vw;
        top: 36vh;

        .icon-elipse-gray {
          width: 9vw;
        }
      }
    }
    .container-form-login {
      width: 100%;
      height: 100%;

      .footer-login {
        margin-bottom: 1vh;
        font-size: 1.8vw;
      }
    }
  }
  
  @media ${Device.laptopL} {
    .container-info-login {
      width: 100%;
      height: 100%;

      .container-path-info-login {
        clip-path: polygon(0 0, 100% 0, 52% 100%, 0% 100%);

        .container-icon {
          width: 75%;
          height: 20%;

          .icon-login {
            width: 25vw;
          }
        }
        .container-title {
          width: 68%;
          height: 22%;

          .title {
            font-size: 2.4vw;
            font-family: Anton-Regular, sans-serif;
            font-weight: 900;
            text-align: center;
          }
        }
        .container-text {
          width: 70%;
          height: 13%;

          .text {
            width: 30vw;
            font-size: 1.5vw;
          }
        }
        .container-button {
          width: 65%;
          height: 20%;
          margin-top: 10vh;

          .button {
            font-size: 2vw;
            width: 18vw;
            height: 3.2vw;
          }
        }
      }
      .icon-center {
        right: 6vw;
        top: 36vh;

        .icon-elipse-gray {
          width: 10vw;
        }
      }
    }
    .container-form-login {
      width: 100%;
      height: 100%;

      .footer-login {
        margin-bottom: 1vh;
        font-size: 2vw;
      }
    }
  }
  
  @media ${Device.laptop} {
    .container-info-login {
      width: 100%;
      height: 100%;

      .container-path-info-login {
        clip-path: polygon(0 0, 100% 0, 52% 100%, 0% 100%);

        .container-icon {
          width: 75%;
          height: 20%;

          .icon-login {
            width: 25vw;
          }
        }
        .container-title {
          width: 68%;
          height: 22%;
          

          .title {
            font-size: 2.4vw;
            font-family: Anton-Regular, sans-serif;
            font-weight: 900;
            text-align: center;
          }
        }
        .container-text {
          width: 70%;
          height: 13%;

          .text {
            width: 30vw;
            font-size: 1.5vw;
          }

        }
        .container-button {
          width: 65%;
          height: 20%;
          margin-top: 10vh;

          .button {
            font-size: 2vw;
            width: 18vw;
            height: 3.2vw;
          }
        }
      }
      .icon-center {
        right: 7vw;
        top: 40vh;

        .icon-elipse-gray {
          width: 8vw;
        }
      }
    }
    .container-form-login {
      width: 100%;
      height: 100%;

      .footer-login {
        margin-bottom: 1vh;
        font-size: 1.5vw;
      }
    }
  }
  
  @media ${Device.tablet} {

    .container-info-login {
      width: 100%;
      height: 100%;

      .container-path-info-login {
        clip-path: polygon(0 0, 100% 0, 52% 100%, 0% 100%);

        .container-icon {
          width: 100%;
          height: 20%;
          margin-top: 5%;

          .icon-login {
            width: 39vw;
          }
        }
        .container-title {
          width: 80%;
          height: 22%;

          .title {
            width: 56vw;
            font-size: 4vw;
            font-family: Anton-Regular, sans-serif;
            font-weight: 600;
          }
        }
        .container-text {
          width: 73%;
          height: 13%;
          
          .text {
            width: 38vw;
            font-size: 2vw;
          }
          
        }
        .container-button {
          width: 65%;
          height: 20%;
          
          .button {
            font-size: 3vw;
            width: 26vw;
            height: 6vw;
          }
        }
      }
      .icon-center {
        right: 4vw;
        top: 40vh;

        .icon-elipse-gray {
          width: 12vw;
        }
      }
    }
    .container-form-login {
      width: 100%;
      height: 100%;

      .footer-login {
        margin-bottom: 1vh;
        font-size: 2vw;
      }
    }
  }
  
  @media ${Device.mobileL} {
    flex-direction: column;

    .container-info-login {
      width: 100%;
      height: 85%;

      .container-path-info-login {
        clip-path: polygon(0 0, 100% 0, 100% 61%, 0 100%);

        .container-icon {
          width: 100%;
          height: 20%;
          margin-top: 5%;

          .icon-login {
            width: 46vw;
          }
        }
        .container-title {
          width: 100%;
          height: 30%;

          .title {
            width: 56vw;
            font-size: 5.5vw;
            font-family: Anton-Regular, sans-serif;
            font-weight: 600;
          }
        }
        .container-text {
          display: none;
        }
        .container-button {
          display: none;
        }
      }
      .icon-center {
        right: 39vw;
        top: 30vh;

        .icon-elipse-gray {
          width: 24vw;
          transform: rotate(90deg);
        }
      }
    }
    .container-form-login {
      width: 100%;
      height: 100%;

      .footer-login {
        margin-bottom: 1vh;
        font-size: 3.5vw;
      }
    }
  }
  
  @media ${Device.mobileM} {
    flex-direction: column;

    .container-info-login {
      width: 100%;
      height: 70%;

      .container-path-info-login {
        clip-path: polygon(0 0, 100% 0, 100% 61%, 0% 100%);
        

        .container-icon {
          width: 100%;
          height: 20%;
          margin-top: 5%;

          .icon-login {
            width: 46vw;
          }
        }
        .container-title {
          width: 100%;
          height: 30%;

          .title {
            width: 56vw;
            font-size: 5.5vw;
            font-family: Anton-Regular, sans-serif;
            font-weight: 600;
          }
        }
        .container-text {
          display: none;
        }
        .container-button {
          display: none;
        }
      }
      .icon-center {
        right: 39vw;
        top: 28vh;

        .icon-elipse-gray {
          width: 23vw;
          transform: rotate(90deg);
        }
      }
    }
    .container-form-login {
      width: 100%;
      height: 100%;

      .footer-login {
        margin-bottom: 1vh;
        font-size: 3.5vw;
      }
    }
  }
  
  @media ${Device.mobileS} {
    flex-direction: column;

    .container-info-login {
      width: 100%;
      height: 70%;

      .container-path-info-login {
        clip-path: polygon(0 0, 100% 0, 100% 61%, 0 100%);

        .container-icon {
          width: 100%;
          height: 20%;
          margin-top: 5%;

          .icon-login {
            width: 46vw;
          }
        }
        .container-title {
          width: 100%;
          height: 30%;

          .title {
            width: 56vw;
            font-size: 5.5vw;
            font-family: Anton-Regular, sans-serif;
            font-weight: 600;
          }
        }
        .container-text {
          display: none;
        }
        .container-button {
          display: none;
        }
      }
      .icon-center {
        right: 37vw;
        top: 28vh;

        .icon-elipse-gray {
          width: 26vw;
          transform: rotate(90deg);
        }
      }
    }
    .container-form-login {
      width: 100%;
      height: 100%;

      .footer-login {
        font-size: 3.5vw;
        margin-bottom: 1vh;
      }
    }
  }
  
`
