import React, {useEffect} from "react";
import {auth} from "../../../../Firebase/Firebase";
import {history} from "../../../../App";
import {getSessionStorageObj} from "../Utils/GetSessionStorageObject";

export default function IsAuthorizedUser({children}: React.PropsWithChildren): JSX.Element {

    // const currentUserFirebase = auth.currentUser;
    const user = getSessionStorageObj('user');

    useEffect(() => {
        // console.log(currentUserFirebase);
        if (user) {
            console.log(user);
        } else {
            history.push("/login");
        }
    }, [user]);

    return (
        <>
            {children}
        </>
    );
}
