import styled from "styled-components";

interface IAddBulkUserStylesProps {
    percentageLoadingCreateRelUserSession: number;
}
export const AddBulkUserStyles = styled.div<IAddBulkUserStylesProps>`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .container-button-close {
        display: flex;
        align-items: center;
        justify-content: end;
        width: 100%;
        margin-top: 1vw;
        
        .close {
            text-align: end;
            letter-spacing: 0;
            color: #2E304E;
            opacity: 1;
            font-size: 2vw;
            font-weight: bold;
            margin-right: 2vw;
            cursor: pointer;
        }
    }
    .container-title {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        .title {
            text-align: center;
            letter-spacing: 0;
            color: #2E304E;
            opacity: 1;
            font-size: 2.1vw;
            font-weight: 600;
            font-family: Poppins-SemiBold, sans-serif;
        }
    }
    .form-add-bulk-users {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        width: 100%;
        box-sizing: border-box;
        margin-top: 2vw;

        .input-img-small {
            display: none;
        }
        .circle-img {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            border: 2px solid #2E304E;
            width: 6vw;
            height: 6vw;
            cursor: pointer;
            
            .options-admin-add-image {
                font-size: 3vw;
                border-radius: 100%;
            }
        }
        .info-add {
            display: block;
            text-align: center;
            letter-spacing: 0;
            color: #606060;
            opacity: 1;
            font-size: .7vw;
            font-weight: 500;
            width: 10vw;
        }
        .container-loading-create-rel-user-session {
            display: flex;
            align-items: center;
            justify-content: start;
            width: 10vw;
            height: .6vw;
            background: #777777;
            margin-top: 1vw;
            border-radius: 1vw;
            
            .percentage-loading {
                display: flex;
                width: ${({percentageLoadingCreateRelUserSession}) => percentageLoadingCreateRelUserSession + '%'};
                height: 100%;
                background: #9933ff;
                border-radius: 1vw;
            }
        }
        .button-form {
            background: transparent linear-gradient(90deg, #9545FF 0%, #C491FC 100%) 0% 0% no-repeat padding-box;
            border-radius: 37px;
            opacity: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 13vw;
            height: 2.5vw;
            text-align: center;
            letter-spacing: 0;
            color: #FFFFFF;
            font-size: 1.5vw;
            transition: box-shadow 0.3s ease;
            cursor: pointer;
            outline: none;
            border: none;
            font-family: Anton, sans-serif;
            font-weight: 400;
            margin-top: 2vw;
        }
        .button-form:hover {
            box-shadow: 0px 0px 10px rgba(149, 69, 255, 0.7), 0px 0px 20px rgba(196, 145, 252, 0.7);
        }
    }
`
