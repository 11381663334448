import {HomeSessionsUserStyled} from "./HomeSessionsUserStyled";
import WelcomeMessage from "../WelcomeMessage/WelcomeMessage";
import SessionUser from "../SessionUser/SessionUser";
import {getSessionStorageObj} from "../../../../admin/shared/Utils/GetSessionStorageObject";
import {useAppDispatch, useAppSelector} from "../../../../admin/shared/Utils/Hooks";
import React, {useEffect, useState} from "react";
import {getUserActive} from "../../../../admin/store/ActionsAdmin/UserActions";
import {getAccountActive} from "../../../../admin/store/ActionsAdmin/AccountActions";
import {Device} from "../../../shared/Utils/devices";
import {getAllSessionsEndUser} from "../../../../admin/store/ActionsAdmin/SessionEndUserActions";
import {IoIosArrowBack, IoIosArrowForward} from 'react-icons/io';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Loading from "../../../../admin/presentation/Components/Loading/Loading";
import { useMediaQuery } from 'react-responsive';
import UserSessionInfo from "../UserSessionInfo/UserSessionInfo";

export default function HomeSessionsUser(): JSX.Element {

    const userSessionStorage = getSessionStorageObj('user');
    const domain = window.location.origin;
    const domainActive = useAppSelector((state) => state.accountState.domainActive);
    const allSessionsEndUser = useAppSelector((state) => state.sessionEndUserState.allSessionsEndUser);
    const showModalSingleSessionUserEnd = useAppSelector((state) => state.modalState.showModalSingleSessionUserEnd);
    const dispatch = useAppDispatch();
    const isSmallScreen  = useMediaQuery({ query: '(max-width: 320px)'});
    const isMediumScreen  = useMediaQuery({ query: '(max-width: 375px)'});
    const isLargeScreen  = useMediaQuery({ query: '(max-width: 425px)'});
    const isTabletScreen  = useMediaQuery({ query: '(max-width: 768px)'});
    const showModalUserInfo = useAppSelector((state) => state.modalState.showModalUserInfo);

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: isSmallScreen ? 1 : isMediumScreen ? 1 : isLargeScreen ? 1 : isTabletScreen ? 2 : 3,
        slidesToScroll: 1,
    };

    useEffect(() => {
        dispatch(getUserActive(userSessionStorage.id)).then(() => {
        });
    }, [userSessionStorage]);

    useEffect(() => {
        dispatch(getAccountActive({domain: domain})).then(() => {
        });
    }, [domain]);

    useEffect(() => {
        console.log(domainActive);
        if (domainActive) {
            dispatch(getAllSessionsEndUser(domainActive.id, 0)).then(() => {
            });
        }
    }, [domainActive]);

    return (
        <HomeSessionsUserStyled>
            <WelcomeMessage/>
            <Slider {...settings}>
                {allSessionsEndUser && allSessionsEndUser.map((sessionsActive) => (
                    <SessionUser
                        key={sessionsActive.id}
                        session_id={sessionsActive.id}
                        main_image={sessionsActive.main_image}
                        session_name={sessionsActive.session_name}
                        session_info={sessionsActive.session_info}
                    />
                ))}
            </Slider>
            {showModalSingleSessionUserEnd &&
                <div className="loadingInSession">
                    <Loading/>
                </div>
            }
            {showModalUserInfo &&
                <div className="modal-user-session-info">
                    <UserSessionInfo/>
                </div>
            }
        </HomeSessionsUserStyled>
    );
}
