import axios from "axios";
import {CreateSessionPostModel} from "../../domain/models/CreateSessionModel";
import {
    ALL_SEARCH_SESSIONS_ACTIVE,
    ALL_SEARCH_SESSIONS_ACTIVE_FAILED,
    ALL_SEARCH_SESSIONS_ACTIVE_SUCCESS,
    ALL_SEARCH_SESSIONS_INACTIVE, ALL_SEARCH_SESSIONS_INACTIVE_SUCCESS,
    CHANGE_CUMULATIVE_VALUE, CLEAN_ALL_SEARCH_SESSIONS_ACTIVE, CLEAN_ALL_SEARCH_SESSIONS_INACTIVE,
    CLEAN_ALL_SESSIONS,
    CLEAN_SINGLE_SESSION,
    CREATE_SESSION,
    CREATE_SESSION_FAILED,
    CREATE_SESSION_SUCCESS,
    DELETE_SESSION,
    DELETE_SESSION_FAILED,
    DELETE_SESSION_SUCCESS,
    EDIT_SESSION,
    EDIT_SESSION_FAILED,
    EDIT_SESSION_SUCCESS,
    GET_ALL_SESSIONS_ACTIVE,
    GET_ALL_SESSIONS_ACTIVE_FAILED,
    GET_ALL_SESSIONS_ACTIVE_SUCCESS,
    GET_ALL_SESSIONS_INACTIVE,
    GET_ALL_SESSIONS_INACTIVE_FAILED,
    GET_ALL_SESSIONS_INACTIVE_SUCCESS,
    GET_SINGLE_SESSION_DATABASE,
    GET_SINGLE_SESSION_DATABASE_FAILED,
    GET_SINGLE_SESSION_DATABASE_SUCCESS,
    SESSION_SELECTED, UPDATE_OFFSET_SESSION_ACTIVE, UPDATE_OFFSET_SESSION_INACTIVE
} from "../TypesAdmin/SessionTypes";
import {EditSessionModel} from "../../domain/models/EditSessionModel";
import {alertSuccess} from "../../shared/Utils/AlertSuccess";
import {alertError} from "../../shared/Utils/AlertError";
import {myDBDomain} from "../../shared/Utils/TestingApi/MyDBDomain";
import {SessionModel} from "../../domain/models/SessionModel";
import {getCumulativePercentage} from "../../shared/Utils/GetCumulativePercentage";


export const getAllSessionsActive = (account_id: number, offset: number ) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    const limit: number = 5;
    console.log('este es el offset active..........');
    console.log(offset);
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/session/get-active/${account_id}?page=${offset}&size=${limit}`,
        headers: {
            'Content-Type': 'application/json'
        },
    };
    dispatch({
        type: GET_ALL_SESSIONS_ACTIVE,
        payload: 'loading'
    });
    await axios.request(config)
        .then((response) => {
            let resData: SessionModel[] = response.data.data as SessionModel[];
            console.log(response.data);
            console.log(resData);
            dispatch({
                type: GET_ALL_SESSIONS_ACTIVE_SUCCESS,
                payload: resData
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_ALL_SESSIONS_ACTIVE_FAILED,
                payload: error
            });
        });
}
export const getAllSessionsInactive = (account_id: number, offset: number ) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    const limit: number = 5;
    console.log('este es el offset..........');
    console.log(offset);
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/session/get-inactive/${account_id}?page=${offset}&size=${limit}`,
        headers: {
            'Content-Type': 'application/json'
        },
    };
    dispatch({
        type: GET_ALL_SESSIONS_INACTIVE,
        payload: 'loading'
    });
    await axios.request(config)
        .then((response) => {
            let resData: SessionModel[] = response.data.data as SessionModel[];
            console.log(response.data);
            console.log(resData);
            dispatch({
                type: GET_ALL_SESSIONS_INACTIVE_SUCCESS,
                payload: resData
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_ALL_SESSIONS_INACTIVE_FAILED,
                payload: error
            });
        });
}
export const getSearchSessionActive = (account_id: number, searchName: string) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/session/search-session-active?account_id=${account_id}&searchName=${searchName}`,
        headers: {
            'Content-Type': 'application/json'
        },
    };
    dispatch({
        type: ALL_SEARCH_SESSIONS_ACTIVE,
        payload: true
    });
    await axios.request(config)
        .then((response) => {
            let resData: SessionModel[] = response.data.data as SessionModel[];
            console.log(resData);
            dispatch({
                type: ALL_SEARCH_SESSIONS_ACTIVE_SUCCESS,
                payload: resData
            });
        })
        .catch((error) => {
            dispatch({
                type: ALL_SEARCH_SESSIONS_ACTIVE_FAILED,
                payload: error
            });
        });
}
export const getSearchSessionInactive = (account_id: number, searchName: string) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/session/search-session-inactive?account_id=${account_id}&searchName=${searchName}`,
        headers: {
            'Content-Type': 'application/json'
        },
    };
    dispatch({
        type: ALL_SEARCH_SESSIONS_INACTIVE,
        payload: true
    });
    await axios.request(config)
        .then((response) => {
            let resData: SessionModel[] = response.data.data as SessionModel[];
            console.log(resData);
            dispatch({
                type: ALL_SEARCH_SESSIONS_INACTIVE_SUCCESS,
                payload: resData
            });
        })
        .catch((error) => {
            dispatch({
                type: ALL_SEARCH_SESSIONS_INACTIVE_SUCCESS,
                payload: error
            });
        });
}
// action database
export const getSingleSessionDatabase = (id: number) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    console.log(id);
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/session/get-id/${id}`,
        headers: { }
    };
    dispatch({
        type: GET_SINGLE_SESSION_DATABASE,
        payload: true
    });
    await axios.request(config)
        .then((response) => {
            console.log(response.data);
            const data: SessionModel = response.data.data;
            // const totalValue: number = data.card_price * data.cards.length;
            // const cumulativePercentage: number = getCumulativePercentage(totalValue, data.prize_pool);
            dispatch({
                type: GET_SINGLE_SESSION_DATABASE_SUCCESS,
                payload: data
            });
            // dispatch({
            //     type: CHANGE_CUMULATIVE_VALUE,
            //     payload: cumulativePercentage
            // });
        })
        .catch((error) => {
            console.log(error);
            dispatch({
                type: GET_SINGLE_SESSION_DATABASE_FAILED,
                payload: error.response.data.error
            });
        });
}
export const createSession = (data: CreateSessionPostModel) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/session/create`,
        headers: {
            'Content-Type': 'application/json'
        },
        data : data
    };
    dispatch({
        type: CREATE_SESSION,
        payload: 'loading'
    });
    await axios.request(config)
        .then((response) => {
            dispatch({
                type: CREATE_SESSION_SUCCESS,
                payload: response.data
            });
        })
        .catch((error) => {
            dispatch({
                type: CREATE_SESSION_FAILED,
                payload: error
            });
        });
}
export const editSession = (data: EditSessionModel, id: number) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    console.log(data);
    let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/session/update/${id}`,
        headers: {
            'Content-Type': 'application/json'
        },
        data : data
    };
    dispatch({
        type: EDIT_SESSION,
        payload: 'loading'
    });
    await axios.request(config)
        .then((response) => {
            console.log(response.data);
            dispatch({
                type: EDIT_SESSION_SUCCESS,
                payload: response.data
            });
        })
        .catch((error) => {
            console.log(error);
            dispatch({
                type: EDIT_SESSION_FAILED,
                payload: error
            });
        });
}
export const deleteSession = (id: number) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    let config = {
        method: 'delete',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/session/delete/${id}`,
        headers: {
            'Content-Type': 'application/json'
        },
    };
    dispatch({
        type: DELETE_SESSION,
        payload: 'loading'
    });
    await axios.request(config)
        .then((response) => {
            dispatch({
                type: DELETE_SESSION_SUCCESS,
                payload: response.data.message
            });
            alertSuccess(response.data.message);
        })
        .catch((error) => {
            dispatch({
                type: DELETE_SESSION_FAILED,
                payload: error.response.data.error
            });
            alertError(error.response.data.error);
        });
}
export const cleanSingleSession = () => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: CLEAN_SINGLE_SESSION,
        payload: null
    });
}
export const cleanAllSessions = () => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: CLEAN_ALL_SESSIONS,
        payload: []
    });
}
export const changeSessionSelected = (selected: number | undefined) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    console.log(selected);
    dispatch({
        type: SESSION_SELECTED,
        payload: selected
    });
}
export const cleanAllSearchSessionsActive = () => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: CLEAN_ALL_SEARCH_SESSIONS_ACTIVE,
        payload: []
    });
}
export const cleanAllSearchSessionsInactive = () => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: CLEAN_ALL_SEARCH_SESSIONS_INACTIVE,
        payload: []
    });
}
export const updateOffsetSessionActive = (offset: number) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: UPDATE_OFFSET_SESSION_ACTIVE,
        payload: offset
    });
}
export const updateOffsetSessionInactive = (offset: number) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: UPDATE_OFFSET_SESSION_INACTIVE,
        payload: offset
    });
}
