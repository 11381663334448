import {myDBDomain} from "../../shared/Utils/TestingApi/MyDBDomain";
import {CreateFinishedGameDataModel} from "../../domain/models/CreateFinishedGameDataModel";
import axios from "axios";
import {alertError} from "../../shared/Utils/AlertError";
import {
    CLEAN_SINGLE_FINISHED_GAME,
    GET_SINGLE_FINISHED_GAME,
    GET_SINGLE_FINISHED_GAME_SUCCESS
} from "../TypesAdmin/FinishedGameTypes";
import {GetFinishedGameDbModel} from "../../domain/models/GetFinishedGameDbModel";

export const createFinishedGame = (finishedGame: CreateFinishedGameDataModel) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/finishedGame/create`,
        headers: {
            'Content-Type': 'application/json'
        },
        data : finishedGame
    };
    await axios.request(config)
        .then((response) => {
            console.log(response.data);
        })
        .catch((error) => {
            console.log(error);
            alertError(error.response.data.message);
        });
}
export const getSingleFinishedGame = (game_id: number) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/finishedGame/getFinishedGameForGameId/${game_id}`,
        headers: {
            'Content-Type': 'application/json'
        }
    };
    dispatch({
        type: GET_SINGLE_FINISHED_GAME,
        payload: true
    });
    await axios.request(config)
        .then((response) => {
            const dataRes = response.data.data;
            const dataFinishedGame: GetFinishedGameDbModel = {
                list_ballot_played: JSON.parse(dataRes.list_ballot_played),
                list_ballot_played_data: JSON.parse(dataRes.list_ballot_played_data),
                winners: JSON.parse(dataRes.winners),
                last_ballot: dataRes.last_ballot
            }
            console.log(dataFinishedGame);
            dispatch({
                type: GET_SINGLE_FINISHED_GAME_SUCCESS,
                payload: dataFinishedGame
            });
        })
        .catch((error) => {
            if (error) {
                console.log(error);
                alertError(error.response.data.error ? error.response.data.error : 'No hay datos');
            }
        });
}
export const cleanSingleFinishedGame = () => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: CLEAN_SINGLE_FINISHED_GAME,
        payload: null
    });
}

