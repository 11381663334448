import {CardGameStyled} from "./CardGameStyled";
import {useAppDispatch, useAppSelector} from "../../../shared/Utils/Hooks";
import {
    closeEditSession,
    openAdnCloseModalCreateGame,
    openAndCloseModalUsersSession,
    openUserInfo
} from "../../../store/ActionsAdmin/ModalsActions";
import {useHistory} from "react-router-dom";
import {getGameInfo, getSingleBingo} from "../../../store/ActionsAdmin/BingosActions";
import {useState} from "react";
import Loading from "../Loading/Loading";
import {alertInfo} from "../../../shared/Utils/AlertInfo";
import {getSingleFinishedGame} from "../../../store/ActionsAdmin/FinishedGameActions";

interface ICardGameProps {
    cardNewGame: boolean;
    gameName: string;
    gamePrize: string;
    image: string;
    id: number;
    status: string;
}

export default function CardGame({
                                     cardNewGame,
                                     gameName,
                                     gamePrize,
                                     id,
                                     status,
                                     image,
                                 }: ICardGameProps): JSX.Element {

    const dispatch = useAppDispatch();
    const history = useHistory();
    const singleSessionDatabase = useAppSelector((state) => state.sessionState.singleSessionDatabase);
    const [loadingSingleBingo, setLoadingSingleBingo] = useState<boolean>(false);

    function handleOpenModalCreateGame() {
        dispatch(openAdnCloseModalCreateGame(true));
    }

    function handleShowGame(id: number) {
        setLoadingSingleBingo(true);
        dispatch(getSingleBingo(id)).then(r => {
            dispatch(getGameInfo(id)).then(() => {
                if (status === 'end') {
                    dispatch(openAndCloseModalUsersSession(false));
                    dispatch(openAdnCloseModalCreateGame(false));
                    dispatch(closeEditSession());
                    dispatch(openUserInfo(false));
                    setLoadingSingleBingo(false);
                    history.push(`/admin/game/${id}`);
                    return;
                }
                dispatch(openAndCloseModalUsersSession(false));
                dispatch(openAdnCloseModalCreateGame(false));
                dispatch(closeEditSession());
                dispatch(openUserInfo(false));
                setLoadingSingleBingo(false);
                history.push(`/admin/game/${id}`);
            });
        });
    }

    return (
        <CardGameStyled>
            {!cardNewGame &&
                <div className="container-img container-border" onClick={() => handleShowGame(id)}>
                    <img
                        className="image-card-session-big"
                        alt="imageCardSessionBig"
                        src={image}
                    />
                    <div className="game-start">
                        {status === 'inactive' &&
                            <img
                                className="img-play-launch-ballot"
                                alt="star-game-img"
                                src="/img/newImage/img-program.png"
                            />
                        }
                        {status === 'active' &&
                            <img
                                className="img-play-launch-ballot"
                                alt="lanch-ballot"
                                src="/img/newImage/in-game.png"
                            />
                        }
                        {status === 'end' &&
                            <img
                                className="img-play-launch-ballot"
                                alt="end-game-img"
                                src="/img/newImage/img-end-game.png"
                            />
                        }
                    </div>
                </div>
            }
            {cardNewGame &&
                <div onClick={handleOpenModalCreateGame} className="container-img container-img-new-game">
                    <img
                        className="icon-new-game"
                        alt="icon-new-game"
                        src="/img/newImage/optionsAdmin/icon-new-game.svg"
                    />
                </div>
            }
            <div className="container-info">
                <h3 className="name-game">{!cardNewGame ? gameName : 'Nuevo Juego'}</h3>
                <span className="prize-game">{!cardNewGame ? gamePrize : ''}</span>
            </div>
            {loadingSingleBingo &&
                <Loading/>
            }
        </CardGameStyled>
    );
}
