import styled from "styled-components";

export const OptionsAdminStyles = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    overflow-y: scroll;
    width: 100%;
    height: 100%;

    /* Estilos para el scrollbar */

    &::-webkit-scrollbar {
        width: 1vw; /* Ancho del scrollbar */
    }

    &::-webkit-scrollbar-track {
        background-color: #CCCFDE;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #00000038;
        border-radius: 5px;
        height: 5px;
    }


    .container-img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 12vw;

        .optionsAdminTitle {
            width: 32vw;
        }
    }

    .container-options {
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 2.5vw;
        overflow: hidden;
        width: 80%;
        height: 18vw;
    }

    .modal-create-session {
        position: absolute;
        top: 10vw;
        left: 19vw;
        width: 50vw;
        height: auto;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 3px 4px 1px #0000004D;
        border-radius: 25px;
        opacity: 1;
        margin-bottom: 1vw;
    }
    .modal-create-session-bulk-users {
        position: absolute;
        top: 35vw;
        left: 30vw;
        width: 50vw;
        height: auto;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 3px 4px 1px #0000004D;
        border-radius: 25px;
        opacity: 1;
        margin-bottom: 1vw;
        padding: 1vw;
    }
`
