export function getListBallotPlayedData(listBallot: any[]) {
    const newList: string[] = [];
    if (listBallot.length > 0) {
        for (const data of listBallot) {
            newList.push(`${data.col}-${data.number}`);
        }
        return newList;
    }else {
        return newList;
    }
}