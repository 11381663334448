import styled from "styled-components";

export const ShowSessionAwardsStyled = styled.div`

  .container-awards {
    font-family: Poppins-Light, sans-serif;
    font-weight: 300;
    font-size: 1.1vw;
    .image-container{
        display: flex;
        justify-content: center; /* Centra horizontalmente */
        align-items: center;
        .image-award {
            width: 45%; 
            margin-top: 70px; 
          }
    }
  }
`




