import styled from "styled-components";
import { Device } from "../../../shared/Utils/devices";

export const FooterStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    width: 100%;
    height: 3vw;

    span {
        margin-right: 2vw;
        font-size: 1vw;
        color: #ffffff;
        cursor: pointer;
    }

    @media ${Device.desktop} {
        span {
            margin-right: 2vw;
            font-size: 1.3vw;
        }
    }
    @media ${Device.laptopL} {

        span {
            margin-right: 2vw;
            font-size: 1.5vw;
        }
    }
    @media ${Device.laptop} {

        span {
            margin-right: 2vw;
            font-size: 1.5vw;
        }
    }
    @media ${Device.tablet} {
        box-sizing: border-box;
        padding: 1.5vw;
        height: 6vw;

        span {
            margin-right: 2vw;
            font-size: 2.5vw;
        }
    }


    @media ${Device.mobileL} {
        position: fixed;
        left: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 5vh;

        span {
            margin-right: 2vw;
            font-size: 4vw;
            color: #ffffff;
            margin-left: 2vw;

        }
    }
    @media ${Device.mobileM} {
        position: fixed;
        left: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 5vh;

        span {
            margin-right: 2vw;
            font-size: 4vw;
            color: #ffffff;
            margin-left: 2vw;

        }
    }
    @media ${Device.mobileS} {
        position: fixed;
        left: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 5vh;

        span {
            margin-right: 2vw;
            font-size: 4vw;
            color: #ffffff;
            margin-left: 2vw;

        }
    }

`
