import styled from "styled-components";

export const LastBallotsStyles = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0;
    width: 40%;
    justify-content: center;
    
    .title {
        position: absolute;
        font-family: "Alata-Regular", sans-serif;
        font-size: 1.3vw;
        text-align: center;
        letter-spacing: .2vw;
        color: #ffffff;
        opacity: 1;
        margin: 0;
        bottom: 4.7vw;
    }
    .container-row {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32vw;

        .container-bar {
            width: 97%;
            height: 2.2vw;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border-radius: 16px;
            opacity: 1;

            .triangle-box-left {
                margin-left: 3.5vw;
            }

            .triangle-box {
                width: 2vw;
                height: 1.5vw;
                position: relative;
                border-radius: 3px;
                opacity: 1;
                cursor: pointer;

                .triangle {
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 5px 0 6px 11px;
                    border-color: transparent transparent transparent #CBCBCB;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-70%, -50%) rotate(180deg);
                    background: #CBCBCB 0% 0% no-repeat padding-box;
                    opacity: 1;
                }

                .triangle-left {
                    width: 2vw;
                    height: 2vw;
                    border-width: 5px 0 6px 11px;
                    border-color: transparent transparent transparent #CBCBCB;
                    position: absolute;
                    top: -.25vw;
                    opacity: 1;
                }

                .triangle-right {
                    width: 2vw;
                    height: 2vw;
                    border-width: 5px 0 6px 11px;
                    border-color: transparent transparent transparent #CBCBCB;
                    position: absolute;
                    top: -.25vw;
                    opacity: 1;
                }
            }

            .triangle-box-right {
                flex-grow: 2;
                overflow-x: hidden;

                .numbers-container {
                    display: flex;
                    width: 20vw;
                    align-items: center;
                    justify-content: flex-start; /* Cambia esto a flex-start */
                    flex-direction: row; /* Agrega esta línea */
                    margin-left: 2.5vw;

                    .numbers {
                        font-family: "Poppins-Light", sans-serif;
                        font-size: 1vw;
                        flex-shrink: 0;
                        white-space: nowrap;
                        color: #878787;
                        text-align: right;
                        letter-spacing: 0;
                        opacity: 1;
                        margin-right: .4vw;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 3vw;
                    }
                }
            }
        }

        .stylized-image {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 8vw;
            position: absolute;
            left: -4vw;
            z-index: 9;
            //right: 27vw;
            //top: 1.7vw;

            .image {
                width: 100%;
            }

            .number-ballot {
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                font-family: AllerDisplay-Regular, sans-serif;
                font-weight: 900;
                font-size: 1.3vw;
                text-align: left;
                letter-spacing: 0px;
                color: #000000;
                opacity: 1;
                margin-left: 0.9vw;
                margin-top: 0.4vw;
            }
        }
    }
    .ballot-counter {
        position: absolute;
        top: 2.5vw;
        left: 7vw;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1vw;
        border: .2vw solid #ffffff;
        box-sizing: border-box;
        padding: .5vw;
        border-radius: .4vw;
        
        .balls-played {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            
            .counter-ballot {
                font-size: 1vw;
                background: #0000AA;
                display: flex;
                align-items: center;
                justify-content: center;
                box-sizing: border-box;
                padding: .4vw;
                border: .2vw solid #ffffff;
                border-radius: 100%;
                width: 3vw;
                height: 3vw;
                color: #ffffff;
            }
            .text-counter-ballot {
                color: #0000AA;
                font-size: 1vw;
                font-family: Alata-Regular, sans-serif;
            }
        }
    }
`
