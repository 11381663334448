import {UsersRelSessionAndCardsStyles} from "./UsersRelSessionAndCardsStyles";
import {useAppDispatch, useAppSelector} from "../../../shared/Utils/Hooks";
import React, {useEffect} from "react";
import {
    openAndCloseModalUsersRelSessionAndCards,
    openAndCloseModalUsersSession
} from "../../../store/ActionsAdmin/ModalsActions";
import {
    cleanAllUsersSearch,
    cleanUsersSessionRel,
    getAllUsersSearch,
    getUsersSessionRel
} from "../../../store/ActionsAdmin/UserActions";
import ButtonExportExcel from "../ButtonExportExcel/ButtonExportExcel";

export default function UsersRelSessionAndCards(): JSX.Element {

    let timeoutUserSessionRel: string | number | NodeJS.Timeout | undefined;
    const allUsersSessionRelSearch = useAppSelector((state) => state.userState.allUsersSessionRelSearch);
    const dispatch = useAppDispatch();
    const singleSessionDatabase = useAppSelector((state) => state.sessionState.singleSessionDatabase);
    const allUsersRelSessionAndCards = useAppSelector((state) => state.userState.allUsersRelSessionAndCards);

    function handleCloseModal() {
        dispatch(openAndCloseModalUsersRelSessionAndCards(false));
    }

    useEffect(() => {
        console.log(allUsersRelSessionAndCards);
    }, [allUsersRelSessionAndCards]);

    return (
        <UsersRelSessionAndCardsStyles>
            <div className="container-title">
                <span className="title">Relacion Usuarios</span>
                <ButtonExportExcel
                    listExcel={allUsersRelSessionAndCards}
                    nameFile={singleSessionDatabase ? `${singleSessionDatabase.session_name}.xlsx` : 'Usuarios-relacionados.xlsx'}
                >Exportar</ButtonExportExcel>
                <span onClick={handleCloseModal} className="close">x</span>
            </div>
            {/*<div className="container-search">*/}
            {/*    <input*/}
            {/*        type="search"*/}
            {/*        className="search-users"*/}
            {/*        placeholder="Buscar usuarios "*/}

            {/*    />*/}
            {/*</div>*/}
            <div className="container-scroll">
                <table>
                    <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Cédula</th>
                        <th>Teléfono</th>
                        <th>Tarjetones</th>
                    </tr>
                    </thead>
                    <tbody>
                    {allUsersRelSessionAndCards.map((user, index) => (
                        <tr key={index}>
                            <td>{user.name}</td>
                            <td>{user.identification_document}</td>
                            <td>{user.phone}</td>
                            <td>{user.cards}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </UsersRelSessionAndCardsStyles>
    );
}
