import {combineReducers} from "@reduxjs/toolkit";
import BingoReducer from "./BingosReducers";
import selectedOptionReducer from "./SelectedOptionReducers";
import SessionReducer from "./SessionReducers";
import AuthUserReducers from "./AuthUserReducers";
import ModalsReducer from "./ModalsReducer";
import CumulativeReducer from "./CumulativeReducer";
import AccountReducer from "./AccountReducer";
import UsersReducer from "./UsersReducer";
import SessionConfigReducers from "./SessionConfigReducers";
import AccountConfigReducer from "./AccountConfigReducer";
import SessionEndUserReducer from "./SessionEndUserReducer";
import UserSessionRelReducer from "./UserSessionRelReducers";
import GameEndUserReducer from "./GameEndUserReducers";
import CardsReducer from "./CardReducers";
import SocketReducer from "./SocketReducer";
import FinishedGameReducer from "./FinishedGameReducer";




const AdminReducer = combineReducers({
    bingoState:BingoReducer,
    sessionState:SessionReducer,
    selectedOptionState: selectedOptionReducer,
    authUserState: AuthUserReducers,
    modalState: ModalsReducer,
    cumulativeState: CumulativeReducer,
    accountState: AccountReducer,
    userState: UsersReducer,
    sessionConfigState: SessionConfigReducers,
    accountConfigState: AccountConfigReducer,
    sessionEndUserState: SessionEndUserReducer,
    userSessionRelState: UserSessionRelReducer,
    gameEndUserState: GameEndUserReducer,
    cardsState: CardsReducer,
    socketState: SocketReducer,
    finishedGameState: FinishedGameReducer,
});
export default AdminReducer;
