import {AccumulatedStyles} from "./AccumulatedStyles"
import {useAppDispatch, useAppSelector} from "../../../shared/Utils/Hooks";
import {MdOutlineDelete} from "react-icons/md";
import {
    openAndCloseModalAddBulkUsers, openAndCloseModalCurrentGameDAta, openAndCloseModalDebugMessage,
    openAndCloseModalUserRelAccountAndSession,
    openAndCloseModalUsersSession
} from "../../../store/ActionsAdmin/ModalsActions";
import UsersSession from "../UsersSession/UsersSession";
import React, {useEffect, useState} from "react";
import {getCumulativePercentage} from "../../../shared/Utils/GetCumulativePercentage";
import {getCardSCountBySessionId} from "../../../store/ActionsAdmin/CardActions";
import UsersRelSessionAndCards from "../UsersRelSessionAndCards/UsersRelSessionAndCards";
import CreateUserToSession from "../CreateUserToSession/CreateUserToSession";
import {
    endGameForced,
    getSingleBingo
} from "../../../store/ActionsAdmin/BingosActions";
import Loading from "../Loading/Loading";
import {alertSuccess} from "../../../shared/Utils/AlertSuccess";
import CurrentGameData from "../CurrentGameData/CurrentGameData";
import {GameStorageDataModel} from "../../../domain/models/GameStorageDataModel";
import {getLocalStorageObj} from "../../../shared/Utils/GetLocalStorageObj";
import {CreateFinishedGameDataModel} from "../../../domain/models/CreateFinishedGameDataModel";
import {createFinishedGame} from "../../../store/ActionsAdmin/FinishedGameActions";

type typeAccumulated = 'showGame' | 'showSession';

interface IAccumulatedProps {
    width: number;
    height: string | number;
    margin: number;
    top: number;
    left: number;
    type: typeAccumulated;
    click: () => void;
    clickEdit: () => void;
    clickDelete: () => void;
}

export default function Accumulated({
                                        width,
                                        height,
                                        margin,
                                        click,
                                        clickEdit,
                                        clickDelete,
                                        top,
                                        left,
                                        type,
                                    }: IAccumulatedProps): JSX.Element {

    const singleSessionDatabase = useAppSelector((state) => state.sessionState.singleSessionDatabase);
    const singleBingo = useAppSelector((state) => state.bingoState.singleBingo);
    const allDebugMessages = useAppSelector((state) => state.bingoState.allDebugMessages);
    const isLoadingAllDebugMessages = useAppSelector((state) => state.bingoState.isLoadingAllDebugMessages);
    // const cumulativePercentage = useAppSelector((state) => state.sessionState.cumulativePercentage);
    const showModalUsersSession = useAppSelector((state) => state.modalState.showModalUsersSession);
    const showModalUsersRelSessionAndCards = useAppSelector((state) => state.modalState.showModalUsersRelSessionAndCards);
    const showModalCreateUserRelAccountAndSession = useAppSelector((state) => state.modalState.showModalCreateUserRelAccountAndSession);
    const cardsCountBySessionId = useAppSelector((state) => state.cardsState.cardsCountBySessionId);
    const showModalCurrentGameData = useAppSelector((state) => state.modalState.showModalCurrentGameData);
    const dispatch = useAppDispatch();
    const [cumulativePercentage, setCumulativePercentage] = useState(0);

    const [isLoadingEndGameForce, setIsLoadingEndGameForce] = useState<boolean>(false);

    function handleOpenModalUsersSession() {
        console.log('click modal usuarios');
        dispatch(openAndCloseModalUsersSession(true));
    }

    useEffect(() => {
        if (singleSessionDatabase) {
            dispatch(getCardSCountBySessionId(singleSessionDatabase.id)).then(() => {
            });
        }
    }, [singleSessionDatabase]);

    useEffect(() => {
        if (singleSessionDatabase) {
            const totalValue = singleSessionDatabase.card_price * cardsCountBySessionId;
            const cumulativePercentage = getCumulativePercentage(totalValue, singleSessionDatabase.prize_pool);
            setCumulativePercentage(cumulativePercentage);
        }
    }, [singleSessionDatabase, cardsCountBySessionId]);

    function handleOpenModalBulkUser() {
        dispatch(openAndCloseModalAddBulkUsers(true));
    }

    function handleCreateUserRelAccountAndSession() {
        dispatch(openAndCloseModalUserRelAccountAndSession(true));
    }

    function handleFinalizeGameForce() {
        if (singleBingo) {
            const dataListBallotLocalStorage: GameStorageDataModel | null = getLocalStorageObj(`game${singleBingo.id.toString()}`);
            const winners: any[] | null = getLocalStorageObj(`winners${singleBingo.id.toString()}`);
            const dataGameFinished: CreateFinishedGameDataModel = {
                game_id: singleBingo.id,
                list_ballot_played: dataListBallotLocalStorage ? JSON.stringify(dataListBallotLocalStorage.listBallotPlayed) : JSON.stringify(['Descartado']),
                list_ballot_played_data: dataListBallotLocalStorage ? JSON.stringify(dataListBallotLocalStorage.listBallotPlayedData) : JSON.stringify(['Descartado']),
                last_ballot: dataListBallotLocalStorage ? dataListBallotLocalStorage.lastBallot : '',
                winners: winners ? JSON.stringify(winners) : JSON.stringify([]),
            }
            setIsLoadingEndGameForce(true);
            dispatch(endGameForced(singleBingo.id)).then(() => {
                dispatch(createFinishedGame(dataGameFinished)).then(() => {
                    dispatch(getSingleBingo(singleBingo.id)).then(() => {
                        setIsLoadingEndGameForce(false);
                        alertSuccess('Juego finalizado con éxito');
                    });
                });
            });
        }
    }

    function handleOpenModalCurrentGameData() {
        dispatch(openAndCloseModalCurrentGameDAta(true));
    }

    // function handleOpenDebugMessage() {
    //     if (singleBingo) {
    //         if (allDebugMessages.length === 0) {
    //             console.log('click con lista debug vacia');
    //             dispatch(getAllDebugMessages(singleBingo.id)).then(() => {
    //                 dispatch(openAndCloseModalDebugMessage(true));
    //             });
    //         } else {
    //             console.log('click con lista debug con datos');
    //             dispatch(openAndCloseModalDebugMessage(true));
    //         }
    //     }
    // }

    return (
        <AccumulatedStyles
            width={width}
            height={height}
            margin={margin}
            cumulativePercentage={cumulativePercentage > 100 ? 100 : cumulativePercentage}
            top={top}
            left={left}
        >
            <div className="container-icon-back">
                <img onClick={click} className="icon-back" alt="back" src="/img/newImage/optionsAdmin/icon-back.png"/>
            </div>
            <div className="container-img">
                <img
                    className="options-admin-img-ball-8-big"
                    alt="optionsAdminImgBall8Big"
                    src={singleSessionDatabase?.main_image}
                />
            </div>
            <div className="container-info">
                <div className="title-info">
                    <h3 className="title">{singleSessionDatabase?.session_name}</h3>
                    <div className="container-icon-delete">
                        {/*{type === 'showGame' &&*/}
                        {/*    <span onClick={handleOpenDebugMessage} className="text-create-user">*/}
                        {/*        {!isLoadingAllDebugMessages ? 'Debug message' : 'Cargando'}*/}
                        {/*    </span>*/}
                        {/*}*/}
                        {type === 'showGame' &&
                            <span onClick={handleOpenModalCurrentGameData}
                                  className="text-create-user">Datos del juego</span>
                        }
                        {type === 'showGame' && singleBingo && singleBingo.status !== 'end' &&
                            <span onClick={handleFinalizeGameForce} className="text-create-user">Finalizar juego</span>
                        }
                        {type === 'showSession' &&
                            <span onClick={handleCreateUserRelAccountAndSession} className="text-create-user">Crear usuario</span>
                        }
                        {type === "showSession" &&
                            <span className="text-create-bulk-users" onClick={handleOpenModalBulkUser}>Crear usuarios masivos</span>
                        }
                        <MdOutlineDelete className="icon-delete" onClick={clickDelete}/>
                        <div className="container-edit" onClick={clickEdit}>
                            <img
                                className="options-admin-img-edit-2"
                                alt="optionsAdminImgEdit2"
                                src="/img/newImage/optionsAdmin/optionsAdminImgEdit2.svg"
                            />
                            <img
                                className="options-admin-img-edit-1"
                                alt="optionsAdminImgEdit1"
                                src="/img/newImage/optionsAdmin/optionsAdminImgEdit1.svg"
                            />
                        </div>
                    </div>
                </div>
                <div className="container-players-cards">
                    <div onClick={handleOpenModalUsersSession} className="players-cards">
                        <img
                            className="options-admin-img-count-players"
                            alt="optionsAdminImgCountPlayers"
                            src="/img/newImage/optionsAdmin/optionsAdminImgCountPlayers.svg"
                        />
                        <span className="count-players">{singleSessionDatabase?.users.length}</span>
                        <span className="text-players">Jugadores</span>
                    </div>
                    <div className="players-cards">
                        <img
                            className="options-admin-img-count-players"
                            alt="optionsAdminImgCardPlay"
                            src="/img/newImage/optionsAdmin/optionsAdminImgCardPlay.svg"
                        />
                        <span className="count-players">{cardsCountBySessionId}</span>
                        <span className="text-players">Cartones en juego</span>
                    </div>
                </div>
                <div className="container-accumulated">
                    <span className="p-accumulated">Acumulado para premio extra:</span>
                    <div className="rectangle">
                        <div className="rectangle2">
                            <span className="result-percentege">{cumulativePercentage}%</span>
                        </div>
                    </div>
                </div>
            </div>
            {showModalUsersSession &&
                <div className="modal-users-session">
                    <UsersSession/>
                </div>
            }
            {showModalUsersRelSessionAndCards &&
                <div className="modal-users-rel-session-and-cards">
                    <UsersRelSessionAndCards/>
                </div>
            }
            {showModalCreateUserRelAccountAndSession &&
                <div className="modal-users-rel-account-and-session">
                    <CreateUserToSession/>
                </div>
            }
            {isLoadingEndGameForce &&
                <Loading/>
            }
            {showModalCurrentGameData &&
                <div className="modal-current-game-data">
                    <CurrentGameData/>
                </div>
            }
        </AccumulatedStyles>
    );
}
