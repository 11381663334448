import {GameStateStyled} from "./GameStateStyled";
import React, {useEffect} from "react";

interface IGameStateProps {
    statusGame: string;
    nameGame: string;
    id: number;
    idSelected: boolean;
    handleGameIdSelected: (id: number) => void;
}

export default function GameState({
                                      statusGame,
                                      nameGame,
                                      id,
                                      handleGameIdSelected,
                                      idSelected,
                                  }: IGameStateProps): JSX.Element {

    function handleSelectedSingleGame(id: number) {
        console.log(id);
        handleGameIdSelected(id);
    }

    useEffect(() => {
        console.log(statusGame);
        console.log(nameGame);
        console.log(id);
        console.log(idSelected);
    }, [statusGame, nameGame, id, idSelected]);

    return (
        <GameStateStyled
            onClick={() => handleSelectedSingleGame(id)}
            border={idSelected}
        >
            {statusGame === 'inactive' &&
                <img
                    className="game-finish"
                    alt="game-finish"
                    src='/img/imgUser/imgGame/game-programmed.png'
                />
            }
            {statusGame === 'active' &&
                <img
                    className="game-finish"
                    alt="game-finish"
                    src='/img/newImage/in-game.png'
                />
            }
            {statusGame === 'end' &&
                <img
                    className="game-finish"
                    alt="game-finish"
                    src='/img/imgUser/imgGame/game-finish.png'
                />
            }
            <span className="game-name">{nameGame}</span>
        </GameStateStyled>
    );
}
