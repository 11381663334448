import {ShowSessionSponsorsStyled} from "./ShowSessionSponsorsStyled";
// import ReactQuill from "react-quill";
import {SetStateAction, useEffect, useState} from "react";
import QuillShowSession from "../QuillShowSession/QuillShowSession";
import {SessionConfigSponsorsModel} from "../../../domain/models/SessionConfigSponsorsModel";
import {
    editSessionConfigSponsors
} from "../../../store/ActionsAdmin/SessionConfigActions";
import {getSingleSessionDatabase} from "../../../store/ActionsAdmin/SessionActions";
import {useAppDispatch, useAppSelector} from "../../../shared/Utils/Hooks";


export default function ShowSessionSponsors(): JSX.Element {

    const dispatch = useAppDispatch();
    const [sponsorsContent, setSponsorsContent] = useState('');
    const singleSessionDatabase = useAppSelector((state) => state.sessionState.singleSessionDatabase);
    const isLoadingSessionConfigSponsors = useAppSelector((state) => state.sessionConfigState.isLoadingSessionConfigSponsors);
    const [dataSponsors, setDataSponsors] = useState<string>('');
    const sponsorsHandleChange = (content: SetStateAction<string>) => {
        setSponsorsContent(content);
    };


    function handleOnClickButton() {
        console.log(sponsorsContent);
        const sponsorsString = JSON.stringify(sponsorsContent);
        console.log(sponsorsString);
        const data: SessionConfigSponsorsModel = {
            sponsors_data: sponsorsString,
        }

        if (singleSessionDatabase) {
            dispatch(editSessionConfigSponsors(data, singleSessionDatabase.session_config_id)).then(() => {
                dispatch(getSingleSessionDatabase(singleSessionDatabase.id)).then(() => {});
            });
        }
    }

    useEffect(() => {
        if (singleSessionDatabase && singleSessionDatabase.session_config.sponsors_data) {
            const dataSponsors: string | null = JSON.parse(singleSessionDatabase.session_config.sponsors_data);
            console.log(dataSponsors);
            if (dataSponsors !== null) {
                setDataSponsors(dataSponsors);
            } else {
                setDataSponsors('');
            }
        }
    }, [singleSessionDatabase]);

    return (
        <ShowSessionSponsorsStyled>
            <QuillShowSession
                value={dataSponsors}
                handleChange={sponsorsHandleChange}
                onClickButton={handleOnClickButton}
                loading={isLoadingSessionConfigSponsors}
            />
        </ShowSessionSponsorsStyled>
    );
}
