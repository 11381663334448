import {SessionConfigInformationModel} from "../../domain/models/SessionConfigInformationModel";
import axios from "axios";
import {myDBDomain} from "../../shared/Utils/TestingApi/MyDBDomain";
import {
    EDIT_SESSION_CONFIG_ACQUIRE_CARDS,
    EDIT_SESSION_CONFIG_ACQUIRE_CARDS_FAILED,
    EDIT_SESSION_CONFIG_ACQUIRE_CARDS_SUCCESS,
    EDIT_SESSION_CONFIG_AWARDS,
    EDIT_SESSION_CONFIG_AWARDS_FAILED,
    EDIT_SESSION_CONFIG_AWARDS_SUCCESS,
    EDIT_SESSION_CONFIG_INFORMATION,
    EDIT_SESSION_CONFIG_INFORMATION_FAILED,
    EDIT_SESSION_CONFIG_INFORMATION_SUCCESS,
    EDIT_SESSION_CONFIG_SPONSORS,
    EDIT_SESSION_CONFIG_SPONSORS_FAILED,
    EDIT_SESSION_CONFIG_SPONSORS_SUCCESS,
    GET_SINGLE_SESSION_CONFIG,
    GET_SINGLE_SESSION_CONFIG_FAILED,
    GET_SINGLE_SESSION_CONFIG_SUCCESS
} from "../TypesAdmin/SessionConfigTypes";
import {alertSuccess} from "../../shared/Utils/AlertSuccess";
import {alertError} from "../../shared/Utils/AlertError";
import {SessionConfigSponsorsModel} from "../../domain/models/SessionConfigSponsorsModel";
import {SessionConfigAwardsModel} from "../../domain/models/SessionConfigAwardsModel";
import {SessionConfigAcquireCardsModel} from "../../domain/models/SessionConfigAcquireCardsModel";

export const editSessionConfigInformation = (information: SessionConfigInformationModel, session_config_id: number) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/sessionConfig/edit/${session_config_id}`,
        headers: {
            'Content-Type': 'application/json'
        },
        data : information
    };
    dispatch({
        type: EDIT_SESSION_CONFIG_INFORMATION,
        payload: true
    });
    await axios.request(config)
        .then((response) => {
            console.log(response.data);
            dispatch({
                type: EDIT_SESSION_CONFIG_INFORMATION_SUCCESS,
                payload: false
            });
            alertSuccess(response.data.message);
        })
        .catch((error) => {
            console.log(error);
            dispatch({
                type: EDIT_SESSION_CONFIG_INFORMATION_FAILED,
                payload: false
            });
            alertError(error.response.data.message);
        });
}

export const editSessionConfigSponsors = (sponsors: SessionConfigSponsorsModel, session_config_id: number) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/sessionConfig/edit/${session_config_id}`,
        headers: {
            'Content-Type': 'application/json'
        },
        data : sponsors
    };
    dispatch({
        type: EDIT_SESSION_CONFIG_SPONSORS,
        payload: true
    });
    await axios.request(config)
        .then((response) => {
            console.log(response.data);
            dispatch({
                type: EDIT_SESSION_CONFIG_SPONSORS_SUCCESS,
                payload: false
            });
            alertSuccess(response.data.message);
        })
        .catch((error) => {
            console.log(error);
            dispatch({
                type: EDIT_SESSION_CONFIG_SPONSORS_FAILED,
                payload: false
            });
            alertError(error.response.data.message);
        });
}

export const editSessionConfigAwards = (awards: SessionConfigAwardsModel, session_config_id: number) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/sessionConfig/edit/${session_config_id}`,
        headers: {
            'Content-Type': 'application/json'
        },
        data : awards
    };
    dispatch({
        type: EDIT_SESSION_CONFIG_AWARDS,
        payload: true
    });
    await axios.request(config)
        .then((response) => {
            console.log(response.data);
            dispatch({
                type: EDIT_SESSION_CONFIG_AWARDS_SUCCESS,
                payload: false
            });
            alertSuccess(response.data.message);
        })
        .catch((error) => {
            console.log(error);
            dispatch({
                type: EDIT_SESSION_CONFIG_AWARDS_FAILED,
                payload: false
            });
            alertError(error.response.data.message);
        });
}

export const editSessionAcquireCards = (acquireCards: SessionConfigAcquireCardsModel, session_config_id: number) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/sessionConfig/edit/${session_config_id}`,
        headers: {
            'Content-Type': 'application/json'
        },
        data : acquireCards
    };
    dispatch({
        type: EDIT_SESSION_CONFIG_ACQUIRE_CARDS,
        payload: true
    });
    await axios.request(config)
        .then((response) => {
            console.log(response.data);
            dispatch({
                type: EDIT_SESSION_CONFIG_ACQUIRE_CARDS_SUCCESS,
                payload: false
            });
            alertSuccess(response.data.message);
        })
        .catch((error) => {
            console.log(error);
            dispatch({
                type: EDIT_SESSION_CONFIG_ACQUIRE_CARDS_FAILED,
                payload: false
            });
            alertError(error.response.data.message);
        });
}


export const getSingleSessionConfig = (session_config_id: number) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/sessionConfig/get/${session_config_id}`,
        headers: {
            'Content-Type': 'application/json'
        },
    };
    dispatch({
        type: GET_SINGLE_SESSION_CONFIG,
        payload: true
    });
    await axios.request(config)
        .then((response) => {
            console.log(response.data);
            dispatch({
                type: GET_SINGLE_SESSION_CONFIG_SUCCESS,
                payload: response.data.data
            });
        })
        .catch((error) => {
            console.log(error);
            dispatch({
                type: GET_SINGLE_SESSION_CONFIG_FAILED,
                payload: error.response.data.error
            });
        });
}
