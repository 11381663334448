import {SessionInfoBingoStyles} from "./SessionInfoBingoStyles";
import GameMode from "../GameMode/GameMode";
import {useThemeContext} from "../../../shared/Hooks/ThemeContextProvider";

interface ISessionInfoBingo {
    bingoName: string | undefined;
    bingoPrize: string | undefined;
    gameMode: string | undefined;
    image: string | undefined;
}

export default function SessionInfoBingo({
                                             bingoPrize,
                                             bingoName,
                                             gameMode,
                                             image,
                                         }: ISessionInfoBingo): JSX.Element {

    const themeActive = useThemeContext();


    return (
        <SessionInfoBingoStyles
            background_info_game={themeActive.theme ? themeActive.theme.background_info_game : ''}
        >
            <div className="img-opacity">
                <div className="container-game-mode">
                    <GameMode gameMode={gameMode}/>
                </div>
                <div className="info-bingo">
                    <div className="container-img-game">
                        <img
                            className="image-card-session-big"
                            alt="imageCardSessionBig"
                            // src="/img/newImage/optionsAdmin/imageCardSessionBig.png"
                            src={image}
                        />
                    </div>
                    <div className="container-info-game">
                        <span className="name-game">{bingoName}</span>
                        <span className="preze-game">Premio: {bingoPrize}</span>
                    </div>
                </div>
            </div>
        </SessionInfoBingoStyles>
    );
}
