import {UsersRelSessionAndCardsModel} from "../../domain/models/UsersRelSessionAndCardsModel";
import {UsersRelSessionAndCardsShowModel} from "../../domain/models/UsersRelSessionAndCardsShowModel";

export function handleGetUsersRelSessionAndCards(list: UsersRelSessionAndCardsModel[]): UsersRelSessionAndCardsShowModel[] {
    const newList: UsersRelSessionAndCardsShowModel[] = []
    list.map((user) => {
        const cardsData = user.players.map((player) => player.card_id).join(', ');
        const data: UsersRelSessionAndCardsShowModel = {
            name: user.name,
            identification_document: user.identification_document,
            phone: user.phone,
            cards: cardsData
        }
        newList.push(data);
    });
    return newList;
}