import styled from "styled-components";
import {Device} from "../../../shared/Utils/devices";

export const LastBallotsEndUserStyles = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0;
    width: 46vw;
    justify-content: center;
    margin-left: 3vw;

    .title {
        position: absolute;
        font-family: "Alata-Regular", sans-serif;
        font-size: .7vw;
        text-align: center;
        letter-spacing: .2vw;
        color: #ffffff;
        opacity: 1;
        margin: 0;
        bottom: 2.7vw;
        left: 4vw;
    }

    .container-row {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 46vw;

        .container-bar {
            width: 99%;
            height: 2.2vw;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: rgb(196, 1, 229);
            background: linear-gradient(90deg, rgba(196, 1, 229, 1) 49%, rgba(126, 2, 227, 1) 100%);
            border-radius: 1vw;
            border: .2vw solid #ffffff;
            opacity: 1;

            .triangle-box-left {
                margin-left: 2vw;
            }

            .triangle-box {
                width: 2vw;
                height: 1.5vw;
                position: relative;
                border-radius: 3px;
                opacity: 1;
                cursor: pointer;

                .triangle {
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 5px 0 6px 11px;
                    border-color: transparent transparent transparent #CBCBCB;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-70%, -50%) rotate(180deg);
                    background: #CBCBCB 0% 0% no-repeat padding-box;
                    opacity: 1;
                }

                .triangle-left {
                    width: 2vw;
                    height: 2vw;
                    border-width: 5px 0 6px 11px;
                    border-color: transparent transparent transparent #CBCBCB;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%) rotate(180deg);
                    opacity: 1;
                }

                .triangle-right {
                    width: 2vw;
                    height: 2vw;
                    border-width: 5px 0 6px 11px;
                    border-color: transparent transparent transparent #CBCBCB;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    opacity: 1;
                }
            }

            .triangle-box-right {
                flex-grow: 2;
                overflow-x: hidden;

                .numbers-container {
                    display: flex;
                    width: 39vw;
                    align-items: center;
                    justify-content: start; /* Cambia esto a flex-start */
                    flex-direction: row; /* Agrega esta línea */
                    margin-left: 2.5vw;

                    .numbers {
                        font-family: "Poppins-Light", sans-serif;
                        flex-shrink: 0;
                        white-space: nowrap;
                        color: #ffff;
                        text-align: right;
                        letter-spacing: 0;
                        opacity: 1;
                        margin-right: .9vw;
                        font-size: 1vw;
                        background: #FF00E650;
                        border-radius: 100%;
                        box-sizing: border-box;
                        width: 2vw;
                        height: 2vw;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }

        .stylized-image {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 7vw;
            position: absolute;
            left: -4vw;
            z-index: 9;
            //right: 27vw;
            //top: 1.7vw;

            .image {
                width: 100%;
            }

            .number-ballot {
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                font-family: AllerDisplay-Regular, sans-serif;
                font-weight: 900;
                font-size: 1.3vw;
                text-align: left;
                letter-spacing: 0px;
                color: #000000;
                opacity: 1;

            }
        }
    }

    @media ${Device.tablet} {
        margin-left: 1vw;

        .title {
            font-size: 3.3vw;
            top: 4vw;
        }

        .container-row {
            width: 88vw;
            top: 8vw;

            .stylized-image {
                width: 13vw;
                left: -8vw;

                .number-ballot {
                    font-size: 2.5vw;
                }
            }

            .container-bar {
                height: 5.5vw;


                .triangle-box-right {

                    .numbers-container {

                        .numbers {
                            margin-right: 2.5vw;
                            font-size: 3vw;
                            background: #FF00E650;
                            border-radius: 100%;
                            box-sizing: border-box;
                            width: 5vw;
                            height: 4.5vw;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }

                .triangle-box-left {
                    margin-left: 3vw;
                }

                .triangle-box {

                    .triangle {
                        display: none;
                    }

                    .triangle-left {
                        display: none;
                    }

                    .triangle-right {
                        display: none;
                    }
                }
            }
        }
    }

    @media ${Device.mobileL} {
        margin-left: 1vw;

        .title {
            font-size: 3.3vw;
            top: 4vw;
        }

        .container-row {
            width: 88vw;
            top: 8vw;

            .stylized-image {
                width: 13vw;
                left: -8vw;

                .number-ballot {
                    font-size: 2.5vw;
                }
            }

            .container-bar {
                height: 5.5vw;


                .triangle-box-right {

                    .numbers-container {

                        .numbers {
                            margin-right: 2.5vw;
                            font-size: 3vw;
                            background: #FF00E650;
                            border-radius: 100%;
                            box-sizing: border-box;
                            width: 5vw;
                            height: 4.5vw;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }

                .triangle-box-left {
                    margin-left: 3vw;
                }

                .triangle-box {

                    .triangle {
                        display: none;
                    }

                    .triangle-left {
                        display: none;
                    }

                    .triangle-right {
                        display: none;
                    }
                }
            }
        }
    }
    @media ${Device.mobileM} {
        margin-left: 1vw;

        .title {
            font-size: 3.3vw;
            top: 4vw;
        }

        .container-row {
            width: 88vw;
            top: 8vw;

            .stylized-image {
                width: 13vw;
                left: -8vw;

                .number-ballot {
                    font-size: 2.5vw;
                }
            }

            .container-bar {
                height: 5.5vw;


                .triangle-box-right {

                    .numbers-container {

                        .numbers {
                            margin-right: 2.5vw;
                            font-size: 3vw;
                            background: #FF00E650;
                            border-radius: 100%;
                            box-sizing: border-box;
                            width: 5vw;
                            height: 4.5vw;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }

                .triangle-box-left {
                    margin-left: 3vw;
                }

                .triangle-box {

                    .triangle {
                        display: none;
                    }

                    .triangle-left {
                        display: none;
                    }

                    .triangle-right {
                        display: none;
                    }
                }
            }
        }
    }
    @media ${Device.mobileS} {
        margin-left: 1vw;

        .title {
            font-size: 3.3vw;
            top: 4vw;
        }

        .container-row {
            width: 88vw;
            top: 8vw;

            .stylized-image {
                width: 13vw;
                left: -8vw;

                .number-ballot {
                    font-size: 2.5vw;
                }
            }

            .container-bar {
                height: 5.5vw;


                .triangle-box-right {

                    .numbers-container {

                        .numbers {
                            margin-right: 2.5vw;
                            font-size: 3vw;
                            background: #FF00E650;
                            border-radius: 100%;
                            box-sizing: border-box;
                            width: 5vw;
                            height: 4.5vw;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }

                .triangle-box-left {
                    margin-left: 3vw;
                }

                .triangle-box {

                    .triangle {
                        display: none;
                    }

                    .triangle-left {
                        display: none;
                    }

                    .triangle-right {
                        display: none;
                    }
                }
            }
        }
    }
`
