import styled from "styled-components";

export const ImagesStorageStyles = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .container-title {
        display: flex;
        align-items: start;
        justify-content: space-between;
        width: 100%;
        margin-top: 1vw;

        .title {
            text-align: center;
            letter-spacing: 0;
            color: #2E304E;
            opacity: 1;
            font-size: 2.1vw;
            font-weight: 600;
            margin-left: 2.5vw;
            font-family: Poppins-SemiBold, sans-serif;
        }

        .close {
            text-align: center;
            letter-spacing: 0;
            color: #2E304E;
            opacity: 1;
            font-size: 2vw;
            font-weight: bold;
            margin-right: 2vw;
            cursor: pointer;
        }
    }
    .add-image {
        display: flex;
        align-items: center;
        justify-content: start;
        width: 80%;
        margin: 1.1vw auto;
        
        div {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: .6vw;
            cursor: pointer;

            span {
                font-size: 1vw;
                color: #777777;
            }
            
            .input-img {
                display: none;
            }
        }
    }
    .container-images {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-items: center;
        gap: .5vw;
        width: 85%;
        height: 80%;
        margin: 0 auto;
        overflow-y: scroll;
        padding: 1vw;
        
        &::-webkit-scrollbar {
            width: 0.5vw; /* Ancho del scrollbar */

        }

        &::-webkit-scrollbar-track {
            background-color: #CCCFDE;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #00000038;
            border-radius: 10px;
            height: 3px;
        }
        
        .container-image {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            width: 6vw;
            height: 6vw;
            overflow: hidden;
            padding: .5vw;
            border: .1vw solid #777777;
            
            img {
                width: 70%;
                cursor: pointer;
            }
        }
    }
    
`
