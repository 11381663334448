import {ReactNode, createContext, useState, useContext, useEffect} from "react";
import {ThemeDefaultModel} from "../../domain/models/ThemeDefaultModel";
import {themeDefault} from "../Utils/ThemeDefault";
import {getLocalStorageObj} from "../Utils/GetLocalStorageObj";

export interface IThemeContextProviderProps {
    children: ReactNode;
}
export interface IThemeContext {
    theme: ThemeDefaultModel | null;
    toggleTheme: (theme: ThemeDefaultModel) => void;
}
export const ThemeContext = createContext<IThemeContext>({
    theme: null,
    toggleTheme: (theme: ThemeDefaultModel) => {}
});

export default function ThemeContextProvider({children}:IThemeContextProviderProps): JSX.Element {

    const [theme, setTheme] = useState<ThemeDefaultModel | null>(null)

    function handleTheme(theme: ThemeDefaultModel) {
        console.log(theme);
        setTheme(theme);
    }

    const context: IThemeContext = {
        theme: theme,
        toggleTheme: handleTheme
    }

    useEffect(() => {
        const themeSelect: ThemeDefaultModel | null = getLocalStorageObj('theme');
        console.log(themeSelect);
        if (themeSelect) {
            setTheme(themeSelect);
        } else {
            setTheme(themeDefault)
        }
    }, []);

    return (
        <ThemeContext.Provider value={context}>
            {children}
        </ThemeContext.Provider>
    );
}

export function useThemeContext() {
    const {theme, toggleTheme} = useContext(ThemeContext);
    return {theme, toggleTheme};
}