export function winnerNumber(value:string) {
    console.log(value);
    if (!value) {
        return "Limite de ganadores requerido";
    }
    let winner_number:number = parseInt(value, 10);
    if (isNaN(winner_number)) {
        return 'Ingresa un número válido';
    }
    if (winner_number < 1) {
        return 'El número debe ser igual o mayor que 1';
    }
    return true;
}
export function bingoNameValidate(value:string) {
    console.log(value);
    if (value === '') {
        return 'Nombre del bingo requerido';
    }

    if (value.length > 30) {
        return 'max 15 caracteres';
    }

    return true;
}

export function bingoNamePrize(value:string) {
    console.log(value);
    if(value === '') {
        return 'Nombre del premio requerido';
    }
    if (value.length > 20) {
        return 'max 15 caracteres';
    }
}

export function bingoGameMode(value:string) {
    if (value === '') {
        return 'Modo de juego requerido';
    }
    return true;
}


