import {HeaderStyled} from "./HeaderStyled";
import ProgressBar from "../ProgressBar/ProgressBar";
import CircularAvatar from "../CircularAvatar/CircularAvatar";
import React, {useState} from "react";
import {useThemeContext} from "../../../../admin/shared/Hooks/ThemeContextProvider";
import {getSessionStorageObj} from "../../../../admin/shared/Utils/GetSessionStorageObject";
import {useHistory} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../admin/shared/Utils/Hooks";
import {openUserGameInfo, openUserInfo} from "../../../../admin/store/ActionsAdmin/ModalsActions";

export default function Header():JSX.Element {

    const dispatch = useAppDispatch();
    const themeActive = useThemeContext();
    const user = getSessionStorageObj('user');
    const history = useHistory();
    const [openAndCloseModalUserGameInfo, setOpenAndCloseModalUserGameInfo] = useState<boolean>(false);
    const showModalUserGameInfo = useAppSelector((state) => state.modalState.showModalUserGameInfo);

    function handleRedirectHome() {
        dispatch(openUserInfo(false));
        history.push('/user/home');
    }

    function handleOpenAndCloseModalUserGameInfo(data:boolean) {
        dispatch(openUserInfo(data));
        setOpenAndCloseModalUserGameInfo(data);
    }

    return (
        <HeaderStyled>
            <img
                onClick={handleRedirectHome}
                className="options-admin-title-header"
                alt="optionsAdminTitle-header"
                src={themeActive.theme?.logo}
            />
            <div onClick={() => handleOpenAndCloseModalUserGameInfo(!openAndCloseModalUserGameInfo)} className="container-user-session">
                <div className="container-icon-session">
                    <img
                        className="img-user-session-circle"
                        alt="optionsAdminImgUserSessionCircle"
                        src="/img/newImage/optionsAdmin/optionsAdminImgUserSessionCircle.svg"
                    />
                    <img
                        className="img-user-session-avatar"
                        alt="optionsAdminImgUserSessionAvatar"
                        src="/img/newImage/optionsAdmin/optionsAdminImgUserSessionAvatar.svg"
                    />
                </div>
                <span className="name-user">{!showModalUserGameInfo ? `Hola, ${user?.name}` : 'Perfil'}</span>
            </div>
        </HeaderStyled>
    );
}
