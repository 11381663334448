import {getCurrentFormattedDate} from "./CurrentDate";

export function cardLimit(value:string) {
    // if (!value) {
    //     return "Numero de tarjetones requerido";
    // }
    const card_limit:number = parseInt(value, 10);
    if (isNaN(card_limit)) {
        return "Ingresa un número válido";
    }
    // if (card_limit > 21) {
    //     return "El límite de tarjetones debe ser menor o igual 21";
    // }
    if (card_limit <= 0) {
        return "El límite de tarjetones debe ser mayor a 0";
    }
    return true;
}
export function cardPrice(value:string) {
    if (!value) {
        return "Valor del bingo requerido";
    }
    const card_price:number = parseInt(value, 10);
    if (isNaN(card_price)) {
        return "Ingresa un número válido";
    }
    if (card_price < 0) {
        return "El precio del bingo debe ser mayor o igual a 0";
    }
    return true;
}
export function getTodayDate(value:string) {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
}

export function sessionName(value:string) {
    console.log(value);
    if (value === '') {
        return 'Nombre de la sesion requerido';
    }
    if (value.length > 25) {
        return 'max 25 caracteres';
    }
    return true;
}

export function sessionDescription(value:string) {
    console.log(value);
    if (value === '') {
        return 'Descripcion de la sesion requerido';
    }
    if (value.length > 50) {
        return 'max 50 caracteres';
    }
    return true;
}

export function accumulatedValue (value:string) {
    if (!value) {
        return "Valor del acumulado requerido";
    }
    const card_price:number = parseInt(value, 10);
    if (isNaN(card_price)) {
        return "Ingresa un número válido";
    }
    if (card_price < 0) {
        return "El precio del bingo debe ser mayor o igual a 0";
    }
    return true;
}

export function sessionLimit (value: string) {
    if (value === '') {
        return "Limite de sesion requerido";
    }
    return true;
}

export function dateEditSession (value: string) {
    const today = getCurrentFormattedDate()
    if (value === '') {
        return "Fecha requerida";
    }
    if(value < today) {
        return 'La fecha debe ser mayor o igual a la fehca actual...';
    }
    return true;
}

export function ValidateCurrentDate(value:string) {
    const today = getCurrentFormattedDate();
    if (value === '') {
        return 'La fecha es requerida...';
    }
    if(value < today) {
        return 'La fecha debe ser mayor o igual a la fehca actual...';
    }
    return true;
}

export function ValidateHeaderSession(value:string) {
    const regex = /^[a-zA-Z]+$/;
    const validateData = regex.test(value);
    if (!validateData) {
        return 'No se aceptan número ni simbolos';
    }
    if (value === '') {
        return 'Nombre cabecera tarjeton requerido...';
    }
    if (value.length < 5) {
        return 'Debe contener 5 caracteres';
    }
    return true;
}
