import {CreateNewGameStyles} from "./CreateNewGameStyles";
import {useForm} from "react-hook-form";
import {useAppDispatch, useAppSelector} from "../../../shared/Utils/Hooks";
import {useEffect, useRef, useState} from "react";
import {
    openAdnCloseModalCreateGame,
    openAndCloseModalImagesStorageGame
} from "../../../store/ActionsAdmin/ModalsActions";
import {CreateBingoFormModel, CreateBingoModel} from "../../../domain/models/CreateBingoModel";
import {gameModes} from "../../../shared/Utils/GameModes";
import {
    createBingo,
    handleSelectedClickAddImage,
    handleSelectedImageMainCreateGame
} from "../../../store/ActionsAdmin/BingosActions";
import {
    bingoGameMode,
    bingoNamePrize,
    bingoNameValidate, winnerNumber
} from "../../../shared/Utils/ValidationformCreateBingo";
import {handleDateNow} from "../../../shared/Utils/DateNow";
import {uploadFile} from "../../../../../Firebase/Firebase";
import {
    getAllSessionsActive,
    getAllSessionsInactive,
    getSingleSessionDatabase
} from "../../../store/ActionsAdmin/SessionActions";
import ImagesStorage from "../ImagesStorage/ImagesStorage";
import {myDBDomain} from "../../../shared/Utils/TestingApi/MyDBDomain";

export default function CreateNewGame(): JSX.Element {

    const dispatch = useAppDispatch();
    const {register, handleSubmit, reset, formState: {errors}} = useForm<CreateBingoFormModel>();
    const loadingCreateBingo = useAppSelector((state) => state.bingoState.loadingCreateBingo);
    const singleSessionDatabase = useAppSelector((state) => state.sessionState.singleSessionDatabase);
    const domainActive = useAppSelector((state) => state.accountState.domainActive);
    const offsetSessionInactive = useAppSelector((state) => state.sessionState.offsetSessionInactive);
    const offsetSessionActive = useAppSelector((state) => state.sessionState.offsetSessionActive);
    const showModalImagesStorageGame = useAppSelector((state) => state.modalState.showModalImagesStorageGame);
    const selectedImageMainCreateGame = useAppSelector((state) => state.bingoState.selectedImageMainCreateGame);
    const fileInputImgSmall = useRef<HTMLInputElement | null>(null);
    const [img, setImg] = useState<string|null>(null);
    const [errorImgCrateGame, setErrorImgCreateGame] = useState('');
    const [checkedListWinners, setCheckedListWinners] = useState<boolean>(false);

    function handleCreateGame(data: CreateBingoFormModel) {
        if (selectedImageMainCreateGame === '') {
            setErrorImgCreateGame('Imagen requerida');
        }
        setErrorImgCreateGame('');
        console.log(data);
        if (singleSessionDatabase && domainActive) {
            const dataEdit: CreateBingoModel = {
                bingo_name: data.bingo_name,
                game_mode: data.game_mode,
                prize: data.prize,
                bingo_header: singleSessionDatabase.bingo_header,
                winner_number: data.hasOwnProperty('winners_number')  ?  parseInt(data.winners_number) : 0,
                main_image: `${myDBDomain}/storage/${selectedImageMainCreateGame}`,
                status: 'inactive',
                session_id: singleSessionDatabase.id,
            }
            console.log(dataEdit);
            if (singleSessionDatabase) {
                dispatch(createBingo(dataEdit)).then(r => {
                    dispatch(getSingleSessionDatabase(singleSessionDatabase.id)).then(() => {
                        dispatch(getAllSessionsActive(domainActive.id, offsetSessionActive)).then(() => {});
                        dispatch(getAllSessionsInactive(domainActive.id, offsetSessionInactive)).then(() => {});
                        reset();
                        dispatch(openAdnCloseModalCreateGame(false));
                        dispatch(handleSelectedClickAddImage(''));
                        dispatch(handleSelectedImageMainCreateGame(''));
                    });
                });
            }
        }

    }

    const onSubmit = (data: CreateBingoFormModel) => handleCreateGame(data);

    function handleClickImgSmall() {
        dispatch(handleSelectedClickAddImage('selectImageMainCreateGame'));
        dispatch(openAndCloseModalImagesStorageGame(true));
    }

    function handleCloseModal() {
        dispatch(openAdnCloseModalCreateGame(false));
    }

    async function handleMainImageGame(e:any) {
        console.log(e.target.files[0].size);
        if (e.target.files[0].size < 100000) {
            const fileMinImage = e.target.files[0];
            console.log(fileMinImage);
            if (fileMinImage && img === null) {
                const dateFile:string = handleDateNow();
                const nameFile:string = `image_main_game_${dateFile}`;
                const fileFirebase = await uploadFile(fileMinImage, nameFile);
                setErrorImgCreateGame('');
                setImg(fileFirebase);
            }
            if(fileMinImage && img !== null) {
                const fileFirebase = await uploadFile(fileMinImage, img);
                setErrorImgCreateGame('');
                setImg(fileFirebase);
            }
        } else {
            setErrorImgCreateGame('La imagen no puede superar los 100K');
        }
    }

    function handleCheckedValue(checked: boolean) {
        console.log(checked);
        setCheckedListWinners(checked);
    }

    useEffect(() => {
        console.log('esta es la imagen del juego');
        console.log(selectedImageMainCreateGame)
    }, [selectedImageMainCreateGame]);

    return (
        <CreateNewGameStyles>
            <div className="container-title">
                <span className="title">Nuevo Juego</span>
                <span onClick={handleCloseModal} className="close">x</span>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="form">
                <div className="container-img-inputs">
                    <div className="container-img">
                        <input
                            ref={fileInputImgSmall}
                            type="file"
                            accept="image/*"
                            className="input-img-small"
                            onChange={handleMainImageGame}
                        />
                        <div onClick={handleClickImgSmall} className="circle-img">
                            <img
                                className="options-admin-add-image"
                                alt="optionsAdminAddImage"
                                src={selectedImageMainCreateGame !== '' ? `${myDBDomain}/storage/${selectedImageMainCreateGame}` : "/img/newImage/optionsAdmin/optionsAdminAddImage.png"}
                            />
                        </div>
                        {errorImgCrateGame !== '' &&
                            <span className="error-img">{errorImgCrateGame}</span>
                        }
                        {errorImgCrateGame === '' &&
                            <span className="info-add">Agregar/editar miniatura</span>
                        }
                    </div>
                    <div className="container-inputs">
                        <div className="container-input">
                            <input
                                {...register('bingo_name', {
                                    validate: bingoNameValidate
                                })}
                                aria-invalid={errors.bingo_name ? 'true' : 'false'}
                                className="input-session-name"
                                type="text"
                                placeholder="Nombre del juego"
                            />
                            {errors.bingo_name?.type === 'validate' && <span className='error'>{errors.bingo_name.message}</span>}
                        </div>
                        <div className="container-input">
                            <select
                                {...register('game_mode', {
                                    validate: bingoGameMode
                                })}
                                aria-invalid={errors.game_mode ? 'true' : 'false'}
                                className="mode-game"
                            >
                                <option value=''>Modo de juego</option>
                                {gameModes.map((mode, index) => (
                                    <option key={index} value={mode.ref}>{mode.translationRef}</option>
                                ))}
                            </select>
                            {errors.game_mode?.type === 'validate' && <span className='error'>{errors.game_mode.message}</span>}
                        </div>
                        <div className="container-input">
                            <input
                                {...register('prize', {
                                    validate: bingoNamePrize
                                })}
                                aria-invalid={errors.prize ? 'true' : 'false'}
                                className="game-prize"
                                type="text"
                                placeholder="Premio del Bingo"
                            />
                            {errors.prize?.type === 'validate' && <span className='error'>{errors.prize.message}</span>}
                        </div>
                        {checkedListWinners &&
                            <div className="container-input">
                                <input
                                    {...register('winners_number', {
                                        validate: winnerNumber
                                    })}
                                    aria-invalid={errors.winners_number ? 'true' : 'false'}
                                    className="game-prize"
                                    type="text"
                                    placeholder="Límite de Ganadores"
                                />
                                {errors.winners_number?.type === 'validate' &&
                                    <span className='error'>{errors.winners_number.message}</span>}
                            </div>
                        }
                        <div className="container-input-toggle">
                            <label className="switch">
                                <input onChange={(event) => handleCheckedValue(event.target.checked)} type="checkbox"/>
                                <span className="slider round"></span>
                            </label>
                            <span className="text-toggle">
                                {!checkedListWinners ? 'Poner limite de ganadores' : 'Quitar limite de ganadores'}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="container-button">
                    <button disabled={loadingCreateBingo} type="submit" className="button-form">{!loadingCreateBingo ? 'Crear Juego' : 'Creando...'}</button>
                </div>
            </form>
            {showModalImagesStorageGame &&
                <div className="modal-images-storage">
                    <ImagesStorage/>
                </div>
            }
        </CreateNewGameStyles>
    );
}
