import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from 'firebase/auth'
import { getStorage, ref, uploadBytes, getDownloadURL, listAll } from 'firebase/storage'
import { getFirestore } from 'firebase/firestore'


// const firebaseConfig = {
//     apiKey: "AIzaSyA7MeRCikcxuirlor2Ni3wbCgaV5O4-sXc",
//     authDomain: "pruebas-536a7.firebaseapp.com",
//     projectId: "pruebas-536a7",
//     storageBucket: "pruebas-536a7.appspot.com",
//     messagingSenderId: "983209322333",
//     appId: "1:983209322333:web:cfe5439d6a38f54d7baab4"
// };
const firebaseConfig = {
    apiKey: "AIzaSyDMqQHLTmVrGpdGNYLktwdAkLO1ZATrSVM",
    authDomain: "bingo-94635.firebaseapp.com",
    projectId: "bingo-94635",
    storageBucket: "bingo-94635.appspot.com",
    messagingSenderId: "384580717687",
    appId: "1:384580717687:web:06e079598421eea98e4a72"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const storage = getStorage(app);
export const db = getFirestore(app);
export const googleProvider = new GoogleAuthProvider();
export async function uploadFile(file:any, nameFile:string) {
    const storageRef = ref(storage, nameFile);
    await uploadBytes(storageRef, file);
    return await getDownloadURL(storageRef);
}
// auth.currentUser?.getIdTokenResult().then((idTokenResult) => {
//     if (!idTokenResult.)
// });