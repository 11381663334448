export function getBallotGame(listBallotPlay: string[], listBallotPlayed: string[], game_mode: string, bingo_limit: number) {
    switch (game_mode) {
        case 'col_1':
            return getBallotGameCol1(listBallotPlayed, bingo_limit);
        case 'col_2':
            return getBallotGameCol2(listBallotPlayed, bingo_limit);
        case 'col_3':
            return getBallotGameCol3(listBallotPlayed, bingo_limit);
        case 'col_4':
            return getBallotGameCol4(listBallotPlayed, bingo_limit);
        case 'col_5':
            return getBallotGameCol5(listBallotPlayed, bingo_limit);
        case 'corners':
            return getBallotGameCorners(listBallotPlayed, bingo_limit);
        case 'shape_X':
            return getBallotGameShapeX(listBallotPlayed, bingo_limit);
        case 'diagonal':
            return getBallotGameShapeX(listBallotPlayed, bingo_limit);
        case 'anti_diagonal':
            return getBallotGameShapeX(listBallotPlayed, bingo_limit);
        case 'row_3':
            return getBallotGameShapeX(listBallotPlayed, bingo_limit);
        default:
            return getBallotGameDefault(listBallotPlayed, listBallotPlay);
    }
}

function getBallotGameCol1(listBallotPlayed: string[], bingo_limit: number) {
    if (bingo_limit === 25) {
        const list = listBallot(1, 5);
        return getNumberNotRepeated(list, listBallotPlayed);
    }
    if (bingo_limit === 50) {
        const list = listBallot(1, 10);
        return getNumberNotRepeated(list, listBallotPlayed);
    }
    if (bingo_limit === 75) {
        const list = listBallot(1, 15);
        return getNumberNotRepeated(list, listBallotPlayed);
    }
    if (bingo_limit === 95 || bingo_limit === 99) {
        const list = listBallot(1, 19);
        return getNumberNotRepeated(list, listBallotPlayed);
    }
}
function getBallotGameCol2(listBallotPlayed: string[], bingo_limit: number) {
    if (bingo_limit === 25) {
        const list = listBallot(6, 10);
        return getNumberNotRepeated(list, listBallotPlayed);
    }
    if (bingo_limit === 50) {
        const list = listBallot(11, 20);
        return getNumberNotRepeated(list, listBallotPlayed);
    }
    if (bingo_limit === 75) {
        const list = listBallot(16, 30);
        return getNumberNotRepeated(list, listBallotPlayed);
    }
    if (bingo_limit === 95) {
        const list = listBallot(20, 38);
        return getNumberNotRepeated(list, listBallotPlayed);
    }
    if (bingo_limit === 99) {
        const list = listBallot(20, 39);
        return getNumberNotRepeated(list, listBallotPlayed);
    }
}
function getBallotGameCol3(listBallotPlayed: string[], bingo_limit: number) {
    if (bingo_limit === 25) {
        const list = listBallot(11, 15);
        return getNumberNotRepeated(list, listBallotPlayed);
    }
    if (bingo_limit === 50) {
        const list = listBallot(21, 30);
        return getNumberNotRepeated(list, listBallotPlayed);
    }
    if (bingo_limit === 75) {
        const list = listBallot(31, 45);
        return getNumberNotRepeated(list, listBallotPlayed);
    }
    if (bingo_limit === 95) {
        const list = listBallot(39, 57);
        return getNumberNotRepeated(list, listBallotPlayed);
    }
    if (bingo_limit === 99) {
        const list = listBallot(40, 59);
        return getNumberNotRepeated(list, listBallotPlayed);
    }
}
function getBallotGameCol4(listBallotPlayed: string[], bingo_limit: number) {
    if (bingo_limit === 25) {
        const list = listBallot(16, 20);
        return getNumberNotRepeated(list, listBallotPlayed);
    }
    if (bingo_limit === 50) {
        const list = listBallot(31, 40);
        return getNumberNotRepeated(list, listBallotPlayed);
    }
    if (bingo_limit === 75) {
        const list = listBallot(46, 60);
        return getNumberNotRepeated(list, listBallotPlayed);
    }
    if (bingo_limit === 95) {
        const list = listBallot(58, 76);
        return getNumberNotRepeated(list, listBallotPlayed);
    }
    if (bingo_limit === 99) {
        const list = listBallot(60, 79);
        return getNumberNotRepeated(list, listBallotPlayed);
    }
}
function getBallotGameCol5(listBallotPlayed: string[], bingo_limit: number) {
    if (bingo_limit === 25) {
        const list = listBallot(21, 25);
        return getNumberNotRepeated(list, listBallotPlayed);
    }
    if (bingo_limit === 50) {
        const list = listBallot(41, 50);
        return getNumberNotRepeated(list, listBallotPlayed);
    }
    if (bingo_limit === 75) {
        const list = listBallot(61, 75);
        return getNumberNotRepeated(list, listBallotPlayed);
    }
    if (bingo_limit === 95) {
        const list = listBallot(77, 95);
        return getNumberNotRepeated(list, listBallotPlayed);
    }
    if (bingo_limit === 99) {
        const list = listBallot(80, 99);
        return getNumberNotRepeated(list, listBallotPlayed);
    }
}
function getBallotGameCorners(listBallotPlayed: string[], bingo_limit: number) {
    if (bingo_limit === 25) {
        const list1 = listBallot(1, 5);
        const list2 = listBallot(21, 25);
        const listFull = list1.concat(list2);
        return getNumberNotRepeated(listFull, listBallotPlayed);
    }
    if (bingo_limit === 50) {
        const list1 = listBallot(1, 10);
        const list2 = listBallot(41, 50);
        const listFull = list1.concat(list2);
        return getNumberNotRepeated(listFull, listBallotPlayed);
    }
    if (bingo_limit === 75) {
        const list1 = listBallot(1, 15);
        const list2 = listBallot(61, 75);
        const listFull = list1.concat(list2);
        return getNumberNotRepeated(listFull, listBallotPlayed);
    }
    if (bingo_limit === 95) {
        const list1 = listBallot(1, 19);
        const list2 = listBallot(77, 95);
        const listFull = list1.concat(list2);
        return getNumberNotRepeated(listFull, listBallotPlayed);
    }
    if (bingo_limit === 99) {
        const list1 = listBallot(1, 19);
        const list2 = listBallot(80, 99);
        const listFull = list1.concat(list2);
        return getNumberNotRepeated(listFull, listBallotPlayed);
    }
}
function getBallotGameShapeX(listBallotPlayed: string[], bingo_limit: number) {
    if (bingo_limit === 25) {
        const list1 = listBallot(1, 10);
        const list2 = listBallot(16, 25);
        const listFull = list1.concat(list2);
        return getNumberNotRepeated(listFull, listBallotPlayed);
    }
    if (bingo_limit === 50) {
        const list1 = listBallot(1, 20);
        const list2 = listBallot(31, 50);
        const listFull = list1.concat(list2);
        return getNumberNotRepeated(listFull, listBallotPlayed);
    }
    if (bingo_limit === 75) {
        const list1 = listBallot(1, 15);
        const list2 = listBallot(61, 75);
        const listFull = list1.concat(list2);
        return getNumberNotRepeated(listFull, listBallotPlayed);
    }
    if (bingo_limit === 95) {
        const list1 = listBallot(1, 38);
        const list2 = listBallot(58, 95);
        const listFull = list1.concat(list2);
        return getNumberNotRepeated(listFull, listBallotPlayed);
    }
    if (bingo_limit === 99) {
        const list1 = listBallot(1, 39);
        const list2 = listBallot(60, 99);
        const listFull = list1.concat(list2);
        return getNumberNotRepeated(listFull, listBallotPlayed);
    }
}
function getBallotGameDefault(listBallotPlayed: string[], listBallotPlay: string[]) {
    return getNumberNotRepeated(listBallotPlay, listBallotPlayed);
}

export function listBallot(number_init: number, range: number) {
    const listData: string[] = [];
    for (number_init; number_init<=range; number_init++) {
        const formattedNumber = number_init.toString().padStart(2, '0');
        listData.push(formattedNumber);
    }
    return listData;
}

function getNumberNotRepeated(list: string[], listBallotPlayed: string[]) {
    const numberAvailable = list.filter(number => !listBallotPlayed.includes(number));
    const randomIndex = Math.floor(Math.random() * numberAvailable.length);
    return numberAvailable[randomIndex];
}