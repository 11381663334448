import {UsersModel} from "../../domain/models/UsersModel";
import {
    ALL_USERS,
    ALL_USERS_FAILED,
    ALL_USERS_SUCCESS,
    CLEAN_SEARCH_ALL_USERS,
    CLEAN_USERS_SESSION_REL,
    CLEAN_USERS_WINNERS_EXPORT,
    CREATE_USER,
    CREATE_USER_FAILED,
    CREATE_USER_LOCAL,
    CREATE_USER_LOCAL_FAILED,
    CREATE_USER_LOCAL_SUCCESS,
    CREATE_USER_REL_ACCOUNT_AND_SESSION,
    CREATE_USER_REL_ACCOUNT_AND_SESSION_FAILED,
    CREATE_USER_REL_ACCOUNT_AND_SESSION_SUCCESS,
    CREATE_USER_SUCCESS,
    CREATE_USERS_AND_USER_SESSION_REL,
    CREATE_USERS_AND_USER_SESSION_REL_FAILED,
    CREATE_USERS_AND_USER_SESSION_REL_SUCCESS,
    DELETE_USER,
    DELETE_USER_FAILED,
    DELETE_USER_SUCCESS,
    GET_END_USER_ACTIVE,
    GET_END_USER_ACTIVE_FAILED,
    GET_END_USER_ACTIVE_SUCCESS,
    GET_USERS_REL_SESSION_AND_CARDS,
    GET_USERS_REL_SESSION_AND_CARDS_FAILED,
    GET_USERS_REL_SESSION_AND_CARDS_SUCCESS,
    GET_USERS_SESSION_REL,
    GET_USERS_SESSION_REL_FAILED,
    GET_USERS_SESSION_REL_SUCCESS,
    GET_USERS_WINNERS_EXPORT,
    GET_USERS_WINNERS_EXPORT_FAILED,
    GET_USERS_WINNERS_EXPORT_SUCCESS,
    SEARCH_ALL_USERS,
    SEARCH_ALL_USERS_FAILED,
    SEARCH_ALL_USERS_SUCCESS
} from "../TypesAdmin/UserType";
import {UserActiveModel} from "../../../user/domain/models/UserActiveModel";
import {UsersRelSessionAndCardsShowModel} from "../../domain/models/UsersRelSessionAndCardsShowModel";
import {UsersWinnersToExportModel} from "../../domain/models/UsersWinnersToExportModel";

interface IStateUser {
    isLoadingAllUsers: boolean;
    allUsers: UsersModel[];
    isLoadingCreateUser: boolean;
    isLoadingCreateUserLocal: boolean;
    isLoadingDeleteUser: boolean;
    isLoadingSearchAllUsers: boolean;
    allUsersSearch: UsersModel[];
    allUsersSessionRelSearch: UsersModel[];
    isLoadingAllUsersSessionRelSearch: boolean;
    isLoadingUserActive: boolean;
    userEndActive: UserActiveModel | null;
    isLoadingCreateUsersAndUserSessionRel: boolean;
    isLoadingUsersRelSessionAndCards: boolean;
    allUsersRelSessionAndCards: UsersRelSessionAndCardsShowModel[];
    isLoadingUserRelAccountAndSession: boolean;
    isLoadingUsersWinnersToExport: boolean;
    usersWinnersToExport: UsersWinnersToExportModel[];
    message: string;
    error: string
}
const initialStateUser: IStateUser = {
    isLoadingAllUsers: false,
    allUsers: [],
    isLoadingCreateUser: false,
    isLoadingCreateUserLocal: false,
    isLoadingDeleteUser: false,
    isLoadingSearchAllUsers: false,
    allUsersSearch: [],
    allUsersSessionRelSearch: [],
    isLoadingAllUsersSessionRelSearch: false,
    isLoadingUserActive: false,
    userEndActive: null,
    isLoadingCreateUsersAndUserSessionRel: false,
    isLoadingUsersRelSessionAndCards: false,
    allUsersRelSessionAndCards: [],
    isLoadingUserRelAccountAndSession: false,
    isLoadingUsersWinnersToExport: false,
    usersWinnersToExport: [],
    message: '',
    error: '',
}
const UsersReducer = function (state = initialStateUser, action: {type:any; payload:any}):IStateUser {
    switch (action.type) {
        case ALL_USERS:
            return {
                isLoadingAllUsers: action.payload,
                allUsers: state.allUsers,
                isLoadingCreateUser: state.isLoadingCreateUser,
                isLoadingCreateUserLocal: state.isLoadingCreateUserLocal,
                isLoadingDeleteUser: state.isLoadingDeleteUser,
                isLoadingSearchAllUsers: state.isLoadingSearchAllUsers,
                allUsersSearch: state.allUsersSearch,
                allUsersSessionRelSearch: state.allUsersSessionRelSearch,
                isLoadingAllUsersSessionRelSearch: state.isLoadingAllUsersSessionRelSearch,
                isLoadingUserActive: state.isLoadingUserActive,
                userEndActive: state.userEndActive,
                isLoadingCreateUsersAndUserSessionRel: state.isLoadingCreateUsersAndUserSessionRel,
                isLoadingUsersRelSessionAndCards: state.isLoadingUsersRelSessionAndCards,
                allUsersRelSessionAndCards: state.allUsersRelSessionAndCards,
                isLoadingUserRelAccountAndSession: state.isLoadingUserRelAccountAndSession,
                isLoadingUsersWinnersToExport: state.isLoadingUsersWinnersToExport,
                usersWinnersToExport: state.usersWinnersToExport,
                message: '',
                error: '',
            };
        case ALL_USERS_SUCCESS:
            return {
                isLoadingAllUsers: false,
                allUsers: action.payload,
                isLoadingCreateUser: state.isLoadingCreateUser,
                isLoadingCreateUserLocal: state.isLoadingCreateUserLocal,
                isLoadingDeleteUser: state.isLoadingDeleteUser,
                isLoadingSearchAllUsers: state.isLoadingSearchAllUsers,
                allUsersSearch: state.allUsersSearch,
                allUsersSessionRelSearch: state.allUsersSessionRelSearch,
                isLoadingAllUsersSessionRelSearch: state.isLoadingAllUsersSessionRelSearch,
                isLoadingUserActive: state.isLoadingUserActive,
                userEndActive: state.userEndActive,
                isLoadingCreateUsersAndUserSessionRel: state.isLoadingCreateUsersAndUserSessionRel,
                isLoadingUsersRelSessionAndCards: state.isLoadingUsersRelSessionAndCards,
                allUsersRelSessionAndCards: state.allUsersRelSessionAndCards,
                isLoadingUserRelAccountAndSession: state.isLoadingUserRelAccountAndSession,
                isLoadingUsersWinnersToExport: state.isLoadingUsersWinnersToExport,
                usersWinnersToExport: state.usersWinnersToExport,
                message: '',
                error: '',
            };
        case ALL_USERS_FAILED:
            return {
                isLoadingAllUsers: false,
                allUsers: state.allUsers,
                isLoadingCreateUser: state.isLoadingCreateUser,
                isLoadingCreateUserLocal: state.isLoadingCreateUserLocal,
                isLoadingDeleteUser: state.isLoadingDeleteUser,
                isLoadingSearchAllUsers: state.isLoadingSearchAllUsers,
                allUsersSearch: state.allUsersSearch,
                allUsersSessionRelSearch: state.allUsersSessionRelSearch,
                isLoadingAllUsersSessionRelSearch: state.isLoadingAllUsersSessionRelSearch,
                isLoadingUserActive: state.isLoadingUserActive,
                userEndActive: state.userEndActive,
                isLoadingCreateUsersAndUserSessionRel: state.isLoadingCreateUsersAndUserSessionRel,
                isLoadingUsersRelSessionAndCards: state.isLoadingUsersRelSessionAndCards,
                allUsersRelSessionAndCards: state.allUsersRelSessionAndCards,
                isLoadingUserRelAccountAndSession: state.isLoadingUserRelAccountAndSession,
                isLoadingUsersWinnersToExport: state.isLoadingUsersWinnersToExport,
                usersWinnersToExport: state.usersWinnersToExport,
                message: '',
                error: action.payload,
            };
        case CREATE_USER:
            return {
                isLoadingAllUsers: state.isLoadingAllUsers,
                allUsers: state.allUsers,
                isLoadingCreateUser: action.payload,
                isLoadingCreateUserLocal: state.isLoadingCreateUserLocal,
                isLoadingDeleteUser: state.isLoadingDeleteUser,
                isLoadingSearchAllUsers: state.isLoadingSearchAllUsers,
                allUsersSearch: state.allUsersSearch,
                allUsersSessionRelSearch: state.allUsersSessionRelSearch,
                isLoadingAllUsersSessionRelSearch: state.isLoadingAllUsersSessionRelSearch,
                isLoadingUserActive: state.isLoadingUserActive,
                userEndActive: state.userEndActive,
                isLoadingCreateUsersAndUserSessionRel: state.isLoadingCreateUsersAndUserSessionRel,
                isLoadingUsersRelSessionAndCards: state.isLoadingUsersRelSessionAndCards,
                allUsersRelSessionAndCards: state.allUsersRelSessionAndCards,
                isLoadingUserRelAccountAndSession: state.isLoadingUserRelAccountAndSession,
                isLoadingUsersWinnersToExport: state.isLoadingUsersWinnersToExport,
                usersWinnersToExport: state.usersWinnersToExport,
                message: '',
                error: '',
            };
        case CREATE_USER_SUCCESS:
            return {
                isLoadingAllUsers: state.isLoadingAllUsers,
                allUsers: state.allUsers,
                isLoadingCreateUser: false,
                isLoadingCreateUserLocal: state.isLoadingCreateUserLocal,
                isLoadingDeleteUser: state.isLoadingDeleteUser,
                isLoadingSearchAllUsers: state.isLoadingSearchAllUsers,
                allUsersSearch: state.allUsersSearch,
                allUsersSessionRelSearch: state.allUsersSessionRelSearch,
                isLoadingAllUsersSessionRelSearch: state.isLoadingAllUsersSessionRelSearch,
                isLoadingUserActive: state.isLoadingUserActive,
                userEndActive: state.userEndActive,
                isLoadingCreateUsersAndUserSessionRel: state.isLoadingCreateUsersAndUserSessionRel,
                isLoadingUsersRelSessionAndCards: state.isLoadingUsersRelSessionAndCards,
                allUsersRelSessionAndCards: state.allUsersRelSessionAndCards,
                isLoadingUserRelAccountAndSession: state.isLoadingUserRelAccountAndSession,
                isLoadingUsersWinnersToExport: state.isLoadingUsersWinnersToExport,
                usersWinnersToExport: state.usersWinnersToExport,
                message: action.payload,
                error: '',
            };
        case CREATE_USER_FAILED:
            return {
                isLoadingAllUsers: state.isLoadingAllUsers,
                allUsers: state.allUsers,
                isLoadingCreateUser: false,
                isLoadingCreateUserLocal: state.isLoadingCreateUserLocal,
                isLoadingDeleteUser: state.isLoadingDeleteUser,
                isLoadingSearchAllUsers: state.isLoadingSearchAllUsers,
                allUsersSearch: state.allUsersSearch,
                allUsersSessionRelSearch: state.allUsersSessionRelSearch,
                isLoadingAllUsersSessionRelSearch: state.isLoadingAllUsersSessionRelSearch,
                isLoadingUserActive: state.isLoadingUserActive,
                userEndActive: state.userEndActive,
                isLoadingCreateUsersAndUserSessionRel: state.isLoadingCreateUsersAndUserSessionRel,
                isLoadingUsersRelSessionAndCards: state.isLoadingUsersRelSessionAndCards,
                allUsersRelSessionAndCards: state.allUsersRelSessionAndCards,
                isLoadingUserRelAccountAndSession: state.isLoadingUserRelAccountAndSession,
                isLoadingUsersWinnersToExport: state.isLoadingUsersWinnersToExport,
                usersWinnersToExport: state.usersWinnersToExport,
                message: '',
                error: action.payload,
            };
        case DELETE_USER:
            return {
                isLoadingAllUsers: state.isLoadingAllUsers,
                allUsers: state.allUsers,
                isLoadingCreateUser: state.isLoadingCreateUser,
                isLoadingCreateUserLocal: state.isLoadingCreateUserLocal,
                isLoadingDeleteUser: action.payload,
                isLoadingSearchAllUsers: state.isLoadingSearchAllUsers,
                allUsersSearch: state.allUsersSearch,
                allUsersSessionRelSearch: state.allUsersSessionRelSearch,
                isLoadingAllUsersSessionRelSearch: state.isLoadingAllUsersSessionRelSearch,
                isLoadingUserActive: state.isLoadingUserActive,
                userEndActive: state.userEndActive,
                isLoadingCreateUsersAndUserSessionRel: state.isLoadingCreateUsersAndUserSessionRel,
                isLoadingUsersRelSessionAndCards: state.isLoadingUsersRelSessionAndCards,
                allUsersRelSessionAndCards: state.allUsersRelSessionAndCards,
                isLoadingUserRelAccountAndSession: state.isLoadingUserRelAccountAndSession,
                isLoadingUsersWinnersToExport: state.isLoadingUsersWinnersToExport,
                usersWinnersToExport: state.usersWinnersToExport,
                message: '',
                error: '',
            };
        case DELETE_USER_SUCCESS:
            return {
                isLoadingAllUsers: state.isLoadingAllUsers,
                allUsers: state.allUsers,
                isLoadingCreateUser: state.isLoadingCreateUser,
                isLoadingCreateUserLocal: state.isLoadingCreateUserLocal,
                isLoadingDeleteUser: false,
                isLoadingSearchAllUsers: state.isLoadingSearchAllUsers,
                allUsersSearch: state.allUsersSearch,
                allUsersSessionRelSearch: state.allUsersSessionRelSearch,
                isLoadingAllUsersSessionRelSearch: state.isLoadingAllUsersSessionRelSearch,
                isLoadingUserActive: state.isLoadingUserActive,
                userEndActive: state.userEndActive,
                isLoadingCreateUsersAndUserSessionRel: state.isLoadingCreateUsersAndUserSessionRel,
                isLoadingUsersRelSessionAndCards: state.isLoadingUsersRelSessionAndCards,
                allUsersRelSessionAndCards: state.allUsersRelSessionAndCards,
                isLoadingUserRelAccountAndSession: state.isLoadingUserRelAccountAndSession,
                isLoadingUsersWinnersToExport: state.isLoadingUsersWinnersToExport,
                usersWinnersToExport: state.usersWinnersToExport,
                message: action.payload,
                error: '',
            };
        case DELETE_USER_FAILED:
            return {
                isLoadingAllUsers: state.isLoadingAllUsers,
                allUsers: state.allUsers,
                isLoadingCreateUser: state.isLoadingCreateUser,
                isLoadingCreateUserLocal: state.isLoadingCreateUserLocal,
                isLoadingDeleteUser: false,
                isLoadingSearchAllUsers: state.isLoadingSearchAllUsers,
                allUsersSearch: state.allUsersSearch,
                allUsersSessionRelSearch: state.allUsersSessionRelSearch,
                isLoadingAllUsersSessionRelSearch: state.isLoadingAllUsersSessionRelSearch,
                isLoadingUserActive: state.isLoadingUserActive,
                userEndActive: state.userEndActive,
                isLoadingCreateUsersAndUserSessionRel: state.isLoadingCreateUsersAndUserSessionRel,
                isLoadingUsersRelSessionAndCards: state.isLoadingUsersRelSessionAndCards,
                allUsersRelSessionAndCards: state.allUsersRelSessionAndCards,
                isLoadingUserRelAccountAndSession: state.isLoadingUserRelAccountAndSession,
                isLoadingUsersWinnersToExport: state.isLoadingUsersWinnersToExport,
                usersWinnersToExport: state.usersWinnersToExport,
                message: '',
                error: action.payload,
            };
        case SEARCH_ALL_USERS:
            return {
                isLoadingAllUsers: state.isLoadingAllUsers,
                allUsers: state.allUsers,
                isLoadingCreateUser: state.isLoadingCreateUser,
                isLoadingCreateUserLocal: state.isLoadingCreateUserLocal,
                isLoadingDeleteUser: state.isLoadingDeleteUser,
                isLoadingSearchAllUsers: action.payload,
                allUsersSearch: state.allUsersSearch,
                allUsersSessionRelSearch: state.allUsersSessionRelSearch,
                isLoadingAllUsersSessionRelSearch: state.isLoadingAllUsersSessionRelSearch,
                isLoadingUserActive: state.isLoadingUserActive,
                userEndActive: state.userEndActive,
                isLoadingCreateUsersAndUserSessionRel: state.isLoadingCreateUsersAndUserSessionRel,
                isLoadingUsersRelSessionAndCards: state.isLoadingUsersRelSessionAndCards,
                allUsersRelSessionAndCards: state.allUsersRelSessionAndCards,
                isLoadingUserRelAccountAndSession: state.isLoadingUserRelAccountAndSession,
                isLoadingUsersWinnersToExport: state.isLoadingUsersWinnersToExport,
                usersWinnersToExport: state.usersWinnersToExport,
                message: '',
                error: '',
            };
        case SEARCH_ALL_USERS_SUCCESS:
            return {
                isLoadingAllUsers: state.isLoadingAllUsers,
                allUsers: state.allUsers,
                isLoadingCreateUser: state.isLoadingCreateUser,
                isLoadingCreateUserLocal: state.isLoadingCreateUserLocal,
                isLoadingDeleteUser: state.isLoadingDeleteUser,
                isLoadingSearchAllUsers: false,
                allUsersSearch: action.payload,
                allUsersSessionRelSearch: state.allUsersSessionRelSearch,
                isLoadingAllUsersSessionRelSearch: state.isLoadingAllUsersSessionRelSearch,
                isLoadingUserActive: state.isLoadingUserActive,
                userEndActive: state.userEndActive,
                isLoadingCreateUsersAndUserSessionRel: state.isLoadingCreateUsersAndUserSessionRel,
                isLoadingUsersRelSessionAndCards: state.isLoadingUsersRelSessionAndCards,
                allUsersRelSessionAndCards: state.allUsersRelSessionAndCards,
                isLoadingUserRelAccountAndSession: state.isLoadingUserRelAccountAndSession,
                isLoadingUsersWinnersToExport: state.isLoadingUsersWinnersToExport,
                usersWinnersToExport: state.usersWinnersToExport,
                message: '',
                error: '',
            };
        case SEARCH_ALL_USERS_FAILED:
            return {
                isLoadingAllUsers: state.isLoadingAllUsers,
                allUsers: state.allUsers,
                isLoadingCreateUser: state.isLoadingCreateUser,
                isLoadingCreateUserLocal: state.isLoadingCreateUserLocal,
                isLoadingDeleteUser: state.isLoadingDeleteUser,
                isLoadingSearchAllUsers: false,
                allUsersSearch: state.allUsersSearch,
                allUsersSessionRelSearch: state.allUsersSessionRelSearch,
                isLoadingAllUsersSessionRelSearch: state.isLoadingAllUsersSessionRelSearch,
                isLoadingUserActive: state.isLoadingUserActive,
                userEndActive: state.userEndActive,
                isLoadingCreateUsersAndUserSessionRel: state.isLoadingCreateUsersAndUserSessionRel,
                isLoadingUsersRelSessionAndCards: state.isLoadingUsersRelSessionAndCards,
                allUsersRelSessionAndCards: state.allUsersRelSessionAndCards,
                isLoadingUserRelAccountAndSession: state.isLoadingUserRelAccountAndSession,
                isLoadingUsersWinnersToExport: state.isLoadingUsersWinnersToExport,
                usersWinnersToExport: state.usersWinnersToExport,
                message: '',
                error: action.payload,
            };
        case CLEAN_SEARCH_ALL_USERS:
            return {
                isLoadingAllUsers: state.isLoadingAllUsers,
                allUsers: state.allUsers,
                isLoadingCreateUser: state.isLoadingCreateUser,
                isLoadingCreateUserLocal: state.isLoadingCreateUserLocal,
                isLoadingDeleteUser: state.isLoadingDeleteUser,
                isLoadingSearchAllUsers: state.isLoadingSearchAllUsers,
                allUsersSearch: [],
                allUsersSessionRelSearch: state.allUsersSessionRelSearch,
                isLoadingAllUsersSessionRelSearch: state.isLoadingAllUsersSessionRelSearch,
                isLoadingUserActive: state.isLoadingUserActive,
                userEndActive: state.userEndActive,
                isLoadingCreateUsersAndUserSessionRel: state.isLoadingCreateUsersAndUserSessionRel,
                isLoadingUsersRelSessionAndCards: state.isLoadingUsersRelSessionAndCards,
                allUsersRelSessionAndCards: state.allUsersRelSessionAndCards,
                isLoadingUserRelAccountAndSession: state.isLoadingUserRelAccountAndSession,
                isLoadingUsersWinnersToExport: state.isLoadingUsersWinnersToExport,
                usersWinnersToExport: state.usersWinnersToExport,
                message: '',
                error: '',
            };
        case GET_USERS_SESSION_REL:
            return {
                isLoadingAllUsers: state.isLoadingAllUsers,
                allUsers: state.allUsers,
                isLoadingCreateUser: state.isLoadingCreateUser,
                isLoadingCreateUserLocal: state.isLoadingCreateUserLocal,
                isLoadingDeleteUser: state.isLoadingDeleteUser,
                isLoadingSearchAllUsers: state.isLoadingSearchAllUsers,
                allUsersSearch: state.allUsersSearch,
                allUsersSessionRelSearch: state.allUsersSessionRelSearch,
                isLoadingAllUsersSessionRelSearch: action.payload,
                isLoadingUserActive: state.isLoadingUserActive,
                userEndActive: state.userEndActive,
                isLoadingCreateUsersAndUserSessionRel: state.isLoadingCreateUsersAndUserSessionRel,
                isLoadingUsersRelSessionAndCards: state.isLoadingUsersRelSessionAndCards,
                allUsersRelSessionAndCards: state.allUsersRelSessionAndCards,
                isLoadingUserRelAccountAndSession: state.isLoadingUserRelAccountAndSession,
                isLoadingUsersWinnersToExport: state.isLoadingUsersWinnersToExport,
                usersWinnersToExport: state.usersWinnersToExport,
                message: '',
                error: '',
            }
        case GET_USERS_SESSION_REL_SUCCESS:
            return {
                isLoadingAllUsers: state.isLoadingAllUsers,
                allUsers: state.allUsers,
                isLoadingCreateUser: state.isLoadingCreateUser,
                isLoadingCreateUserLocal: state.isLoadingCreateUserLocal,
                isLoadingDeleteUser: state.isLoadingDeleteUser,
                isLoadingSearchAllUsers: state.isLoadingSearchAllUsers,
                allUsersSearch: state.allUsersSearch,
                allUsersSessionRelSearch: action.payload,
                isLoadingAllUsersSessionRelSearch: false,
                isLoadingUserActive: state.isLoadingUserActive,
                userEndActive: state.userEndActive,
                isLoadingCreateUsersAndUserSessionRel: state.isLoadingCreateUsersAndUserSessionRel,
                isLoadingUsersRelSessionAndCards: state.isLoadingUsersRelSessionAndCards,
                allUsersRelSessionAndCards: state.allUsersRelSessionAndCards,
                isLoadingUserRelAccountAndSession: state.isLoadingUserRelAccountAndSession,
                isLoadingUsersWinnersToExport: state.isLoadingUsersWinnersToExport,
                usersWinnersToExport: state.usersWinnersToExport,
                message: '',
                error: '',
            }
        case GET_USERS_SESSION_REL_FAILED:
            return {
                isLoadingAllUsers: state.isLoadingAllUsers,
                allUsers: state.allUsers,
                isLoadingCreateUser: state.isLoadingCreateUser,
                isLoadingCreateUserLocal: state.isLoadingCreateUserLocal,
                isLoadingDeleteUser: state.isLoadingDeleteUser,
                isLoadingSearchAllUsers: state.isLoadingSearchAllUsers,
                allUsersSearch: state.allUsersSearch,
                allUsersSessionRelSearch: state.allUsersSessionRelSearch,
                isLoadingAllUsersSessionRelSearch: false,
                isLoadingUserActive: state.isLoadingUserActive,
                userEndActive: state.userEndActive,
                isLoadingCreateUsersAndUserSessionRel: state.isLoadingCreateUsersAndUserSessionRel,
                isLoadingUsersRelSessionAndCards: state.isLoadingUsersRelSessionAndCards,
                allUsersRelSessionAndCards: state.allUsersRelSessionAndCards,
                isLoadingUserRelAccountAndSession: state.isLoadingUserRelAccountAndSession,
                isLoadingUsersWinnersToExport: state.isLoadingUsersWinnersToExport,
                usersWinnersToExport: state.usersWinnersToExport,
                message: '',
                error: action.payload,
            }
        case CLEAN_USERS_SESSION_REL:
            return {
                isLoadingAllUsers: state.isLoadingAllUsers,
                allUsers: state.allUsers,
                isLoadingCreateUser: state.isLoadingCreateUser,
                isLoadingCreateUserLocal: state.isLoadingCreateUserLocal,
                isLoadingDeleteUser: state.isLoadingDeleteUser,
                isLoadingSearchAllUsers: state.isLoadingSearchAllUsers,
                allUsersSearch: state.allUsersSearch,
                allUsersSessionRelSearch: action.payload,
                isLoadingAllUsersSessionRelSearch: state.isLoadingAllUsersSessionRelSearch,
                isLoadingUserActive: state.isLoadingUserActive,
                userEndActive: state.userEndActive,
                isLoadingCreateUsersAndUserSessionRel: state.isLoadingCreateUsersAndUserSessionRel,
                isLoadingUsersRelSessionAndCards: state.isLoadingUsersRelSessionAndCards,
                allUsersRelSessionAndCards: state.allUsersRelSessionAndCards,
                isLoadingUserRelAccountAndSession: state.isLoadingUserRelAccountAndSession,
                isLoadingUsersWinnersToExport: state.isLoadingUsersWinnersToExport,
                usersWinnersToExport: state.usersWinnersToExport,
                message: '',
                error: '',
            };
        case CREATE_USER_LOCAL:
            return {
                isLoadingAllUsers: state.isLoadingAllUsers,
                allUsers: state.allUsers,
                isLoadingCreateUser: state.isLoadingCreateUser,
                isLoadingCreateUserLocal: action.payload,
                isLoadingDeleteUser: state.isLoadingDeleteUser,
                isLoadingSearchAllUsers: state.isLoadingSearchAllUsers,
                allUsersSearch: state.allUsersSearch,
                allUsersSessionRelSearch: state.allUsersSessionRelSearch,
                isLoadingAllUsersSessionRelSearch: state.isLoadingAllUsersSessionRelSearch,
                isLoadingUserActive: state.isLoadingUserActive,
                userEndActive: state.userEndActive,
                isLoadingCreateUsersAndUserSessionRel: state.isLoadingCreateUsersAndUserSessionRel,
                isLoadingUsersRelSessionAndCards: state.isLoadingUsersRelSessionAndCards,
                allUsersRelSessionAndCards: state.allUsersRelSessionAndCards,
                isLoadingUserRelAccountAndSession: state.isLoadingUserRelAccountAndSession,
                isLoadingUsersWinnersToExport: state.isLoadingUsersWinnersToExport,
                usersWinnersToExport: state.usersWinnersToExport,
                message: '',
                error: '',
            };
        case CREATE_USER_LOCAL_SUCCESS:
            return {
                isLoadingAllUsers: state.isLoadingAllUsers,
                allUsers: state.allUsers,
                isLoadingCreateUser: state.isLoadingCreateUser,
                isLoadingCreateUserLocal: false,
                isLoadingDeleteUser: state.isLoadingDeleteUser,
                isLoadingSearchAllUsers: state.isLoadingSearchAllUsers,
                allUsersSearch: state.allUsersSearch,
                allUsersSessionRelSearch: state.allUsersSessionRelSearch,
                isLoadingAllUsersSessionRelSearch: state.isLoadingAllUsersSessionRelSearch,
                isLoadingUserActive: state.isLoadingUserActive,
                userEndActive: state.userEndActive,
                isLoadingCreateUsersAndUserSessionRel: state.isLoadingCreateUsersAndUserSessionRel,
                isLoadingUsersRelSessionAndCards: state.isLoadingUsersRelSessionAndCards,
                allUsersRelSessionAndCards: state.allUsersRelSessionAndCards,
                isLoadingUserRelAccountAndSession: state.isLoadingUserRelAccountAndSession,
                isLoadingUsersWinnersToExport: state.isLoadingUsersWinnersToExport,
                usersWinnersToExport: state.usersWinnersToExport,
                message: action.payload,
                error: '',
            };
        case CREATE_USER_LOCAL_FAILED:
            return {
                isLoadingAllUsers: state.isLoadingAllUsers,
                allUsers: state.allUsers,
                isLoadingCreateUser: state.isLoadingCreateUser,
                isLoadingCreateUserLocal: false,
                isLoadingDeleteUser: state.isLoadingDeleteUser,
                isLoadingSearchAllUsers: state.isLoadingSearchAllUsers,
                allUsersSearch: state.allUsersSearch,
                allUsersSessionRelSearch: state.allUsersSessionRelSearch,
                isLoadingAllUsersSessionRelSearch: state.isLoadingAllUsersSessionRelSearch,
                isLoadingUserActive: state.isLoadingUserActive,
                userEndActive: state.userEndActive,
                isLoadingCreateUsersAndUserSessionRel: state.isLoadingCreateUsersAndUserSessionRel,
                isLoadingUsersRelSessionAndCards: state.isLoadingUsersRelSessionAndCards,
                allUsersRelSessionAndCards: state.allUsersRelSessionAndCards,
                isLoadingUserRelAccountAndSession: state.isLoadingUserRelAccountAndSession,
                isLoadingUsersWinnersToExport: state.isLoadingUsersWinnersToExport,
                usersWinnersToExport: state.usersWinnersToExport,
                message: '',
                error: action.payload,
            };
        case GET_END_USER_ACTIVE:
            return {
                isLoadingAllUsers: state.isLoadingAllUsers,
                allUsers: state.allUsers,
                isLoadingCreateUser: state.isLoadingCreateUser,
                isLoadingCreateUserLocal: state.isLoadingCreateUserLocal,
                isLoadingDeleteUser: state.isLoadingDeleteUser,
                isLoadingSearchAllUsers: state.isLoadingSearchAllUsers,
                allUsersSearch: state.allUsersSearch,
                allUsersSessionRelSearch: state.allUsersSessionRelSearch,
                isLoadingAllUsersSessionRelSearch: state.isLoadingAllUsersSessionRelSearch,
                isLoadingUserActive: action.payload,
                userEndActive: state.userEndActive,
                isLoadingCreateUsersAndUserSessionRel: state.isLoadingCreateUsersAndUserSessionRel,
                isLoadingUsersRelSessionAndCards: state.isLoadingUsersRelSessionAndCards,
                allUsersRelSessionAndCards: state.allUsersRelSessionAndCards,
                isLoadingUserRelAccountAndSession: state.isLoadingUserRelAccountAndSession,
                isLoadingUsersWinnersToExport: state.isLoadingUsersWinnersToExport,
                usersWinnersToExport: state.usersWinnersToExport,
                message: '',
                error: '',
            };
        case GET_END_USER_ACTIVE_SUCCESS:
            return {
                isLoadingAllUsers: state.isLoadingAllUsers,
                allUsers: state.allUsers,
                isLoadingCreateUser: state.isLoadingCreateUser,
                isLoadingCreateUserLocal: state.isLoadingCreateUserLocal,
                isLoadingDeleteUser: state.isLoadingDeleteUser,
                isLoadingSearchAllUsers: state.isLoadingSearchAllUsers,
                allUsersSearch: state.allUsersSearch,
                allUsersSessionRelSearch: state.allUsersSessionRelSearch,
                isLoadingAllUsersSessionRelSearch: state.isLoadingAllUsersSessionRelSearch,
                isLoadingUserActive: false,
                userEndActive: action.payload,
                isLoadingCreateUsersAndUserSessionRel: state.isLoadingCreateUsersAndUserSessionRel,
                isLoadingUsersRelSessionAndCards: state.isLoadingUsersRelSessionAndCards,
                allUsersRelSessionAndCards: state.allUsersRelSessionAndCards,
                isLoadingUserRelAccountAndSession: state.isLoadingUserRelAccountAndSession,
                isLoadingUsersWinnersToExport: state.isLoadingUsersWinnersToExport,
                usersWinnersToExport: state.usersWinnersToExport,
                message: '',
                error: '',
            };
        case GET_END_USER_ACTIVE_FAILED:
            return {
                isLoadingAllUsers: state.isLoadingAllUsers,
                allUsers: state.allUsers,
                isLoadingCreateUser: state.isLoadingCreateUser,
                isLoadingCreateUserLocal: state.isLoadingCreateUserLocal,
                isLoadingDeleteUser: state.isLoadingDeleteUser,
                isLoadingSearchAllUsers: state.isLoadingSearchAllUsers,
                allUsersSearch: state.allUsersSearch,
                allUsersSessionRelSearch: state.allUsersSessionRelSearch,
                isLoadingAllUsersSessionRelSearch: state.isLoadingAllUsersSessionRelSearch,
                isLoadingUserActive: false,
                userEndActive: state.userEndActive,
                isLoadingCreateUsersAndUserSessionRel: state.isLoadingCreateUsersAndUserSessionRel,
                isLoadingUsersRelSessionAndCards: state.isLoadingUsersRelSessionAndCards,
                allUsersRelSessionAndCards: state.allUsersRelSessionAndCards,
                isLoadingUserRelAccountAndSession: state.isLoadingUserRelAccountAndSession,
                isLoadingUsersWinnersToExport: state.isLoadingUsersWinnersToExport,
                usersWinnersToExport: state.usersWinnersToExport,
                message: '',
                error: action.payload,
            };
        case CREATE_USERS_AND_USER_SESSION_REL:
            return {
                isLoadingAllUsers: state.isLoadingAllUsers,
                allUsers: state.allUsers,
                isLoadingCreateUser: state.isLoadingCreateUser,
                isLoadingCreateUserLocal: state.isLoadingCreateUserLocal,
                isLoadingDeleteUser: state.isLoadingDeleteUser,
                isLoadingSearchAllUsers: state.isLoadingSearchAllUsers,
                allUsersSearch: state.allUsersSearch,
                allUsersSessionRelSearch: state.allUsersSessionRelSearch,
                isLoadingAllUsersSessionRelSearch: state.isLoadingAllUsersSessionRelSearch,
                isLoadingUserActive: state.isLoadingUserActive,
                userEndActive: state.userEndActive,
                isLoadingCreateUsersAndUserSessionRel: action.payload,
                isLoadingUsersRelSessionAndCards: state.isLoadingUsersRelSessionAndCards,
                allUsersRelSessionAndCards: state.allUsersRelSessionAndCards,
                isLoadingUserRelAccountAndSession: state.isLoadingUserRelAccountAndSession,
                isLoadingUsersWinnersToExport: state.isLoadingUsersWinnersToExport,
                usersWinnersToExport: state.usersWinnersToExport,
                message: '',
                error: '',
            };
        case CREATE_USERS_AND_USER_SESSION_REL_SUCCESS:
            return {
                isLoadingAllUsers: state.isLoadingAllUsers,
                allUsers: state.allUsers,
                isLoadingCreateUser: state.isLoadingCreateUser,
                isLoadingCreateUserLocal: state.isLoadingCreateUserLocal,
                isLoadingDeleteUser: state.isLoadingDeleteUser,
                isLoadingSearchAllUsers: state.isLoadingSearchAllUsers,
                allUsersSearch: state.allUsersSearch,
                allUsersSessionRelSearch: state.allUsersSessionRelSearch,
                isLoadingAllUsersSessionRelSearch: state.isLoadingAllUsersSessionRelSearch,
                isLoadingUserActive: state.isLoadingUserActive,
                userEndActive: state.userEndActive,
                isLoadingCreateUsersAndUserSessionRel: false,
                isLoadingUsersRelSessionAndCards: state.isLoadingUsersRelSessionAndCards,
                allUsersRelSessionAndCards: state.allUsersRelSessionAndCards,
                isLoadingUserRelAccountAndSession: state.isLoadingUserRelAccountAndSession,
                isLoadingUsersWinnersToExport: state.isLoadingUsersWinnersToExport,
                usersWinnersToExport: state.usersWinnersToExport,
                message: '',
                error: '',
            };
        case CREATE_USERS_AND_USER_SESSION_REL_FAILED:
            return {
                isLoadingAllUsers: state.isLoadingAllUsers,
                allUsers: state.allUsers,
                isLoadingCreateUser: state.isLoadingCreateUser,
                isLoadingCreateUserLocal: state.isLoadingCreateUserLocal,
                isLoadingDeleteUser: state.isLoadingDeleteUser,
                isLoadingSearchAllUsers: state.isLoadingSearchAllUsers,
                allUsersSearch: state.allUsersSearch,
                allUsersSessionRelSearch: state.allUsersSessionRelSearch,
                isLoadingAllUsersSessionRelSearch: state.isLoadingAllUsersSessionRelSearch,
                isLoadingUserActive: state.isLoadingUserActive,
                userEndActive: state.userEndActive,
                isLoadingCreateUsersAndUserSessionRel: false,
                isLoadingUsersRelSessionAndCards: state.isLoadingUsersRelSessionAndCards,
                allUsersRelSessionAndCards: state.allUsersRelSessionAndCards,
                isLoadingUserRelAccountAndSession: state.isLoadingUserRelAccountAndSession,
                isLoadingUsersWinnersToExport: state.isLoadingUsersWinnersToExport,
                usersWinnersToExport: state.usersWinnersToExport,
                message: '',
                error: '',
            };
        case GET_USERS_REL_SESSION_AND_CARDS:
            return {
                isLoadingAllUsers: state.isLoadingAllUsers,
                allUsers: state.allUsers,
                isLoadingCreateUser: state.isLoadingCreateUser,
                isLoadingCreateUserLocal: state.isLoadingCreateUserLocal,
                isLoadingDeleteUser: state.isLoadingDeleteUser,
                isLoadingSearchAllUsers: state.isLoadingSearchAllUsers,
                allUsersSearch: state.allUsersSearch,
                allUsersSessionRelSearch: state.allUsersSessionRelSearch,
                isLoadingAllUsersSessionRelSearch: state.isLoadingAllUsersSessionRelSearch,
                isLoadingUserActive: state.isLoadingUserActive,
                userEndActive: state.userEndActive,
                isLoadingCreateUsersAndUserSessionRel: state.isLoadingCreateUsersAndUserSessionRel,
                isLoadingUsersRelSessionAndCards: action.payload,
                allUsersRelSessionAndCards: state.allUsersRelSessionAndCards,
                isLoadingUserRelAccountAndSession: state.isLoadingUserRelAccountAndSession,
                isLoadingUsersWinnersToExport: state.isLoadingUsersWinnersToExport,
                usersWinnersToExport: state.usersWinnersToExport,
                message: '',
                error: '',
            };
        case GET_USERS_REL_SESSION_AND_CARDS_SUCCESS:
            return {
                isLoadingAllUsers: state.isLoadingAllUsers,
                allUsers: state.allUsers,
                isLoadingCreateUser: state.isLoadingCreateUser,
                isLoadingCreateUserLocal: state.isLoadingCreateUserLocal,
                isLoadingDeleteUser: state.isLoadingDeleteUser,
                isLoadingSearchAllUsers: state.isLoadingSearchAllUsers,
                allUsersSearch: state.allUsersSearch,
                allUsersSessionRelSearch: state.allUsersSessionRelSearch,
                isLoadingAllUsersSessionRelSearch: state.isLoadingAllUsersSessionRelSearch,
                isLoadingUserActive: state.isLoadingUserActive,
                userEndActive: state.userEndActive,
                isLoadingCreateUsersAndUserSessionRel: state.isLoadingCreateUsersAndUserSessionRel,
                isLoadingUsersRelSessionAndCards: false,
                allUsersRelSessionAndCards: action.payload,
                isLoadingUserRelAccountAndSession: state.isLoadingUserRelAccountAndSession,
                isLoadingUsersWinnersToExport: state.isLoadingUsersWinnersToExport,
                usersWinnersToExport: state.usersWinnersToExport,
                message: '',
                error: '',
            };
        case GET_USERS_REL_SESSION_AND_CARDS_FAILED:
            return {
                isLoadingAllUsers: state.isLoadingAllUsers,
                allUsers: state.allUsers,
                isLoadingCreateUser: state.isLoadingCreateUser,
                isLoadingCreateUserLocal: state.isLoadingCreateUserLocal,
                isLoadingDeleteUser: state.isLoadingDeleteUser,
                isLoadingSearchAllUsers: state.isLoadingSearchAllUsers,
                allUsersSearch: state.allUsersSearch,
                allUsersSessionRelSearch: state.allUsersSessionRelSearch,
                isLoadingAllUsersSessionRelSearch: state.isLoadingAllUsersSessionRelSearch,
                isLoadingUserActive: state.isLoadingUserActive,
                userEndActive: state.userEndActive,
                isLoadingCreateUsersAndUserSessionRel: state.isLoadingCreateUsersAndUserSessionRel,
                isLoadingUsersRelSessionAndCards: false,
                allUsersRelSessionAndCards: state.allUsersRelSessionAndCards,
                isLoadingUserRelAccountAndSession: state.isLoadingUserRelAccountAndSession,
                isLoadingUsersWinnersToExport: state.isLoadingUsersWinnersToExport,
                usersWinnersToExport: state.usersWinnersToExport,
                message: '',
                error: action.payload,
            };
        case CREATE_USER_REL_ACCOUNT_AND_SESSION:
            return {
                isLoadingAllUsers: state.isLoadingAllUsers,
                allUsers: state.allUsers,
                isLoadingCreateUser: state.isLoadingCreateUser,
                isLoadingCreateUserLocal: state.isLoadingCreateUserLocal,
                isLoadingDeleteUser: state.isLoadingDeleteUser,
                isLoadingSearchAllUsers: state.isLoadingSearchAllUsers,
                allUsersSearch: state.allUsersSearch,
                allUsersSessionRelSearch: state.allUsersSessionRelSearch,
                isLoadingAllUsersSessionRelSearch: state.isLoadingAllUsersSessionRelSearch,
                isLoadingUserActive: state.isLoadingUserActive,
                userEndActive: state.userEndActive,
                isLoadingCreateUsersAndUserSessionRel: state.isLoadingCreateUsersAndUserSessionRel,
                isLoadingUsersRelSessionAndCards: state.isLoadingUsersRelSessionAndCards,
                allUsersRelSessionAndCards: state.allUsersRelSessionAndCards,
                isLoadingUserRelAccountAndSession: action.payload,
                isLoadingUsersWinnersToExport: state.isLoadingUsersWinnersToExport,
                usersWinnersToExport: state.usersWinnersToExport,
                message: '',
                error: '',
            };
        case CREATE_USER_REL_ACCOUNT_AND_SESSION_SUCCESS:
            return {
                isLoadingAllUsers: state.isLoadingAllUsers,
                allUsers: state.allUsers,
                isLoadingCreateUser: state.isLoadingCreateUser,
                isLoadingCreateUserLocal: state.isLoadingCreateUserLocal,
                isLoadingDeleteUser: state.isLoadingDeleteUser,
                isLoadingSearchAllUsers: state.isLoadingSearchAllUsers,
                allUsersSearch: state.allUsersSearch,
                allUsersSessionRelSearch: state.allUsersSessionRelSearch,
                isLoadingAllUsersSessionRelSearch: state.isLoadingAllUsersSessionRelSearch,
                isLoadingUserActive: state.isLoadingUserActive,
                userEndActive: state.userEndActive,
                isLoadingCreateUsersAndUserSessionRel: state.isLoadingCreateUsersAndUserSessionRel,
                isLoadingUsersRelSessionAndCards: state.isLoadingUsersRelSessionAndCards,
                allUsersRelSessionAndCards: state.allUsersRelSessionAndCards,
                isLoadingUserRelAccountAndSession: false,
                isLoadingUsersWinnersToExport: state.isLoadingUsersWinnersToExport,
                usersWinnersToExport: state.usersWinnersToExport,
                message: '',
                error: '',
            };
        case CREATE_USER_REL_ACCOUNT_AND_SESSION_FAILED:
            return {
                isLoadingAllUsers: state.isLoadingAllUsers,
                allUsers: state.allUsers,
                isLoadingCreateUser: state.isLoadingCreateUser,
                isLoadingCreateUserLocal: state.isLoadingCreateUserLocal,
                isLoadingDeleteUser: state.isLoadingDeleteUser,
                isLoadingSearchAllUsers: state.isLoadingSearchAllUsers,
                allUsersSearch: state.allUsersSearch,
                allUsersSessionRelSearch: state.allUsersSessionRelSearch,
                isLoadingAllUsersSessionRelSearch: state.isLoadingAllUsersSessionRelSearch,
                isLoadingUserActive: state.isLoadingUserActive,
                userEndActive: state.userEndActive,
                isLoadingCreateUsersAndUserSessionRel: state.isLoadingCreateUsersAndUserSessionRel,
                isLoadingUsersRelSessionAndCards: state.isLoadingUsersRelSessionAndCards,
                allUsersRelSessionAndCards: state.allUsersRelSessionAndCards,
                isLoadingUserRelAccountAndSession: false,
                isLoadingUsersWinnersToExport: state.isLoadingUsersWinnersToExport,
                usersWinnersToExport: state.usersWinnersToExport,
                message: '',
                error: '',
            };
        case GET_USERS_WINNERS_EXPORT:
            return {
                isLoadingAllUsers: state.isLoadingAllUsers,
                allUsers: state.allUsers,
                isLoadingCreateUser: state.isLoadingCreateUser,
                isLoadingCreateUserLocal: state.isLoadingCreateUserLocal,
                isLoadingDeleteUser: state.isLoadingDeleteUser,
                isLoadingSearchAllUsers: state.isLoadingSearchAllUsers,
                allUsersSearch: state.allUsersSearch,
                allUsersSessionRelSearch: state.allUsersSessionRelSearch,
                isLoadingAllUsersSessionRelSearch: state.isLoadingAllUsersSessionRelSearch,
                isLoadingUserActive: state.isLoadingUserActive,
                userEndActive: state.userEndActive,
                isLoadingCreateUsersAndUserSessionRel: state.isLoadingCreateUsersAndUserSessionRel,
                isLoadingUsersRelSessionAndCards: state.isLoadingUsersRelSessionAndCards,
                allUsersRelSessionAndCards: state.allUsersRelSessionAndCards,
                isLoadingUserRelAccountAndSession: state.isLoadingUserRelAccountAndSession,
                isLoadingUsersWinnersToExport: action.payload,
                usersWinnersToExport: state.usersWinnersToExport,
                message: '',
                error: '',
            };
        case GET_USERS_WINNERS_EXPORT_SUCCESS:
            return {
                isLoadingAllUsers: state.isLoadingAllUsers,
                allUsers: state.allUsers,
                isLoadingCreateUser: state.isLoadingCreateUser,
                isLoadingCreateUserLocal: state.isLoadingCreateUserLocal,
                isLoadingDeleteUser: state.isLoadingDeleteUser,
                isLoadingSearchAllUsers: state.isLoadingSearchAllUsers,
                allUsersSearch: state.allUsersSearch,
                allUsersSessionRelSearch: state.allUsersSessionRelSearch,
                isLoadingAllUsersSessionRelSearch: state.isLoadingAllUsersSessionRelSearch,
                isLoadingUserActive: state.isLoadingUserActive,
                userEndActive: state.userEndActive,
                isLoadingCreateUsersAndUserSessionRel: state.isLoadingCreateUsersAndUserSessionRel,
                isLoadingUsersRelSessionAndCards: state.isLoadingUsersRelSessionAndCards,
                allUsersRelSessionAndCards: state.allUsersRelSessionAndCards,
                isLoadingUserRelAccountAndSession: state.isLoadingUserRelAccountAndSession,
                isLoadingUsersWinnersToExport: false,
                usersWinnersToExport: action.payload,
                message: '',
                error: '',
            };
        case GET_USERS_WINNERS_EXPORT_FAILED:
            return {
                isLoadingAllUsers: state.isLoadingAllUsers,
                allUsers: state.allUsers,
                isLoadingCreateUser: state.isLoadingCreateUser,
                isLoadingCreateUserLocal: state.isLoadingCreateUserLocal,
                isLoadingDeleteUser: state.isLoadingDeleteUser,
                isLoadingSearchAllUsers: state.isLoadingSearchAllUsers,
                allUsersSearch: state.allUsersSearch,
                allUsersSessionRelSearch: state.allUsersSessionRelSearch,
                isLoadingAllUsersSessionRelSearch: state.isLoadingAllUsersSessionRelSearch,
                isLoadingUserActive: state.isLoadingUserActive,
                userEndActive: state.userEndActive,
                isLoadingCreateUsersAndUserSessionRel: state.isLoadingCreateUsersAndUserSessionRel,
                isLoadingUsersRelSessionAndCards: state.isLoadingUsersRelSessionAndCards,
                allUsersRelSessionAndCards: state.allUsersRelSessionAndCards,
                isLoadingUserRelAccountAndSession: state.isLoadingUserRelAccountAndSession,
                isLoadingUsersWinnersToExport: false,
                usersWinnersToExport: action.payload,
                message: '',
                error: '',
            };
        case CLEAN_USERS_WINNERS_EXPORT:
            return {
                isLoadingAllUsers: state.isLoadingAllUsers,
                allUsers: state.allUsers,
                isLoadingCreateUser: state.isLoadingCreateUser,
                isLoadingCreateUserLocal: state.isLoadingCreateUserLocal,
                isLoadingDeleteUser: state.isLoadingDeleteUser,
                isLoadingSearchAllUsers: state.isLoadingSearchAllUsers,
                allUsersSearch: state.allUsersSearch,
                allUsersSessionRelSearch: state.allUsersSessionRelSearch,
                isLoadingAllUsersSessionRelSearch: state.isLoadingAllUsersSessionRelSearch,
                isLoadingUserActive: state.isLoadingUserActive,
                userEndActive: state.userEndActive,
                isLoadingCreateUsersAndUserSessionRel: state.isLoadingCreateUsersAndUserSessionRel,
                isLoadingUsersRelSessionAndCards: state.isLoadingUsersRelSessionAndCards,
                allUsersRelSessionAndCards: state.allUsersRelSessionAndCards,
                isLoadingUserRelAccountAndSession: state.isLoadingUserRelAccountAndSession,
                isLoadingUsersWinnersToExport: state.isLoadingUsersWinnersToExport,
                usersWinnersToExport: action.payload,
                message: '',
                error: '',
            };
        default:
            return state;
    }
}
export default UsersReducer;
