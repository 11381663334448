import styled from "styled-components";

interface IConfigurationOptionsStylesProps {
    buttonColor:string;
    buttonColorShadow:string;
}
export const ConfigurationThemeFormStyles = styled.div<IConfigurationOptionsStylesProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 1vw;
  width: 100%;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0vw;
    width: 60%;

    .container-banner-img {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 7vw;
      margin-top: 2vw;

      .banner-img {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background: #FFFFFF 0 0 no-repeat padding-box;
        border: 3px solid #000000;
        border-radius: 14px;
        opacity: 1;
        width: 90%;
        height: 90%;
        
        .input-img-banner {
          padding: 3vw 1vw;
          cursor: pointer;
          width: 5rem;
          display: none;
        }
        .options-admin-add-image-banner {
          width: 5vw;
          cursor: pointer;
        }
        .options-admin-add-image-banner-show {
          width: 80%;
          height: 100%;
          cursor: pointer;
        }
      }
    }
    .container-text-info-input-banner {
      display: flex;
      align-items: center;
      justify-content: end;
      text-align: center;
      letter-spacing: 0;
      color: #606060;
      opacity: 1;
      margin-top: 0.4vw;
      width: 95%;
      font-size: .7vw;

      .info-add {
        display: flex;
        align-items: center;
        justify-content: end;
        width: 100%;
        letter-spacing: 0;
        color: #606060;
        opacity: 1;
        font-size: .7vw;
        font-weight: 500;
        margin-right: 2vw;
      }
      .error-img-banner {
        display: flex;
        align-items: center;
        justify-content: end;
        text-align: center;
        letter-spacing: 0;
        color: red;
        opacity: 1;
        margin-top: 0.4vw;
        width: 95%;
        font-size: .7vw;
      }
    }
    .container-input {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: .5vw;
      width: 90%;
      
      label {
        display: flex;
        align-items: center;
        justify-content: start;
        text-align: center;
        letter-spacing: 0;
        color: #000;
        opacity: 1;
        margin-top: 0.4vw;
        width: 95%;
        font-size: 1.2vw;
      }
      input {
        text-align: left;
        letter-spacing: 0;
        color: #000;
        opacity: 1;
        font-size: 1.2vw;
        width: 95%;
        outline: none;
        border: 3px solid #000000;
        border-radius: 1vw;
        padding: .5vw 1vw;
      }
      input::placeholder {
        color: #C4C4C4;
        font-size: .9vw;
      }
    }
  }
  
  
  .container-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1vw;
    width: 100%;
    
    .buttons {
      border: none;
      font-size: 1.5vw;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 10vw;
      height: 2.5vw;
      background: ${({buttonColor}) => buttonColor};
      border-radius: 33px;
      opacity: 1;
      cursor: pointer;
      transition: box-shadow 0.3s ease;
      font-family: Anton, sans-serif;
      font-weight: 400;
    }
    .buttons:hover {
      box-shadow: ${({buttonColorShadow}) => buttonColorShadow};
    }
  }
`

