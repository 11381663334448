import styled from "styled-components";

export const CardsWinnerUserAndGameStyles = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .container-title {
        display: flex;
        align-items: end;
        justify-content: space-between;
        width: 100%;
        margin-top: 1vw;
        margin-bottom: 1vw;

        .title {
            text-align: center;
            letter-spacing: 0;
            color: #2E304E;
            opacity: 1;
            font-size: 1.5vw;
            font-weight: 600;
            margin-left: 2.5vw;
            font-family: Poppins-SemiBold, sans-serif;
        }

        .text-see-users {
            font-size: .9vw;
            font-family: Poppins-SemiBold, sans-serif;
            cursor: pointer;
        }

        .close {
            text-align: center;
            letter-spacing: 0;
            color: #2E304E;
            opacity: 1;
            font-size: 2vw;
            font-weight: bold;
            margin-right: 2vw;
            cursor: pointer;
        }
    }

    .container-search {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-bottom: 0.5vw;
        font-family: Poppins-SemiBold, sans-serif;

        .search-users {
            width: 20vw;
            border: 2px solid transparent;
            border-radius: 8px;
            outline: none;
            background-color: #f3f3f4;
            color: #0d0c22;
            transition: .3s ease;
        }
    }


    .container-title-data {
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        border-bottom: 1px solid black;
        font-family: Poppins-SemiBold, sans-serif;

        .text-phone {
            margin-right: 1.2vw;
            font-weight: bold;
        }

        .text-name {
            font-weight: bold;
        }

    }

    .container-data {
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        font-family: Poppins-SemiBold, sans-serif;
    }

    .container-scroll {
        width: 90%;
        height: 47vh;
        display: flex;
        flex-direction: column;
        gap: 0.2vw;
        margin: 1vw auto 0;
        overflow-y: scroll;
       
        font-family: Poppins-SemiBold, sans-serif;

        /* Estilos para el scrollbar */

        &::-webkit-scrollbar {
            width: 0.5vw; /* Ancho del scrollbar */

        }

        &::-webkit-scrollbar-track {
            background-color: #CCCFDE;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #00000038;
            border-radius: 10px;
            height: 3px;
        }

        table {
            border-collapse: collapse;
            width: 100%;
        }
        th {
            border: 1px solid #ddd;
            padding: 8px;
            text-align: left;
        }
        td {
            border: 1px solid #ddd;
            padding: 8px;
            text-align: left;
        }
        .name {
            font-size: 1vw;
            width: 17vw;
            text-overflow: ellipsis;
        }

        .phone {
            font-size: 1vw;
        }
    }




`
