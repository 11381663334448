import styled from "styled-components";

export const CreateNewGameStyles = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .container-title {
        display: flex;
        align-items: start;
        justify-content: space-between;
        width: 100%;
        margin-top: 1vw;

        .title {
            text-align: center;
            letter-spacing: 0;
            color: #2E304E;
            opacity: 1;
            font-size: 2.1vw;
            font-weight: 600;
            margin-left: 2.5vw;
            font-family: Poppins-SemiBold, sans-serif;
        }

        .close {
            text-align: center;
            letter-spacing: 0;
            color: #2E304E;
            opacity: 1;
            font-size: 2vw;
            font-weight: bold;
            margin-right: 2vw;
            cursor: pointer;
        }
    }

    .form {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        margin-top: 2vw;

        .container-img-inputs {
            display: flex;
            width: 100%;
            height: 40%;

            .error-img {
                display: block;
                position: absolute;
                top: 6.2vw;
                text-align: center;
                letter-spacing: 0;
                color: red;
                opacity: 1;
                font-size: .7vw;
                font-weight: 500;
                width: 7vw;
            }

            .container-img {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 20%;
                height: 80%;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 3px solid #000000;
                border-radius: 14px;
                opacity: 1;
                margin: 0 1.2vw;

                .input-img-small {
                    padding: 3vw 1vw;
                    cursor: pointer;
                    width: 5rem;
                    display: none;
                }

                .circle-img {
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 45%;
                    height: 45%;
                    cursor: pointer;

                    .options-admin-add-image {
                        width: 4.2vw;
                    }
                }

                .info-add {
                    display: block;
                    position: absolute;
                    top: 6.2vw;
                    text-align: center;
                    letter-spacing: 0;
                    color: #606060;
                    opacity: 1;
                    font-size: .7vw;
                    font-weight: 500;
                    width: 7vw;
                }
            }

            .container-inputs {
                display: flex;
                gap: .8vw;
                flex-direction: column;
                width: 70%;
                height: 100%;
                margin-top: 0;

                .container-input {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    .input-session-name {
                        text-align: left;
                        letter-spacing: 0;
                        color: #C4C4C4;
                        opacity: 1;
                        font-size: .9vw;
                        width: 90%;
                        outline: none;
                        border: none;
                        border-bottom: 1px solid #C4C4C4;
                    }

                    .input-session-name::placeholder {
                        color: #C4C4C4;
                        font-size: .9vw;
                    }

                    .error {
                        color: red;
                        font-size: .7vw;
                    }

                    .mode-game {
                        text-align: left;
                        letter-spacing: 0;
                        color: #C4C4C4;
                        opacity: 1;
                        font-size: .9vw;
                        width: 92%;
                        outline: none;
                        border: none;
                        border-bottom: 1px solid #C4C4C4;
                    }

                    .bingo-value {
                        margin-left: 2.2vw;
                        text-align: left;
                        letter-spacing: 0;
                        color: #C4C4C4;
                        opacity: 1;
                        font-size: .9vw;
                        width: 42%;
                        outline: none;
                        border: none;
                        border-bottom: 1px solid #C4C4C4;
                    }

                    .bingo-value::placeholder {
                        color: #C4C4C4;
                        font-size: .9vw;
                    }

                    .game-prize {
                        text-align: left;
                        letter-spacing: 0;
                        color: #C4C4C4;
                        opacity: 1;
                        font-size: .9vw;
                        width: 91%;
                        outline: none;
                        border: none;
                        border-bottom: 1px solid #C4C4C4;
                    }

                    .game-prize::placeholder {
                        color: #C4C4C4;
                        font-size: .9vw;
                    }

                    .date1 {
                        text-align: left;
                        letter-spacing: 0;
                        color: #C4C4C4;
                        opacity: 1;
                        font-size: .9vw;
                        width: 42%;
                        outline: none;
                        border: none;
                        border-bottom: 1px solid #C4C4C4;
                    }

                    .date2 {
                        margin-left: 2.2vw;
                        text-align: left;
                        letter-spacing: 0;
                        color: #C4C4C4;
                        opacity: 1;
                        font-size: .9vw;
                        width: 42%;
                        outline: none;
                        border: none;
                        border-bottom: 1px solid #C4C4C4;
                    }
                }
            }
            .container-input-toggle {
                display: flex;
                align-items: center;
                justify-content: start;
                gap: .5vw;
                width: 100%;

                .switch {
                    position: relative;
                    display: inline-block;
                    width: 2.5vw;
                    height: 1.3vw;
                }
                .slider {
                    position: absolute;
                    cursor: pointer;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: #ccc;
                    -webkit-transition: .4s;
                    transition: .4s;
                }
                .slider:before {
                    position: absolute;
                    content: "";
                    height: 1vw;
                    width: 1vw;
                    left: .2vw;
                    bottom: .18vw;
                    background-color: white;
                    -webkit-transition: .4s;
                    transition: .4s;
                }
                input:checked + .slider {
                    background-color: #2196F3;
                }
                input:focus + .slider {
                    box-shadow: 0 0 1px #2196F3;
                }
                input:checked + .slider:before {
                    -webkit-transform: translateX(1.1vw);
                    -ms-transform: translateX(1.1vw);
                    transform: translateX(1.1vw);
                }
                /* Rounded sliders */
                .slider.round {
                    border-radius: 34px;
                }
                .slider.round:before {
                    border-radius: 50%;
                }
                .text-toggle {
                    text-align: center;
                    letter-spacing: 0;
                    color: #000000;
                    opacity: 1;
                    font-size: .7vw;
                }
            }
        }

        .container-banner-img {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 7vw;

            .banner-img {
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 3px solid #000000;
                border-radius: 14px;
                opacity: 1;
                width: 90%;
                height: 90%;
                margin-top: 5vw;

                .input-img-banner {
                    padding: 3vw 1vw;
                    cursor: pointer;
                    width: 5rem;
                    display: none;
                }

                .options-admin-add-image-banner {
                    width: 5vw;
                    cursor: pointer;
                }
            }
        }

        .container-text-info-input-banner {
            display: flex;
            align-items: center;
            justify-content: end;
            text-align: center;
            letter-spacing: 0;
            color: #606060;
            opacity: 1;
            margin-top: 2.4vw;
            width: 95%;
            font-size: .7vw;
        }

        .container-button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-top: 4.5vw;

            .button-form {
                background: transparent linear-gradient(90deg, #9545FF 0%, #C491FC 100%) 0% 0% no-repeat padding-box;
                border-radius: 37px;
                opacity: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 13vw;
                height: 2.5vw;
                text-align: center;
                letter-spacing: 0;
                color: #FFFFFF;
                font-size: 1.6vw;
                transition: box-shadow 0.3s ease;
                cursor: pointer;
                outline: none;
                border: none;
                font-family: Anton, sans-serif;
                font-weight: 400;
            }

            .button-form:hover {
                box-shadow: 0px 0px 10px rgba(149, 69, 255, 0.7), 0px 0px 20px rgba(196, 145, 252, 0.7);
            }
        }
    }
    .modal-images-storage {
        position: absolute;
        top: 0vw;
        left: 25vw;
        width: 30vw;
        height: 23.4vw;
        padding: .5vw;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 3px 4px 1px #0000004D;
        border-radius: 25px;
        opacity: 1;
        margin-bottom: 1vw;
        overflow: hidden;
        z-index: 999999;
    }
`
