import {ThemeDefaultModel} from "../../domain/models/ThemeDefaultModel";

export const themeDefault: ThemeDefaultModel = {
    logo: '/img/newImage/optionsAdmin/optionsAdminTitle.svg',
    background_login: 'transparent linear-gradient(8deg, #2C2F4F 0%, #393D60 100%) 0% 0% no-repeat padding-box',
    button_color: 'transparent linear-gradient(90deg, #9545FF 0%, #C491FC 100%) 0% 0% no-repeat padding-box',
    button_color_shadow: '0px 0px 10px rgba(149, 69, 255, 0.7), 0px 0px 20px rgba(196, 145, 252, 0.7)',
    icon_center_login: '/img/newImage/Recurso 2logo f.png',
    // background_game: '/img/font_porple.png',
    // background_info_game: "/img/newImage/optionsAdmin/img-fondo-info-game.svg",
    // background_numbers_game: '',
    // background_winners_game: "/img/newImage/img-content-sup-winner.svg",
    // icon_trow_ballot: '',
    icon_trowing_ballot: '',
    // icon_last_ballot: '',

    // logo: '/img/newImage/optionsAdmin/optionsAdminTitle.svg',
    // background_login: 'transparent linear-gradient(8deg, #2C2F4F 0%, #393D60 100%) 0% 0% no-repeat padding-box',
    // button_color: 'transparent linear-gradient(90deg, #9545FF 0%, #C491FC 100%) 0% 0% no-repeat padding-box',
    // button_color_shadow: '0px 0px 10px rgba(149, 69, 255, 0.7), 0px 0px 20px rgba(196, 145, 252, 0.7)',
    // icon_center_login: '/img/newImage/Recurso 2logo f.png',
    background_game: '/img/new-img-change/img-fondo-game-new.jpg',
    background_info_game: "/img/new-img-change/img-banner-info-session.png",
    background_numbers_game: '/img/new-img-change/img-fondo-numbers.png',
    background_winners_game: "/img/new-img-change/img-banner-winners.png",
    icon_trow_ballot: '/img/new-img-change/img-icono-lanzar-balota.svg',
    // icon_trowing_ballot: '',
    icon_last_ballot: '/img/new-img-change/img-last-ballot.svg',


}