import {ShowSessionGamesStyled} from "./ShowSessionGamesStyled";
import CardGame from "../CardGame/CardGame";
import {useAppDispatch, useAppSelector} from "../../../shared/Utils/Hooks";
import CreateNewGame from "../CreateNewGame/CreateNewGame";

export default function ShowSessionGames(): JSX.Element {

    const showModalCreateGame = useAppSelector((state) => state.modalState.showModalCreateGame);
    const singleSessionDatabase = useAppSelector((state) => state.sessionState.singleSessionDatabase);

    return (
        <ShowSessionGamesStyled>
            <CardGame image={''} status={""} gameName={''} gamePrize={''} id={0} cardNewGame={true}/>
            {singleSessionDatabase && singleSessionDatabase.games.map((game) => (
                <CardGame image={game.main_image} status={game.status} gameName={game.bingo_name} gamePrize={game.prize} id={game.id} key={game.id} cardNewGame={false}/>
            ))}
            {showModalCreateGame &&
                <div className="modal-user-info">
                    <CreateNewGame/>
                </div>
            }
        </ShowSessionGamesStyled>
    );
}