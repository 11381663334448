import styled from "styled-components";

export const CreateUserToSessionStyles = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .container-title {
        display: flex;
        align-items: start;
        justify-content: space-between;
        width: 100%;
        margin-top: 1vw;

        .title {
            text-align: center;
            letter-spacing: 0;
            color: #2E304E;
            opacity: 1;
            font-size: 2.1vw;
            font-weight: 600;
            margin-left: 2.5vw;
            font-family: Poppins-SemiBold, sans-serif;
        }

        .close {
            text-align: center;
            letter-spacing: 0;
            color: #2E304E;
            opacity: 1;
            font-size: 2vw;
            font-weight: bold;
            margin-right: 2vw;
            cursor: pointer;
        }
    }

    .form {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 2vw;
        margin-bottom: 2vw;

        .container-img-inputs {
            display: flex;
            width: 100%;
            height: 40%;

            .container-img {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 20%;
                height: 60%;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 3px solid #000000;
                border-radius: 14px;
                opacity: 1;
                margin: 0 1.2vw;

                .input-img-small {
                    padding: 3vw 1vw;
                    cursor: pointer;
                    width: 5rem;
                    display: none;
                }

                .circle-img {
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 45%;
                    height: 45%;
                    cursor: pointer;

                    .options-admin-add-image {
                        width: 4.2vw;
                    }
                }

                .info-add {
                    display: block;
                    position: absolute;
                    top: 6.2vw;
                    text-align: center;
                    letter-spacing: 0;
                    color: #606060;
                    opacity: 1;
                    font-size: .7vw;
                    font-weight: 500;
                    width: 7vw;
                }
            }

            .container-inputs {
                display: flex;
                align-items: center;
                gap: .8vw;
                flex-direction: column;
                width: 70%;
                height: 100%;
                margin-top: 0vw;
                margin-left: 4vw;

                .container-input {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    .error {
                        color: red;
                        font-size: .7vw;
                    }

                    .input-session-name {
                        text-align: left;
                        letter-spacing: 0;
                        color: #C4C4C4;
                        opacity: 1;
                        font-size: .9vw;
                        width: 90%;
                        outline: none;
                        border: none;
                        border-bottom: 1px solid #C4C4C4;
                    }

                    .input-session-name::placeholder {
                        color: #C4C4C4;
                        font-size: .9vw;
                    }

                    .mode-game {
                        text-align: left;
                        letter-spacing: 0;
                        color: #C4C4C4;
                        opacity: 1;
                        font-size: .9vw;
                        width: 92%;
                        outline: none;
                        border: none;
                        border-bottom: 1px solid #C4C4C4;
                    }

                    .bingo-value {
                        margin-left: 2.2vw;
                        text-align: left;
                        letter-spacing: 0;
                        color: #C4C4C4;
                        opacity: 1;
                        font-size: .9vw;
                        width: 42%;
                        outline: none;
                        border: none;
                        border-bottom: 1px solid #C4C4C4;
                    }

                    .bingo-value::placeholder {
                        color: #C4C4C4;
                        font-size: .9vw;
                    }

                    .game-prize {
                        text-align: left;
                        letter-spacing: 0;
                        color: #C4C4C4;
                        opacity: 1;
                        font-size: .9vw;
                        width: 91%;
                        outline: none;
                        border: none;
                        border-bottom: 1px solid #C4C4C4;
                    }

                    .game-prize::placeholder {
                        color: #C4C4C4;
                        font-size: .9vw;
                    }

                    .date1 {
                        text-align: left;
                        letter-spacing: 0;
                        color: #C4C4C4;
                        opacity: 1;
                        font-size: .9vw;
                        width: 42%;
                        outline: none;
                        border: none;
                        border-bottom: 1px solid #C4C4C4;
                    }

                    .date2 {
                        margin-left: 2.2vw;
                        text-align: left;
                        letter-spacing: 0;
                        color: #C4C4C4;
                        opacity: 1;
                        font-size: .9vw;
                        width: 42%;
                        outline: none;
                        border: none;
                        border-bottom: 1px solid #C4C4C4;
                    }
                }
            }
        }

        .container-banner-img {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 7vw;

            .banner-img {
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 3px solid #000000;
                border-radius: 14px;
                opacity: 1;
                width: 90%;
                height: 90%;
                margin-top: 5vw;

                .input-img-banner {
                    padding: 3vw 1vw;
                    cursor: pointer;
                    width: 5rem;
                    display: none;
                }

                .options-admin-add-image-banner {
                    width: 5vw;
                    cursor: pointer;
                }
            }
        }

        .container-text-info-input-banner {
            display: flex;
            align-items: center;
            justify-content: end;
            text-align: center;
            letter-spacing: 0;
            color: #606060;
            opacity: 1;
            margin-top: 2.4vw;
            width: 95%;
            font-size: .7vw;
        }

        .container-button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-top: 4vw;

            .button-form {
                background: transparent linear-gradient(90deg, #9545FF 0%, #C491FC 100%) 0% 0% no-repeat padding-box;
                border-radius: 37px;
                opacity: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 13vw;
                height: 2.5vw;
                text-align: center;
                letter-spacing: 0;
                color: #FFFFFF;
                font-size: 1.6vw;
                transition: box-shadow 0.3s ease;
                cursor: pointer;
                outline: none;
                border: none;
                font-family: Anton, sans-serif;
                font-weight: 400;
            }

            .button-form:hover {
                box-shadow: 0px 0px 10px rgba(149, 69, 255, 0.7), 0px 0px 20px rgba(196, 145, 252, 0.7);
            }
        }
    }
`
