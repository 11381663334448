export function getWordGame(ballotPlay: string, bingo_limit: number) {
    const resultLists: IResultLists | undefined = getLists(bingo_limit);
    if (resultLists) {
        return getWord(resultLists, ballotPlay);
    } else {
        return '';
    }
}

function getLists(bingo_limit: number) {
    if (bingo_limit === 25) {
        return {
            listColB: listBallot1(1, 5),
            listColI: listBallot1(6, 10),
            listColN: listBallot1(11, 15),
            listColG: listBallot1(16, 20),
            listColO: listBallot1(21, 25),
        }
    }
    if (bingo_limit === 50) {
        return {
            listColB: listBallot1(1, 10),
            listColI: listBallot1(11, 20),
            listColN: listBallot1(21, 30),
            listColG: listBallot1(31, 40),
            listColO: listBallot1(41, 50),
        }
    }
    if (bingo_limit === 75) {
        return {
            listColB: listBallot1(1, 15),
            listColI: listBallot1(16, 30),
            listColN: listBallot1(31, 45),
            listColG: listBallot1(46, 60),
            listColO: listBallot1(61, 75),
        }
    }
    if (bingo_limit === 95) {
        return {
            listColB: listBallot1(1, 19),
            listColI: listBallot1(20, 38),
            listColN: listBallot1(39, 57),
            listColG: listBallot1(58, 76),
            listColO: listBallot1(77, 95),
        }
    }
    if (bingo_limit === 99) {
        return {
            listColB: listBallot1(1, 19),
            listColI: listBallot1(20, 39),
            listColN: listBallot1(40, 59),
            listColG: listBallot1(60, 79),
            listColO: listBallot1(80, 99),
        }
    }
}

function getWord(resultLists: IResultLists, ballotPlay: string) {
    if (resultLists.listColB.includes(ballotPlay)) {
        return 'B';
    }
    if (resultLists.listColI.includes(ballotPlay)) {
        return 'I';
    }
    if (resultLists.listColN.includes(ballotPlay)) {
        return 'N';
    }
    if (resultLists.listColG.includes(ballotPlay)) {
        return 'G';
    }
    if (resultLists.listColO.includes(ballotPlay)) {
        return 'O';
    } else {
        return '';
    }
}

function listBallot1(number_init: number, range: number) {
    const listData: string[] = [];
    for (number_init; number_init <= range; number_init++) {
        const formattedNumber = number_init.toString().padStart(2, '0');
        listData.push(formattedNumber);
    }
    return listData;
}

interface IResultLists {
    listColB: string[];
    listColI: string[];
    listColN: string[];
    listColG: string[];
    listColO: string[];
}