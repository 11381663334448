import {ContentStyles} from "./ContentStyles";
import {Redirect, Route, Switch} from "react-router-dom";
import OptionsAdmin from "../OptionsAdmin/OptionsAdmin";
import UserManagement from "../UserManagement/UserManagement";
import ShowSession from "../ShowSession/ShowSession";
import ShowGame from "../ShowGame/ShowGame";
import ConfigurationOptions from "../ConfigurationOptions/ConfigurationOptions";

export default function Content(): JSX.Element {

    return (
        <ContentStyles>
            <Switch>
                <Route path="/admin/options" children={<OptionsAdmin/>}/>
                <Route path="/admin/user-management" children={<UserManagement/>}/>
                <Route path="/admin/configuration-options" children={<ConfigurationOptions/>}/>
                <Route path="/admin/session/:id" children={<ShowSession/>}/>
                <Route path="/admin/game/:id" children={<ShowGame/>}/>
                <Redirect to="/admin/options"/>
            </Switch>
        </ContentStyles>
    );
}
