import styled from "styled-components";

export const SessionInfoStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  .container-user-info {
    display: flex;
    align-items: center;
    justify-content: start;
    width: 85%;
    margin-top: 1.2vw;

    .container-user-session {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: .5vw;
      margin-right: 2vw;

      .container-icon-session {
        display: flex;
        align-items: center;
        justify-content: center;
        position: revert;
        border-radius: 100%;

        .img-user-session-circle {
          width: 2vw;
        }
        .img-user-session-avatar {
          width: 1vw;
          position: absolute;
        }
      }
      .name-user {
        text-align: center;
        letter-spacing: 0;
        color: #2E304E;
        opacity: 1;
        font-size: 1vw;
        font-weight: 600;
        font-family: Poppins-SemiBold, sans-serif;
      }
    }
  }
  .container-user-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap: .5vw;
    width: 80%;
    height: 12.5vw;
    margin-top: 2.5vw;
    
    .container-link {
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 1vw;
      width: 100%;
      cursor: pointer;
      
      .options-admin-img-legal-information {
        width: .8vw;
      }
      .text-link {
        text-align: left;
        letter-spacing: 0;
        color: #2E304E;
        opacity: 1;
        font-size: 1vw;
      }
    }
  }
  .container-user-logout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap: .5vw;
    width: 80%;
    
    .container-logout {
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 1vw;
      width: 100%;
      cursor: pointer;
      
      .options-admin-img-logout {
        width: .8vw;
      }
      .text-logout {
        text-align: left;
        letter-spacing: 0;
        color: #F45858;
        opacity: 1;
        font-size: 1vw;
      }
    }
  }
`
