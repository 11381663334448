import React from "react";
import {useDrag} from "react-dnd";
import Draggable, { DraggableEvent, DraggableData } from 'react-draggable';

interface IDraggableDivProps {
    children: React.ReactNode;
    position: { x: number; y: number };
    onDrag: (x: number, y: number) => void;
}
export default function DraggableDiv({ children,position, onDrag }:IDraggableDivProps):JSX.Element {

    return (
        <Draggable
            position={position}
            onStop={(event: DraggableEvent, data: DraggableData) => onDrag(data.x, data.y)}
            bounds="parent"
        >
            <div
                style={{
                    position: 'absolute',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: '#FFFFFF',
                    boxShadow: '3px 4px 1px #0000004D',
                    boxSizing: 'border-box',
                    width: '15vw',
                    height: '13vw',
                    borderRadius: '1.5vw',
                    zIndex: 99999,
                }}
            >
                {children}
            </div>
        </Draggable>
    );
}