export function getResponseStringToGameMode(game_mode: string) {
    switch (game_mode) {
        case 'col_1':
            return 'Solo se aceptan balotas de la Columna B';
        case 'col_2':
            return 'Solo se aceptan balotas de la Columna I';
        case 'col_3':
            return 'Solo se aceptan balotas de la Columna N';
        case 'col_4':
            return 'Solo se aceptan balotas de la Columna G';
        case 'col_5':
            return 'Solo se aceptan balotas de la Columna O';
        case 'diagonal':
            return 'No se aceptan balotas de la Columna N';
        case 'anti_diagonal':
            return 'No se aceptan balotas de la Columna N';
        case 'shape_X':
            return 'No se aceptan balotas de la Columna N';
        case 'corners':
            return 'Solo se aceptan balotas de la Columna B y Columna O';
        case 'row_3':
            return 'No se aceptan balotas de la Columna N';
        default:
            return '';
    }
}