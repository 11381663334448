import {ButtonExportExcelStyles} from "./ButtonExportExcelStyles";
import React, {ReactNode, useEffect, useState} from "react";
import * as XLSX from 'xlsx';

interface IButtonExportExcelProps {
    children: ReactNode;
    listExcel: any[];
    nameFile: string;
}

export default function ButtonExportExcel({children, listExcel, nameFile}:IButtonExportExcelProps): JSX.Element {
    
    function exportToExcel () {
        const ws = XLSX.utils.json_to_sheet(listExcel);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, nameFile);
    }

    return (
        <ButtonExportExcelStyles>
            <button onClick={exportToExcel}>
                {children}
            </button>
        </ButtonExportExcelStyles>
    );
}
