import styled from "styled-components";

export const ShowSessionInformationStyled = styled.div`
  .container-information {
    font-family: 'Poppins-Light', sans-serif;
    font-size: 1.1vw;

    .quill-input {
      
    }
  }
`
