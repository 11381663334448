import {CreateSessionStyles} from "./CreateSessionStyles";
import {useForm} from "react-hook-form";
import {CreateSessionModel, CreateSessionPostModel} from "../../../domain/models/CreateSessionModel";
import {useAppDispatch, useAppSelector} from "../../../shared/Utils/Hooks";
import {useEffect, useRef, useState} from "react";
import {
    closeCreateSession,
    openAndCloseModalAddBulkUsersInCreateSession, openAndCloseModalImagesStorage
} from "../../../store/ActionsAdmin/ModalsActions";
import {createSession, getAllSessionsActive, getAllSessionsInactive} from "../../../store/ActionsAdmin/SessionActions";
import {alertSuccess} from "../../../shared/Utils/AlertSuccess";
import {alertError} from "../../../shared/Utils/AlertError";
import {
    accumulatedValue,
    cardLimit,
    cardPrice,
    sessionDescription, sessionLimit,
    sessionName, ValidateCurrentDate, ValidateHeaderSession
} from "../../../shared/Utils/ValidationformCreateSession";
import {IUser} from "../Header/AppHeader";
import {handleDateNow} from "../../../shared/Utils/DateNow";
import {uploadFile} from "../../../../../Firebase/Firebase";
import * as XLSX from "xlsx";
import {CreateUserModel} from "../../../domain/models/CreateUserModel";
import {IBulkUsers} from "../AddBulkUser/AddBulkUser";
import ImagesStorage from "../ImagesStorage/ImagesStorage";
import {
    handleSelectedClickAddImage, handleSelectedImageBannerCreateSession,
    handleSelectedImageMainCreateSession
} from "../../../store/ActionsAdmin/BingosActions";
import {myDBDomain} from "../../../shared/Utils/TestingApi/MyDBDomain";

export default function CreateSession(): JSX.Element {

    const dispatch = useAppDispatch();
    const {register, handleSubmit, reset, formState: {errors}} = useForm<CreateSessionModel>();
    const fileInputImgSmall = useRef<HTMLInputElement | null>(null);
    const fileInputImgBanner = useRef<HTMLInputElement | null>(null);
    const fileInputExcelBulkUsers = useRef<HTMLInputElement | null>(null);
    const [img, setImg] = useState<string | null>(null);
    const [imgBanner, setImgBanner] = useState<string | null>(null);
    const [fileBulkSessions, setFileBulkSessions] = useState<CreateUserModel[]>([]);
    const CreatingSession = useAppSelector((state) => state.sessionState.CreatingSession);
    const domainActive = useAppSelector((state) => state.accountState.domainActive);
    const offsetSessionInactive = useAppSelector((state) => state.sessionState.offsetSessionInactive);
    const offsetSessionActive = useAppSelector((state) => state.sessionState.offsetSessionActive);
    const showModalImagesStorage = useAppSelector((state) => state.modalState.showModalImagesStorage);
    const selectedImageMainCreateSession = useAppSelector((state) => state.bingoState.selectedImageMainCreateSession);
    const selectedImageBannerCreateSession = useAppSelector((state) => state.bingoState.selectedImageBannerCreateSession);
    const [userActive, setUserActive] = useState<string>('');
    const [errorImgCrateSession, setErrorImgCreateSession] = useState<string>('');
    const [errorImgBannerCreateSession, setErrorImgBannerCreateSession] = useState<string>('');
    const [toggleCardLimit, setToggleCardLimit] = useState<boolean>(false);

    function handleCreateSession(data: CreateSessionModel) {
        console.log(data);
        if (selectedImageMainCreateSession === '') {
            console.log(selectedImageMainCreateSession);
            setErrorImgCreateSession('Imagen es requerida');
            return;
        }
        setErrorImgCreateSession('');
        if (selectedImageBannerCreateSession === '') {
            setErrorImgBannerCreateSession('Imagen es requerida');
            return;
        }
        setErrorImgBannerCreateSession('');
        if (domainActive) {
            const dataCreateSession: CreateSessionPostModel = {
                session_name: data.session_name,
                bingo_limit: parseInt(data.bingo_limit),
                card_limit: data.card_limit ? parseInt(data.card_limit) : 0,
                card_price: parseInt(data.card_price),
                scheduled_date: data.scheduled_date,
                main_image: `${myDBDomain}/storage/${selectedImageMainCreateSession}`,
                banner_image: `${myDBDomain}/storage/${selectedImageBannerCreateSession}`,
                session_info: data.session_info,
                prize_pool: parseInt(data.prize_pool),
                bingo_header: data.bingo_header,
                account_id: domainActive.id,
                listUsers: fileBulkSessions
            }
            dispatch(createSession(dataCreateSession)).then(r => {
                dispatch(getAllSessionsActive(domainActive.id, offsetSessionActive)).then(() => {
                });
                dispatch(getAllSessionsInactive(domainActive.id, offsetSessionInactive)).then(() => {
                });
                alertSuccess('sesión creada con éxito');
                reset();
                handleCloseModal();
            }).catch((error) => {
                alertError('Hubo un error interno');
                console.log(error);
            });
        }
    }

    useEffect(() => {
        console.log('imagen main.....');
        console.log(selectedImageMainCreateSession);
        console.log('imagen banner.....');
        console.log(selectedImageBannerCreateSession);
    }, []);

    const onSubmit = (data: CreateSessionModel) => handleCreateSession(data);

    function handleClickImgSmall() {
        dispatch(handleSelectedClickAddImage('selectImageMainCreateSession'));
        dispatch(openAndCloseModalImagesStorage(true));
    }

    function handleClickImgBanner() {
        dispatch(handleSelectedClickAddImage('selectImageBannerCreateSession'));
        dispatch(openAndCloseModalImagesStorage(true));
    }

    function handleCloseModal() {
        dispatch(closeCreateSession());
        dispatch(handleSelectedClickAddImage(''));
        dispatch(handleSelectedImageMainCreateSession(''))
        dispatch(handleSelectedImageBannerCreateSession(''));
    }

    async function handleMainImage(e: any) {
        console.log(e.target.files[0].size);
        if (e.target.files[0].size < 100000) {
            const fileMinImage = e.target.files[0];
            console.log(fileMinImage);
            if (fileMinImage && img === null) {
                const dateFile: string = handleDateNow();
                const nameFile: string = `image_main_session_${dateFile}`;
                const fileFirebase = await uploadFile(fileMinImage, nameFile);
                setErrorImgCreateSession('');
                setImg(fileFirebase);
            }
            if (fileMinImage && img !== null) {
                const fileFirebase = await uploadFile(fileMinImage, img);
                setErrorImgCreateSession('');
                setImg(fileFirebase);
            }
        } else {
            setErrorImgCreateSession('La imagen no puede superar los 100K');
        }
    }

    function handleFileExcelInCreateSession(e: any) {
        const fileExcel = e.target.files[0];
        // console.log(fileExcel);
        if (fileExcel !== null && domainActive) {
            const fileExcelReader = new FileReader();
            // console.log(fileExcelReader);
            fileExcelReader.onload = (e: any) => {
                // console.log(e.target);
                if (e.target) {
                    const excelData = e.target.result;
                    const workbook = XLSX.read(excelData, {type: "binary"});
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];
                    const excelDataListObj: IBulkUsers[] = XLSX.utils.sheet_to_json(sheet);
                    const user_type = 'user';
                    const accountId = domainActive.id;
                    const newListBulkUsers: CreateUserModel[] = excelDataListObj.map((user) => ({
                        ...user,
                        user_type,
                        accountId
                    }));
                    console.log(newListBulkUsers);
                    setFileBulkSessions(newListBulkUsers);
                }
            }
            fileExcelReader.readAsBinaryString(fileExcel);
        }
    }

    async function handleBannerImage(e: any) {
        console.log(e.target.files[0].size);
        if (e.target.files[0].size < 300000) {
            const fileBannerImage = e.target.files[0];
            console.log(fileBannerImage);
            if (fileBannerImage && imgBanner === null) {
                const dateFile: string = handleDateNow();
                const nameFile: string = `image_banner_session_${dateFile}`;
                const fileFirebase = await uploadFile(fileBannerImage, nameFile);
                setErrorImgBannerCreateSession('');
                setImgBanner(fileFirebase);
            }
            if (fileBannerImage && imgBanner !== null) {
                const fileFirebase = await uploadFile(fileBannerImage, imgBanner);
                setErrorImgBannerCreateSession('');
                setImgBanner(fileFirebase);
            }
        } else {
            setErrorImgBannerCreateSession('La imagen no puede superar los 300K');
        }
    }

    function getSessionStorage() {
        const dataUser = sessionStorage.getItem('user');
        if (dataUser) {
            const userObj: IUser = JSON.parse(dataUser);
            console.log(userObj);
            setUserActive(userObj.name);
        }
    }

    useEffect(() => {
        getSessionStorage();
    }, [userActive]);

    function handleOpenModalAddBulkUserInCreateSession() {
        if (fileInputExcelBulkUsers.current) {
            fileInputExcelBulkUsers.current?.click();
        }
    }

    function handleToggleCardLimit(checked: boolean) {
        setToggleCardLimit(checked);
    }

    return (
        <CreateSessionStyles>
            <div className="container-title">
                <span className="title">Nueva Sesión</span>
                <span onClick={handleCloseModal} className="close">x</span>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="form">
                <div className="container-img-inputs">
                    <div className="container-img">
                        <input
                            ref={fileInputImgSmall}
                            type="file"
                            data-max-size="2"
                            accept="image/*"
                            className="input-img-small"
                            onChange={handleMainImage}
                        />
                        <div onClick={handleClickImgSmall} className="circle-img">
                            <img
                                className="options-admin-add-image"
                                alt="optionsAdminAddImage"
                                src={selectedImageMainCreateSession !== '' ? `${myDBDomain}/storage/${selectedImageMainCreateSession}` : "/img/newImage/optionsAdmin/optionsAdminAddImage.png"}
                            />
                        </div>
                        {errorImgCrateSession !== '' &&
                            <span className="error-img">{errorImgCrateSession}</span>
                        }
                        {errorImgCrateSession === '' &&
                            <span className="info-add">Agregar/editar miniatura</span>
                        }

                    </div>
                    <div className="container-inputs">
                        <div className="container-input">
                            <input
                                {...register('session_name', {
                                    validate: sessionName
                                })}
                                className="input-session-name"
                                type="text"
                                placeholder="Nombre de la sesión"

                            />
                            {errors.session_name?.type === 'validate' &&
                                <span className='error'>{errors.session_name.message}</span>}
                        </div>
                        <div className="container-input-row">
                            <div className="container-column">
                                <select
                                    {...register('bingo_limit', {
                                        validate: sessionLimit
                                    })}
                                    className="session-limit"
                                >
                                    <option value=''>Límite de la sesión</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="75">75</option>
                                    <option value="95">95</option>
                                    <option value="99">99</option>
                                </select>
                                {errors.bingo_limit?.type === 'validate' &&
                                    <span className='error'>{errors.bingo_limit.message}</span>}
                            </div>
                            <div className="container-column">
                                <input
                                    {...register('card_price', {
                                        validate: cardPrice
                                    })}
                                    className="bingo-value"
                                    type="text"
                                    placeholder="Valor del Bingo"
                                />
                                {errors.card_price?.type === 'validate' &&
                                    <span className='error'>{errors.card_price.message}</span>}
                            </div>

                        </div>
                        <div className="container-input-row">

                            <div className="container-card-limit-toggle">
                                <div className="container-input-toggle">
                                    <label className="switch">
                                        <input onChange={(event) => handleToggleCardLimit(event.target.checked)}
                                               type="checkbox"/>
                                        <span className="slider round"></span>
                                    </label>
                                    <span className="text-toggle">
                                        {!toggleCardLimit ? 'Limite de cards' : 'No limite de cards'}
                                    </span>
                                </div>
                                {toggleCardLimit &&
                                    <div className="container-column">
                                        <input
                                            {...register('card_limit', {
                                                validate: cardLimit
                                            })}
                                            className="card-value"
                                            type="text"
                                            placeholder="Número de tarjetones"
                                        />
                                        {errors.card_limit?.type === 'validate' &&
                                            <span className='error'>{errors.card_limit.message}</span>}
                                    </div>
                                }
                            </div>

                            <div className="container-column">
                                <input
                                    {...register('prize_pool', {
                                        validate: accumulatedValue
                                    })}
                                    className="prize-card"
                                    type="text"
                                    placeholder="Valor del Acumulado"
                                />
                                {errors.prize_pool?.type === 'validate' &&
                                    <span className='error'>{errors.prize_pool.message}</span>}
                            </div>

                        </div>
                        <div className="container-input-row">
                            <div className="container-column">
                                <input
                                    {...register('session_info', {
                                        validate: sessionDescription
                                    })}
                                    className="card-value"
                                    type="text"
                                    placeholder="Descripción de la sesión"
                                />
                                {errors.session_info?.type === 'validate' &&
                                    <span className='error'>{errors.session_info.message}</span>}
                            </div>
                            <div className="container-column">
                                <input
                                    {...register('scheduled_date', {
                                        validate: ValidateCurrentDate
                                    })}
                                    className="date2"
                                    type="date"
                                    placeholder="Fecha Final"
                                />
                                {errors.scheduled_date?.type === 'validate' &&
                                    <span className='error'>{errors.scheduled_date.message}</span>}
                            </div>
                        </div>
                        <div className="container-input-row">
                            <div className="container-column">
                                <input
                                    {...register('bingo_header', {
                                        validate: ValidateHeaderSession
                                    })}
                                    className="card-value"
                                    type="text"
                                    placeholder="Cabecera de tarjetones"
                                    maxLength={5}
                                    defaultValue={domainActive ? domainActive.key_word_card : ''}
                                />
                                {errors.bingo_header?.type === 'validate' &&
                                    <span className='error'>{errors.bingo_header.message}</span>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-banner-img">
                    <div className="banner-img">
                        <input
                            ref={fileInputImgBanner}
                            type="file"
                            data-max-size="2"
                            accept="image/*"
                            className="input-img-banner"
                            onChange={handleBannerImage}
                        />
                        {selectedImageBannerCreateSession === '' &&
                            <img
                                onClick={handleClickImgBanner}
                                className="options-admin-add-image-banner"
                                alt="optionsAdminAddImage"
                                src="/img/newImage/optionsAdmin/optionsAdminAddImage.png"
                            />
                        }
                        {selectedImageBannerCreateSession !== '' &&
                            <img
                                onClick={handleClickImgBanner}
                                className="options-admin-add-image-banner-show"
                                alt="optionsAdminAddImage"
                                src={`${myDBDomain}/storage/${selectedImageBannerCreateSession}`}
                            />
                        }
                    </div>
                </div>
                <div className="container-text-info-input-banner">
                    {errorImgBannerCreateSession !== '' &&
                        <span className="error-img-banner">{errorImgBannerCreateSession}</span>
                    }
                    {errorImgBannerCreateSession === '' &&
                        <span className="info-add">Agregar/editar banner</span>
                    }
                    {/*{errorImgBanner !== '' ? errorImgBanner : 'Agregar/editar banner'}*/}
                </div>
                {/*<div className="container-button">*/}
                {/*    <input*/}
                {/*        ref={fileInputExcelBulkUsers}*/}
                {/*        type="file"*/}
                {/*        data-max-size="2"*/}
                {/*        accept=".xls, .xlsx"*/}
                {/*        className="input-bulk-users"*/}
                {/*        onChange={handleFileExcelInCreateSession}*/}
                {/*    />*/}
                {/*    <button onClick={handleOpenModalAddBulkUserInCreateSession} type="button"*/}
                {/*            className="button-form-add-users">*/}
                {/*        {fileBulkSessions.length === 0 ? 'Agregar usuarios' : 'Excel cargado'}*/}
                {/*    </button>*/}
                {/*</div>*/}
                <div className="container-button">
                    <button disabled={CreatingSession} type="submit"
                            className="button-form">{!CreatingSession ? 'Crear sesión' : 'Creando...'}
                    </button>
                </div>
            </form>
            {showModalImagesStorage &&
                <div className="modal-images-storage">
                    <ImagesStorage/>
                </div>
            }
        </CreateSessionStyles>
    );
}
