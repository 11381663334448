import {LastBallotsStyles} from "./LastBallotsStyles";
import {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../shared/Utils/Hooks";
import {useThemeContext} from "../../../shared/Hooks/ThemeContextProvider";
import {getGameInfo} from "../../../store/ActionsAdmin/BingosActions";
import {GameStorageDataModel} from "../../../domain/models/GameStorageDataModel";
import {getRemainingBallots} from "../../../shared/Utils/GetRemainingBallots";

export default function LastBallots(): JSX.Element {

    const dispatch = useAppDispatch();
    const themeActive = useThemeContext();
    const gameInfo = useAppSelector((state) => state.bingoState.gameInfo);
    const singleSessionDatabase = useAppSelector((state) => state.sessionState.singleSessionDatabase);
    const lastBallot = useAppSelector((state) => state.bingoState.lastBallot);
    const allBallotsPlayed = useAppSelector((state) => state.bingoState.allBallotsPlayed);
    const singleBingo = useAppSelector((state) => state.bingoState.singleBingo);
    const [scrollOffset, setScrollOffset] = useState<number>(6);
    const [ballotPlayedInfo, setBallotPlayedInfo] = useState<number>(0);
    const [ballotsRemaining, setBallotsRemaining] = useState<number>(0);

    function handleClickScroll(data_direction: string) {
        if (data_direction === 'left' && scrollOffset > 6) {
            // Mueve el scroll hacia la izquierda, reduciendo el desplazamiento.
            setScrollOffset((prevOffset) => prevOffset - 23.8);
        }
        if (data_direction === 'right') {
            // Mueve el scroll hacia la derecha, aumentando el desplazamiento.
            setScrollOffset((prevOffset) => prevOffset + 23.8);
        }
    }

    useEffect(() => {
        if (singleSessionDatabase && gameInfo) {
            const totalBallot = singleSessionDatabase.bingo_limit;
            const ballotsPlayed = gameInfo.listBallotPlayed.length;
            setBallotPlayedInfo(ballotsPlayed);
            setBallotsRemaining(totalBallot-ballotsPlayed);
        }
    }, [gameInfo]);

    // useEffect(() => {
    //     if (gameInfo) {
    //         dispatch(getGameInfo(gameInfo.game_id)).then(() => {});
    //     }
    // }, []);

    useEffect(() => {
        console.log(scrollOffset);
    }, [scrollOffset]);

    useEffect(() => {
        console.log('todas las balotas jugadas.....');
        console.log(allBallotsPlayed);
    }, [allBallotsPlayed]);

    return (
        <LastBallotsStyles>
            <span className="title">Últimas Balotas: </span>
            <div className="container-row">
                <div className="stylized-image">
                    {/*<img src="/img/newImage/img-number-ballot-big.png" alt="Image right" className="image"/>*/}
                    <img
                        src={themeActive.theme ? themeActive.theme.icon_last_ballot : '/img/newImage/img-number-ballot-big.png'}
                        alt="Image right"
                        className="image"
                    />
                    <div className="number-ballot">{gameInfo ? gameInfo.lastBallot : ''}</div>
                </div>
                <div className="container-bar">
                    <div className="triangle-box triangle-box-left" onClick={() => handleClickScroll('left')}>
                        <img src="/img/new-img-change/ATRÁS-8.png" alt="right" className="triangle-left"/>
                    </div>

                    <div className="triangle-box-right">
                        <div
                            className="numbers-container"
                            style={{transform: `translateX(-${scrollOffset}vw)`}}
                        >
                            {gameInfo && gameInfo.listBallotPlayedData.map((ballot, index) => (
                                    <div key={index} className="numbers">
                                        {ballot}
                                    </div>
                                ))
                                    .reverse()
                            }
                        </div>
                    </div>
                    <div className="triangle-box" onClick={() => handleClickScroll('right')}>
                        <img src="/img/new-img-change/SIGUIENTE-8.png" alt="right" className="triangle-right"/>
                    </div>
                </div>
            </div>
            <div className="ballot-counter">
                <div className="balls-played">
                    <div className="counter-ballot">
                        {gameInfo ? gameInfo.listBallotPlayed.length : 0}
                    </div>
                    <span className="text-counter-ballot">balotas jugadas</span>
                </div>
                <div className="balls-played">
                    <div className="counter-ballot">
                        {getRemainingBallots(singleBingo?.game_mode, singleBingo?.session.bingo_limit) - (gameInfo ? gameInfo?.listBallotPlayed.length : 0)}
                    </div>
                    <span className="text-counter-ballot">balotas restantes</span>
                </div>
            </div>
        </LastBallotsStyles>
    );
}
