import {ButtonReloadGameStyled} from "./ButtonReloadGameStyled";
import {useAppDispatch, useAppSelector} from "../../../shared/Utils/Hooks";
import {useState} from "react";
import {
    changePlayLaunchBallotAuto,
    getGameInfo,
    getSingleBingo,
    resetLaunchingBallot
} from "../../../store/ActionsAdmin/BingosActions";

export default function ButtonReloadGame(): JSX.Element {

    const dispatch = useAppDispatch();
    let timeoutRefresh: string | number | NodeJS.Timeout | undefined;
    const singleBingo = useAppSelector((state) => state.bingoState.singleBingo);
    const [loadingSingleBingo, setLoadingSingleBingo] = useState<boolean>(false);

    function handleReloadGame() {
        if (singleBingo) {
            clearTimeout(timeoutRefresh);
            setLoadingSingleBingo(true);
            dispatch(getSingleBingo(singleBingo.id)).then(() => {
                dispatch(resetLaunchingBallot(false));
                dispatch(changePlayLaunchBallotAuto(false));
            });
            timeoutRefresh = setTimeout(() => {
                setLoadingSingleBingo(false);
            }, 5000);
        }
    }

    return (
        <ButtonReloadGameStyled>
            {!loadingSingleBingo &&
                <img onClick={handleReloadGame} className="icon-reload" alt="button-reload" src="/icon/button-reload.png"/>
            }
            {loadingSingleBingo &&
                <img className="icon-reload-animation" alt="spin-button-reload" src="/icon/spin-reload.png"/>
            }
        </ButtonReloadGameStyled>
    );
}
