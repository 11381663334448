import {UserSessionInfoStyles} from "./UserSessionInfoStyles";
import React from "react";
import {useHistory} from "react-router-dom";
import {auth} from "../../../../../Firebase/Firebase";
import {getSessionStorageObj} from "../../../../admin/shared/Utils/GetSessionStorageObject";

export  default function UserSessionInfo(): JSX.Element {

    const history = useHistory();
    const user = getSessionStorageObj('user');

    function handleLogout() {
        auth.signOut().then(() => {
            localStorage.removeItem('user');
            history.push('/login');
        });
    }

    return (
        <UserSessionInfoStyles>
            <div className="container-user-info">
                <div className="container-user-session">
                    <div className="container-icon-session">
                        <img
                            className="img-user-session-circle"
                            alt="optionsAdminImgUserSessionCircle"
                            src="/img/newImage/optionsAdmin/optionsAdminImgUserSessionCircle.svg"
                        />
                        <img
                            className="img-user-session-avatar"
                            alt="optionsAdminImgUserSessionAvatar"
                            src="/img/newImage/optionsAdmin/optionsAdminImgUserSessionAvatar.svg"
                        />
                    </div>
                    <span className="name-user">Hola, {user.name}</span>
                </div>
            </div>
            <div className="container-user-link">
                <div className="container-link">

                </div>
                <div className="container-link">
                    <img
                        className="options-admin-img-legal-information"
                        alt="optionsAdminImgLegalInformation"
                        src="/img/newImage/optionsAdmin/optionsAdminImgLegalInformation.svg"
                    />
                    <span className="text-link">Información Legal</span>
                </div>
                <div className="container-link">
                    <img
                        className="options-admin-img-legal-information"
                        alt="optionsAdminImgLegalInformation"
                        src="/img/newImage/optionsAdmin/optionsAdminImgLegalInformation.svg"
                    />
                    <span className="text-link">Reglas del Bingo</span>
                </div>
            </div>
            <div className="container-user-logout">
                <div className="container-logout">
                    <img
                        className="options-admin-img-logout"
                        alt="optionsAdminImgLogout"
                        src="/img/newImage/optionsAdmin/optionsAdminImgLogout.svg"
                    />
                    <span onClick={handleLogout} className="text-logout">Cerrar sesión</span>
                </div>
            </div>
        </UserSessionInfoStyles>
    );
}
