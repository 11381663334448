import {ShowSessionStyled} from "./ShowSessionStyled";
import {Redirect, Route, Switch, useHistory, useParams} from "react-router-dom";
import React, {useEffect} from "react";
import ShowSessionInformation from "../ShowSessionInformation/ShowSessionInformation";
import ShowSessionSponsors from "../ShowSessionSponsors/ShowSessionSponsors";
import ShowSessionAwards from "../ShowSessionAwards/ShowSessionAwards";
import ShowSessionAcquireCards from "../ShowSessionAcquireCards/ShowSessionAcquireCards";
import ShowSessionGames from "../ShowSessionGames/ShowSessionGames";
import Nav from "../Nav/Nav";
import {useAppDispatch, useAppSelector} from "../../../shared/Utils/Hooks";
import {
    closeCreateSession,
    closeEditSession,
    openAdnCloseModalCreateGame,
    openAndCloseModalCreateUser,
    openAndCloseModalEditGame,
    openAndCloseModalLaunch_Ballot_Manual,
    openEditSession,
    openUserInfo
} from "../../../store/ActionsAdmin/ModalsActions";
import {
    changeSessionSelected, deleteSession, getAllSessionsActive, getAllSessionsInactive,
    getSingleSessionDatabase, updateOffsetSessionActive, updateOffsetSessionInactive
} from "../../../store/ActionsAdmin/SessionActions";
import Accumulated from "../Accumulated/Accumulated";
import {alertConfirmDelete} from "../../../shared/Utils/AlertConfirmDelete";
import UsersSession from "../UsersSession/UsersSession";
import {getCardSCountBySessionId, getCountCardsByBingoLimit} from "../../../store/ActionsAdmin/CardActions";
import AddBulkUser from "../AddBulkUser/AddBulkUser";
import {removeItemLocalStorage} from "../../../shared/Utils/RemoveItemLocalStorage";


export interface IParams {
    id: string;
}

export default function ShowSession(): JSX.Element {

    const history = useHistory();
    const id = useParams<IParams>();
    const dispatch = useAppDispatch();
    const singleSessionDatabase = useAppSelector((state) => state.sessionState.singleSessionDatabase);
    const domainActive = useAppSelector((state) => state.accountState.domainActive);
    const offsetSessionInactive = useAppSelector((state) => state.sessionState.offsetSessionInactive);
    const offsetSessionActive = useAppSelector((state) => state.sessionState.offsetSessionActive);
    const showModalUsersSession = useAppSelector((state) => state.modalState.showModalUsersSession);
    const showModalAddBulkUsers = useAppSelector((state) => state.modalState.showModalAddBulkUsers);

    function redirectHome() {
        history.push('/admin/options');
        dispatch(changeSessionSelected(undefined));
        dispatch(openUserInfo(false));
        dispatch(openAdnCloseModalCreateGame(false));
        dispatch(openAndCloseModalLaunch_Ballot_Manual(''));
    }

    useEffect(() => {
        console.log(id);
        console.log(singleSessionDatabase);
        if (id && domainActive) {
            dispatch(getSingleSessionDatabase(parseInt(id.id))).then(r => {
                dispatch(getAllSessionsActive(domainActive.id, offsetSessionActive)).then(() => {
                });
                dispatch(getAllSessionsInactive(domainActive.id, offsetSessionInactive)).then(() => {
                });
                dispatch(getCardSCountBySessionId(parseInt(id.id))).then(() => {});
                dispatch(changeSessionSelected(parseInt(id.id)));
            });
        }
    }, [id]);

    function handleOpenModalEditSession() {
        dispatch(openEditSession());
    }

    async function handleDeleteSession() {
        console.log('click eliminar sesión');
        if (singleSessionDatabase && domainActive) {
            const confirmDeleteSession = await alertConfirmDelete();
            console.log(confirmDeleteSession);
            if (confirmDeleteSession) {
                dispatch(deleteSession(singleSessionDatabase.id)).then(() => {
                    dispatch(updateOffsetSessionActive(0));
                    dispatch(updateOffsetSessionInactive(0));
                    dispatch(getAllSessionsInactive(domainActive.id, 0)).then(() => {});
                    dispatch(getAllSessionsActive(domainActive.id, 0)).then(() => {});
                    dispatch(changeSessionSelected(undefined));
                    dispatch(closeCreateSession());
                    dispatch(closeEditSession());
                    dispatch(openAndCloseModalEditGame(false));
                    dispatch(openUserInfo(false));
                    dispatch(openAdnCloseModalCreateGame(false));
                    dispatch(openAndCloseModalCreateUser(false));
                    singleSessionDatabase.games.map((game) => {
                        removeItemLocalStorage(`game${game.id}`);
                        removeItemLocalStorage(`winners${game.id}`);
                    });
                    history.push('/admin/options');
                });
            }
        }
    }

    useEffect(() => {
        if (singleSessionDatabase) {
            dispatch(getCountCardsByBingoLimit(singleSessionDatabase.bingo_limit)).then(() => {});
        }
    }, []);

    return (
        <ShowSessionStyled>
            <>
                <div className="container-img-banner">
                    <img
                        className="options-admin-img-banner-big"
                        alt="optionsAdminImgBannerBig"
                        src={singleSessionDatabase?.banner_image}
                    />
                </div>
                <Accumulated
                    type="showSession"
                    clickDelete={handleDeleteSession}
                    clickEdit={handleOpenModalEditSession}
                    click={redirectHome}
                    width={80}
                    height={'unset'}
                    margin={1.5}
                    top={2}
                    left={20}
                />
                <Nav id={id.id}/>
                <div className="container-content">
                    <Switch>
                        <Route path="/admin/session/:id/games" children={<ShowSessionGames/>}/>
                        <Route path="/admin/session/:id/information" children={<ShowSessionInformation/>}/>
                        <Route path="/admin/session/:id/sponsors" children={<ShowSessionSponsors/>}/>
                        <Route path="/admin/session/:id/awards" children={<ShowSessionAwards/>}/>
                        <Route path="/admin/session/:id/acquireCards" children={<ShowSessionAcquireCards/>}/>
                        <Redirect to="/admin/session/:id/games"/>
                    </Switch>
                </div>
            </>
            {showModalAddBulkUsers &&
                <div className="modal-add-bulk-users">
                    <AddBulkUser session_id={singleSessionDatabase?.id} card_limit={singleSessionDatabase?.card_limit}/>
                </div>
            }
        </ShowSessionStyled>
    );
}
