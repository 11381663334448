import {SignInStyles} from "./SignInStyles"
import React, {useEffect} from "react";
import {themeDefault} from "../../../shared/Utils/ThemeDefault";
import {useForm} from "react-hook-form";
import {
    changeLoadingSignIn,
    getConfirmationResultFirebase,
    getNumberPhoneLogin, verifyNumberPhone,
    verifyNumberPhoneAndAccount
} from "../../../store/ActionsAdmin/AuthUserActions";
import {VerifyNumberPhoneModel, VerifyPhoneModel} from "../../../domain/models/VerifyNumberPhoneModel";
import {useAppDispatch, useAppSelector} from "../../../shared/Utils/Hooks";
import {setLocalStorageObj} from "../../../shared/Utils/SetLocalStorageObj";
import {getLocalStorageObj} from "../../../shared/Utils/GetLocalStorageObj";
import {RecaptchaVerifier, signInWithPhoneNumber} from "firebase/auth";
import {auth} from "../../../../../Firebase/Firebase";
import {
    openAndCloseModalConfirmOTP,
    openAndCloseModalRegister,
    openAndCloseModalSignIn
} from "../../../store/ActionsAdmin/ModalsActions";
import {validatePhoneNumber} from "../../../shared/Utils/ValidatePhoneNumber";

export interface signInModel {
    phone: string;
    remember: boolean;
}

export default function SignIn(): JSX.Element {

    const domain = window.location.origin;
    const {register, handleSubmit, setValue, formState: {errors}} = useForm<signInModel>();
    const dispatch = useAppDispatch();
    const isLoadingVerifyPhone = useAppSelector((state) => state.authUserState.isLoadingVerifyPhone);
    const numberPhoneLogin = useAppSelector((state) => state.authUserState.numberPhoneLogin);
    const domainActive = useAppSelector((state) => state.accountState.domainActive);
    const numberPhoneIsCorrect = useAppSelector((state) => state.authUserState.numberPhoneIsCorrect);

    function handleSignIn(data: signInModel) {
        if (domainActive) {
            console.log(data);
            dispatch(changeLoadingSignIn(true));
            dispatch(getNumberPhoneLogin(`57${data.phone}`));
            const dataVerifyNumberPhone: VerifyNumberPhoneModel = {
                domain: domain,
                phone: `57${data.phone}`,
                account_id: domainActive.id
            }
            console.log(dataVerifyNumberPhone);
            if (data.remember) {
                setLocalStorageObj(data, 'remember');
            }
            if (!data.remember) {
                localStorage.removeItem('remember');
            }
            dispatch(verifyNumberPhoneAndAccount(dataVerifyNumberPhone)).then(() => {
                sendVerificationCode(data.phone).then(() => {
                    dispatch(changeLoadingSignIn(false));
                });
            }).catch(() => {
                dispatch(openAndCloseModalSignIn(false));
                dispatch(openAndCloseModalRegister(true));
                dispatch(changeLoadingSignIn(false));
            });
        }
    }

    async function sendVerificationCode(phone: string) {
        console.log(phone);
        let phoneNumberFormatted: string = `+57${phone}`;
        console.log(phoneNumberFormatted);
        try {
            const appVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
                size: 'normal',
                callback: (response: any) => {
                    console.log(response);
                },
            });
            await signInWithPhoneNumber(auth, phoneNumberFormatted, appVerifier).then((confirmation) => {
                console.log(confirmation);
                dispatch(getConfirmationResultFirebase(confirmation));
                dispatch(openAndCloseModalSignIn(false));
                dispatch(openAndCloseModalConfirmOTP(true));
            }).catch((e) => {
                console.log(e);
            });

        } catch (error) {
            console.error('Error al enviar el código de verificación:', error);

        }
    }

    const submitFormSignIn = (data:signInModel) => handleSignIn(data);

    useEffect(() => {
        const phoneLocalStorage: signInModel | null = getLocalStorageObj('remember');
        if (phoneLocalStorage) {
            setValue('phone', phoneLocalStorage.phone);
            setValue('remember', phoneLocalStorage.remember);
            return;
        }
        setValue('phone', numberPhoneLogin);
        setValue('remember', false);
    }, [setValue]);

    useEffect(() => {
        console.log(numberPhoneLogin);
    }, [numberPhoneLogin]);

    useEffect(() => {
        console.log(domainActive);
    }, [domainActive]);

    return (
        <SignInStyles
            button_color={themeDefault.button_color}
            button_color_shadow={themeDefault.button_color_shadow}
        >
            <form onSubmit={handleSubmit(submitFormSignIn)} className="form-login">
                <div className="title-form">
                    Inicia sesión
                </div>
                <div className="container-input">
                    <input
                        type="text"
                        placeholder="Teléfono"
                        maxLength={10}
                        {...register('phone', {
                            validate: validatePhoneNumber
                        })}
                    />
                </div>
                <div className="container-input-check">
                    <div className="input-check">
                        <input
                            type="checkbox"
                            {...register('remember')}
                        />
                        <label className="text-label">Recordarme</label>
                    </div>
                </div>
                <div className="container-button">
                    <button
                        type="submit"
                        disabled={isLoadingVerifyPhone}
                        className="button-form"
                        // onClick={(event) => verifyPhone(event)}
                    >
                        {!isLoadingVerifyPhone ? 'Entrar' : 'Esperando...'}
                    </button>
                </div>
                {/*<div className="not-have-account">*/}
                {/*    <span className="not-have-account-text">Aún no tengo una cuenta</span>*/}
                {/*</div>*/}
                {errors.phone?.type === 'validate' &&
                    <div className="container-error-massages">
                        {errors.phone.message}
                    </div>
                }
                <div className="recaptcha">
                    <div id="recaptcha-container"></div>
                </div>
            </form>
        </SignInStyles>
    );
}
