import styled from "styled-components";

export const QuillShowSessionStyled = styled.div`
    position: absolute;
    left: 2.5vw;
    width: 85%;

    .quill-input{
        margin-top: 1vw;
    }
    .mi-boton {
        background-color: #9545FF; 
        color: #fff; /* Color del texto blanco */
        border: none; /* Sin borde */
        padding: 10px 20px; /* Relleno interior */
        border-radius: 5px; /* Bordes redondeados */
        cursor: pointer; /* Cambiar el cursor al pasar el mouse */
    }
    
`