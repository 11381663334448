import styled from "styled-components";

export const ButtonExportExcelStyles = styled.div`
    button {
        background: darkblue;
        border-radius: 37px;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        letter-spacing: 0;
        color: #FFFFFF;
        font-size: .8vw;
        transition: box-shadow 0.3s ease;
        cursor: pointer;
        outline: none;
        border: none;
        font-family: Anton, sans-serif;
        padding: .2vw 2vw;
    }
    button:hover {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Agrega una sombra suave al hacer hover */
    }
`
