import {AppSidebarStyles} from "./AppSidebarStyles";
import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../shared/Utils/Hooks";
import {
    cleanAllSearchSessionsActive,
    cleanAllSearchSessionsInactive,
    getAllSessionsActive,
    getAllSessionsInactive,
    getSearchSessionActive,
    getSearchSessionInactive, updateOffsetSessionActive,
    updateOffsetSessionInactive,
} from "../../../store/ActionsAdmin/SessionActions";
import EditSession from "../EditSession/EditSession";
import SessionInfo from "../SessionInfo/SessionInfo";
import Session from "../Session/Session";
import Loading from "../Loading/Loading";
import {getAccountActive} from "../../../store/ActionsAdmin/AccountActions";
import {LuLoader} from "react-icons/lu";

export default function AppSidebar(): JSX.Element {


    const showModalEditSession = useAppSelector((state) => state.modalState.showModalEditSession);
    const showModalUserInfo = useAppSelector((state) => state.modalState.showModalUserInfo);
    const allSessionsActive = useAppSelector((state) => state.sessionState.allSessionsActive);
    const allSessionsInactive = useAppSelector((state) => state.sessionState.allSessionsInactive);
    const allSearchSessionsActive = useAppSelector((state) => state.sessionState.allSearchSessionsActive);
    const allSearchSessionsInactive = useAppSelector((state) => state.sessionState.allSearchSessionsInactive);
    const loadingAllSessionsInactive = useAppSelector((state) => state.sessionState.loadingAllSessionsInactive);
    const loadingAllSessionsActive = useAppSelector((state) => state.sessionState.loadingAllSessionsActive);
    const loadingAllSearchSessionsActive = useAppSelector((state) => state.sessionState.loadingAllSearchSessionsActive);
    const loadingAllSearchSessionsInactive = useAppSelector((state) => state.sessionState.loadingAllSearchSessionsInactive);
    const dispatch = useAppDispatch();
    const gameInfo = useAppSelector((state) => state.bingoState.gameInfo);
    const [showActiveSessionsSearch, setShowActiveSessionsSearch] = useState<boolean>(false);
    const [showCompletedSessionsSearch, setShowCompletedSessionsSearch] = useState<boolean>(false);
    const [isLoadingSessions, setIsLoadingSessions] = useState<boolean>(false);
    const domainActive = useAppSelector((state) => state.accountState.domainActive);
    const offsetSessionInactive = useAppSelector((state) => state.sessionState.offsetSessionInactive);
    const offsetSessionActive = useAppSelector((state) => state.sessionState.offsetSessionActive);
    let timeout3: string | number | NodeJS.Timeout | undefined;
    let timeout2: string | number | NodeJS.Timeout | undefined;
    const domain = window.location.origin;
    const [wordSearchActive, setWordSearchActive] = useState<string>('');
    const [wordSearchInactive, setWordSearchInactive] = useState<string>('');

    const toggleActiveSessionsSearch = () => {
        setShowActiveSessionsSearch(!showActiveSessionsSearch);
    };
    const toggleInactiveSessionsSearch = () => {
        setShowCompletedSessionsSearch(!showCompletedSessionsSearch);
    };
    const toggleCompletedSessionsSearch = () => {
        setShowCompletedSessionsSearch(!showCompletedSessionsSearch);
    };

    useEffect(() => {
        console.log(domain);
        dispatch(getAccountActive({domain: domain})).then(r => {
        });
    }, [domain]);

    useEffect(() => {
        console.log(allSessionsActive);
        console.log(allSessionsInactive);
        console.log(domainActive);
    }, [allSessionsActive, allSessionsInactive]);

    useEffect(() => {
        if (domainActive) {
            setIsLoadingSessions(true);
            console.log(domainActive.id);
            dispatch(getAllSessionsActive(domainActive.id, offsetSessionActive)).then(() => {
            });
            dispatch(getAllSessionsInactive(domainActive.id, offsetSessionInactive)).then(() => {
            });
            setIsLoadingSessions(false);
        }
    }, [dispatch, domainActive]);

    useEffect(() => {
        console.log('lista de la busqueda sesiones activas........');
        console.log(allSearchSessionsActive);
    }, [allSearchSessionsActive]);

    function handlePrevPaginationSessionsActive() {
        if (offsetSessionActive > 0 && domainActive) {
            const newOffset: number = offsetSessionActive - 1;
            console.log('este es el nuevo offset inactivo...........');
            console.log(newOffset);
            dispatch(updateOffsetSessionActive(newOffset));
            dispatch(getAllSessionsActive(domainActive.id, newOffset)).then(() => {
            });
        }
    }

    function handleNextPaginationSessionActive() {
        if (allSessionsActive.length === 5 && domainActive) {
            const newOffset: number = offsetSessionActive + 1;
            dispatch(updateOffsetSessionActive(newOffset));
            dispatch(getAllSessionsActive(domainActive.id, newOffset)).then(() => {
            });
        }
    }

    function handlePrevPaginationSessionsInactive() {
        if (offsetSessionInactive > 0 && domainActive) {
            const newOffset: number = offsetSessionInactive - 1;
            dispatch(updateOffsetSessionInactive(newOffset));
            dispatch(getAllSessionsInactive(domainActive.id, newOffset)).then(() => {
            });
        }
    }

    function handleNextPaginationSessionInactive() {
        if (allSessionsInactive.length === 5 && domainActive) {
            const newOffset: number = offsetSessionInactive + 1;
            dispatch(updateOffsetSessionInactive(newOffset));
            dispatch(getAllSessionsInactive(domainActive.id, newOffset)).then(() => {
            });
        }
    }

    function handleCloseSearch() {
        setShowActiveSessionsSearch(false);
        dispatch(cleanAllSearchSessionsActive());
    }
    function handleCloseSearchInactive() {
        setShowCompletedSessionsSearch(false);
        dispatch(cleanAllSearchSessionsInactive());
    }

    function handleSearchSessionActive(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (domainActive) {
            dispatch(getSearchSessionActive(domainActive.id, wordSearchActive)).then(() => {});
        }
    }

    function handleSearchSessionInactive(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (domainActive) {
            dispatch(getSearchSessionInactive(domainActive.id, wordSearchInactive)).then(() => {});
        }
    }

    function handleSetWordSearchActive(searchWord: string) {
        if (searchWord === '') {
            setWordSearchActive(searchWord);
            dispatch(cleanAllSearchSessionsActive());
        } else {
            setWordSearchActive(searchWord);
        }
    }

    function handleSetWordSearchInactive(searchWord: string) {
        if (searchWord === '') {
            setWordSearchInactive(searchWord);
            dispatch(cleanAllSearchSessionsInactive());
        } else {
            setWordSearchInactive(searchWord);
        }
    }

    return (
        <AppSidebarStyles>
            <div className="container-session-active">
                <div className="formal-session">
                    {showActiveSessionsSearch && (
                        <form onSubmit={(event) => handleSearchSessionActive(event)} className="container-search">
                            <input
                                type="search"
                                className="active-sessions-search"
                                placeholder="Buscar sesiones activas"
                                value={wordSearchActive}
                                onChange={(event) => handleSetWordSearchActive(event.target.value)}
                            />
                            {wordSearchActive !== '' &&
                                <button className="button-search" type="submit">
                                    {loadingAllSearchSessionsActive
                                        ?
                                        <LuLoader className="icon-loading"/>
                                        :
                                        <img
                                            className="icon-menu"
                                            alt="icon-menu"
                                            src="/img/newImage/optionsAdmin/iconsSidebarSession/icon-menu-sidebar.png"
                                            onClick={toggleActiveSessionsSearch}
                                        />
                                    }
                                </button>
                            }
                            {wordSearchActive === '' &&
                                <button
                                    onClick={handleCloseSearch}
                                    className="button-search"
                                        type="button"
                                >
                                    <img
                                        className="icon-menu"
                                        alt="icon-menu"
                                        src="/img/newImage/optionsAdmin/iconsSidebarSession/icon-menu-sidebar.png"
                                        onClick={toggleActiveSessionsSearch}
                                    />
                                </button>
                            }
                        </form>
                    )}
                    {!showActiveSessionsSearch &&
                        <div className="view-active-sessions">
                            <span className="formal-session-text">Sesiones</span>
                            <img
                                className="icon-menu"
                                alt="icon-menu"
                                src="/img/newImage/optionsAdmin/iconsSidebarSession/icon-menu-sidebar.png"
                                onClick={toggleActiveSessionsSearch}
                            />
                        </div>
                    }
                </div>
                {allSearchSessionsActive.length === 0
                    ?
                    <>
                        {!loadingAllSessionsActive && allSessionsActive && allSessionsActive.map((sessionActive) => (
                            <Session
                                id={sessionActive.id}
                                key={sessionActive.id}
                                imgSession={sessionActive.main_image}
                                sessionName={sessionActive.session_name}
                                sessionInfo={sessionActive.session_info}
                                amountPeople={sessionActive.users.length}
                                sessionEnded={false}
                            />
                        ))}
                    </>
                    :
                    <>
                        {allSearchSessionsActive && allSearchSessionsActive.map((sessionActive) => (
                            <Session
                                id={sessionActive.id}
                                key={sessionActive.id}
                                imgSession={sessionActive.main_image}
                                sessionName={sessionActive.session_name}
                                sessionInfo={sessionActive.session_info}
                                amountPeople={654}
                                sessionEnded={false}
                            />
                        ))}
                    </>
                }

                <div className="container-show-more">
                    <span
                        onClick={handlePrevPaginationSessionsActive}
                        className={offsetSessionActive === 0 ? "text-show-more text-show-more-inactive" : "text-show-more"}
                    >
                        Mostrar menos...
                    </span>
                    <span
                        onClick={handleNextPaginationSessionActive}
                        className={allSessionsActive.length < 5 ? "text-show-more text-show-more-inactive" : "text-show-more"}
                    >
                        Mostrar más...
                    </span>
                </div>
            </div>

            <div className="container-session-ended">
                <div className="formal-session">
                    {showCompletedSessionsSearch && (
                        <form onSubmit={(event) => handleSearchSessionInactive(event)} className="container-search">
                            <input
                                type="search"
                                className="active-sessions-search"
                                placeholder="Buscar sesiones activas"
                                value={wordSearchInactive}
                                onChange={(event) => handleSetWordSearchInactive(event.target.value)}
                            />
                            {wordSearchInactive !== '' &&
                                <button
                                    disabled={loadingAllSearchSessionsInactive}
                                    className="button-search"
                                    type="submit"
                                >
                                    {loadingAllSearchSessionsInactive
                                        ?
                                        <LuLoader className="icon-loading"/>
                                        :
                                        <img
                                            className="icon-menu"
                                            alt="icon-menu"
                                            src="/img/newImage/optionsAdmin/iconsSidebarSession/icon-menu-sidebar.png"
                                            onClick={toggleInactiveSessionsSearch}
                                        />
                                    }
                                </button>
                            }
                            {wordSearchInactive === '' &&
                                <button
                                    onClick={handleCloseSearchInactive}
                                    className="button-search"
                                    type="button"
                                >
                                    <img
                                        className="icon-menu"
                                        alt="icon-menu"
                                        src="/img/newImage/optionsAdmin/iconsSidebarSession/icon-menu-sidebar.png"
                                        onClick={toggleInactiveSessionsSearch}
                                    />
                                </button>
                            }
                        </form>
                    )}
                    {!showCompletedSessionsSearch &&
                        <div className="view-active-sessions">
                            <span className="formal-session-text">Sesiones Inactivas</span>
                            <img
                                className="icon-menu"
                                alt="icon-menu"
                                src="/img/newImage/optionsAdmin/iconsSidebarSession/icon-menu-sidebar.png"
                                onClick={toggleInactiveSessionsSearch}
                            />
                        </div>
                    }
                </div>
                {allSearchSessionsInactive.length === 0
                    ?
                    <>
                        {!loadingAllSessionsInactive && allSessionsInactive && allSessionsInactive.map((sessionInactive) => (
                            <Session
                                id={sessionInactive.id}
                                key={sessionInactive.id}
                                imgSession={sessionInactive.main_image}
                                sessionName={sessionInactive.session_name}
                                sessionInfo={sessionInactive.session_info}
                                amountPeople={654}
                                sessionEnded={true}
                            />
                        ))}
                    </>
                    :
                    <>
                        {allSearchSessionsInactive && allSearchSessionsInactive.map((sessionInactive) => (
                            <Session
                                id={sessionInactive.id}
                                key={sessionInactive.id}
                                imgSession={sessionInactive.main_image}
                                sessionName={sessionInactive.session_name}
                                sessionInfo={sessionInactive.session_info}
                                amountPeople={654}
                                sessionEnded={true}
                            />
                        ))}
                    </>
                }
                <div className="container-show-more">
                    <span
                        onClick={handlePrevPaginationSessionsInactive}
                        className={offsetSessionInactive === 0 ? "text-show-more text-show-more-inactive" : "text-show-more"}
                    >
                        Mostrar menos...
                    </span>
                    <span
                        onClick={handleNextPaginationSessionInactive}
                        className={allSessionsInactive.length < 5 ? "text-show-more text-show-more-inactive" : "text-show-more"}
                    >
                        Mostrar más...
                    </span>
                </div>
            </div>
            {showModalEditSession &&
                <div className="modal-create-session">
                    <EditSession/>
                </div>
            }
            {showModalUserInfo &&
                <div className="modal-user-info">
                    <SessionInfo/>
                </div>
            }
            {isLoadingSessions &&
                <Loading/>
            }
        </AppSidebarStyles>
    );
}
