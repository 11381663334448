import styled from "styled-components";
import {Device} from "../../../shared/Utils/devices";

export const HomeSessionsUserStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    width: 100%;

    .container-sessions-user {
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        align-items: center;
        justify-items: center;
        gap: 6vw;
        width: 90%;
        //height: 20vh;
        //background: #61dafb;
        box-sizing: border-box;
        margin-top: 2vw;
        margin-bottom: 10vw;
        padding: 0 1vw;

        .mobile-icon-back {
            position: absolute;
            top: 60%;
            left: -2vw;
            display: flex;
            cursor: pointer;
            color: #ffffff;
        }
        .icon-opacity {
            color: #ffffff60;
        }
        .mobile-icon-forward {
            position: absolute;
            top: 60%;
            right: -2vw;
            display: flex;
            cursor: pointer;
        }
    }
    .slick-slider {
        width: 80vw;
        margin: 3vw auto;
        height: 34vw;
    }
    .slick-track {
        display: flex;
        align-items: center;
        gap: .5vw;
        height: 33vw;
    }
    /* Estilo para los iconos de los botones (flechas) */
    .slick-prev:before, .slick-next:before {
        font-size: 2vw; /* Ajusta el tamaño del icono */
    }
    .slick-slide img {
        width: 100%;
        height: auto;
    }

    .modal-user-session-info {
        position: absolute;
        top: 3vw;
        right: 3vw;
        width: 14vw;
        height: 21vw;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 3px 4px 1px #0000004D;
        border-radius: 25px;
        opacity: 1;
        margin-bottom: 1vw;
        z-index: 999999999999999;
    }
    
    @media ${Device.desktop} {
        .slick-slider {
            margin: 3.9vw auto;
        }
    }
    
    @media ${Device.tablet} {
        height: 95%;

        .slick-slider {
            width: 80vw;
            margin: 5vw auto;
            height: 46vw;
        }

        .slick-track {
            display: flex;
            align-items: center;
            gap: .5vw;
            height: 50vw;
        }

        .slick-prev {
            left: -4.4vw;
        }

        .slick-next {
            right: -2vw;
        }

        /* Estilo para los iconos de los botones (flechas) */
        .slick-prev:before, .slick-next:before {
            font-size: 4vw; /* Ajusta el tamaño del icono */
        }
    }


    @media ${Device.mobileL} {

        .container-sessions-user {
            display: flex;
            overflow-x: auto; /* Permite desplazamiento horizontal si hay más elementos que el ancho del contenedor */
            white-space: nowrap; /* Evita que los elementos se dividan en varias líneas */
            padding: 0 1vw;
            margin-top: 11vw;
            position: relative;


            .mobile-icon-back {
                display: inline-block;
                position: absolute;
                left: -3vw;
                top: 16vh;
            }

            .mobile-icon-forward {
                display: inline-block;
                position: absolute;
                right: -3vw;
                top: 16vh;
            }
        }

        .container-sessions-user > * {
            flex: 0 0 auto;
            margin-right: 6vw;
        }

        .container-sessions-user > :last-child {
            margin-right: 0;
        }

        .slick-slider {
            width: 90vw;
            margin: 5vw auto;
            height: 85vw;
        }

        .slick-track {
            display: flex;
            align-items: center;
            gap: .5vw;
            height: 75vw;
        }
        /* Estilo para los iconos de los botones (flechas) */
        .slick-prev:before, .slick-next:before {
            font-size: 5vw; /* Ajusta el tamaño del icono */
        }

        .slick-slide img {
            width: 100%;
            height: auto;
        }

        .slick-prev {
            left: -2vw;
            z-index: 1;
        }

        .slick-next {
            right: -2vw;
            z-index: 1;
        }

        .modal-user-session-info {
            position: absolute;
            top: 16vw;
            right: 2vw;
            width: 57vw;
            height: 48vw;
        }
    }
    @media ${Device.mobileM} {

        .container-sessions-user {
            display: flex;
            overflow-x: auto; /* Permite desplazamiento horizontal si hay más elementos que el ancho del contenedor */
            white-space: nowrap; /* Evita que los elementos se dividan en varias líneas */
            padding: 0 1vw;
            margin-top: 11vw;
            position: relative;


            .mobile-icon-back {
                display: inline-block;
                position: absolute;
                left: -3vw;
                top: 16vh;
            }

            .mobile-icon-forward {
                display: inline-block;
                position: absolute;
                right: -3vw;
                top: 16vh;
            }
        }

        .container-sessions-user > * {
            flex: 0 0 auto;
            margin-right: 6vw;
        }

        .container-sessions-user > :last-child {
            margin-right: 0;
        }

        .slick-slider {
            width: 90vw;
            margin: 5vw auto;
            height: 85vw;
        }

        .slick-track {
            display: flex;
            align-items: center;
            gap: .5vw;
            height: 75vw;
        }
        /* Estilo para los iconos de los botones (flechas) */
        .slick-prev:before, .slick-next:before {
            font-size: 5vw; /* Ajusta el tamaño del icono */
        }

        .slick-slide img {
            width: 100%;
            height: auto;
        }

        .slick-prev {
            left: -2vw;
            z-index: 1;
        }

        .slick-next {
            right: -2vw;
            z-index: 1;
        }

        .modal-user-session-info {
            position: absolute;
            top: 16vw;
            right: 2vw;
            width: 57vw;
            height: 48vw;
        }
    }
    @media ${Device.mobileS} {

        .container-sessions-user {
            display: flex;
            overflow-x: auto; /* Permite desplazamiento horizontal si hay más elementos que el ancho del contenedor */
            white-space: nowrap; /* Evita que los elementos se dividan en varias líneas */
            padding: 0 1vw;
            margin-top: 11vw;
            position: relative;


            .mobile-icon-back {
                display: inline-block;
                position: absolute;
                left: -3vw;
                top: 16vh;
            }

            .mobile-icon-forward {
                display: inline-block;
                position: absolute;
                right: -3vw;
                top: 16vh;
            }
        }

        .container-sessions-user > * {
            flex: 0 0 auto;
            margin-right: 6vw;
        }

        .container-sessions-user > :last-child {
            margin-right: 0;
        }

        .slick-slider {
            width: 90vw;
            margin: 5vw auto;
            height: 85vw;
        }

        .slick-track {
            display: flex;
            align-items: center;
            gap: .5vw;
            height: 75vw;
        }
        /* Estilo para los iconos de los botones (flechas) */
        .slick-prev:before, .slick-next:before {
            font-size: 5vw; /* Ajusta el tamaño del icono */
        }

        .slick-slide img {
            width: 100%;
            height: auto;
        }

        .slick-prev {
            left: -2vw;
            z-index: 1;
        }

        .slick-next {
            right: -2vw;
            z-index: 1;
        }

        .modal-user-session-info {
            position: absolute;
            top: 16vw;
            right: 2vw;
            width: 57vw;
            height: 48vw;
        }
    }
`
