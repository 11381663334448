import styled from "styled-components";
import {Device} from "../../../shared/Utils/devices";

interface IHomeStyledProps {
    background_game: string;
}

export const HomeStyled = styled.div<IHomeStyledProps>`
    width: 100%;
    height: 100vh;
    padding-top: 3vw;
    // background-image: ${({background_game}) => `url(${background_game})`};
    // background-repeat: no-repeat;
    // background-position: center;
    background: #FFE800;
    background-size: cover;
    box-sizing: border-box;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: 1vw; /* Ancho del scrollbar */
    }

    &::-webkit-scrollbar-track {
        background-color: #CCCFDE;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #ffffff;
        border-radius: 5px;
    }

    @media ${Device.desktopL} {

    }

    @media ${Device.desktop} {

    }
    @media ${Device.mobileL} {
        margin-top: 0;
        padding-top: 0;
    }
    @media ${Device.mobileM} {
        margin-top: 0;
        padding-top: 0;
    }
    @media ${Device.mobileS} {
        margin-top: 0;
        padding-top: 0;
    }
`
