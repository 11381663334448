import {AdminHomeStyles} from "./AdminHomeStyles";
import {AppHeader} from "../../Components/Header/AppHeader";
import Content from "../../Components/Content/Content";
import {useAppDispatch, useAppSelector} from "../../../shared/Utils/Hooks";
import {useEffect} from "react";
import {getAllSessionsActive, getAllSessionsInactive} from "../../../store/ActionsAdmin/SessionActions";
import AppSidebar from "../../Components/AppSidebar/AppSidebar";


export default function AdminHome(): JSX.Element {

    const dispatch = useAppDispatch();
    const domainActive = useAppSelector((state) => state.accountState.domainActive);
    const loadingSingleSession = useAppSelector((state)=>state.sessionState.loadingSingleSession);
    const loadingAllBingos = useAppSelector((state)=>state.bingoState.loadingAllBingos);
    const offsetSessionInactive = useAppSelector((state) => state.sessionState.offsetSessionInactive);
    const offsetSessionActive = useAppSelector((state) => state.sessionState.offsetSessionActive);

    useEffect(()=>{
        console.log(loadingSingleSession);
        console.log(loadingAllBingos);
    },[loadingSingleSession, loadingAllBingos]);

    useEffect(() => {
        if (domainActive) {
            console.log(domainActive.id);
            dispatch(getAllSessionsActive(domainActive.id, offsetSessionActive)).then(() => {});
            dispatch(getAllSessionsInactive(domainActive.id, offsetSessionInactive)).then(() => {});
        }
    }, [dispatch, domainActive]);
    return (
        <AdminHomeStyles>

            <AppHeader/>

            <AppSidebar/>

            <Content/>

        </AdminHomeStyles>
    );
}
