import {CreateUserStyles} from "./CreateUserStyles";
import {useForm} from "react-hook-form";
import {useAppDispatch, useAppSelector} from "../../../shared/Utils/Hooks";
import {useRef} from "react";
import {
    openAndCloseModalCreateUser
} from "../../../store/ActionsAdmin/ModalsActions";
import {CreateUserModel} from "../../../domain/models/CreateUserModel";
import {createUserLocal, createUsers} from "../../../store/ActionsAdmin/UserActions";
import {
    identificationDocumentUserValidate,
    numberPhoneValidate,
    userEmailValidate, userGameModeValidate,
    userNameValidate
} from "../../../shared/Utils/ValidationFormCreateUser";
import {getAccountActive} from "../../../store/ActionsAdmin/AccountActions";

export default function CreateUser(): JSX.Element {

    const domain = window.location.origin;
    const dispatch = useAppDispatch();
    const {register, handleSubmit, reset, formState: {errors}} = useForm<CreateUserModel>();
    const isLoadingCreateUser = useAppSelector((state) => state.userState.isLoadingCreateUser);
    const domainActive = useAppSelector((state) => state.accountState.domainActive);

    function handleCreateUser(data: CreateUserModel) {
        console.log(data);
        if (domainActive) {
            const dataUser: CreateUserModel = {
                name: data.name,
                email: data.email,
                identification_document: data.identification_document,
                phone: data.phone,
                user_type: data.user_type,
                accountId: domainActive.id
            }
            dispatch(createUserLocal(dataUser)).then(() => {
                dispatch(getAccountActive({domain: domain})).then(() => {
                    reset();
                    dispatch(openAndCloseModalCreateUser(false));
                });
            });
        }
    }

    const onSubmit = (data: CreateUserModel) => handleCreateUser(data);

    function handleCloseModal() {
        dispatch(openAndCloseModalCreateUser(false));
    }

    return (
        <CreateUserStyles>
            <div className="container-title">
                <span className="title">Nuevo usuario</span>
                <span onClick={handleCloseModal} className="close">x</span>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="form">
                <div className="container-img-inputs">
                    <div className="container-inputs">
                        <div className="container-input">
                            <input
                                {...register('name', {
                                    validate: userNameValidate
                                })}
                                className="input-session-name"
                                type="text"
                                placeholder="Nombre del usuario"
                            />
                            {errors.name?.type === 'validate' && <span className='error'>{errors.name.message}</span>}
                        </div>
                        <div className="container-input">
                            <input
                                {...register('email', {
                                    validate: userEmailValidate
                                })}
                                className="input-session-name"
                                type="email"
                                placeholder="Correo electrónico"
                            />
                            {errors.email?.type === 'validate' && <span className='error'>{errors.email.message}</span>}
                        </div>
                        <div className="container-input">
                            <input
                                {...register('identification_document', {
                                    validate: identificationDocumentUserValidate
                                })}
                                className="input-session-name"
                                type="text"
                                placeholder="Cédula de ciudadanía"
                            />
                            {errors.identification_document?.type === 'validate' && <span className='error'>{errors.identification_document.message}</span>}
                        </div>
                        <div className="container-input">
                            <input
                                {...register('phone', {
                                    validate: numberPhoneValidate
                                })}
                                className="input-session-name"
                                type="text"
                                placeholder="Número de celular"
                                maxLength={10}
                            />
                            {errors.phone?.type === 'validate' && <span className='error'>{errors.phone.message}</span>}
                        </div>
                        <div className="container-input">
                            <select
                                {...register('user_type', {
                                    validate: userGameModeValidate
                                })}
                                className="mode-game"
                            >
                                <option value=''>Tipo Usuario</option>
                                <option value="user">Juagador</option>
                                <option value="admin">Administrador</option>
                            </select>
                            {errors.user_type?.type === 'validate' && <span className='error'>{errors.user_type.message}</span>}
                        </div>
                    </div>
                </div>
                <div className="container-button">
                    <button
                        disabled={isLoadingCreateUser}
                        type="submit"
                        className="button-form"
                    >
                        {!isLoadingCreateUser ? 'Crear usuario' : 'Creando...'}
                    </button>
                </div>
            </form>
        </CreateUserStyles>
    );
}
