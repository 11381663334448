export const GET_SINGLE_SESSION_CONFIG = 'GET_SINGLE_SESSION_CONFIG';
export const GET_SINGLE_SESSION_CONFIG_SUCCESS = 'GET_SINGLE_SESSION_CONFIG_SUCCESS';
export const GET_SINGLE_SESSION_CONFIG_FAILED = 'GET_SINGLE_SESSION_CONFIG_FAILED';
export const EDIT_SESSION_CONFIG_INFORMATION = 'EDIT_SESSION_CONFIG_INFORMATION';
export const EDIT_SESSION_CONFIG_INFORMATION_SUCCESS = 'EDIT_SESSION_CONFIG_INFORMATION_SUCCESS';
export const EDIT_SESSION_CONFIG_INFORMATION_FAILED = 'EDIT_SESSION_CONFIG_INFORMATION_FAILED';
export const EDIT_SESSION_CONFIG_SPONSORS = 'EDIT_SESSION_CONFIG_SPONSORS';
export const EDIT_SESSION_CONFIG_SPONSORS_SUCCESS = 'EDIT_SESSION_CONFIG_SPONSORS_SUCCESS';
export const EDIT_SESSION_CONFIG_SPONSORS_FAILED = 'EDIT_SESSION_CONFIG_SPONSORS_FAILED';
export const EDIT_SESSION_CONFIG_AWARDS = 'EDIT_SESSION_CONFIG_AWARDS';
export const EDIT_SESSION_CONFIG_AWARDS_SUCCESS = 'EDIT_SESSION_CONFIG_AWARDS_SUCCESS';
export const EDIT_SESSION_CONFIG_AWARDS_FAILED = 'EDIT_SESSION_CONFIG_AWARDS_FAILED';
export const EDIT_SESSION_CONFIG_ACQUIRE_CARDS = 'EDIT_SESSION_CONFIG_ACQUIRE_CARDS';
export const EDIT_SESSION_CONFIG_ACQUIRE_CARDS_SUCCESS = 'EDIT_SESSION_CONFIG_ACQUIRE_CARDS_SUCCESS';
export const EDIT_SESSION_CONFIG_ACQUIRE_CARDS_FAILED = 'EDIT_SESSION_CONFIG_ACQUIRE_CARDS_FAILED';