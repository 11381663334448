import {AddBulkUserInCreateSessionStyles} from "./AddBulkUserInCreateSessionStyles";
import {useForm} from "react-hook-form";
import {useAppDispatch, useAppSelector} from "../../../shared/Utils/Hooks";
import {CreateUserModel} from "../../../domain/models/CreateUserModel";
import {
    openAndCloseModalAddBulkUsers,
    openAndCloseModalAddBulkUsersInCreateSession
} from "../../../store/ActionsAdmin/ModalsActions";
import {useRef, useState} from "react";
import * as XLSX from 'xlsx';
import {BsHandThumbsUp} from "react-icons/bs";
import {FaUsers} from "react-icons/fa";

interface IBulkUsers {
    name: string;
    email: string;
    identification_document: string;
    phone: string;
}

export default function AddBulkUserInCreateSession(): JSX.Element {

    const domain = window.location.origin;
    const dispatch = useAppDispatch();
    const isLoadingCreateUser = useAppSelector((state) => state.userState.isLoadingCreateUser);
    const domainActive = useAppSelector((state) => state.accountState.domainActive);

    const fileExcelBulkUsers = useRef<HTMLInputElement | null>(null);
    const [fileExcelData, setFileExcelData] = useState<CreateUserModel[]>([]);

    function handleOpenModalAddBulkUserInCreateSession() {
        dispatch(openAndCloseModalAddBulkUsersInCreateSession(false));
    }

    function handleClickInputFile() {
        if (fileExcelBulkUsers.current) {
            fileExcelBulkUsers.current?.click();
        }
    }

    function handleFileExcel(e: any) {
        const fileExcel = e.target.files[0];
        // console.log(fileExcel);
        if (fileExcel !== null && domainActive) {
            const fileExcelReader = new FileReader();
            // console.log(fileExcelReader);
            fileExcelReader.onload = (e: any) => {
                // console.log(e.target);
                if (e.target) {
                    const excelData = e.target.result;
                    const workbook = XLSX.read(excelData, {type: "binary"});
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];
                    const excelDataListObj: IBulkUsers[] = XLSX.utils.sheet_to_json(sheet);
                    const user_type = 'user';
                    const accountId = domainActive.id;
                    const newListBulkUsers: CreateUserModel[] = excelDataListObj.map((user) => ({...user, user_type, accountId}));
                    console.log(newListBulkUsers);
                    setFileExcelData(newListBulkUsers);
                }
            }
            fileExcelReader.readAsBinaryString(fileExcel);
        }
    }

    function handleCreateBulkUsers() {
        console.log(fileExcelData);
    }

    return (
        <AddBulkUserInCreateSessionStyles>
            <div className="container-button-close">
                <span onClick={handleOpenModalAddBulkUserInCreateSession} className="close">x</span>
            </div>
            <div className="container-title">
                <span className="title">Agregar usuarios masivos</span>
            </div>
            <form className="form-add-bulk-users">
                <input
                    ref={fileExcelBulkUsers}
                    type="file"
                    data-max-size="2"
                    accept=".xls, .xlsx"
                    className="input-img-small"
                    onChange={handleFileExcel}
                />
                <div className="circle-img" onClick={handleClickInputFile}>
                    {fileExcelData.length === 0 &&
                        <FaUsers className="options-admin-add-image"/>
                    }
                    {fileExcelData.length > 0 &&
                        <BsHandThumbsUp className="options-admin-add-image"/>
                    }
                </div>
                {fileExcelData.length === 0 &&
                    <span className="info-add">Email, teléfono y número de celular únicos</span>
                }
                {fileExcelData.length > 0 &&
                    <span className="info-add">Lista de usuarios cargada con éxito</span>
                }
                <button onClick={handleCreateBulkUsers} type="button" className="button-form">
                    Crear Usuarios
                </button>
            </form>
        </AddBulkUserInCreateSessionStyles>
    );
}
