import {LaunchBallotModel} from "../domain/models/LaunchBallotModel";
import {myDBDomain} from "./Utils/TestingApi/MyDBDomain";
import axios from "axios";

async function updateLaunchBallot(data_launch: LaunchBallotModel) {
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/game/launch-ballot`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data_launch
    };
    try {
        const response = await axios.request(config);
        const launch = response.data.data;
        console.log(launch);
        if (launch.status === 200) {
            if (launch.response.is_finalized) {
                return 'finalized';
            } else {
                return true;
            }
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export async function resolveLaunchBallotData(data_launch: LaunchBallotModel) {
    const resLaunchBallot = await updateLaunchBallot(data_launch);
    if (resLaunchBallot === false) {
        while (true) {
            const confirmLaunchBallot = await updateLaunchBallot(data_launch);
            if (confirmLaunchBallot === true) {
                return true;
            }
            if (confirmLaunchBallot === 'finalized') {
                return 'finalized';
            }
        }
    }
    if (resLaunchBallot === true) {
        return true;
    }
    return 'finalized';
}