import {QuillShowSessionStyled} from "./QuillShowSessionStyled";
import {SetStateAction, useEffect, useState} from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {format} from "date-fns";

interface ShowSessionGamesProps {
    value: string;
    loading: boolean;
    handleChange: (newValue: string) => void;
    onClickButton: () => void;
}

export default function ShowSessionGames({
                                             value,
                                             loading,
                                             handleChange,
                                             onClickButton
                                         }: ShowSessionGamesProps): JSX.Element {

    const [confirmed, setConfirmed] = useState(false);
    const [isEditing, setIsEditing] = useState(false);


    const modules = {
        toolbar: [
            [{header: [1, 2, 3, 4, 5, 6, false]}],

            ["bold", "italic", "underline", "strike", "blockquote"],
            [
                {list: "ordered"},
                {list: "bullet"},
                {indent: "-1"},
                {indent: "+1"},
            ],
            ["link", "image",],
            ["clean"],
        ],
    };

    useEffect(() => {
        console.log(value);
    }, [value]);

    return (
        <QuillShowSessionStyled>
            <div className="quill-input">
                <ReactQuill
                    theme="snow"
                    defaultValue={value}
                    onChange={handleChange}
                    className="editor-input"
                    modules={modules}
                    readOnly={isEditing}
                />
            </div>
            {confirmed ? (
                <div className="preview" dangerouslySetInnerHTML={{__html: value}}>
                    {/*Contenido confirmado*/}
                </div>
            ) : (
                <button
                    disabled={loading}
                    className="mi-boton"
                    onClick={onClickButton}
                >
                    {!loading ? 'Confirmar' : 'Confirmando...'}
                </button>
            )}
            <div className="preview" dangerouslySetInnerHTML={{__html: value}}>

            </div>
        </QuillShowSessionStyled>
    );
}
