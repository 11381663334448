import styled from "styled-components";
import {Device} from "../../../shared/Utils/devices";


export const LoadingStyles = styled.div`
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        /* Define la rotación inicial */
        100% {
            transform: rotate(360deg);
        }
        /* Define la rotación final */
    }

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    z-index: 5;
    position: fixed;
    top: 0;
    left: 0;
    background: #A0A0A090;

    .icon-loading {
        width: 4vw;
        animation: spin 4s linear infinite;
    }

    @media ${Device.tablet} {

        .icon-loading {
            width: 15vw;
        }

    }
    @media ${Device.mobileL} {

        .icon-loading {
            width: 15vw;
        }

    }
    @media ${Device.mobileM} {

        .icon-loading {
            width: 15vw;
        }

    }
    @media ${Device.mobileS} {

        .icon-loading {
            width: 15vw;
        }
    }




`
