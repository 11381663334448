import {SessionStyles} from "./SessionStyles";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../shared/Utils/Hooks";
import {
    closeCreateSession,
    closeEditSession,
    openAdnCloseModalCreateGame,
    openAndCloseModalCreateUser,
    openAndCloseModalEditGame,
    openAndCloseModalLaunch_Ballot_Manual,
    openAndCloseModalSignIn,
    openAndCloseModalUsersSession,
    openEditSession,
    openUserInfo
} from "../../../store/ActionsAdmin/ModalsActions";
import {
    changeSessionSelected,
    deleteSession, getAllSessionsActive, getAllSessionsInactive,
    getSingleSessionDatabase, updateOffsetSessionActive, updateOffsetSessionInactive,
} from "../../../store/ActionsAdmin/SessionActions";
import {changeOptionNav} from "../../../store/ActionsAdmin/SelectedOptionActions";
import Loading from "../Loading/Loading";
import {MdOutlineDelete} from "react-icons/md";
import {alertConfirmDelete} from "../../../shared/Utils/AlertConfirmDelete";
import {getCardSCountBySessionId} from "../../../store/ActionsAdmin/CardActions";
import {removeItemLocalStorage} from "../../../shared/Utils/RemoveItemLocalStorage";
import {cleanSingleFinishedGame} from "../../../store/ActionsAdmin/FinishedGameActions";
import {myDBDomain} from "../../../shared/Utils/TestingApi/MyDBDomain";

interface ISessionProps {
    imgSession: string;
    sessionName: string;
    sessionInfo: string;
    amountPeople: number;
    sessionEnded: boolean;
    id: number;
}

export default function Session({
                                    imgSession,
                                    sessionName,
                                    sessionInfo,
                                    amountPeople,
                                    sessionEnded,
                                    id,
                                }: ISessionProps): JSX.Element {

    const domainActive = useAppSelector((state) => state.accountState.domainActive);
    const history = useHistory();
    const dispatch = useAppDispatch();
    const sessionSelected = useAppSelector((state) => state.sessionState.sessionSelected);
    const showModalEditSession = useAppSelector((state) => state.modalState.showModalEditSession);
    const singleSessionDatabase = useAppSelector((state) => state.sessionState.singleSessionDatabase);
    const [loadingSingleSession, setLoadingSingleSession] = useState<boolean>(false);

    function handleSessionSelected(id: number) {
        setLoadingSingleSession(true);
        dispatch(getSingleSessionDatabase(id)).then(() => {
            dispatch(getCardSCountBySessionId(id)).then(() => {
                dispatch(changeSessionSelected(id));
                dispatch(changeOptionNav('games'));
                history.push(`/admin/session/${id}/games`);
                dispatch(openAdnCloseModalCreateGame(false));
                dispatch(openAndCloseModalEditGame(false));
                dispatch(openAndCloseModalUsersSession(false));
                dispatch(closeCreateSession());
                dispatch(openAndCloseModalCreateUser(false));
                dispatch(openUserInfo(false));
                dispatch(cleanSingleFinishedGame());
                setLoadingSingleSession(false);
                if (showModalEditSession) {
                    dispatch(closeEditSession());
                }
                dispatch(openAndCloseModalLaunch_Ballot_Manual(''));
            });
        });
    }

    function handleOpenModalEditSession(sessionEnded: boolean) {
        console.log('click de editar');
        if (!sessionEnded) {
            dispatch(openEditSession());
        }
    }

    useEffect(() => {
        console.log(sessionSelected);
    }, [sessionSelected]);

    async function handleDelete(id: number) {
        console.log('hice click en delete');
        console.log(id);
        const confirmRes = await alertConfirmDelete();
        console.log(confirmRes);
        if (confirmRes && domainActive && singleSessionDatabase) {
            setLoadingSingleSession(true);
            dispatch(deleteSession(id)).then(() => {
                dispatch(updateOffsetSessionActive(0));
                dispatch(updateOffsetSessionInactive(0));
                dispatch(getAllSessionsInactive(domainActive.id, 0)).then(() => {});
                dispatch(getAllSessionsActive(domainActive.id, 0)).then(() => {});
                dispatch(changeSessionSelected(undefined));
                dispatch(closeCreateSession());
                dispatch(closeEditSession());
                dispatch(openAndCloseModalEditGame(false));
                dispatch(openUserInfo(false));
                dispatch(openAdnCloseModalCreateGame(false));
                dispatch(openAndCloseModalCreateUser(false));
                singleSessionDatabase.games.map((game) => {
                    removeItemLocalStorage(`game${game.id}`);
                    removeItemLocalStorage(`winners${game.id}`);
                });
                history.push('/admin/options');
                setLoadingSingleSession(false);
            });
        }
    }

    return (
        <SessionStyles onClick={() => handleSessionSelected(id)}>
            <div className={sessionSelected === id ? "container active" : "container"}>
                <img src={imgSession} alt="Imagen de usuario" className="user-image"/>
                <div className="container-info-session">
                    <div className="container-name-edit">
                        {sessionSelected === id ?
                            <span
                                className={!sessionEnded ? "title-session white" : "title-session white"}>{sessionName}</span> :
                            <span
                                className={!sessionEnded ? "title-session" : "title-session ended"}>{sessionName}</span>
                        }
                        <div className="container-icons-sidebar">
                                {sessionSelected === id ?
                                    <div onClick={() => handleDelete(id)}>
                                        <MdOutlineDelete  className={!sessionEnded ? "icon-delete white" : "icon-delete white"} />
                                    </div> :
                                    <div onClick={() => handleDelete(id)}>
                                        <MdOutlineDelete className={!sessionEnded ?  "icon-delete " : "icon-delete ended"}  />
                                    </div>
                                }
                            {sessionSelected === id ?
                                <div
                                    className="container-edit"
                                    onClick={() => handleOpenModalEditSession(sessionEnded)}
                                    // onClick={() => handleDeleteSession(id)}
                                >
                                    <img
                                        className={!sessionEnded ? "options-admin-img-edit-2 white" : "options-admin-img-edit-2 ended"}
                                        alt="optionsAdminImgEdit2"
                                        src="/img/newImage/optionsAdmin/pruevaEdit2.svg"
                                    />
                                    <img
                                        className="options-admin-img-edit-1 white"
                                        alt="optionsAdminImgEdit1"
                                        src="/img/newImage/optionsAdmin/pruebaEdit1.svg"
                                    />
                                </div> :
                                <div className="container-edit"
                                     onClick={() => handleOpenModalEditSession(sessionEnded)}>
                                    <img
                                        className={!sessionEnded ? "options-admin-img-edit-2" : "options-admin-img-edit-2 ended"}
                                        alt="optionsAdminImgEdit2"
                                        src="/img/newImage/optionsAdmin/optionsAdminImgEdit2.svg"
                                    />
                                    <img
                                        className="options-admin-img-edit-1"
                                        alt="optionsAdminImgEdit1"
                                        src="/img/newImage/optionsAdmin/optionsAdminImgEdit1.svg"
                                    />
                                </div>
                            }
                        </div>

                    </div>
                    <div className="info-session">
                        {sessionSelected === id ?
                            <span
                                className={!sessionEnded ? "info-text white" : "info-text white"}>{sessionInfo}</span> :
                            <span className={!sessionEnded ? "info-text" : "info-text ended"}>{sessionInfo}</span>
                        }

                        <div className="container-icon-people">
                            {sessionSelected === id ?
                                <img
                                    src="/img/newImage/optionsAdmin/acount-player-white.svg"
                                    alt="people-play"
                                    className={!sessionEnded ? "people-play white" : "people-play ended"}
                                /> :
                                <img
                                    src="/img/newImage/optionsAdmin/acount-player.svg"
                                    alt="people-play"
                                    className={!sessionEnded ? "people-play" : "people-play ended"}
                                />
                            }
                            {sessionSelected === id ?
                                <span
                                    className={!sessionEnded ? "count-people white" : "count-people white"}>{amountPeople}</span> :
                                <span
                                    className={!sessionEnded ? "count-people" : "count-people ended"}>{amountPeople}</span>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {loadingSingleSession &&
                <Loading/>
            }
        </SessionStyles>
    );
}
