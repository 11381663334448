import {ConfigurationThemeFormStyles} from "./ConfigurationThemeFormStyles"
import React, {useEffect, useRef, useState} from "react";
import {useThemeContext} from "../../../shared/Hooks/ThemeContextProvider";
import {ThemeDefaultModel} from "../../../domain/models/ThemeDefaultModel";
import {getLocalStorageObj} from "../../../shared/Utils/GetLocalStorageObj";
import {uploadFile} from "../../../../../Firebase/Firebase";
import {useAppDispatch, useAppSelector} from "../../../shared/Utils/Hooks";
import {validateImgLogoConf} from "../../../shared/Utils/ValidateImgLogoConf";
import {ConfigurationThemeFormModel} from "../../../domain/models/ConfigurationThemeFormModel";
import {editAccountConfig} from "../../../store/ActionsAdmin/AccountConfigActions";
import {getAccountActive} from "../../../store/ActionsAdmin/AccountActions";

interface IConfigurationThemeFormProps {
    setCreatedTheme: (action: boolean) => void;
}

export default function ConfigurationThemeForm({setCreatedTheme}: IConfigurationThemeFormProps): JSX.Element {

    const dispatch = useAppDispatch();
    const domainActive = useAppSelector((state) => state.accountState.domainActive);
    const domain = window.location.origin;
    const themeActive = useThemeContext();
    const themeSelect: ThemeDefaultModel | null = getLocalStorageObj('theme');
    const fileInputLogoPage = useRef<HTMLInputElement | null>(null);
    const fileIconLogo = useRef<HTMLInputElement | null>(null);
    const fileBackgroundGame = useRef<HTMLInputElement | null>(null);
    const fileBackgroundInfoGame = useRef<HTMLInputElement | null>(null);
    const fileBackgroundWinnerGame = useRef<HTMLInputElement | null>(null);
    const [imgLogo, setImgLogo] = useState<string>(domainActive?.account_config ? domainActive.account_config.logo: '');
    const [loginColor, setLoginColor] = useState<string>(domainActive?.account_config ? domainActive.account_config.background_login: '');
    const [buttonColor, setButtonColor] = useState<string>(domainActive?.account_config ? domainActive.account_config.button_color: '');
    const [buttonShadow, setButtonShadow] = useState<string>(domainActive?.account_config ? domainActive.account_config.button_color_shadow: '');
    const [iconCenterLogin, setIconCenterLogin] = useState<string>(domainActive?.account_config ? domainActive.account_config.icon_center_login: '')
    const [iconBackgroundGame, setIconBackgroundGame] = useState<string>(domainActive?.account_config ? domainActive.account_config.background_game: '');
    const [iconBackgroundInfo, setIconBackgroundInfo] = useState<string>(domainActive?.account_config ? domainActive.account_config.background_info_game: '');
    const [iconBackgroundWinners, setIconBackgroundWinners] = useState<string>(domainActive?.account_config ? domainActive.account_config.background_winners_game: '');
    const [errors, setErrors] = useState<string>('');

    function handleClickFileLogo() {
        if (fileInputLogoPage.current) {
            fileInputLogoPage.current?.click();
        }
    }
    function handleClickIconLogo() {
        if (fileIconLogo.current) {
            fileIconLogo.current?.click();
        }
    }
    function handleClickFileBackgroundGame() {
        if (fileBackgroundGame.current) {
            fileBackgroundGame.current?.click();
        }
    }
    function handleClickFileBackgroundInfoGame() {
        if (fileBackgroundInfoGame.current) {
            fileBackgroundInfoGame.current?.click();
        }
    }
    function handleClickFileBackgroundWinnerGame() {
        if (fileBackgroundWinnerGame.current) {
            fileBackgroundWinnerGame.current?.click();
        }
    }

    useEffect(() => {
        console.log(themeActive);
        console.log(themeSelect);
    }, [themeActive, themeSelect]);

    async function handleImageLogo(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.files) {
            console.log(event.target.files[0]);
            const file = event.target.files[0];
            const fileName = `logo_${domain}`
            const fileFirebaseUrl = await uploadFile(file, fileName);
            console.error(fileFirebaseUrl);
            setImgLogo(fileFirebaseUrl);
        }
    }
    async function handleIconCenterLogin(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.files) {
            console.log(event.target.files[0]);
            const file = event.target.files[0];
            const fileName = `icon_center_login_${domain}`
            const fileFirebaseUrl = await uploadFile(file, fileName);
            console.error(fileFirebaseUrl);
            setIconCenterLogin(fileFirebaseUrl);
        }
    }
    async function handleIconBackgroundGame(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.files) {
            console.log(event.target.files[0]);
            const file = event.target.files[0];
            const fileName = `icon_background_game_${domain}`
            const fileFirebaseUrl = await uploadFile(file, fileName);
            console.error(fileFirebaseUrl);
            setIconBackgroundGame(fileFirebaseUrl);
        }
    }
    async function handleIconBackgroundInfo(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.files) {
            console.log(event.target.files[0]);
            const file = event.target.files[0];
            const fileName = `icon_background_info_${domain}`
            const fileFirebaseUrl = await uploadFile(file, fileName);
            console.error(fileFirebaseUrl);
            setIconBackgroundInfo(fileFirebaseUrl);
        }
    }
    async function handleIconBackgroundWinners(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.files) {
            console.log(event.target.files[0]);
            const file = event.target.files[0];
            const fileName = `icon_background_winners_${domain}`
            const fileFirebaseUrl = await uploadFile(file, fileName);
            console.error(fileFirebaseUrl);
            setIconBackgroundWinners(fileFirebaseUrl);
        }
    }

    function handleEditSessionConfig(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const err = validateImgLogoConf(imgLogo, loginColor, buttonColor, buttonShadow, iconCenterLogin, iconBackgroundGame, iconBackgroundInfo, iconBackgroundWinners);
        if (err !== '') {
            setErrors(err);
            return
        }
        const dataAccountConfigEdit: ConfigurationThemeFormModel = {
            logo: imgLogo,
            background_login: loginColor,
            button_color: buttonColor,
            button_color_shadow: buttonShadow,
            icon_center_login: iconCenterLogin,
            background_game: iconBackgroundGame,
            background_info_game: iconBackgroundInfo,
            background_winners_game: iconBackgroundWinners
        }
        if (domainActive) {
            dispatch(editAccountConfig(dataAccountConfigEdit, domainActive.account_config_id)).then(() => {
                dispatch(getAccountActive({domain:domain})).then(() => {
                    setErrors('');
                    setCreatedTheme(false);
                });
            });
        }
    }

    return (
        <ConfigurationThemeFormStyles
            buttonColor={themeActive.theme ? themeActive.theme.button_color : ''}
            buttonColorShadow={themeActive.theme ? themeActive.theme.button_color_shadow : ''}
        >
            <form onSubmit={(event) => handleEditSessionConfig(event)}>
                <div className="container-banner-img">
                    <div className="banner-img">
                        <input
                            ref={fileInputLogoPage}
                            type="file"
                            data-max-size="2"
                            accept="image/*"
                            className="input-img-banner"
                            onChange={(event) => handleImageLogo(event)}
                        />

                        <img
                            onClick={handleClickFileLogo}
                            className={imgLogo === '' ? "options-admin-add-image-banner" : "options-admin-add-image-banner-show"}
                            alt="optionsAdminAddImage"
                            src={imgLogo === '' ? "/img/newImage/optionsAdmin/optionsAdminAddImage.png" : imgLogo}
                        />

                    </div>
                </div>
                <div className="container-text-info-input-banner">
                    <span className="info-add">Agreaga aquí la imágen del logo</span>
                </div>
                <div className="container-input">
                    <label>Color del login</label>
                    <input
                        defaultValue={loginColor}
                        type="text"
                        placeholder="Ejemplo: transparent linear-gradient(8deg, #2C2F4F 0%, #393D60 100%) 0% 0% no-repeat padding-box"
                        onChange={(event) => setLoginColor(event.target.value)}
                    />
                </div>
                <div className="container-input">
                    <label>Color de los botones</label>
                    <input
                        defaultValue={buttonColor}
                        type="text"
                        placeholder="Ejemplo: transparent linear-gradient(90deg, #9545FF 0%, #C491FC 100%) 0% 0% no-repeat padding-box"
                        onChange={(event) => setButtonColor(event.target.value)}
                    />
                </div>
                <div className="container-input">
                    <label>Sombras</label>
                    <input
                        defaultValue={buttonShadow}
                        type="text"
                        placeholder="Ejemplo: 0px 0px 10px rgba(149, 69, 255, 0.7), 0px 0px 20px rgba(196, 145, 252, 0.7)"
                        onChange={(event) => setButtonShadow(event.target.value)}
                    />
                </div>
                <div className="container-banner-img">
                    <div className="banner-img">
                        <input
                            ref={fileIconLogo}
                            type="file"
                            data-max-size="2"
                            accept="image/*"
                            className="input-img-banner"
                            onChange={(event) => handleIconCenterLogin(event)}
                        />
                        <img
                            onClick={handleClickIconLogo}
                            className={iconCenterLogin === '' ? "options-admin-add-image-banner" : "options-admin-add-image-banner-show"}
                            alt="optionsAdminAddImage"
                            src={iconCenterLogin === '' ? "/img/newImage/optionsAdmin/optionsAdminAddImage.png" : iconCenterLogin}
                        />
                    </div>
                </div>
                <div className="container-text-info-input-banner">
                    <span className="info-add">Icono central del login</span>
                </div>
                <div className="container-banner-img">
                    <div className="banner-img">
                        <input
                            ref={fileBackgroundGame}
                            type="file"
                            data-max-size="2"
                            accept="image/*"
                            className="input-img-banner"
                            onChange={(event) => handleIconBackgroundGame(event)}
                        />

                        <img
                            onClick={handleClickFileBackgroundGame}
                            className={iconBackgroundGame === '' ? "options-admin-add-image-banner" : "options-admin-add-image-banner-show"}
                            alt="optionsAdminAddImage"
                            src={iconBackgroundGame === '' ? "/img/newImage/optionsAdmin/optionsAdminAddImage.png" : iconBackgroundGame}
                        />

                    </div>
                </div>
                <div className="container-text-info-input-banner">
                    <span className="info-add">Fondo de la pagina de juego</span>
                </div>
                <div className="container-banner-img">
                    <div className="banner-img">
                        <input
                            ref={fileBackgroundInfoGame}
                            type="file"
                            data-max-size="2"
                            accept="image/*"
                            className="input-img-banner"
                            onChange={(event) => handleIconBackgroundInfo(event)}
                        />

                        <img
                            onClick={handleClickFileBackgroundInfoGame}
                            className={iconBackgroundInfo === '' ? "options-admin-add-image-banner" : "options-admin-add-image-banner-show"}
                            alt="optionsAdminAddImage"
                            src={iconBackgroundInfo === '' ? "/img/newImage/optionsAdmin/optionsAdminAddImage.png" : iconBackgroundInfo}
                        />

                    </div>
                </div>
                <div className="container-text-info-input-banner">
                    <span className="info-add">Fondo vista de información de sesión</span>
                </div>
                <div className="container-banner-img">
                    <div className="banner-img">
                        <input
                            ref={fileBackgroundWinnerGame}
                            type="file"
                            data-max-size="2"
                            accept="image/*"
                            className="input-img-banner"
                            onChange={(event) => handleIconBackgroundWinners(event)}
                        />

                        <img
                            onClick={handleClickFileBackgroundWinnerGame}
                            className={iconBackgroundWinners === '' ? "options-admin-add-image-banner" : "options-admin-add-image-banner-show"}
                            alt="optionsAdminAddImage"
                            src={iconBackgroundWinners === '' ? "/img/newImage/optionsAdmin/optionsAdminAddImage.png" : iconBackgroundWinners}
                        />

                    </div>
                </div>
                <div className="container-text-info-input-banner">
                    <span className="info-add">Fondo vista de ganadores</span>
                </div>
                <div className="container-buttons">
                    <button type="submit" className="buttons">Configurar</button>
                </div>
                <span>{errors}</span>
            </form>
        </ConfigurationThemeFormStyles>
    );
}
