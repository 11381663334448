import React, {useEffect, useState} from 'react'
import {AppHeaderStyled} from "./AppHeaderStyled";
import {useAppDispatch, useAppSelector} from "../../../shared/Utils/Hooks";
import {useHistory} from "react-router-dom";
import {
    closeCreateSession,
    closeEditSession, openAdnCloseModalCreateGame, openAndCloseModalCreateUser,
    openAndCloseModalEditGame, openAndCloseModalUsersSession,
    openUserInfo
} from "../../../store/ActionsAdmin/ModalsActions";
import {
    changeSessionSelected, getAllSessionsActive,
    getAllSessionsInactive, updateOffsetSessionActive,
    updateOffsetSessionInactive
} from "../../../store/ActionsAdmin/SessionActions";
import {useThemeContext} from "../../../shared/Hooks/ThemeContextProvider";
import {getSessionStorageObj} from "../../../shared/Utils/GetSessionStorageObject";
import {cleanSingleFinishedGame} from "../../../store/ActionsAdmin/FinishedGameActions";

export interface IUser {
    id: number;
    name:string;
    userType:string;
}

export function AppHeader():JSX.Element  {

    const dispatch = useAppDispatch();
    const history = useHistory();
    const themeActive = useThemeContext();
    const [openAndCloseModalUserInfo, setOpenAndCloseModalUserInfo] = useState<boolean>(false);
    const showModalUserInfo = useAppSelector((state) => state.modalState.showModalUserInfo);
    const domainActive = useAppSelector((state) => state.accountState.domainActive);
    const user = getSessionStorageObj('user');

    function redirectHome() {
        if (domainActive) {
            dispatch(updateOffsetSessionInactive(0));
            dispatch(updateOffsetSessionActive(0));
            dispatch(getAllSessionsInactive(domainActive.id, 0)).then(() => {});
            dispatch(getAllSessionsActive(domainActive.id, 0)).then(() => {});
            dispatch(changeSessionSelected(undefined));
            dispatch(closeCreateSession());
            dispatch(closeEditSession());
            dispatch(openAndCloseModalEditGame(false));
            dispatch(openUserInfo(false));
            dispatch(openAdnCloseModalCreateGame(false));
            dispatch(openAndCloseModalCreateUser(false));
            dispatch(openAndCloseModalUsersSession(false));
            dispatch(cleanSingleFinishedGame());
            history.push('/admin/options');
        }

    }

    function handleOpenAndCloseModalUserInfo(data:boolean) {
        dispatch(openUserInfo(data));
        setOpenAndCloseModalUserInfo(data);
    }

    useEffect(() => {
        console.log(user);
    }, [user]);

    return (
        <AppHeaderStyled>
            <img
                onClick={redirectHome}
                className="options-admin-title-header"
                alt="optionsAdminTitle-header"
                src={themeActive.theme?.logo}
            />
            <div onClick={() => handleOpenAndCloseModalUserInfo(!openAndCloseModalUserInfo)} className="container-user-session">
                <div className="container-icon-session">
                    <img
                        className="img-user-session-circle"
                        alt="optionsAdminImgUserSessionCircle"
                        src="/img/newImage/optionsAdmin/optionsAdminImgUserSessionCircle.svg"
                    />
                    <img
                        className="img-user-session-avatar"
                        alt="optionsAdminImgUserSessionAvatar"
                        src="/img/newImage/optionsAdmin/optionsAdminImgUserSessionAvatar.svg"
                    />
                </div>
                <span className="name-user">{!showModalUserInfo ? `Hola, ${user?.name}` : 'Perfil'}</span>
            </div>
        </AppHeaderStyled>
    )
}

