import styled from "styled-components";

export const ShowSessionStyled = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: scroll;

    /* Estilos para el scrollbar */

    &::-webkit-scrollbar {
        width: 1vw; /* Ancho del scrollbar */
    }

    &::-webkit-scrollbar-track {
        background-color: #CCCFDE;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #00000038;
        border-radius: 5px;
        height: 5px;
    }

    .modal-users-session {
        position: absolute;
        top: 1vw;
        left: 20vw;
        width: 30vw;
        height: 30vw;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 3px 4px 1px #0000004D;
        border-radius: 25px;
        opacity: 1;
        margin-bottom: 1vw;
        z-index: 999999999999999;
    }

    .container-img-banner {
        display: flex;
        width: 100%;
        height: 45%;

        .options-admin-img-banner-big {
            width: 100%;
        }
    }

    .container-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 97%;
        height: 50rem;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-radius: 28px;
        opacity: 1;
        margin: 1.5vw auto 1.5vw;
    }

    .modal-add-bulk-users {
        position: absolute;
        top: 10vw;
        left: 47vw;
        width: 24vw;
        height: 25vw;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 3px 4px 1px #0000004D;
        border-radius: 25px;
        opacity: 1;
        margin-bottom: 1vw;
        z-index: 999999999999999;
    }
`
