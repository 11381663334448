import {
    EDIT_SESSION_CONFIG_INFORMATION,
    EDIT_SESSION_CONFIG_INFORMATION_FAILED,
    EDIT_SESSION_CONFIG_INFORMATION_SUCCESS,
    EDIT_SESSION_CONFIG_SPONSORS,
    EDIT_SESSION_CONFIG_SPONSORS_SUCCESS,
    EDIT_SESSION_CONFIG_SPONSORS_FAILED,
    GET_SINGLE_SESSION_CONFIG,
    GET_SINGLE_SESSION_CONFIG_FAILED,
    GET_SINGLE_SESSION_CONFIG_SUCCESS,
    EDIT_SESSION_CONFIG_AWARDS,
    EDIT_SESSION_CONFIG_AWARDS_SUCCESS,
    EDIT_SESSION_CONFIG_AWARDS_FAILED,
    EDIT_SESSION_CONFIG_ACQUIRE_CARDS,
    EDIT_SESSION_CONFIG_ACQUIRE_CARDS_SUCCESS,
    EDIT_SESSION_CONFIG_ACQUIRE_CARDS_FAILED
} from "../TypesAdmin/SessionConfigTypes";
import {SingleSessionConfigModel} from "../../domain/models/SingleSessionConfigModel";

interface IStateSessionConfig {
    isLoadingSessionConfigInformation: boolean;
    isLoadingSessionConfigSponsors: boolean;
    isLoadingSessionConfigAwards: boolean;
    isLoadingSessionConfigAcquireCards: boolean;
    isLoadingSingleSessionConfig: boolean;
    singleSessionConfig: SingleSessionConfigModel | null;
    message: string;
    error: string;
}

const initialStateSessionConfig: IStateSessionConfig = {
    isLoadingSessionConfigInformation: false,
    isLoadingSessionConfigSponsors: false,
    isLoadingSessionConfigAwards: false,
    isLoadingSessionConfigAcquireCards: false,
    isLoadingSingleSessionConfig: false,
    singleSessionConfig: null,
    message: '',
    error: ''
}
const SessionConfigReducer = function (state = initialStateSessionConfig, action: { type: any; payload: any }): IStateSessionConfig {
    switch (action.type) {
        case EDIT_SESSION_CONFIG_INFORMATION:
            return {
                isLoadingSessionConfigInformation: action.payload,
                isLoadingSessionConfigSponsors: state.isLoadingSessionConfigSponsors,
                isLoadingSessionConfigAwards: state.isLoadingSessionConfigAwards,
                isLoadingSessionConfigAcquireCards: state.isLoadingSessionConfigAcquireCards,
                isLoadingSingleSessionConfig: state.isLoadingSingleSessionConfig,
                singleSessionConfig: state.singleSessionConfig,
                message: '',
                error: ''
            };
        case EDIT_SESSION_CONFIG_INFORMATION_SUCCESS:
            return {
                isLoadingSessionConfigInformation: action.payload,
                isLoadingSessionConfigSponsors: state.isLoadingSessionConfigSponsors,
                isLoadingSessionConfigAwards: state.isLoadingSessionConfigAwards,
                isLoadingSessionConfigAcquireCards: state.isLoadingSessionConfigAcquireCards,
                isLoadingSingleSessionConfig: state.isLoadingSingleSessionConfig,
                singleSessionConfig: state.singleSessionConfig,
                message: '',
                error: ''
            };
        case EDIT_SESSION_CONFIG_INFORMATION_FAILED:
            return {
                isLoadingSessionConfigInformation: action.payload,
                isLoadingSessionConfigSponsors: state.isLoadingSessionConfigSponsors,
                isLoadingSessionConfigAwards: state.isLoadingSessionConfigAwards,
                isLoadingSessionConfigAcquireCards: state.isLoadingSessionConfigAcquireCards,
                isLoadingSingleSessionConfig: state.isLoadingSingleSessionConfig,
                singleSessionConfig: state.singleSessionConfig,
                message: '',
                error: ''
            };
        case EDIT_SESSION_CONFIG_SPONSORS:
            return {
                isLoadingSessionConfigInformation: state.isLoadingSessionConfigInformation,
                isLoadingSessionConfigSponsors: action.payload,
                isLoadingSessionConfigAwards: state.isLoadingSessionConfigAwards,
                isLoadingSessionConfigAcquireCards: state.isLoadingSessionConfigAcquireCards,
                isLoadingSingleSessionConfig: state.isLoadingSingleSessionConfig,
                singleSessionConfig: state.singleSessionConfig,
                message: '',
                error: ''
            };

        case EDIT_SESSION_CONFIG_SPONSORS_SUCCESS:
            return {
                isLoadingSessionConfigInformation: state.isLoadingSessionConfigInformation,
                isLoadingSessionConfigSponsors: action.payload,
                isLoadingSessionConfigAwards: state.isLoadingSessionConfigAwards,
                isLoadingSessionConfigAcquireCards: state.isLoadingSessionConfigAcquireCards,
                isLoadingSingleSessionConfig: state.isLoadingSingleSessionConfig,
                singleSessionConfig: state.singleSessionConfig,
                message: '',
                error: ''
            };
        case EDIT_SESSION_CONFIG_SPONSORS_FAILED:
            return {
                isLoadingSessionConfigInformation: state.isLoadingSessionConfigInformation,
                isLoadingSessionConfigSponsors: action.payload,
                isLoadingSessionConfigAwards: state.isLoadingSessionConfigAwards,
                isLoadingSessionConfigAcquireCards: state.isLoadingSessionConfigAcquireCards,
                isLoadingSingleSessionConfig: state.isLoadingSingleSessionConfig,
                singleSessionConfig: state.singleSessionConfig,
                message: '',
                error: ''
            };

        case EDIT_SESSION_CONFIG_AWARDS:
            return {
                isLoadingSessionConfigInformation: state.isLoadingSessionConfigInformation,
                isLoadingSessionConfigSponsors: state.isLoadingSessionConfigSponsors,
                isLoadingSessionConfigAwards: action.payload,
                isLoadingSessionConfigAcquireCards: state.isLoadingSessionConfigAcquireCards,
                isLoadingSingleSessionConfig: state.isLoadingSingleSessionConfig,
                singleSessionConfig: state.singleSessionConfig,
                message: '',
                error: ''
            };

        case EDIT_SESSION_CONFIG_AWARDS_SUCCESS:
            return {
                isLoadingSessionConfigInformation: state.isLoadingSessionConfigInformation,
                isLoadingSessionConfigSponsors: state.isLoadingSessionConfigSponsors,
                isLoadingSessionConfigAwards: action.payload,
                isLoadingSessionConfigAcquireCards: state.isLoadingSessionConfigAcquireCards,
                isLoadingSingleSessionConfig: state.isLoadingSingleSessionConfig,
                singleSessionConfig: state.singleSessionConfig,
                message: '',
                error: ''
            };

        case EDIT_SESSION_CONFIG_AWARDS_FAILED:
            return {
                isLoadingSessionConfigInformation: state.isLoadingSessionConfigInformation,
                isLoadingSessionConfigSponsors: state.isLoadingSessionConfigSponsors,
                isLoadingSessionConfigAwards: action.payload,
                isLoadingSessionConfigAcquireCards: state.isLoadingSessionConfigAcquireCards,
                isLoadingSingleSessionConfig: state.isLoadingSingleSessionConfig,
                singleSessionConfig: state.singleSessionConfig,
                message: '',
                error: ''
            };

        case EDIT_SESSION_CONFIG_ACQUIRE_CARDS:
            return {
                isLoadingSessionConfigInformation: state.isLoadingSessionConfigInformation,
                isLoadingSessionConfigSponsors: state.isLoadingSessionConfigSponsors,
                isLoadingSessionConfigAwards: state.isLoadingSessionConfigAwards,
                isLoadingSessionConfigAcquireCards: action.payload,
                isLoadingSingleSessionConfig: state.isLoadingSingleSessionConfig,
                singleSessionConfig: state.singleSessionConfig,
                message: '',
                error: ''
            }

        case EDIT_SESSION_CONFIG_ACQUIRE_CARDS_SUCCESS:
            return {
                isLoadingSessionConfigInformation: state.isLoadingSessionConfigInformation,
                isLoadingSessionConfigSponsors: state.isLoadingSessionConfigSponsors,
                isLoadingSessionConfigAwards: state.isLoadingSessionConfigAwards,
                isLoadingSessionConfigAcquireCards: action.payload,
                isLoadingSingleSessionConfig: state.isLoadingSingleSessionConfig,
                singleSessionConfig: state.singleSessionConfig,
                message: '',
                error: ''
            }

        case EDIT_SESSION_CONFIG_ACQUIRE_CARDS_FAILED:
            return {
                isLoadingSessionConfigInformation: state.isLoadingSessionConfigInformation,
                isLoadingSessionConfigSponsors: state.isLoadingSessionConfigSponsors,
                isLoadingSessionConfigAwards: state.isLoadingSessionConfigAwards,
                isLoadingSessionConfigAcquireCards: action.payload,
                isLoadingSingleSessionConfig: state.isLoadingSingleSessionConfig,
                singleSessionConfig: state.singleSessionConfig,
                message: '',
                error: ''
            }




        case GET_SINGLE_SESSION_CONFIG:
            return {
                isLoadingSessionConfigInformation: state.isLoadingSessionConfigInformation,
                isLoadingSessionConfigSponsors: state.isLoadingSessionConfigSponsors,
                isLoadingSessionConfigAwards: state.isLoadingSessionConfigAwards,
                isLoadingSessionConfigAcquireCards: state.isLoadingSessionConfigAcquireCards,
                isLoadingSingleSessionConfig: action.payload,
                singleSessionConfig: state.singleSessionConfig,
                message: '',
                error: ''
            };
        case GET_SINGLE_SESSION_CONFIG_SUCCESS:
            return {
                isLoadingSessionConfigInformation: state.isLoadingSessionConfigInformation,
                isLoadingSessionConfigSponsors: state.isLoadingSessionConfigSponsors,
                isLoadingSessionConfigAwards: state.isLoadingSessionConfigAwards,
                isLoadingSessionConfigAcquireCards: state.isLoadingSessionConfigAcquireCards,
                isLoadingSingleSessionConfig: false,
                singleSessionConfig: action.payload,
                message: '',
                error: ''
            };
        case GET_SINGLE_SESSION_CONFIG_FAILED:
            return {
                isLoadingSessionConfigInformation: state.isLoadingSessionConfigInformation,
                isLoadingSessionConfigSponsors: state.isLoadingSessionConfigSponsors,
                isLoadingSessionConfigAwards: state.isLoadingSessionConfigAwards,
                isLoadingSessionConfigAcquireCards: state.isLoadingSessionConfigAcquireCards,
                isLoadingSingleSessionConfig: false,
                singleSessionConfig: state.singleSessionConfig,
                message: '',
                error: action.payload
            };
        default:
            return state
    }
}
export default SessionConfigReducer;
