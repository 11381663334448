import {LastBallotsEndUserStyles} from "./LastBallotsEndUserStyles";
import {useEffect, useState} from "react";
import {useAppSelector} from "../../../../admin/shared/Utils/Hooks";




export default function LastBallotsEndUser(): JSX.Element {

    const gameInfo = useAppSelector((state) => state.bingoState.gameInfo);
    const lastBallot = useAppSelector((state) => state.bingoState.lastBallot);
    const [scrollOffset, setScrollOffset] = useState<number>(70);

    function handleClickScroll(data_direction: string) {
        if (data_direction === 'left' && scrollOffset > 70) {
            // Mueve el scroll hacia la izquierda, reduciendo el desplazamiento.
            setScrollOffset((prevOffset) => prevOffset - 70);
        }
        if (data_direction === 'right') {
            // Mueve el scroll hacia la derecha, aumentando el desplazamiento.
            setScrollOffset((prevOffset) => prevOffset + 70);
        }
    }

    useEffect(() => {
        console.log(scrollOffset);
    }, [scrollOffset]);

    return (
        <LastBallotsEndUserStyles>
            <span className="title">Últimas Balotas: </span>
            <div className="container-row">
                <div className="stylized-image">
                    <img src="/img/newImage/img-number-ballot-big.png" alt="Image right" className="image"/>
                    <div className="number-ballot">{lastBallot}</div>
                </div>
                <div className="container-bar">
                    <div className="triangle-box triangle-box-left" onClick={() => handleClickScroll('left')}>
                        <img src="/img/newImage/direction-right.png" alt="right" className="triangle-left"/>
                    </div>

                    <div className="triangle-box-right">
                        <div
                            className="numbers-container"
                            style={{transform: `translateX(-${scrollOffset}px)`}}
                        >
                            {gameInfo &&
                                gameInfo.listBallotPlayedData.map((ballot, index) => (
                                    <div key={index} className="numbers">
                                        {ballot}
                                    </div>
                                ))
                                    .reverse()
                            }
                        </div>
                    </div>
                    <div className="triangle-box" onClick={() => handleClickScroll('right')}>
                        <img src="/img/newImage/direction-right.png" alt="right" className="triangle-right"/>
                    </div>
                </div>
            </div>
        </LastBallotsEndUserStyles>
    );
}
