import styled from "styled-components";

export const UserManagementStyles = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1vw;
    width: 100%;

    .user-management-container {
        width: 98%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1vw;
        margin-left: 1vw;

        .icon-back-container {
            //display: flex;
            justify-content: start;
            align-items: center;
            align-self: start;
            margin-top: 0.5vw;

            .icon-back {
                width: 1.4vw;
                cursor: pointer;
            }
        }


        img {
            width: 2.3vw;

        }

        h1 {
            color: #2E304E;
            font-size: 1.5vw;
            font-family: Poppins-SemiBold, sans-serif;
            font-weight: 600;

        }
    }

    .options-container {
        width: 98%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 1vw;
        gap: 5vw;

        input {
            width: 20vw;
            padding: 5px 5px 5px 32px;
            border: none;
            border-radius: 20px;
            outline: none;
        }

        .icon-search {
            width: 1.3vw;
            position: absolute;
            margin-left: 1vw;
        }

        .add-user-container {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0.3vw;


            .icon-add-user {
                width: 0.8vw;
            }

            span {
                font-size: 0.9vw;
                font-family: Alata-Regular, sans-serif;
                font-weight: 600;
                cursor: pointer;

            }
        }

        .delete-user-container {
            display: flex;
            justify-content: center;
            align-items: center;
            //margin-left: 1vw;
            gap: 0.3vw;


            .icon-delete-user {
                width: 0.8vw;
            }

            span {
                font-size: 0.9vw;
                font-family: Alata-Regular, sans-serif;
                font-weight: 600;
                cursor: pointer;
            }
        }

        .export-container {
            display: flex;
            justify-content: center;
            align-items: center;
            //margin-left: 1vw;
            gap: 0.3vw;


            .icon-export {
                width: 0.8vw;
            }

            span {
                font-size: 0.9vw;
                font-family: Alata-Regular, sans-serif;
                font-weight: 600;
                cursor: pointer;
            }
        }

    }

    .table-container {
        width: 96%;
        height: 32vw;
        justify-content: start;
        align-items: center;
        overflow-y: scroll;

        ::-webkit-scrollbar {
            width: 5px;
        }

        /* Track */

        ::-webkit-scrollbar-track {
            background: #61dafb;
        }

        /* Handle */

        ::-webkit-scrollbar-thumb {
            background: red;
            border-radius: 15px;
        }

        /* Handle on hover */

        ::-webkit-scrollbar-thumb:hover {
            background: green;
        }

        table {
            width: 97%;
            border-collapse: collapse;
            margin-bottom: 1vw;

            thead {

                tr {
                    border-bottom: 1px solid #000;

                    th {
                        text-align: left;
                        letter-spacing: 0px;
                        color: #000000;
                        opacity: 1;
                        font-size: 1vw;
                        padding-left: 1.5vw;
                        font-family: Alata-Regular, sans-serif;
                        font-weight: 600;
                    }

                    .check-input-column {
                        display: flex;
                        align-items: center;
                        justify-content: start;
                    }

                    .check-input-column input[type="checkbox"] {
                        display: none; /* Ocultar el checkbox nativo */
                    }

                    .check-input-column input[type="checkbox"] + label {
                        display: flex; /* Alinear elementos horizontalmente */
                        align-items: center; /* Alinear elementos verticalmente */
                    }

                    .check-input-column input[type="checkbox"] + label::before {
                        content: ''; /* Agregar un pseudo-elemento */
                        width: 0.7vw; /* Ancho del checkbox personalizado */
                        height: 0.6vw; /* Alto del checkbox personalizado */
                        margin-right: 0.5vw; /* Espacio entre el checkbox y el texto */
                        border: 1px solid #00000080; /* Borde morado */
                        border-left: none; /* No hay borde en el lado derecho */
                        border-top: none;
                        background-color: #fff; /* Fondo blanco */
                        cursor: pointer;
                        border-radius: 2px;
                        box-shadow: .2px 0 0 0 #00000080, 0 .2px 0 0 #00000080;
                    }

                    .check-input-column input[type="checkbox"]:checked + label::before {
                        background-color: #9545FF; /* Color morado cuando está marcado */

                    }
                }
            }

            tbody {

                tr {

                    td {
                        text-align: left;
                        letter-spacing: 0px;
                        color: #000000;
                        opacity: 1;
                        font-family: Poppins-Light, sans-serif;
                        font-weight: 300;
                        font-size: 1vw;
                        padding-left: 1.5vw;
                    }

                    .check-input-column {
                        display: flex;
                        align-items: center;
                        justify-content: start;
                    }

                    .check-input-column input[type="checkbox"] {
                        display: none; /* Ocultar el checkbox nativo */
                    }

                    .check-input-column input[type="checkbox"] + label {
                        display: flex; /* Alinear elementos horizontalmente */
                        align-items: center; /* Alinear elementos verticalmente */
                    }

                    .check-input-column input[type="checkbox"] + label::before {
                        content: ''; /* Agregar un pseudo-elemento */
                        width: 0.7vw; /* Ancho del checkbox personalizado */
                        height: 0.6vw; /* Alto del checkbox personalizado */
                        margin-right: 0.5vw; /* Espacio entre el checkbox y el texto */
                        border: 1px solid #00000080; /* Borde morado */
                        border-left: none; /* No hay borde en el lado derecho */
                        border-top: none;
                        background-color: #fff; /* Fondo blanco */
                        cursor: pointer;
                        border-radius: 2px;
                        box-shadow: .2px 0 0 0 #00000080, 0 .2px 0 0 #00000080;
                    }

                    .check-input-column input[type="checkbox"]:checked + label::before {
                        background-color: #9545FF; /* Color morado cuando está marcado */

                    }


                    .icons-table {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .icons-container {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: .4vw;


                            .icon-ballot {
                                width: 1vw;
                                cursor: pointer;
                            }

                            .icon-calabaza {
                                width: 1vw;
                                cursor: pointer;
                            }

                            .icon-new-session {
                                width: 1vw;
                                cursor: pointer;
                            }
                        }

                        .icon-delete-user {
                            width: 1vw;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }

    .modal-user-info {
        position: absolute;
        top: 10vw;
        left: 33vw;
        width: 24vw;
        height: 25vw;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 3px 4px 1px #0000004D;
        border-radius: 25px;
        opacity: 1;
        margin-bottom: 1vw;
        z-index: 999999999999999;
    }

    .modal-add-bulk-users {
        position: absolute;
        top: 10vw;
        left: 47vw;
        width: 24vw;
        height: 25vw;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 3px 4px 1px #0000004D;
        border-radius: 25px;
        opacity: 1;
        margin-bottom: 1vw;
        z-index: 999999999999999;
    }
`

