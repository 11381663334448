import {ShowSessionInformationStyled} from "./ShowSessionInformationStyled";
import {SetStateAction, useEffect, useState} from "react";
import 'react-quill/dist/quill.snow.css';
import QuillShowSession from "../QuillShowSession/QuillShowSession";
import {SessionConfigInformationModel} from "../../../domain/models/SessionConfigInformationModel";
import {useAppDispatch, useAppSelector} from "../../../shared/Utils/Hooks";
import {getSingleSessionDatabase} from "../../../store/ActionsAdmin/SessionActions";
import {editSessionConfigInformation} from "../../../store/ActionsAdmin/SessionConfigActions";

export default function ShowSessionGames(): JSX.Element {

    const dispatch = useAppDispatch();
    const [informationContent, setInformationContent] = useState('');
    const singleSessionDatabase = useAppSelector((state) => state.sessionState.singleSessionDatabase);
    const isLoadingSessionConfigInformation = useAppSelector((state) => state.sessionConfigState.isLoadingSessionConfigInformation);
    const [dataInformation, setDataInformation] = useState<string>('');

    const informationHandleChange = (content: SetStateAction<string>) => {
        console.log(content);
        setInformationContent(content);
    };

    function handleOnClickButton() {
        console.log(informationContent);
        const informationString = JSON.stringify(informationContent);
        console.log(informationString);
        const data: SessionConfigInformationModel = {
            information_data: informationString,
        }
        if (singleSessionDatabase) {
            dispatch(editSessionConfigInformation(data, singleSessionDatabase.session_config_id)).then(() => {
                dispatch(getSingleSessionDatabase(singleSessionDatabase.id)).then(() => {});
            });
        }

    }

    useEffect(() => {
        if (singleSessionDatabase && singleSessionDatabase.session_config.information_data) {
            console.log(singleSessionDatabase.session_config.information_data);
            const dataInformation: string | null = JSON.parse(singleSessionDatabase.session_config.information_data);
            console.log(dataInformation);
            if (dataInformation !== null) {
                setDataInformation(dataInformation);
            } else {
                setDataInformation('');
            }
        }
    }, [singleSessionDatabase]);

    useEffect(() => {
        console.log(dataInformation);
    }, [dataInformation]);

    return (
        <ShowSessionInformationStyled>    
                <QuillShowSession
                    value={dataInformation}
                    handleChange={informationHandleChange}
                    onClickButton={handleOnClickButton}
                    loading={isLoadingSessionConfigInformation}
                />
        </ShowSessionInformationStyled>
    );
}
