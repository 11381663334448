export function validateEmailRegister(value:string) {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const emailOk = emailPattern.test(value);
    if (value === '') {
        return 'Email requerido...';
    }
    if (!emailOk) {
        return 'ejemplo@correo.com'
    }
    return true;
}
