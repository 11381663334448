import {
    CARDS_WINNER_WITH_USER_AND_GAME,
    CARDS_WINNER_WITH_USER_AND_GAME_FAILED,
    CARDS_WINNER_WITH_USER_AND_GAME_SUCCESS,
    COUNT_CARDS_BY_BINGO_LIMIT,
    GET_CARDS_AWS,
    GET_CARDS_AWS_FAILED,
    GET_CARDS_AWS_SUCCESS,
    GET_CARDS_COUNT_BY_SESSION_ID,
    GET_CARDS_COUNT_BY_SESSION_ID_FAILED,
    GET_CARDS_COUNT_BY_SESSION_ID_SUCCESS
} from "../TypesAdmin/CardTypes";
import {CardsAwsModel} from "../../../user/domain/models/CardsAwsModel";
import {CardsWinnerWithUserAndGameModel} from "../../domain/models/CardsWinnerWithUserAndGameModel";

interface IStateCards {
    isLoadingGetCards: boolean;
    allCardsUserSession: CardsAwsModel | null;
    isLoadingCardsCountBySessionId: boolean;
    cardsCountBySessionId: number;
    isLoadingAllCardsWinnerUserAndGame: boolean;
    allCardsWinnerUserAndGame: CardsWinnerWithUserAndGameModel[];
    countCardsByBingoLimit: number;
    message: string;
    error: string;
}
const initialStateCards: IStateCards = {
    isLoadingGetCards: false,
    allCardsUserSession: null,
    isLoadingCardsCountBySessionId: false,
    cardsCountBySessionId: 0,
    isLoadingAllCardsWinnerUserAndGame: false,
    allCardsWinnerUserAndGame: [],
    countCardsByBingoLimit: 0,
    message: '',
    error: ''
}
const CardsReducer = function (state = initialStateCards, action: { type: any; payload: any }): IStateCards {
    switch (action.type) {
        case GET_CARDS_AWS:
            return {
                isLoadingGetCards: action.payload,
                allCardsUserSession: state.allCardsUserSession,
                isLoadingCardsCountBySessionId: state.isLoadingCardsCountBySessionId,
                cardsCountBySessionId: state.cardsCountBySessionId,
                isLoadingAllCardsWinnerUserAndGame: state.isLoadingAllCardsWinnerUserAndGame,
                allCardsWinnerUserAndGame: state.allCardsWinnerUserAndGame,
                countCardsByBingoLimit: state.countCardsByBingoLimit,
                message: '',
                error: ''
            }
        case GET_CARDS_AWS_SUCCESS:
            return {
                isLoadingGetCards: false,
                allCardsUserSession: action.payload,
                isLoadingCardsCountBySessionId: state.isLoadingCardsCountBySessionId,
                cardsCountBySessionId: state.cardsCountBySessionId,
                isLoadingAllCardsWinnerUserAndGame: state.isLoadingAllCardsWinnerUserAndGame,
                allCardsWinnerUserAndGame: state.allCardsWinnerUserAndGame,
                countCardsByBingoLimit: state.countCardsByBingoLimit,
                message: '',
                error: ''
            }
        case GET_CARDS_AWS_FAILED:
            return {
                isLoadingGetCards: false,
                allCardsUserSession: state.allCardsUserSession,
                isLoadingCardsCountBySessionId: state.isLoadingCardsCountBySessionId,
                cardsCountBySessionId: state.cardsCountBySessionId,
                isLoadingAllCardsWinnerUserAndGame: state.isLoadingAllCardsWinnerUserAndGame,
                allCardsWinnerUserAndGame: state.allCardsWinnerUserAndGame,
                countCardsByBingoLimit: state.countCardsByBingoLimit,
                message: '',
                error: action.payload
            }
        case GET_CARDS_COUNT_BY_SESSION_ID:
            return {
                isLoadingGetCards: state.isLoadingGetCards,
                allCardsUserSession: state.allCardsUserSession,
                isLoadingCardsCountBySessionId: action.payload,
                cardsCountBySessionId: state.cardsCountBySessionId,
                isLoadingAllCardsWinnerUserAndGame: state.isLoadingAllCardsWinnerUserAndGame,
                allCardsWinnerUserAndGame: state.allCardsWinnerUserAndGame,
                countCardsByBingoLimit: state.countCardsByBingoLimit,
                message: '',
                error: ''
            }
        case GET_CARDS_COUNT_BY_SESSION_ID_SUCCESS:
            return {
                isLoadingGetCards: state.isLoadingGetCards,
                allCardsUserSession: state.allCardsUserSession,
                isLoadingCardsCountBySessionId: false,
                cardsCountBySessionId: action.payload,
                isLoadingAllCardsWinnerUserAndGame: state.isLoadingAllCardsWinnerUserAndGame,
                allCardsWinnerUserAndGame: state.allCardsWinnerUserAndGame,
                countCardsByBingoLimit: state.countCardsByBingoLimit,
                message: '',
                error: ''
            }
        case GET_CARDS_COUNT_BY_SESSION_ID_FAILED:
            return {
                isLoadingGetCards: state.isLoadingGetCards,
                allCardsUserSession: state.allCardsUserSession,
                isLoadingCardsCountBySessionId: false,
                cardsCountBySessionId: state.cardsCountBySessionId,
                isLoadingAllCardsWinnerUserAndGame: state.isLoadingAllCardsWinnerUserAndGame,
                allCardsWinnerUserAndGame: state.allCardsWinnerUserAndGame,
                countCardsByBingoLimit: state.countCardsByBingoLimit,
                message: '',
                error: action.payload
            }
        case CARDS_WINNER_WITH_USER_AND_GAME:
            return {
                isLoadingGetCards: state.isLoadingGetCards,
                allCardsUserSession: state.allCardsUserSession,
                isLoadingCardsCountBySessionId: state.isLoadingCardsCountBySessionId,
                cardsCountBySessionId: state.cardsCountBySessionId,
                isLoadingAllCardsWinnerUserAndGame: action.payload,
                allCardsWinnerUserAndGame: state.allCardsWinnerUserAndGame,
                countCardsByBingoLimit: state.countCardsByBingoLimit,
                message: '',
                error: ''
            }
        case CARDS_WINNER_WITH_USER_AND_GAME_SUCCESS:
            return {
                isLoadingGetCards: state.isLoadingGetCards,
                allCardsUserSession: state.allCardsUserSession,
                isLoadingCardsCountBySessionId: state.isLoadingCardsCountBySessionId,
                cardsCountBySessionId: state.cardsCountBySessionId,
                isLoadingAllCardsWinnerUserAndGame: false,
                allCardsWinnerUserAndGame: action.payload,
                countCardsByBingoLimit: state.countCardsByBingoLimit,
                message: '',
                error: ''
            }
        case CARDS_WINNER_WITH_USER_AND_GAME_FAILED:
            return {
                isLoadingGetCards: state.isLoadingGetCards,
                allCardsUserSession: state.allCardsUserSession,
                isLoadingCardsCountBySessionId: state.isLoadingCardsCountBySessionId,
                cardsCountBySessionId: state.cardsCountBySessionId,
                isLoadingAllCardsWinnerUserAndGame: false,
                allCardsWinnerUserAndGame: state.allCardsWinnerUserAndGame,
                countCardsByBingoLimit: state.countCardsByBingoLimit,
                message: '',
                error: action.payload
            }
        case COUNT_CARDS_BY_BINGO_LIMIT:
            return {
                isLoadingGetCards: state.isLoadingGetCards,
                allCardsUserSession: state.allCardsUserSession,
                isLoadingCardsCountBySessionId: state.isLoadingCardsCountBySessionId,
                cardsCountBySessionId: state.cardsCountBySessionId,
                isLoadingAllCardsWinnerUserAndGame: state.isLoadingAllCardsWinnerUserAndGame,
                allCardsWinnerUserAndGame: state.allCardsWinnerUserAndGame,
                countCardsByBingoLimit: action.payload,
                message: '',
                error: ''
            }
        default:
            return state;
    }
}
export default CardsReducer;