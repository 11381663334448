export const OPEN_MODAL_CREATE_SESSION = 'OPEN_MODAL_CREATE_SESSION';
export const CLOSE_MODAL_CREATE_SESSION = 'CLOSE_MODAL_CREATE_SESSION';
export const OPEN_MODAL_EDIT_SESSION = 'OPEN_MODAL_EDIT_SESSION';
export const CLOSE_MODAL_EDIT_SESSION = 'CLOSE_MODAL_EDIT_SESSION';
export const OPEN_MODAL_USER_INFO = 'OPEN_MODAL_USER_INFO';
export const CLOSE_MODAL_USER_INFO = 'CLOSE_MODAL_USER_INFO';
export const OPEN_MODAL_USER_GAME_INFO = 'OPEN_MODAL_USER_GAME_INFO';
export const CLOSE_MODAL_USER_GAME_INFO = 'CLOSE_MODAL_USER_GAME_INFO';
export const OPEN_AND_CLOSE_MODAL_CREATE_GAME = 'OPEN_AND_CLOSE_MODAL_CREATE_GAME';
export const OPEN_AND_CLOSE_MODAL_EDIT_GAME = 'OPEN_AND_CLOSE_MODAL_EDIT_GAME';
export const OPEN_AND_CLOSE_MODAL_CREATE_USER = 'OPEN_AND_CLOSE_MODAL_CREATE_USER';
export const OPEN_AND_CLOSE_MODAL_SIGN_IN = 'OPEN_AND_CLOSE_MODAL_SIGN_IN';
export const OPEN_AND_CLOSE_MODAL_CONFIRM_OTP = 'OPEN_AND_CLOSE_MODAL_CONFIRM_OTP';
export const OPEN_AND_CLOSE_MODAL_REGISTER = 'OPEN_AND_CLOSE_MODAL_REGISTER';
export const OPEN_AND_CLOSE_MODAL_USERS_SESSION = 'OPEN_AND_CLOSE_MODAL_USERS_SESSION';
export const OPEN_AND_CLOSE_MODAL_LAUNCH_BALLOT_MANUAL = 'OPEN_AND_CLOSE_MODAL_LAUNCH_BALLOT_MANUAL';
export const OPEN_AND_CLOSE_MODAL_SINGLE_SESSION_USER_END = 'OPEN_AND_CLOSE_MODAL_SINGLE_SESSION_USER_END';
export const OPEN_AND_CLOSE_MODAL_ADD_BULK_USERS = 'OPEN_AND_CLOSE_MODAL_ADD_BULK_USERS';
export const OPEN_AND_CLOSE_MODAL_ADD_BULK_USERS_IN_CREATE_SESSION = 'OPEN_AND_CLOSE_MODAL_ADD_BULK_USERS_IN_CREATE_SESSION';
export const OPEN_AND_CLOSE_MODAL_USERS_REL_SESSION_AND_CARDS = 'OPEN_AND_CLOSE_MODAL_USERS_REL_SESSION_AND_CARDS';
export const OPEN_AND_CLOSE_MODAL_CARDS_WINNERS_USER_AND_GAME = 'OPEN_AND_CLOSE_MODAL_CARDS_WINNERS_USER_AND_GAME';
export const OPEN_AND_CLOSE_MODAL_CREATE_USER_REL_ACCOUNT_AND_SESSION = 'OPEN_AND_CLOSE_MODAL_CREATE_USER_REL_ACCOUNT_AND_SESSION';
export const OPEN_AND_CLOSE_MODAL_CURRENT_GAME_DATA = 'OPEN_AND_CLOSE_MODAL_CURRENT_GAME_DATA';
export const OPEN_AND_CLOSE_MODAL_IMAGES_STORAGE = 'OPEN_AND_CLOSE_MODAL_IMAGES_STORAGE';
export const OPEN_AND_CLOSE_MODAL_IMAGES_STORAGE_GAME = 'OPEN_AND_CLOSE_MODAL_IMAGES_STORAGE_GAME';
export const OPEN_AND_CLOSE_MODAL_DEBUG_MESSAGE = 'OPEN_AND_CLOSE_MODAL_DEBUG_MESSAGE';
