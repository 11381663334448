import styled from "styled-components";

interface IShowGameStyledProps {
    background_game: string;
    background_winners_game: string;
    background_numbers_game: string;
}
export const ShowGameStyled = styled.div<IShowGameStyledProps>`
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        /* Define la rotación inicial */
        100% {
            transform: rotate(360deg);
        }
        /* Define la rotación final */
    }

    display: flex;
    flex-direction: column;
    background-image: ${({background_game}) => `url(${background_game})`};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 93.5vh;
    overflow-y: scroll;
    flex-grow: 1;

    /* Estilos para el scrollbar */

    &::-webkit-scrollbar {
        width: 1vw; /* Ancho del scrollbar */
    }

    &::-webkit-scrollbar-track {
        background-color: #CCCFDE;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #ffffff;
        border-radius: 5px;
    }

    .container {
        display: flex;
        flex-direction: column;
        width: 100%;

        .container-1 {
            display: flex;
            width: 100%;
            height: 20vw;
            background: #61dafb;
        }

        .container-2 {
            display: flex;
            width: 100%;
            height: 20vw;
            background: #282c34;
        }

        .container-3 {
            display: flex;
            width: 100%;
            height: 40vw;
            background: maroon;
        }
    }


    .container-4 {
        display: flex;
        width: 100%;
        height: 40vw;
        background: green;
    }

    .container-info-session {
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 1vw;
        width: 100%;
        height: 8vw;
        background: #EFEFF1;

        .container-icon-back {
            display: flex;
            align-items: start;
            justify-content: center;
            width: 3vw;
            height: 100%;

            .icon-back {
                width: 1.5vw;
                height: 1.5vw;
                margin-top: 1vw;
            }
        }

        .options-admin-img-ball-8-big {
            width: 5vw;
        }

        .info-session {
            display: flex;
            flex-direction: column;

            .name-session {
                font-family: Poppins-SemiBold, sans-serif;
                font-weight: 600;
                font-size: 1.8vw;
                text-align: left;
                letter-spacing: 0;
                color: #303030;
                opacity: 1;
            }

            .container-players-cards {
                display: flex;
                align-items: center;
                justify-content: start;
                gap: 2vw;

                .players-cards {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: .4vw;

                    .options-admin-img-count-players {
                        width: 1.8vw;
                    }

                    .count-players {
                        font-family: Poppins-Light, sans-serif;
                        font-weight: 300;
                        font-size: 1vw;
                        text-align: left;
                        letter-spacing: 0;
                        color: #303030;
                        opacity: 1;
                    }

                    .text-players {
                        font-family: Poppins-Light, sans-serif;
                        font-weight: 300;
                        font-size: 1vw;
                        text-align: left;
                        letter-spacing: 0;
                        color: #303030;
                        opacity: 1;
                    }
                }
            }

            .container-accumulated {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 8px;
                margin-left: 15px;

                .p-accumulated {
                    margin-right: 10px;
                    font-family: Alata-Regular, sans-serif;
                    font-weight: 300;
                    font-size: .8vw;
                    text-align: left;
                    letter-spacing: 0;
                    color: #545454;
                    opacity: 1;
                }

                .rectangle {
                    width: 30vw;
                    height: 1vh;
                    background: transparent linear-gradient(0deg, #631313 0%, #580F0F 10%, #3D0808 40%, #2D0303 66%, #280202 87%) 0% 0% no-repeat padding-box;
                    opacity: 1;
                    border-radius: 7px;
                    padding: 0;
                    font-size: 10.22px;
                    letter-spacing: 0px;
                    color: #F0FF00;

                    .rectangle2 {
                        width: 50%;
                        height: 1vh;
                        background: #C491FC 0% 0% no-repeat padding-box;
                        opacity: 1;
                        border-radius: 7px;
                    }
                }
            }
        }

        .container-play-pause {
            display: flex;
            align-items: end;
            justify-content: center;
            width: 35%;
            height: 100%;

            .img-play-pause-super {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 15vw;
                height: 4vw;
                background: #F7F7F8;
                border-radius: 40px;

                .img-play-pause-interior {
                    display: flex;
                    width: 80%;
                    height: 80%;
                    background: #EFEFF1;
                    border-radius: 40px;
                }
            }
        }


        .container-info {
            display: flex;
            flex-direction: column;
            width: 100%;

            .title-info {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .title {
                    margin: 0;
                }

                .container-edit {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    cursor: pointer;

                    .options-admin-img-edit-2 {
                        width: 1.4vw;
                    }

                    .options-admin-img-edit-1 {
                        position: absolute;
                        bottom: .5vw;
                        left: .6vw;
                        width: 1.1vw;
                    }
                }
            }

            .container-players-cards {
                display: flex;
                gap: 2vw;
                align-items: center;
                justify-content: start;

                .players-cards {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: .3vw;

                    .options-admin-img-count-players {
                        width: 1.5vw;
                    }

                    .count-players {
                        text-align: left;
                        letter-spacing: 0;
                        color: #303030;
                        opacity: 1;
                        font-size: 1vw;
                    }

                    .text-players {
                        text-align: left;
                        letter-spacing: 0;
                        color: #303030;
                        opacity: 1;
                        font-size: 1vw;
                    }
                }
            }


        }
    }

    .container-last-ballots {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
        margin: 2vw 0 1vw;

        .container-sup-winner {
            width: 19vw;
            height: 13vw;
            background-image: ${({background_winners_game}) => `url(${background_winners_game})`};;
            background-repeat: no-repeat;
            background-size: contain;

            .container-inf-winner {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: start;
                width: 19vw;
                height: 13vw;
                //background-image: url("/img/newImage/img-content-inf-winner.svg");
                //background-repeat: no-repeat;
                //background-size: contain;

                .header-card-winner {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid #FFFFFF;
                    width: 90%;
                    padding-top: 1vw;

                    .players {
                        font-family: Alata-Regular, sans-serif;
                        font-weight: 400;
                        font-size: 1vw;
                        text-align: left;
                        letter-spacing: 0;
                        color: #FFFFFF;
                        opacity: 1;
                        width: 40%;
                    }

                    .cards {
                        font-family: Alata-Regular, sans-serif;
                        font-weight: 400;
                        font-size: .7vw;
                        text-align: left;
                        letter-spacing: 0;
                        color: #FFFFFF;
                        opacity: 1;
                    }

                    .winners {
                        font-family: Alata-Regular, sans-serif;
                        font-weight: 400;
                        font-size: .7vw;
                        text-align: left;
                        letter-spacing: 0;
                        color: #FFFFFF;
                        opacity: 1;
                    }
                }

                .body-card-winner {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: start;
                    gap: .5vw;
                    width: 90%;
                    height: 7vw;
                    margin-top: .5vw;
                    overflow-y: scroll;
                    direction: rtl;
                    margin-bottom: 1vw;

                    .card-winners {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: start;
                        gap: .5vw;
                        width: 90%;
                        height: 9vw;
                        margin-top: .5vw;
                        direction: ltr;

                        .content-winner {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 100%;

                            .name-winner {
                                font-family: RoundedMplus1c-Regular, sans-serif;
                                font-weight: 400;
                                font-size: .82vw;
                                text-align: left;
                                letter-spacing: 0;
                                color: #FFFFFF;
                                opacity: 1;
                                width: 30%;

                            }

                            .container-serial {
                                color: #FFFFFF;
                                font-size: .82vw;
                                font-family: RoundedMplus1c-Regular, sans-serif;
                                font-weight: 400;
                            }

                            .start-winner {
                                width: 1vw;
                            }
                        }
                    }
                }

                .body-card-winner::-webkit-scrollbar {
                    width: 0.4vw;
                    height: 0.2vw;
                }

                .body-card-winner::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                    border-radius: 5vw;
                    background: transparent;
                    opacity: 1;


                }

                .body-card-winner::-webkit-scrollbar-thumb {
                    background-color: #FFFFFF;
                    outline: 1px solid slategrey;
                }
                button {
                    background: darkblue;
                    border-radius: 37px;
                    opacity: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    letter-spacing: 0;
                    color: #FFFFFF;
                    font-size: .8vw;
                    transition: box-shadow 0.3s ease;
                    cursor: pointer;
                    outline: none;
                    border: none;
                    font-family: Anton, sans-serif;
                    padding: .2vw 1vw;
                }
                button:hover {
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Agrega una sombra suave al hacer hover */
                }
            }
        }

        .container-play-actions {
            display: flex;
            align-items: center;
            justify-content: center;

            .container-start-game {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            .container-starting-game {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            .container-throwing-ballot {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .img-play-launch-ballot {
                    width: 6vw;
                    cursor: pointer;
                }

                .text-launch-auto {
                    display: block;
                    font-size: 1vw;
                    font-weight: bold;
                    cursor: pointer;
                    color: darkblue;
                    width: 10vw;
                    text-align: center;
                }
            }

            .img-play-launch-ballot {
                width: 8vw;
                cursor: pointer;
            }

            .img-play-launch-ballot-end {
                width: 11vw;
                cursor: pointer;
            }

            .img-play-launch-ballot-spin {
                width: 7vw;
                cursor: pointer;
                animation: spin 4s linear infinite;
            }
        }
    }

    .container-sup-numbers {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80%;
        height: 23.5vw;
        //border: 2px solid #FFFFFF;
        //border-radius: 22px;
        margin: 0 auto;
        opacity: 1;

        .container-int-numbers {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            //background: #393D6080;
            background-image: ${({background_numbers_game}) => `url(${background_numbers_game})`};
            background-repeat: no-repeat;
            background-size: contain;
            //border-radius: 22px;
        }
    }

    .modal-user-info {
        position: absolute;
        top: 4vw;
        right: 5vw;
        width: 24vw;
        height: 21vw;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 3px 4px 1px #0000004D;
        border-radius: 25px;
        opacity: 1;
        margin-bottom: 1vw;
        z-index: 999999999999999;
    }

    .modal-automatic-launch {
        //position: absolute;
        //top: 17vw;
        //left: 12vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 3px 4px 1px #0000004D;
        box-sizing: border-box;
        width: 15vw;
        height: 13vw;
        border-radius: 1.5vw;
        z-index: 99999;

        .close-modal-automatic-launch {
            display: flex;
            align-items: center;
            justify-content: end;
            box-sizing: border-box;
            width: 90%;
            font-size: .8vw;
        }
        label {
            font-size: .8vw;
            box-sizing: border-box;
            padding: .5vw;
            text-align: center;
            font-family: RoundedMplus1c-Regular, sans-serif;
            font-weight: 400;
            letter-spacing: 0;
            color: #9545FF;
        }
        input {
            outline: none;
            font-size: .8vw;
            box-sizing: border-box;
            padding: .5vw;
            text-align: center;
            font-family: RoundedMplus1c-Regular, sans-serif;
            font-weight: 400;
            letter-spacing: 0;
            color: rgba(155, 159, 160, 0.96);
            width: 6vw;
            margin-bottom: 2vw;
        }
        button {
            background: transparent linear-gradient(90deg, #9545FF 0%, #C491FC 100%) 0% 0% no-repeat padding-box;
            border-radius: 37px;
            opacity: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 8vw;
            height: 2vw;
            text-align: center;
            letter-spacing: 0;
            color: #FFFFFF;
            font-size: .85vw;
            transition: box-shadow 0.3s ease;
            cursor: pointer;
            outline: none;
            border: none;
            font-family: Anton, sans-serif;
            font-weight: 400;
        }

        button:hover {
            box-shadow: 0px 0px 10px rgba(149, 69, 255, 0.7), 0px 0px 20px rgba(196, 145, 252, 0.7);
        }
    }
    .modal-debug-message {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 3px 4px 1px #0000004D;
        box-sizing: border-box;
        width: 15vw;
        padding: 1vw;
        border-radius: 1.5vw;
        z-index: 99999;

        .close-modal-automatic-launch {
            display: flex;
            align-items: center;
            justify-content: end;
            box-sizing: border-box;
            width: 90%;
            font-size: .8vw;
            
            .close-icon {
                color: #9933ff;
                font-size: 1.3vw;
                cursor: pointer;
            }
        }
        .title {
            font-size: .8vw;
            box-sizing: border-box;
            padding: .5vw;
            text-align: center;
            font-family: RoundedMplus1c-Regular, sans-serif;
            font-weight: 400;
            letter-spacing: 0;
            color: #9545FF;
        }
        .container-debug-message {
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: start;
            gap: .6vw;
            width: 90%;
            height: 30vh;
            overflow-y: scroll;
            margin: .5vw 0 1vw;

            &::-webkit-scrollbar {
                width: 0.5vw; /* Ancho del scrollbar */

            }

            &::-webkit-scrollbar-track {
                background-color: #CCCFDE;
                border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #00000038;
                border-radius: 10px;
                height: 3px;
            }
            
            span {
                font-size: .8vw;
            }
        }
        button {
            background: transparent linear-gradient(90deg, #9545FF 0%, #C491FC 100%) 0% 0% no-repeat padding-box;
            border-radius: 37px;
            opacity: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 8vw;
            height: 2vw;
            text-align: center;
            letter-spacing: 0;
            color: #FFFFFF;
            font-size: .85vw;
            transition: box-shadow 0.3s ease;
            cursor: pointer;
            outline: none;
            border: none;
            font-family: Anton, sans-serif;
            font-weight: 400;
        }

        button:hover {
            box-shadow: 0px 0px 10px rgba(149, 69, 255, 0.7), 0px 0px 20px rgba(196, 145, 252, 0.7);
        }
    }
    .modal-cards-winner-user-and-game {
        position: absolute;
        top: 13vw;
        left: 25vw;
        height: 30vw;
        width: 60vw;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 3px 4px 1px #0000004D;
        border-radius: 25px;
        opacity: 1;
        margin-bottom: 1vw;
        z-index: 9999999999;
    }
`
