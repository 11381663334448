import {LoadingStyles} from "./LoadingStyles";


export default function Loading(): JSX.Element {

    return (
        <LoadingStyles>
            <img className="icon-loading" alt="loading" src="/img/newImage/icon-loading.png"/>
        </LoadingStyles>
    );


}
