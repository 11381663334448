import styled from "styled-components";
import {Device} from "../../../shared/Utils/devices";

export const SessionGamesStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  background-image: url("/img/imgUser/imgGame/Rectángulo 254.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 24.15vw;
  height: 38.3vw;
  margin-top: 1vw;

  .sidebar-header {
    background-image: url("/img/imgUser/imgGame/Rectángulo 253.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 95%;
    height: 10.5vh;
    border-radius: 1vw;
    margin-top: .3vw;

    .container-circle-img {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      background: #ffffff;
      width: 6vw;
      height: 6vw;
      z-index: 1;
      top: -1vw;
      left: -1vw;

      .game-img {
        border-radius: 100%;
        width: 90%;
        height: 90%;
      }
    }

    .text-header {
      width: 15.5vw;
      font-family: 'RoundedMplus1c-ExtraBold', sans-serif;
      font-weight: 900;
      letter-spacing: -1.52px;
      color: #FFFFFF;
      white-space: nowrap;
      text-align: start;
      text-overflow: ellipsis;
      overflow: hidden;
      z-index: 1;
      font-size: 1.6vw;
      margin-left: .2vw;
      color: white;
      padding-left: 1vw;
      text-shadow: #681BE0 6px 0px 0px, #681BE0 5.91686px 0.995377px 0px, #681BE0 5.66974px 1.96317px 0px, #681BE0 5.2655px 2.87655px 0px, #681BE0 4.71532px 3.71022px 0px, #681BE0 4.03447px 4.44106px 0px, #681BE0 3.24181px 5.04883px 0px, #681BE0 2.35931px 5.51667px 0px, #681BE0 1.41143px 5.83163px 0px, #681BE0 0.424423px 5.98497px 0px, #681BE0 -0.574341px 5.97245px 0px, #681BE0 -1.55719px 5.79441px 0px, #681BE0 -2.49688px 5.45578px 0px, #681BE0 -3.36738px 4.96596px 0px, #681BE0 -4.14455px 4.33852px 0px, #681BE0 -4.80686px 3.59083px 0px, #681BE0 -5.33596px 2.74364px 0px, #681BE0 -5.71718px 1.8204px 0px, #681BE0 -5.93995px 0.84672px 0px, #681BE0 -5.99811px -0.150428px 0px, #681BE0 -5.89004px -1.14341px 0px, #681BE0 -5.61874px -2.1047px 0px, #681BE0 -5.19172px -3.00766px 0px, #681BE0 -4.62082px -3.82727px 0px, #681BE0 -3.92186px -4.54081px 0px, #681BE0 -3.11421px -5.12852px 0px, #681BE0 -2.22026px -5.57409px 0px, #681BE0 -1.26477px -5.86518px 0px, #681BE0 -0.274238px -5.99373px 0px, #681BE0 0.723898px -5.95617px 0px, #681BE0 1.70197px -5.75355px 0px, #681BE0 2.63288px -5.39147px 0px, #681BE0 3.49082px -4.87998px 0px, #681BE0 4.25202px -4.23324px 0px, #681BE0 4.89538px -3.46919px 0px, #681BE0 5.40307px -2.60899px 0px, #681BE0 5.76102px -1.67649px 0px, #681BE0 5.95932px -0.697531px 0px;
      
    }
  }

  .sidebar-content_modal {
    background-image: url("/img/imgUser/imgGame/Rectángulo 2849.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 23vw;
    height: 30vw;
    border-radius: 1vw;
    margin-top: .8vw;

    .container-description {
      width: 21vw;
      display: flex;
      justify-content: left;
      align-items: center;
      border-bottom: 0.1vw solid #ffffff;
      margin-top: 0.3vw;
      margin-bottom: 0.5vw;

      .text-description {
        color: #ffffff;
        margin-left: 0.5vw;
        font-size: 1vw;
      }
    }

    .game-state-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: end;
      width: 22vw;
      height: 27vw;
      overflow-y: scroll;
      direction: rtl;

      &::-webkit-scrollbar {
        width: 0.5vw; /* Ancho del scrollbar */
      }

      &::-webkit-scrollbar-track {
        background-color: #ffffff;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #00000038;
        border-radius: 10px;
        height: 3px;
      }
    }

    .game-state-container-mobile {
      display: none;
    }
  }

  .loadingSingleGame {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media ${Device.tablet} {
    background-image: url("/img/imgUser/mobile/Rectángulo-Header-Mobile.png");
    justify-content: start;
    width: 87vw;
    height: 42.5vw;
    margin-top: 7vw;

    .sidebar-header {
      background-image: url("/img/imgUser/mobile/Rectángulo-purple-header.png");
      width: 80vw;
      height: 30vw;
      border-radius: 3vw;
      margin-top: 1vw;

      .container-circle-img {
        width: 30vw;
        height: 30vw;
        top: -5.2vw;
        left: -7vw;

        .game-img {
          width: 93%;
          height: 93%;
        }
      }

      .text-header {
        font-size: 6.5vw;
        display: block;
        width: 45vw;
        text-align: start;
      }
    }

    .sidebar-content_modal {
      display: flex;
      background-image: url("/img/imgUser/mobile/Rectángulo-modal-responsive.png");
      //background: ;
      width: 82vw;
      height: 10vw;
      border-radius: 5vw;
      margin-top: 1vw;
      margin-bottom: 1vw;

      .container-description {
        display: none;

        .text-description {
          display: none;
        }
      }

      .game-state-container {
        display: none;

        &::-webkit-scrollbar {
          display: none;
        }

        &::-webkit-scrollbar-track {
          display: none;
        }

        &::-webkit-scrollbar-thumb {
          display: none;
        }
      }

      .game-state-container-mobile {
        position: relative;
        display: flex;
        width: 80vw;

        .selected-game {
          width: 100%;
          gap: 2vw;
        }

        .toggle-games-button {
          background: transparent;
          border: none;
          position: absolute;
          right: 3vw;
          top: 4vw;
        }

        .dropdown-content {
          position: absolute;
          top: 12vw;
          left: 3vw;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: start;
          width: 71vw;
          color: white;
          z-index: 5;
          border-radius: 4vw;
          opacity: 90;
          background: #9c56d8;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.9);
          padding: 2vw;
          gap: 2vw;

          .icon-state {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1vw;
            font-weight: lighter;
            font-size: 5.1vw;
            margin-left: 2vw;

            .game-icon {
              width: 7vw;
            }
          }
        }
      }
    }
  }

  @media ${Device.mobileL} {
    background-image: url("/img/imgUser/mobile/Rectángulo-Header-Mobile.png");
    justify-content: start;
    width: 87vw;
    height: 42.5vw;
    margin-top: 7vw;

    .sidebar-header {
      background-image: url("/img/imgUser/mobile/Rectángulo-purple-header.png");
      width: 80vw;
      height: 30vw;
      border-radius: 3vw;
      margin-top: 1vw;

      .container-circle-img {
        width: 30vw;
        height: 30vw;
        top: -5.2vw;
        left: -7vw;

        .game-img {
          width: 93%;
          height: 93%;
        }
      }

      .text-header {
        font-size: 6.5vw;
        display: block;
        width: 45vw;
        text-align: start;
      }
    }

    .sidebar-content_modal {
      display: flex;
      background-image: url("/img/imgUser/mobile/Rectángulo-modal-responsive.png");
      //background: ;
      width: 82vw;
      height: 10vw;
      border-radius: 5vw;
      margin-top: 1vw;
      margin-bottom: 1vw;

      .container-description {
        display: none;

        .text-description {
          display: none;
        }
      }

      .game-state-container {
        display: none;

        &::-webkit-scrollbar {
          display: none;
        }

        &::-webkit-scrollbar-track {
          display: none;
        }

        &::-webkit-scrollbar-thumb {
          display: none;
        }
      }

      .game-state-container-mobile {
        position: relative;
        display: flex;
        width: 80vw;

        .selected-game {
          width: 100%;
          gap: 2vw;
        }

        .toggle-games-button {
          background: transparent;
          border: none;
          position: absolute;
          right: 3vw;
          top: 4vw;
        }

        .dropdown-content {
          position: absolute;
          top: 12vw;
          left: 3vw;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: start;
          width: 71vw;
          color: white;
          z-index: 5;
          border-radius: 4vw;
          opacity: 90;
          background: #9c56d8;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.9);
          padding: 2vw;
          gap: 2vw;

          .icon-state {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1vw;
            font-weight: lighter;
            font-size: 5.1vw;
            margin-left: 2vw;

            .game-icon {
              width: 7vw;
            }
          }
        }
      }
    }
  }
  @media ${Device.mobileM} {
    background-image: url("/img/imgUser/mobile/Rectángulo-Header-Mobile.png");
    justify-content: start;
    width: 87vw;
    height: 42.5vw;
    margin-top: 7vw;

    .sidebar-header {
      background-image: url("/img/imgUser/mobile/Rectángulo-purple-header.png");
      width: 80vw;
      height: 30vw;
      border-radius: 3vw;
      margin-top: 1vw;

      .container-circle-img {
        width: 30vw;
        height: 30vw;
        top: -5.2vw;
        left: -7vw;

        .game-img {
          width: 93%;
          height: 93%;
        }
      }

      .text-header {
        font-size: 6.5vw;
        display: block;
        width: 45vw;
        text-align: start;
      }
    }

    .sidebar-content_modal {
      display: flex;
      background-image: url("/img/imgUser/mobile/Rectángulo-modal-responsive.png");
      //background: ;
      width: 82vw;
      height: 10vw;
      border-radius: 5vw;
      margin-top: 1vw;
      margin-bottom: 1vw;

      .container-description {
        display: none;

        .text-description {
          display: none;
        }
      }

      .game-state-container {
        display: none;

        &::-webkit-scrollbar {
          display: none;
        }

        &::-webkit-scrollbar-track {
          display: none;
        }

        &::-webkit-scrollbar-thumb {
          display: none;
        }
      }

      .game-state-container-mobile {
        position: relative;
        display: flex;
        width: 80vw;

        .selected-game {
          width: 100%;
          gap: 2vw;
        }

        .toggle-games-button {
          background: transparent;
          border: none;
          position: absolute;
          right: 3vw;
          top: 4vw;
        }

        .dropdown-content {
          position: absolute;
          top: 12vw;
          left: 3vw;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: start;
          width: 71vw;
          color: white;
          z-index: 5;
          border-radius: 4vw;
          opacity: 90;
          background: #9c56d8;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.9);
          padding: 2vw;
          gap: 2vw;

          .icon-state {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1vw;
            font-weight: lighter;
            font-size: 5.1vw;
            margin-left: 2vw;

            .game-icon {
              width: 7vw;
            }
          }
        }
      }
    }
  }
  @media ${Device.mobileS} {
    background-image: url("/img/imgUser/mobile/Rectángulo-Header-Mobile.png");
    justify-content: start;
    width: 87vw;
    height: 42.5vw;
    margin-top: 7vw;

    .sidebar-header {
      background-image: url("/img/imgUser/mobile/Rectángulo-purple-header.png");
      width: 80vw;
      height: 30vw;
      border-radius: 3vw;
      margin-top: 1vw;

      .container-circle-img {
        width: 30vw;
        height: 30vw;
        top: -5.2vw;
        left: -7vw;

        .game-img {
          width: 93%;
          height: 93%;
        }
      }

      .text-header {
        font-size: 6.5vw;
        display: block;
        width: 45vw;
        text-align: start;
      }
    }

    .sidebar-content_modal {
      display: flex;
      background-image: url("/img/imgUser/mobile/Rectángulo-modal-responsive.png");
      //background: ;
      width: 82vw;
      height: 10vw;
      border-radius: 5vw;
      margin-top: 1vw;
      margin-bottom: 1vw;

      .container-description {
        display: none;

        .text-description {
          display: none;
        }
      }

      .game-state-container {
        display: none;

        &::-webkit-scrollbar {
          display: none;
        }

        &::-webkit-scrollbar-track {
          display: none;
        }

        &::-webkit-scrollbar-thumb {
          display: none;
        }
      }

      .game-state-container-mobile {
        position: relative;
        display: flex;
        width: 80vw;

        .selected-game {
          width: 100%;
          gap: 2vw;
        }

        .toggle-games-button {
          background: transparent;
          border: none;
          position: absolute;
          right: 3vw;
          top: 4vw;
        }

        .dropdown-content {
          position: absolute;
          top: 12vw;
          left: 3vw;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: start;
          width: 71vw;
          color: white;
          z-index: 5;
          border-radius: 4vw;
          opacity: 90;
          background: #9c56d8;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.9);
          padding: 2vw;
          gap: 2vw;

          .icon-state {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1vw;
            font-weight: lighter;
            font-size: 5.1vw;
            margin-left: 2vw;

            .game-icon {
              width: 7vw;
            }
          }
        }
      }
    }
  }


`
