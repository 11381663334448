export const ALL_USERS = 'ALL_USERS';
export const ALL_USERS_SUCCESS = 'ALL_USERS_SUCCESS';
export const ALL_USERS_FAILED = 'ALL_USERS_FAILED';
export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILED = 'CREATE_USER_FAILED';
export const CREATE_USER_LOCAL = 'CREATE_USER_LOCAL';
export const CREATE_USER_LOCAL_SUCCESS = 'CREATE_USER_LOCAL_SUCCESS';
export const CREATE_USER_LOCAL_FAILED = 'CREATE_USER_LOCAL_FAILED';
export const CREATE_USER_REL_ACCOUNT_AND_SESSION = 'CREATE_USER_REL_ACCOUNT_AND_SESSION';
export const CREATE_USER_REL_ACCOUNT_AND_SESSION_SUCCESS = 'CREATE_USER_REL_ACCOUNT_AND_SESSION_SUCCESS';
export const CREATE_USER_REL_ACCOUNT_AND_SESSION_FAILED = 'CREATE_USER_REL_ACCOUNT_AND_SESSION_FAILED';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED';
export const SEARCH_ALL_USERS = 'SEARCH_ALL_USERS';
export const SEARCH_ALL_USERS_SUCCESS = 'SEARCH_ALL_USERS_SUCCESS';
export const SEARCH_ALL_USERS_FAILED = 'SEARCH_ALL_USERS_FAILED';
export const CLEAN_SEARCH_ALL_USERS = 'CLEAN_SEARCH_ALL_USERS';
export const GET_USERS_SESSION_REL = 'GET_USERS_SESSION_REL';
export const GET_USERS_SESSION_REL_SUCCESS = 'GET_USERS_SESSION_REL_SUCCESS';
export const GET_USERS_SESSION_REL_FAILED = 'GET_USERS_SESSION_REL_FAILED';
export const CLEAN_USERS_SESSION_REL = 'CLEAN_USERS_SESSION_REL';
export const CREATE_USERS_AND_USER_SESSION_REL = 'CREATE_USERS_AND_USER_SESSION_REL';
export const CREATE_USERS_AND_USER_SESSION_REL_SUCCESS = 'CREATE_USERS_AND_USER_SESSION_REL_SUCCESS';
export const CREATE_USERS_AND_USER_SESSION_REL_FAILED = 'CREATE_USERS_AND_USER_SESSION_REL_FAILED';
export const GET_USERS_REL_SESSION_AND_CARDS = 'GET_USERS_REL_SESSION_AND_CARDS';
export const GET_USERS_REL_SESSION_AND_CARDS_SUCCESS = 'GET_USERS_REL_SESSION_AND_CARDS_SUCCESS';
export const GET_USERS_REL_SESSION_AND_CARDS_FAILED = 'GET_USERS_REL_SESSION_AND_CARDS_FAILED';
export const GET_USERS_WINNERS_EXPORT = 'GET_USERS_WINNERS_EXPORT';
export const GET_USERS_WINNERS_EXPORT_SUCCESS = 'GET_USERS_WINNERS_EXPORT_SUCCESS';
export const GET_USERS_WINNERS_EXPORT_FAILED = 'GET_USERS_WINNERS_EXPORT_FAILED';
export const CLEAN_USERS_WINNERS_EXPORT = 'CLEAN_USERS_WINNERS_EXPORT';


// en user

export const GET_END_USER_ACTIVE = 'GET_END_USER_ACTIVE';
export const GET_END_USER_ACTIVE_SUCCESS = 'GET_END_USER_ACTIVE_SUCCESS';
export const GET_END_USER_ACTIVE_FAILED = 'GET_END_USER_ACTIVE_FAILED';