import {CardsWinnerUserAndGameStyles} from "./CardsWinnerUserAndGameStyles";
import {useAppDispatch, useAppSelector} from "../../../shared/Utils/Hooks";
import {
    openAndCloseModalCardsWinnerUserAndGame
} from "../../../store/ActionsAdmin/ModalsActions";
import ButtonExportExcel from "../ButtonExportExcel/ButtonExportExcel";
import {cleanUsersWinnersExport} from "../../../store/ActionsAdmin/UserActions";

export default function CardsWinnerUserAndGame(): JSX.Element {

    const dispatch = useAppDispatch();
    const allCardsWinnerUserAndGame = useAppSelector((state) => state.cardsState.allCardsWinnerUserAndGame);
    const usersWinnersToExport = useAppSelector((state) => state.userState.usersWinnersToExport);

    function handleCloseModal() {
        dispatch(cleanUsersWinnersExport())
        dispatch(openAndCloseModalCardsWinnerUserAndGame(false));
    }

    return (
        <CardsWinnerUserAndGameStyles>
            <div className="container-title">
                <span className="title">Relación ganadores</span>
                <ButtonExportExcel
                    listExcel={usersWinnersToExport}
                    nameFile={usersWinnersToExport.length > 0 ? `Ganadores-juego-${usersWinnersToExport[0].name_game}.xlsx` : 'Ganadores.xlsx'}
                >Exportar</ButtonExportExcel>
                <span onClick={handleCloseModal} className="close">x</span>
            </div>
            <div className="container-scroll">
                <table>
                    <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Cédula</th>
                        <th>Teléfono</th>
                        <th>Tarjetón</th>
                        <th>Nombre del juego</th>
                        <th>Modo de juego</th>
                    </tr>
                    </thead>
                    <tbody>
                    {usersWinnersToExport.map((cardWinner, index) => (
                        <tr key={index}>
                            <td>{cardWinner.name}</td>
                            <td>{cardWinner.identification_document}</td>
                            <td>{cardWinner.phone}</td>
                            <td>{cardWinner.serial}</td>
                            <td>{cardWinner.name_game}</td>
                            <td>{cardWinner.game_mode}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </CardsWinnerUserAndGameStyles>
    );
}
