import {ImagesStorageStyles} from "./ImagesStorageStyles";
import {useAppDispatch, useAppSelector} from "../../../shared/Utils/Hooks";
import {useEffect, useRef, useState} from "react";
import {
    openAndCloseModalImagesStorage, openAndCloseModalImagesStorageGame
} from "../../../store/ActionsAdmin/ModalsActions";
import {IoIosAddCircleOutline} from "react-icons/io";
import {
    createImageStorage,
    deleteImageStorage,
    getAllImageStorage, handleSelectedImageBannerCreateSession, handleSelectedImageMainCreateGame,
    handleSelectedImageMainCreateSession
} from "../../../store/ActionsAdmin/BingosActions";
import {myDBDomain} from "../../../shared/Utils/TestingApi/MyDBDomain";

export default function ImagesStorage(): JSX.Element {

    const dispatch = useAppDispatch();
    const fileInputImage = useRef<HTMLInputElement | null>(null);
    const selectedClickAddImage = useAppSelector((state) => state.bingoState.selectedClickAddImage);
    const allImagesStorage = useAppSelector((state) => state.bingoState.allImagesStorage);

    function handleCloseModal() {
        dispatch(openAndCloseModalImagesStorage(false));
        dispatch(openAndCloseModalImagesStorageGame(false));
    }

    useEffect(() => {
        console.log('desde aqui viene el click');
        console.log(selectedClickAddImage);
    }, [selectedClickAddImage]);

    function handleImage(files: FileList | null) {
        if (files) {
            const file: File = files[0];
            console.log('esta es la imagen');
            console.log(file);
            dispatch(createImageStorage(file)).then(() => {
                dispatch(getAllImageStorage()).then(() => {});
            });
        }
    }

    function handleClickImage() {
        if (fileInputImage.current) {
            fileInputImage.current?.click();
        }
    }

    useEffect(() => {
        dispatch(getAllImageStorage()).then(() => {});
    }, []);

    function handleDeleteImage(image: string) {
        dispatch(deleteImageStorage(image)).then(() => {
            dispatch(getAllImageStorage()).then(() => {});
        });
    }

    function selectedImage(image: string) {
        if (selectedClickAddImage === 'selectImageMainCreateSession') {
            dispatch(handleSelectedImageMainCreateSession(image));
            handleCloseModal();
        }
        if (selectedClickAddImage === 'selectImageBannerCreateSession') {
            dispatch(handleSelectedImageBannerCreateSession(image));
            handleCloseModal();
        }
        if (selectedClickAddImage === 'selectImageMainCreateGame') {
            dispatch(handleSelectedImageMainCreateGame(image));
            handleCloseModal();
        }
    }

    return (
        <ImagesStorageStyles>
            <div className="container-title">
                <span className="title">Mis imágenes</span>
                <span onClick={handleCloseModal} className="close">x</span>
            </div>
            <div className="add-image">
                <div onClick={handleClickImage}>
                    <span>Agregar imagen</span>
                    <IoIosAddCircleOutline className="icon.add"/>
                    <input
                        ref={fileInputImage}
                        type="file"
                        data-max-size="2"
                        accept="image/*"
                        className="input-img"
                        onChange={(event) => handleImage(event.target.files)}
                    />
                </div>
            </div>
            <div className="container-images">
                {allImagesStorage.length > 0 && allImagesStorage.map((image, index) => (
                    <div className="container-image" key={index}>
                        <img
                            alt={index.toString()}
                            src={`${myDBDomain}/storage/${image}`}
                            onClick={() => selectedImage(image)}
                        />
                        <button onClick={() => handleDeleteImage(image)}>Eliminar</button>
                    </div>
                ))}
            </div>
        </ImagesStorageStyles>
    );
}
