import {CreateUserToSessionStyles} from "./CreateUserToSessionStyles";
import {useForm} from "react-hook-form";
import {useAppDispatch, useAppSelector} from "../../../shared/Utils/Hooks";
import {useRef} from "react";
import {
    openAndCloseModalCreateUser, openAndCloseModalUserRelAccountAndSession
} from "../../../store/ActionsAdmin/ModalsActions";
import {CreateUserModel} from "../../../domain/models/CreateUserModel";
import {createUserLocal, createUserRelAccountAndSession, createUsers} from "../../../store/ActionsAdmin/UserActions";
import {
    identificationDocumentUserValidate,
    numberPhoneValidate,
    userEmailValidate, userGameModeValidate,
    userNameValidate
} from "../../../shared/Utils/ValidationFormCreateUser";
import {getAccountActive} from "../../../store/ActionsAdmin/AccountActions";
import {CreateUserRelAccountRelSessionModel} from "../../../domain/models/CreateUserRelAccounRelSessionModel";
import {getSingleSessionDatabase} from "../../../store/ActionsAdmin/SessionActions";
import {cardLimit} from "../../../shared/Utils/ValidationformCreateSession";

export default function CreateUserToSession(): JSX.Element {

    const domain = window.location.origin;
    const dispatch = useAppDispatch();
    const {register, handleSubmit, reset, formState: {errors}} = useForm<CreateUserRelAccountRelSessionModel>();
    const isLoadingUserRelAccountAndSession = useAppSelector((state) => state.userState.isLoadingUserRelAccountAndSession);
    const domainActive = useAppSelector((state) => state.accountState.domainActive);
    const singleSessionDatabase = useAppSelector((state) => state.sessionState.singleSessionDatabase);

    function handleCreateUser(data: CreateUserRelAccountRelSessionModel) {
        if (domainActive && singleSessionDatabase) {
            if (data.cards) {
                const newDataUser = {
                    session_id: singleSessionDatabase.id,
                    cards: parseInt(data.cards),
                    bulk_users: [
                        {
                            name: data.name,
                            identification_document: data.identification_document,
                            phone: `57${data.phone}`,
                            email: data.email,
                            user_type: 'user',
                            accountId: domainActive.id
                        }
                    ]
                }
                dispatch(createUserRelAccountAndSession(newDataUser)).then(() => {
                    dispatch(getAccountActive({domain: domain})).then(() => {
                        dispatch(getSingleSessionDatabase(singleSessionDatabase.id)).then(() => {
                            reset();
                            dispatch(openAndCloseModalUserRelAccountAndSession(false));
                        });
                    });
                });
            } else {
                const newDataUser = {
                    session_id: singleSessionDatabase.id,
                    cards: singleSessionDatabase.card_limit,
                    bulk_users: [
                        {
                            name: data.name,
                            identification_document: data.identification_document,
                            phone: `57${data.phone}`,
                            email: data.email,
                            user_type: 'user',
                            accountId: domainActive.id
                        }
                    ]
                }
                dispatch(createUserRelAccountAndSession(newDataUser)).then(() => {
                    dispatch(getAccountActive({domain: domain})).then(() => {
                        dispatch(getSingleSessionDatabase(singleSessionDatabase.id)).then(() => {
                            reset();
                            dispatch(openAndCloseModalUserRelAccountAndSession(false));
                        });
                    });
                });
            }
        }
    }

    const onSubmit = (data: CreateUserRelAccountRelSessionModel) => handleCreateUser(data);

    function handleCloseModal() {
        dispatch(openAndCloseModalUserRelAccountAndSession(false));
    }

    return (
        <CreateUserToSessionStyles>
            <div className="container-title">
                <span className="title">Nuevo usuario</span>
                <span onClick={handleCloseModal} className="close">x</span>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="form">
                <div className="container-img-inputs">
                    <div className="container-inputs">
                        <div className="container-input">
                            <input
                                {...register('name', {
                                    validate: userNameValidate
                                })}
                                className="input-session-name"
                                type="text"
                                placeholder="Nombre del usuario"
                            />
                            {errors.name?.type === 'validate' && <span className='error'>{errors.name.message}</span>}
                        </div>
                        <div className="container-input">
                            <input
                                {...register('email', {
                                    validate: userEmailValidate
                                })}
                                className="input-session-name"
                                type="email"
                                placeholder="Correo electrónico"
                            />
                            {errors.email?.type === 'validate' && <span className='error'>{errors.email.message}</span>}
                        </div>
                        <div className="container-input">
                            <input
                                {...register('identification_document', {
                                    validate: identificationDocumentUserValidate
                                })}
                                className="input-session-name"
                                type="text"
                                placeholder="Cédula de ciudadanía"
                            />
                            {errors.identification_document?.type === 'validate' && <span className='error'>{errors.identification_document.message}</span>}
                        </div>
                        <div className="container-input">
                            <input
                                {...register('phone', {
                                    validate: numberPhoneValidate
                                })}
                                className="input-session-name"
                                type="text"
                                placeholder="Número de celular"
                                maxLength={10}
                            />
                            {errors.phone?.type === 'validate' && <span className='error'>{errors.phone.message}</span>}
                        </div>
                        {singleSessionDatabase && singleSessionDatabase.card_limit === 0 &&
                            <div className="container-input">
                                <input
                                    {...register('cards', {
                                        validate: cardLimit
                                    })}
                                    className="input-session-name"
                                    type="text"
                                    placeholder="Cantidad de tarjetones"
                                />
                                {errors.cards?.type === 'validate' &&
                                    <span className='error'>{errors.cards.message}</span>}
                            </div>
                        }
                    </div>
                </div>
                <div className="container-button">
                    <button
                        disabled={isLoadingUserRelAccountAndSession}
                        type="submit"
                        className="button-form"
                    >
                        {!isLoadingUserRelAccountAndSession ? 'Crear usuario' : 'Creando...'}
                    </button>
                </div>
            </form>
        </CreateUserToSessionStyles>
    );
}
