import {
    CLOSE_MODAL_CREATE_SESSION,
    CLOSE_MODAL_EDIT_SESSION,
    CLOSE_MODAL_USER_GAME_INFO,
    CLOSE_MODAL_USER_INFO,
    OPEN_AND_CLOSE_MODAL_ADD_BULK_USERS,
    OPEN_AND_CLOSE_MODAL_ADD_BULK_USERS_IN_CREATE_SESSION,
    OPEN_AND_CLOSE_MODAL_CARDS_WINNERS_USER_AND_GAME,
    OPEN_AND_CLOSE_MODAL_CONFIRM_OTP,
    OPEN_AND_CLOSE_MODAL_CREATE_GAME,
    OPEN_AND_CLOSE_MODAL_CREATE_USER,
    OPEN_AND_CLOSE_MODAL_CREATE_USER_REL_ACCOUNT_AND_SESSION,
    OPEN_AND_CLOSE_MODAL_CURRENT_GAME_DATA, OPEN_AND_CLOSE_MODAL_DEBUG_MESSAGE,
    OPEN_AND_CLOSE_MODAL_EDIT_GAME, OPEN_AND_CLOSE_MODAL_IMAGES_STORAGE, OPEN_AND_CLOSE_MODAL_IMAGES_STORAGE_GAME,
    OPEN_AND_CLOSE_MODAL_LAUNCH_BALLOT_MANUAL,
    OPEN_AND_CLOSE_MODAL_REGISTER,
    OPEN_AND_CLOSE_MODAL_SIGN_IN,
    OPEN_AND_CLOSE_MODAL_SINGLE_SESSION_USER_END,
    OPEN_AND_CLOSE_MODAL_USERS_REL_SESSION_AND_CARDS,
    OPEN_AND_CLOSE_MODAL_USERS_SESSION,
    OPEN_MODAL_CREATE_SESSION,
    OPEN_MODAL_EDIT_SESSION,
    OPEN_MODAL_USER_GAME_INFO,
    OPEN_MODAL_USER_INFO
} from "../TypesAdmin/ModalsType";

export const openCreateSession = () => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: OPEN_MODAL_CREATE_SESSION,
        payload: true
    });
}
export const closeCreateSession = () => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: CLOSE_MODAL_CREATE_SESSION,
        payload: false
    });
}
export const openEditSession = () => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: OPEN_MODAL_EDIT_SESSION,
        payload: true
    });
}
export const closeEditSession = () => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: CLOSE_MODAL_EDIT_SESSION,
        payload: false
    });
}
export const openUserInfo = (data:boolean) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: OPEN_MODAL_USER_INFO,
        payload: data
    });
}
export const closeUserInfo = () => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: CLOSE_MODAL_USER_INFO,
        payload: false
    });
}
export const openAdnCloseModalCreateGame = (action: boolean) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: OPEN_AND_CLOSE_MODAL_CREATE_GAME,
        payload: action
    });
}
export const openAndCloseModalEditGame = (action: boolean) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: OPEN_AND_CLOSE_MODAL_EDIT_GAME,
        payload: action
    });
}
export const openAndCloseModalCreateUser = (action: boolean) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: OPEN_AND_CLOSE_MODAL_CREATE_USER,
        payload: action
    });
}
export const openAndCloseModalSignIn = (action: boolean) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: OPEN_AND_CLOSE_MODAL_SIGN_IN,
        payload: action
    });
}
export const openAndCloseModalConfirmOTP = (action: boolean) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: OPEN_AND_CLOSE_MODAL_CONFIRM_OTP,
        payload: action
    });
}
export const openAndCloseModalRegister = (action: boolean) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: OPEN_AND_CLOSE_MODAL_REGISTER,
        payload: action
    });
}

export const openAndCloseModalUsersSession = (action: boolean) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: OPEN_AND_CLOSE_MODAL_USERS_SESSION,
        payload: action
    });
}
export const openAndCloseModalLaunch_Ballot_Manual = (ballot: string) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    console.log(ballot);
    dispatch({
        type: OPEN_AND_CLOSE_MODAL_LAUNCH_BALLOT_MANUAL,
        payload: ballot
    });
}
export const openAndCloseModalSingleSessionUserEnd = (action: boolean) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: OPEN_AND_CLOSE_MODAL_SINGLE_SESSION_USER_END,
        payload: action
    });
}

export const openUserGameInfo = (action:boolean) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: OPEN_MODAL_USER_GAME_INFO,
        payload: action
    });
}
export const closeUserGameInfo = () => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: CLOSE_MODAL_USER_GAME_INFO,
        payload: false
    });
}
export const openAndCloseModalAddBulkUsers = (action:boolean) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: OPEN_AND_CLOSE_MODAL_ADD_BULK_USERS,
        payload: action
    });
}
export const openAndCloseModalAddBulkUsersInCreateSession = (action:boolean) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: OPEN_AND_CLOSE_MODAL_ADD_BULK_USERS_IN_CREATE_SESSION,
        payload: action
    });
}
export const openAndCloseModalUsersRelSessionAndCards = (action:boolean) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: OPEN_AND_CLOSE_MODAL_USERS_REL_SESSION_AND_CARDS,
        payload: action
    });
}
export const openAndCloseModalCardsWinnerUserAndGame = (action:boolean) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: OPEN_AND_CLOSE_MODAL_CARDS_WINNERS_USER_AND_GAME,
        payload: action
    });
}
export const openAndCloseModalUserRelAccountAndSession = (action:boolean) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: OPEN_AND_CLOSE_MODAL_CREATE_USER_REL_ACCOUNT_AND_SESSION,
        payload: action
    });
}
export const openAndCloseModalCurrentGameDAta = (action:boolean) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: OPEN_AND_CLOSE_MODAL_CURRENT_GAME_DATA,
        payload: action
    });
}
export const openAndCloseModalImagesStorage = (action:boolean) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: OPEN_AND_CLOSE_MODAL_IMAGES_STORAGE,
        payload: action
    });
}
export const openAndCloseModalImagesStorageGame = (action:boolean) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: OPEN_AND_CLOSE_MODAL_IMAGES_STORAGE_GAME,
        payload: action
    });
}
export const openAndCloseModalDebugMessage = (action:boolean) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: OPEN_AND_CLOSE_MODAL_DEBUG_MESSAGE,
        payload: action
    });
}
