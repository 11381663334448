import {NavStyled} from "./NavStyled";
import {useState} from "react";
import {IParams} from "../ShowSession/ShowSession";
import {useHistory} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../shared/Utils/Hooks";
import {changeOptionNav} from "../../../store/ActionsAdmin/SelectedOptionActions";
import {
    closeEditSession,
    openAdnCloseModalCreateGame,
    openAndCloseModalUsersSession, openUserInfo
} from "../../../store/ActionsAdmin/ModalsActions";

export default function Nav({id}:IParams): JSX.Element {

    const history = useHistory();
    const dispatch = useAppDispatch();
    const optionNav = useAppSelector((state) => state.selectedOptionState.optionNav)

    function handleOptionNav(optionNav: string) {
        dispatch(changeOptionNav(optionNav));
        if (optionNav === 'games') {
            dispatch(openAndCloseModalUsersSession(false));
            dispatch(openUserInfo(false));
            history.push(`/admin/session/${id}/games`);
        }
        if (optionNav === 'information') {
            dispatch(openAdnCloseModalCreateGame(false));
            dispatch(openUserInfo(false));
            history.push(`/admin/session/${id}/information`);
        }
        if (optionNav === 'Sponsors') {
            dispatch(openAdnCloseModalCreateGame(false));
            dispatch(closeEditSession());
            dispatch(openAndCloseModalUsersSession(false));
            dispatch(openUserInfo(false));
            history.push(`/admin/session/${id}/sponsors`);
        }
        if (optionNav === 'Awards') {
            dispatch(closeEditSession());
            dispatch(openAdnCloseModalCreateGame(false));
            dispatch(openAndCloseModalUsersSession(false));
            dispatch(openUserInfo(false));
            history.push(`/admin/session/${id}/awards`);
        }
        if (optionNav === 'Acquire-cards') {
            dispatch(closeEditSession());
            dispatch(openAdnCloseModalCreateGame(false));
            dispatch(openAndCloseModalUsersSession(false));
            dispatch(openUserInfo(false));
            history.push(`/admin/session/${id}/acquireCards`);
        }
    }

    return (
        <NavStyled>
            <h3 onClick={() => handleOptionNav('games')} className={optionNav === 'games' ? 'link-active' : 'link'}>¡Juegos!</h3>
            {/*<div className="container-links">*/}
            {/*    <h3 onClick={() => handleOptionNav('information')} className={optionNav === 'information' ? 'link-active' : 'link'}>Información</h3>*/}
            {/*    <h3 onClick={() => handleOptionNav('Sponsors')} className={optionNav === 'Sponsors' ? 'link-active' : 'link'}>Patrocinadores</h3>*/}
            {/*    <h3 onClick={() => handleOptionNav('Awards')} className={optionNav === 'Awards' ? 'link-active' : 'link'}>Premios</h3>*/}
            {/*    <h3 onClick={() => handleOptionNav('Acquire-cards')} className={optionNav === 'Acquire-cards' ? 'link-active' : 'link'}>Adquiere tarjetones</h3>*/}
            {/*</div>*/}
        </NavStyled>
    );
}
