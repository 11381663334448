export function userNameValidate(value:string) {
    if (value === '') {
        return "Nombre de usuario requerido...";
    }

    if (value.length > 30) {
        return "max 15 caracteres...";
    }

    return true;
}

export function userEmailValidate(value: string) {
    if (value === '') {
        return "Correo electronico requerido...";
    }

    return true;
}

export function identificationDocumentUserValidate(value: string) {
    if (!value) {
        return "Cedula requerida...";
    }
    let identificationDocument:number = parseInt(value, 10);
    if (isNaN(identificationDocument)) {
        return "Ingresa un número válido...";
    }

    return true;
}

export function numberPhoneValidate(value: string) {
    if (!value) {
        return "Numero celular requerido...";
    }
    let numberPhone:number = parseInt(value, 10);
    if (isNaN(numberPhone)) {
        return "Ingrese un numero valido..."
    }
    if (value.length < 10) {
        return 'El teléfono debe tener 10 cifras...';
    }
    if (value.length > 10) {
        return "El telefono no debe pasar las 10 cifras...";
    }

    return true;
}

export function userGameModeValidate(value:string) {
    if (value === '') {
        return 'Tipo usuario requerido...';
    }
    return true;
}




// export function winnerNumber(value:string) {
//     console.log(value);
//     if (!value) {
//         return "Limite de ganadores requerido";
//     }
//     let winner_number:number = parseInt(value, 10);
//     if (isNaN(winner_number)) {
//         return 'Ingresa un número válido';
//     }
//     if (winner_number < 1) {
//         return 'El número debe ser igual o mayor que 1';
//     }
//     return true;
// }
