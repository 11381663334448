import {CHANGE_OPTION_NAV, CHANGE_SELECTED_OPTIONS, UPDATE_SELECTED_OPTIONS} from "../TypesAdmin/SelectedOptionTypes";

export const changeSelectedOptions = (selected_option:string) => (dispatch: (arg0: { type:any; payload:any }) => void) => {
    dispatch({
        type: CHANGE_SELECTED_OPTIONS,
        payload: selected_option
    });
}
export const updateSelectedOptions = () => (dispatch: (arg0: { type:any; payload:any }) => void) => {
    dispatch({
        type: UPDATE_SELECTED_OPTIONS,
        payload: 'semi'
    });
}

export const changeOptionNav = (option: string) => (dispatch: (arg0: { type:any; payload:any }) => void) => {
    dispatch({
        type: CHANGE_OPTION_NAV,
        payload: option
    });
}
