import styled from "styled-components";
import {Device} from "../../../shared/Utils/devices";

export const SessionUserStyled = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 26vw;
  height: 58vh;

  .container-circle-img {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background: #ffffff;
    width: 13vw;
    height: 13vw;
    z-index: 1;

    .img-session-small {
      width: 95%;
      height: 95%;
      border-radius: 100%;
    }
  }

  .container-card-ext {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 24vw;
    height: 23.4vw;
    background-image: url("/img/imgUser/imgHome/Rectángulo 254.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 4vw;

    .container-card-int {
      position: absolute;
      top: .3vw;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 22vw;
      height: 19.5vw;
      background-image: url("/img/imgUser/imgHome/Rectángulo 253.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      //border-radius: .5vw;

      .container-text-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 22vw;
        z-index: 1;

        .text-session-name {
          width: 100%;
          font-family: RoundedMplus1c-ExtraBold, sans-serif;
          font-weight: 900;
          font-size: 1.8vw;
          padding: .3vw;
          letter-spacing: -1.52px;
          color: #FFFFFF;
          opacity: 1;
          white-space: nowrap;
          text-align: center;
          text-overflow: ellipsis;
          overflow: hidden;
          line-height: 1.3;
          text-shadow: #681BE0 6px 0px 0px, #681BE0 5.91686px 0.995377px 0px, #681BE0 5.66974px 1.96317px 0px, #681BE0 5.2655px 2.87655px 0px, #681BE0 4.71532px 3.71022px 0px, #681BE0 4.03447px 4.44106px 0px, #681BE0 3.24181px 5.04883px 0px, #681BE0 2.35931px 5.51667px 0px, #681BE0 1.41143px 5.83163px 0px, #681BE0 0.424423px 5.98497px 0px, #681BE0 -0.574341px 5.97245px 0px, #681BE0 -1.55719px 5.79441px 0px, #681BE0 -2.49688px 5.45578px 0px, #681BE0 -3.36738px 4.96596px 0px, #681BE0 -4.14455px 4.33852px 0px, #681BE0 -4.80686px 3.59083px 0px, #681BE0 -5.33596px 2.74364px 0px, #681BE0 -5.71718px 1.8204px 0px, #681BE0 -5.93995px 0.84672px 0px, #681BE0 -5.99811px -0.150428px 0px, #681BE0 -5.89004px -1.14341px 0px, #681BE0 -5.61874px -2.1047px 0px, #681BE0 -5.19172px -3.00766px 0px, #681BE0 -4.62082px -3.82727px 0px, #681BE0 -3.92186px -4.54081px 0px, #681BE0 -3.11421px -5.12852px 0px, #681BE0 -2.22026px -5.57409px 0px, #681BE0 -1.26477px -5.86518px 0px, #681BE0 -0.274238px -5.99373px 0px, #681BE0 0.723898px -5.95617px 0px, #681BE0 1.70197px -5.75355px 0px, #681BE0 2.63288px -5.39147px 0px, #681BE0 3.49082px -4.87998px 0px, #681BE0 4.25202px -4.23324px 0px, #681BE0 4.89538px -3.46919px 0px, #681BE0 5.40307px -2.60899px 0px, #681BE0 5.76102px -1.67649px 0px, #681BE0 5.95932px -0.697531px 0px;
        }

        .text-session-info {
          font-family: RoundedMplus1c-ExtraBold, sans-serif;
          font-weight: 900;
          font-size: 1.8vw;
          text-align: center;
          letter-spacing: -1.49px;
          color: #FFFFFF;
          opacity: 1;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 100%;
          height: 3vw;
          overflow: hidden;
          line-height: 1;
          text-shadow: #681BE0 6px 0px 0px, #681BE0 5.91686px 0.995377px 0px, #681BE0 5.66974px 1.96317px 0px, #681BE0 5.2655px 2.87655px 0px, #681BE0 4.71532px 3.71022px 0px, #681BE0 4.03447px 4.44106px 0px, #681BE0 3.24181px 5.04883px 0px, #681BE0 2.35931px 5.51667px 0px, #681BE0 1.41143px 5.83163px 0px, #681BE0 0.424423px 5.98497px 0px, #681BE0 -0.574341px 5.97245px 0px, #681BE0 -1.55719px 5.79441px 0px, #681BE0 -2.49688px 5.45578px 0px, #681BE0 -3.36738px 4.96596px 0px, #681BE0 -4.14455px 4.33852px 0px, #681BE0 -4.80686px 3.59083px 0px, #681BE0 -5.33596px 2.74364px 0px, #681BE0 -5.71718px 1.8204px 0px, #681BE0 -5.93995px 0.84672px 0px, #681BE0 -5.99811px -0.150428px 0px, #681BE0 -5.89004px -1.14341px 0px, #681BE0 -5.61874px -2.1047px 0px, #681BE0 -5.19172px -3.00766px 0px, #681BE0 -4.62082px -3.82727px 0px, #681BE0 -3.92186px -4.54081px 0px, #681BE0 -3.11421px -5.12852px 0px, #681BE0 -2.22026px -5.57409px 0px, #681BE0 -1.26477px -5.86518px 0px, #681BE0 -0.274238px -5.99373px 0px, #681BE0 0.723898px -5.95617px 0px, #681BE0 1.70197px -5.75355px 0px, #681BE0 2.63288px -5.39147px 0px, #681BE0 3.49082px -4.87998px 0px, #681BE0 4.25202px -4.23324px 0px, #681BE0 4.89538px -3.46919px 0px, #681BE0 5.40307px -2.60899px 0px, #681BE0 5.76102px -1.67649px 0px, #681BE0 5.95932px -0.697531px 0px;
        }
      }
    }

    .button-session-card {
      position: absolute;
      bottom: -1.4vw;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      background: #ffffff;
      color: #7202E3;
      font-size: 1.5vw;
      font-weight: bold;
      border-radius: 0.5vw;
      cursor: pointer;
      transition: box-shadow 0.3s ease;
      box-sizing: border-box;
      width: 20vw;
      height: 3vw;
    }

    .button-session-card:hover {
      box-shadow: 0px 0px 10px rgba(149, 69, 255, 0.7), 0px 0px 20px rgba(196, 145, 252, 0.7);
    }
  }

  @media ${Device.laptop} {
    .container-card-ext {
      width: 25vw;
      height: 24.2vw;
      margin-top: 4vw;
    }
  }


  @media ${Device.tablet} {
    width: 38.2vw;
    height: 51vw;

    .container-circle-img {
      width: 16vw;
      height: 16vw;


      .img-session-small {
        width: 95%;
        height: 95%;
        border-radius: 100%;
      }
    }

    .container-card-ext {
      height: 73%;
      margin-top: 4vw;
      width: 38vw;

      .container-card-int {
        width: 90%;
        height: 31vw;


        .container-text-card {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          z-index: 1;

          .text-session-name {
            width: 100%;
            font-family: RoundedMplus1c-ExtraBold, sans-serif;
            font-weight: 900;
            font-size: 4vw;
            letter-spacing: -1.52px;
            color: #FFFFFF;
            opacity: 1;
            white-space: nowrap;
            text-align: center;
            text-overflow: ellipsis;
            overflow: hidden;
            line-height: 1.3;
          }

          .text-session-info {
            font-family: RoundedMplus1c-ExtraBold, sans-serif;
            font-weight: 900;
            font-size: 3vw;
            text-align: center;
            letter-spacing: -1.49px;
            color: #FFFFFF;
            opacity: 1;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
            height: 5vw;
            overflow: hidden;
            line-height: 1;
          }
        }
      }

      .button-session-card {
        font-size: 2.8vw;
        box-sizing: border-box;
        width: 32vw;
        height: 5vw;
        bottom: -2.4vw;
      }
    }
  }

  @media ${Device.mobileL} {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 50vh;

    .container-circle-img {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      background: #ffffff;
      width: 42vw;
      height: 42vw;
      z-index: 1;

      .img-session-small {
        width: 94%;
        height: 94%;
        border-radius: 100%;
      }
    }

    .container-card-ext {
      width: 76vw;
      height: 59vw;
      background-image: url("/img/imgUser/mobile/Rectángulo-session-active 254.png");
      margin-top: 12vw;

      .container-card-int {
        top: 1vw;
        width: 68vw;
        height: 48vw;
        background-image: url("/img/imgUser/imgHome/Rectángulo 253.svg");
        border-radius: 4vw;

        .container-text-card {
          width: 100%;
          margin-top: 12vw;

          .text-session-name {
            font-size: 7vw;
          }

          .text-session-info {
            font-size: 6vw;
            width: 100%;
            height: 8vw;
          }
        }
      }

      .button-session-card {
        bottom: -4vw;
        font-size: 5vw;
        border-radius: 3.5vw;
        box-sizing: border-box;
        width: 60vw;
        height: 9vw;
      }
    }
  }
  @media ${Device.mobileM} {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 50vh;

    .container-circle-img {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      background: #ffffff;
      width: 42vw;
      height: 42vw;
      z-index: 1;

      .img-session-small {
        width: 94%;
        height: 94%;
        border-radius: 100%;
      }
    }

    .container-card-ext {
      width: 76vw;
      height: 59vw;
      background-image: url("/img/imgUser/mobile/Rectángulo-session-active 254.png");
      margin-top: 12vw;

      .container-card-int {
        top: 1vw;
        width: 68vw;
        height: 48vw;
        background-image: url("/img/imgUser/imgHome/Rectángulo 253.svg");
        border-radius: 4vw;

        .container-text-card {
          width: 100%;
          margin-top: 12vw;

          .text-session-name {
            font-size: 7vw;
          }

          .text-session-info {
            font-size: 6vw;
            width: 100%;
            height: 8vw;
          }
        }
      }

      .button-session-card {
        bottom: -4vw;
        font-size: 5vw;
        border-radius: 3.5vw;
        box-sizing: border-box;
        width: 60vw;
        height: 9vw;
      }
    }
  }
  @media ${Device.mobileS} {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 50vh;

    .container-circle-img {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      background: #ffffff;
      width: 42vw;
      height: 42vw;
      z-index: 1;

      .img-session-small {
        width: 94%;
        height: 94%;
        border-radius: 100%;
      }
    }

    .container-card-ext {
      width: 76vw;
      height: 59vw;
      background-image: url("/img/imgUser/mobile/Rectángulo-session-active 254.png");
      margin-top: 12vw;

      .container-card-int {
        top: 1vw;
        width: 68vw;
        height: 48vw;
        background-image: url("/img/imgUser/imgHome/Rectángulo 253.svg");
        border-radius: 4vw;

        .container-text-card {
          width: 100%;
          margin-top: 12vw;

          .text-session-name {
            font-size: 7vw;
          }

          .text-session-info {
            font-size: 6vw;
            width: 100%;
            height: 8vw;
          }
        }
      }

      .button-session-card {
        bottom: -4vw;
        font-size: 5vw;
        border-radius: 3.5vw;
        box-sizing: border-box;
        width: 60vw;
        height: 9vw;
      }
    }
  }
`
