export const IS_AUTHORIZED = "IS_AUTHORIZED";
export const IS_NOT_AUTHORIZED = "IS_NOT_AUTHORIZED";
export const GET_USER_ACTIVE = "GET_USER_ACTIVE";
export const GET_USER_ACTIVE_SUCCESS = "GET_USER_ACTIVE_SUCCESS";
export const GET_USER_ACTIVE_FAILED = "GET_USER_ACTIVE_FAILED";
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILED = "LOGOUT_FAILED";
export const VERIFY_NUMBER_PHONE = "VERIFY_NUMBER_PHONE";
export const VERIFY_NUMBER_PHONE_SUCCESS = "VERIFY_NUMBER_PHONE_SUCCESS";
export const VERIFY_NUMBER_PHONE_FAILED = "VERIFY_NUMBER_PHONE_FAILED";
export const VERIFY_NUMBER_PHONE1 = "VERIFY_NUMBER_PHONE1";
export const VERIFY_NUMBER_PHONE1_SUCCESS = "VERIFY_NUMBER_PHONE1_SUCCESS";
export const VERIFY_NUMBER_PHONE1_FAILED = "VERIFY_NUMBER_PHONE1_FAILED";
export const NUMBER_PHONE_LOGIN = "NUMBER_PHONE_LOGIN";
export const CONFIRMATION_RESULT_FIREBASE = "CONFIRMATION_RESULT_FIREBASE";
export const CHANGE_LOADING_SIGN_IN = "CHANGE_LOADING_SIGN_IN";
export const CHANGE_LOADING_REGISTER = "CHANGE_LOADING_REGISTER";
export const CHANGE_LOADING_OTP = "CHANGE_LOADING_OTP";