import {UserManagementStyles} from "./UserManagementStyles"
import {changeSessionSelected} from "../../../store/ActionsAdmin/SessionActions";
import {useAppDispatch, useAppSelector} from "../../../shared/Utils/Hooks";
import {useHistory} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {
    openAndCloseModalAddBulkUsers,
    openAndCloseModalCreateUser,
    openUserInfo
} from "../../../store/ActionsAdmin/ModalsActions";
import {
    cleanAllUsersSearch,
    deleteUsers,
    getAllUsersSearch
} from "../../../store/ActionsAdmin/UserActions";
import CreateUser from "../CreateUser/CreateUser";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {handleDateNow} from "../../../shared/Utils/DateNow";
import {getAccountActive} from "../../../store/ActionsAdmin/AccountActions";
import {alertConfirmDelete} from "../../../shared/Utils/AlertConfirmDelete";
import AddBulkUser from "../AddBulkUser/AddBulkUser";
import ButtonExportExcel from "../ButtonExportExcel/ButtonExportExcel";
import {alertError} from "../../../shared/Utils/AlertError";
import {alertInfo} from "../../../shared/Utils/AlertInfo";
import {getListUserManagementExportExcel} from "../../../shared/Utils/getListUserManagementExportExcel";


export default function UserManagement(): JSX.Element {

    let timeout: string | number | NodeJS.Timeout | undefined;
    const dispatch = useAppDispatch();
    const domainActive = useAppSelector((state) => state.accountState.domainActive);
    const allUsersSearch = useAppSelector((state) => state.userState.allUsersSearch);
    const showModalCreateUser = useAppSelector((state) => state.modalState.showModalCreateUser);
    const showModalAddBulkUsers = useAppSelector((state) => state.modalState.showModalAddBulkUsers);
    const history = useHistory();
    const domain = window.location.origin;
    const [listCheckbox, setListCheckbox] = useState<number[]>([]);
    const [listUsersExportExcel, setListUsersExportExcel] = useState<any[]>([]);

    // function exportTableUserPDF() {
    //     console.log('hice click en exportar pdf');
    //     const showDAte = handleDateNow();
    //     const refTableUser = document.getElementById('table-users');
    //     if (refTableUser) {
    //         html2canvas(refTableUser).then((canvas) => {
    //             const imgData = canvas.toDataURL('image/png');
    //             const pdf = new jsPDF('p', 'mm', 'a4');
    //             pdf.addImage(imgData, 'PNG', 10, 10, 190, 0);
    //             pdf.save(`tabla-usuarios ${showDAte}.pdf`);
    //         });
    //     }
    // }

    function redirectHome() {
        history.push('/admin/options');
        dispatch(changeSessionSelected(undefined));
        dispatch(openUserInfo(false));
        dispatch(openAndCloseModalCreateUser(false));
    }

    function openModalCreateUser() {
        dispatch(openAndCloseModalCreateUser(true));
        dispatch(openAndCloseModalAddBulkUsers(false));
    }

    function handleCheckbox(check: boolean, id: number) {
        if (check) {
            setListCheckbox([...listCheckbox, id]);
        } else {
            const newListCheckbox: number[] = listCheckbox.filter(number => number !== id);
            setListCheckbox(newListCheckbox);
        }
    }

    useEffect(() => {
        if (domainActive) {
            const dataListUserExport = getListUserManagementExportExcel(domainActive.users);
            if (dataListUserExport.length === 0) {
                alertInfo('No hay usuarios por el momento');
            } else {
                setListUsersExportExcel(dataListUserExport);
            }
            console.log(dataListUserExport);
        } else {
            alertError('No hay un dominio activo');
        }
    }, [domainActive]);

    useEffect(() => {
        console.log(allUsersSearch);
        console.log(listCheckbox);
    }, [listCheckbox]);

    async function handleDeleteUser() {
        const confirmDeleteUser = await alertConfirmDelete();
        if (confirmDeleteUser) {
            if (listCheckbox.length !== 0) {
                await Promise.all(listCheckbox.map(async (id) => {
                    await dispatch(deleteUsers(id));
                }));
                await dispatch(getAccountActive({domain: domain}));
                setListCheckbox([]);
                dispatch(cleanAllUsersSearch());
            }
        }
    }

    function handleSearchUser(search: string) {
        if (search === '') {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                dispatch(cleanAllUsersSearch());
            }, 1000);
        } else {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                if (domainActive) {
                    dispatch(getAllUsersSearch(search, domainActive.id)).then(() => {
                    });
                }
            }, 1000);
        }
    }

    useEffect(() => {
        console.log(domain);
        dispatch(getAccountActive({domain: domain})).then(r => {
        });
    }, [domain]);

    // function openModalAddBulkUsers() {
    //     dispatch(openAndCloseModalAddBulkUsers(true));
    //     dispatch(openAndCloseModalCreateUser(false));
    // }

    function handleCheckAllUsers(checked: boolean) {
        if (domainActive && domainActive.users.length > 0) {
            if (allUsersSearch.length > 0) {
                if (checked) {
                    const list:number[] = [];
                    allUsersSearch.map((user) => {
                        list.push(user.id);
                    });
                    setListCheckbox(list);
                } else {
                    setListCheckbox([]);
                }
            } else {
                if (checked) {
                    const list:number[] = [];
                    domainActive.users.map((user) => {
                        list.push(user.id);
                    });
                    setListCheckbox(list);
                } else {
                    setListCheckbox([]);
                }
            }
        }
    }

    return (
        <UserManagementStyles>
            <div className="user-management-container">
                <div className="icon-back-container">
                    <img onClick={redirectHome} className="icon-back" alt="back"
                         src="/img/newImage/optionsAdmin/icon-back.png"/>
                </div>
                <img className="icon-group-users" alt="group-users" src="/img/newImage/optionsAdmin/group-users.png"/>
                <h1>Gestión de Usuarios</h1>
            </div>
            <div className="options-container">
                <input
                    type="search"
                    onChange={(event) => handleSearchUser(event.target.value)}
                />
                <img className="icon-search" alt="search" src="/img/newImage/optionsAdmin/icon-search.png"/>
                <div className="add-user-container">
                    <img className="icon-add-user" alt="add-user" src="/img/newImage/optionsAdmin/add-user.png"/>
                    <span onClick={openModalCreateUser}>Agregar usuario</span>
                </div>
                <div className="add-user-container">
                    <img className="icon-add-user" alt="add-user" src="/img/newImage/optionsAdmin/add-user.png"/>
                    <span>Importar usuarios</span>
                </div>
                <div onClick={handleDeleteUser} className="delete-user-container">
                    <img className="icon-delete-user" alt="delete-user"
                         src="/img/newImage/optionsAdmin/delete-user.png"/>
                    <span>Eliminar usuario</span>
                </div>
                {/*<div  className="export-container">*/}
                {/*    <img className="icon-export" alt="search" src="/img/newImage/optionsAdmin/export.png"/>*/}
                {/*    <span>Exportar</span>*/}
                {/*</div>*/}
                <ButtonExportExcel listExcel={listUsersExportExcel} nameFile={`user-management-${handleDateNow()}.xlsx`}>
                    Exportar
                </ButtonExportExcel>
            </div>
            <div className="table-container">
                <table id="table-users">
                    <thead>
                    <tr>
                        <th className="check-input-column">
                            <input
                                id="all-users"
                                type="checkbox"
                                onChange={(event) => handleCheckAllUsers(event.target.checked)}
                            />
                            <label htmlFor="all-users">Nombre</label>
                        </th>
                        <th>Telefono</th>
                        <th>Correo electrónico</th>
                    </tr>
                    </thead>
                    {allUsersSearch.length === 0
                        ?
                        <tbody>
                        {domainActive && domainActive.users.map((user, index) => (
                            <tr key={index}>
                                <td className="check-input-column">
                                    <input
                                        type="checkbox"
                                        checked={listCheckbox.includes(user.id)}
                                        id={user.id.toString()}
                                        onChange={(event) => handleCheckbox(event.target.checked, user.id)}
                                    />
                                    <label htmlFor={user.id.toString()}>{user.name}</label>
                                </td>
                                <td>{user.phone}</td>
                                <td className="icons-table">
                                    <div className="icons-container">
                                        {user.email}
                                        {/*    <img className="icon-ballot" alt="ballot" src="/img/newImage/optionsAdmin/optionsAdminImgBall8Small.png"/>*/}
                                        {/*    <img className="icon-calabaza" alt="calabaza" src="/img/newImage/optionsAdmin/calabza.png"/>*/}
                                        {/*    <img className="icon-new-session" alt="new-session" src="/img/newImage/optionsAdmin/new-session.png"/>*/}
                                    </div>
                                    <img className="icon-delete-user" alt="delete"
                                         src="/img/newImage/optionsAdmin/delete-user.png"/>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                        :
                        <tbody>
                        {allUsersSearch && allUsersSearch.map((user, index) => (
                            <tr key={index}>
                                <td className="check-input-column">
                                    <input
                                        type="checkbox"
                                        id={user.id.toString()}
                                        checked={listCheckbox.includes(user.id)}
                                        onChange={(event) => handleCheckbox(event.target.checked, user.id)}
                                    />
                                    <label htmlFor={user.id.toString()}>{user.name}</label>
                                </td>
                                <td>{user.phone}</td>
                                <td className="icons-table">
                                    <div className="icons-container">
                                        {user.email}
                                        {/*    <img className="icon-ballot" alt="ballot" src="/img/newImage/optionsAdmin/optionsAdminImgBall8Small.png"/>*/}
                                        {/*    <img className="icon-calabaza" alt="calabaza" src="/img/newImage/optionsAdmin/calabza.png"/>*/}
                                        {/*    <img className="icon-new-session" alt="new-session" src="/img/newImage/optionsAdmin/new-session.png"/>*/}
                                    </div>
                                    <img className="icon-delete-user" alt="delete"
                                         src="/img/newImage/optionsAdmin/delete-user.png"/>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    }
                </table>
            </div>
            {showModalCreateUser &&
                <div className="modal-user-info">
                    <CreateUser/>
                </div>
            }
            {showModalAddBulkUsers &&
                <div className="modal-add-bulk-users">
                    <AddBulkUser/>
                </div>
            }
        </UserManagementStyles>
    );
}
