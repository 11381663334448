import {UserModel} from "../../domain/models/UserAuthModel";

export function getListUserManagementExportExcel(listUserExport: any[]) {
    const listUserDataExport = [];
    if (listUserExport.length === 0) {
        return [];
    } else {
        for (const user of listUserExport) {
            const data = {
                name: user.name,
                email: user.email,
                identification_document: user.identification_document,
                phone: user.phone
            }
            listUserDataExport.push(data);
        }
        return listUserDataExport;
    }
}