import styled from "styled-components";

export const ShowSessionSponsorsStyled = styled.div`
  .sponsor {
    overflow-y: scroll;
    
    .quill {
      position: relative;
      width: 95%;
      margin-left: 0.7vw;
      top: 0.5vw;
    }

    .header-text-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 2vw;
      margin-left: 1.5vw;
      gap: 0.1vw;
      
      
      .word-logo-container {
        display: flex;
        align-items: center;
        height: 1vw;
        
        .icon-letter-L {
          width: 0.3vw;
          height: 100%;
          margin-top: 0.2vw;
        }

        .icon-letter-O {
          
          margin-top: 0.5vw;
          height: 90%;
          margin-left: 0.1vw;
        }

        .icon-letter-G {
          
          margin-top: 0.6vw;
          height: 100%;
        }
      }
      
      .icon-cat {
        width: 2vw;
        margin-top: 0.6vw;
      }
      
      .word-ipsum-container {
        display: flex;
        align-items: center;
        gap: 0;
        height: 1.5vw;

        .icon-letter-I {
          
          margin-top: 0.3vw;
          height: 75%;
        }

        .icon-letter-P {
          
          margin-top: 0.8vw;
          height: 75%;
        }

        .icon-letter-S {
          
          margin-top: 0.6vw;
          height: 63%;
        }

        .icon-letter-U {
          
          margin-top: 0.6vw;
          height: 54%;
          margin-left: 0;
        }

        .icon-letter-M {
         
          margin-top: 0.6vw;
          height: 54%;
          margin-left: 0.1vw;
        }
      }
    }
    
    .title-info {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-left: 1vw;
    }
    
    .text-container {
      width: 93%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-left: 1vw;
      margin-top: 1vw;
      font-family: Poppins-Light, sans-serif;
      font-weight: 300;
      font-size: 1.1vw;
    }

  }



`
