import {OptionStyles} from "./OptionStyles"
import {IOptions} from "../../../shared/Utils/OptionsComponentData";
import {useHistory} from "react-router-dom";

export interface IOptionsProps {
    id:number;
    img:string;
    text:string;
    click: (id:number) => void;
}
export default function Option({id, img, text, click}: IOptionsProps): JSX.Element {

    const history = useHistory();

    function getClassName(id:number) {
        switch (id) {
            case 1:
                return "optionsAdminUserManagement-img1"
            case 2:
                return "optionsAdminUserManagement-img2"
            case 3:
                return "optionsAdminUserManagement-img3"
            default:
                return "optionsAdminUserManagement-img3"

        }
    }

    return (
        <OptionStyles key={id} onClick={() => click(id)}>
            <img
                className={getClassName(id)}
                alt="optionsAdminUserManagement"
                src={img}
            />
            <span className={id===2 ? "text-option text-option-new-width":"text-option"}>{text}</span>
        </OptionStyles>
    );
}
