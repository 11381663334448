import axios from "axios";
import {
    ALL_BALLOTS_PLAYED,
    CHANGE_IS_AUTOMATIC_LAUNCH,
    CHANGE_LAUNCHING_BALLOT_FALSE,
    CHANGE_LAUNCHING_BALLOT_TRUE,
    CHANGE_PLAY_LAUNCH_BALLOT_AUTO,
    CHANGE_STARTING_GAME,
    CLEAN_ALL_BINGOS,
    CLEAN_LAST_BALLOT,
    CLEAN_SINGLE_BINGO,
    CLEAR_DEBUG_MESSAGES,
    COUNT_LAUNCH_BALLOT,
    CREATE_BINGO,
    CREATE_BINGO_FAILED,
    CREATE_BINGO_SUCCESS,
    CREATE_IMAGE,
    CREATE_IMAGE_FAILED,
    CREATE_IMAGE_SUCCESS,
    DEBUG_MESSAGES,
    DEBUG_MESSAGES_FAILED,
    DEBUG_MESSAGES_SUCCESS,
    DELETE_BINGO,
    DELETE_BINGO_FAILED,
    DELETE_BINGO_SUCCESS,
    DELETE_IMAGE,
    DELETE_IMAGE_FAILED,
    DELETE_IMAGE_SUCCESS,
    EDIT_BINGO,
    EDIT_BINGO_FAILED,
    EDIT_BINGO_SUCCESS,
    FINALIZED_PLAY_LAUNCH_BALLOT_AUTO,
    GET_ALL_DEBUG_MESSAGES_DATA_BASE,
    GET_ALL_DEBUG_MESSAGES_DATA_BASE_FAILED,
    GET_ALL_DEBUG_MESSAGES_DATA_BASE_SUCCESS,
    GET_ALL_IMAGES,
    GET_ALL_IMAGES_FAILED,
    GET_ALL_IMAGES_SUCCESS,
    GET_GAME_INFO,
    GET_GAME_INFO_FAILED,
    GET_GAME_INFO_SUCCESS,
    GET_SHOW_WINNERS,
    GET_SINGLE_BINGO,
    GET_SINGLE_BINGO_FAILED,
    GET_SINGLE_BINGO_SUCCESS,
    LAUNCH_BALLOT,
    LAUNCH_BALLOT_FAILED,
    LAUNCH_BALLOT_SUCCESS, RESET_LAUNCHING_BALLOT,
    SELECTED_CLICK_ADD_IMAGE,
    SELECTED_IMAGE_BANNER_CREATE_SESSION,
    SELECTED_IMAGE_MAIN_CREATE_GAME,
    SELECTED_IMAGE_MAIN_CREATE_SESSION,
    START_GAME,
    START_GAME_FAILED,
    START_GAME_SUCCESS,
    SWITCH_DEBUG_MESSAGES,
    SWITCH_DEBUG_MESSAGES_SOCKET, UPDATE_GAME_INFO,
    UPDATE_LAST_BALLOT,
    UPDATE_STATE_SINGLE_BINGO
} from "../TypesAdmin/BingosType";
import {CreateBingoModel} from "../../domain/models/CreateBingoModel";
import {LaunchBallotModel} from "../../domain/models/LaunchBallotModel";
import {ListAllBingosModel} from "../../domain/models/ListAllBingosModel";
import {EditBingoModel} from "../../domain/models/EditBingoModel";
import {myDBDomain} from "../../shared/Utils/TestingApi/MyDBDomain";
import {lastBallotFunction} from "../../shared/Utils/LastBallotFunction";
import {showWinnersListFunction} from "../../shared/Utils/ShowWinnersListFunction";
import {winnersListFunction} from "../../shared/Utils/WinnersListFunction";
import {GameInfoModel} from "../../domain/models/GameInfoModel";
import {ResponseLaunchBallotModel} from "../../domain/models/ResponseLaunchBallotModel";
import {getAllBallotsInGameInfo} from "../../shared/Utils/GetAllBallotsInGameInfo";
import {alertError} from "../../shared/Utils/AlertError";
import {alertInfo} from "../../shared/Utils/AlertInfo";
import {GameStorageDataModel} from "../../domain/models/GameStorageDataModel";
import {setLocalStorageObj} from "../../shared/Utils/SetLocalStorageObj";
import {ResLaunchBallotSocketModel} from "../../domain/models/ResLaunchBallotSocketModel";
import {alertSuccess} from "../../shared/Utils/AlertSuccess";
import {getCurrentDateInSeconds} from "../../shared/Utils/GetCurrentDateInSeconds";
import {SelectedClickAddImageType} from "../../domain/models/SelectedClickAddImage";
import {getListBallotPlayed} from "../../shared/Utils/getListBallotPlayed";
import {getListBallotPlayedData} from "../../shared/Utils/getListBallotPlayedData";
import {getLastBallotTs} from "../../shared/Utils/getLastBallot.ts";
import {updateGameInfoData} from "../../shared/updateGameInfoData";
import {LaunchBallotMessageModel} from "../../domain/models/LaunchBallotMessageModel";


export const getSingleBingo = (id: number) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/game/get-for-id/${id}`,
        headers: {}
    };
    dispatch({
        type: GET_SINGLE_BINGO,
        payload: 'loading'
    });
    await axios.request(config)
        .then((response) => {
            let data: ListAllBingosModel = response.data.data;
            dispatch({
                type: GET_SINGLE_BINGO_SUCCESS,
                payload: data
            });
        })
        .catch((error) => {
            console.log(error);
            dispatch({
                type: GET_SINGLE_BINGO_FAILED,
                payload: error.response.data.error
            });
        });
}
export const createBingo = (data: CreateBingoModel) => async (dispatch: (arg0: {
    type: string;
    payload: any;
}) => void) => {
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/game/create`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };
    dispatch({
        type: CREATE_BINGO,
        payload: 'loanding'
    });
    await axios.request(config)
        .then((response) => {
            const newGame = response.data.data;
            console.log('datos al creal el juego');
            console.log(newGame);
            setLocalStorageObj([], `listBallotPlayed_${newGame.id}`);
            dispatch({
                type: CREATE_BINGO_SUCCESS,
                payload: response.data.data.message
            });
        })
        .catch((error) => {
            console.log(error);
            dispatch({
                type: CREATE_BINGO_FAILED,
                payload: error.response.data.error
            });
        });
}
export const deleteBingo = (id: number) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    let config = {
        method: 'delete',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/game/delete/${id}`,
        headers: {
            'Content-Type': 'application/json'
        },
    };
    dispatch({
        type: DELETE_BINGO,
        payload: true
    });
    await axios.request(config)
        .then((response) => {
            dispatch({
                type: DELETE_BINGO_SUCCESS,
                payload: response.data.message
            });
        })
        .catch((error) => {
            console.log(error);
            dispatch({
                type: DELETE_BINGO_FAILED,
                payload: error.response.data.error
            });
        });
}
export const launchBallot = (data_launch: LaunchBallotModel) => async (dispatch: (arg0: {
    type: string;
    payload: any;
}) => void) => {
    console.log(data_launch);
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/game/launch-ballot`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data_launch
    };
    dispatch({
        type: LAUNCH_BALLOT,
        payload: 'loading'
    });
    await axios.request(config)
        .then((response) => {
            const newData: ResponseLaunchBallotModel = response.data.data;
            console.log(newData);
            // const newListBallots = getAllBallotsInLaunchBallot(newData);
            // console.log(newListBallots);
            // dispatch({
            //     type: ALL_BALLOTS_PLAYED_IN_LAUNCH_BALLOT,
            //     payload: newListBallots
            // });
            // const newLastBallot = updateLastBallotInLaunchBallot(newData.new_ballot);
            // console.log(newLastBallot);
            // dispatch({
            //     type: UPDATE_LAST_BALLOT_LAUNCH,
            //     payload: newLastBallot
            // });

            dispatch({
                type: LAUNCH_BALLOT_SUCCESS,
                payload: response.data
            });
        })
        .catch((error) => {
            dispatch({
                type: LAUNCH_BALLOT_FAILED,
                payload: error.response.data.error
            });
        });
}
export const startGame = (game_id: number) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    const data = {
        status: 'active'
    }
    let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/game/start-game/${game_id}`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };
    dispatch({
        type: START_GAME,
        payload: 'loading'
    });
    await axios.request(config)
        .then((response) => {
            dispatch({
                type: START_GAME_SUCCESS,
                payload: response.data
            });
        })
        .catch((error) => {
            console.log(error.response.data);
            dispatch({
                type: START_GAME_FAILED,
                payload: error
            });
        });
}
export const endGame = (game_id: number) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    const data = {
        status: 'end'
    }
    let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/game/end-game/${game_id}`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };
    await axios.request(config)
        .then((response) => {
            console.log(response.data.data);
        })
        .catch((error) => {
            console.error(error.response);
        });
}
export const endGameForced = (game_id: number) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    const data = {
        status: 'end'
    }
    let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/game/endGameForced/${game_id}`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };
    await axios.request(config)
        .then((response) => {
            console.log(response.data.data);
        })
        .catch((error) => {
            console.error(error.response);
            alertError(error.response.data.error);
        });
}
export const editBingo = (dataEdit: EditBingoModel) => async (dispatch: (arg0: {
    type: string;
    payload: any;
}) => void) => {
    console.log(dataEdit);
    const data = {
        // bingo_name: dataEdit.new_bingo_name,
        // game_mode: dataEdit.new_game_mode,
        // prize: dataEdit.new_prize,
        // winner_number: parseInt(dataEdit.new_winners_number),
        main_image: dataEdit.main_image
    }
    let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/game/update/${dataEdit.id}`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };
    dispatch({
        type: EDIT_BINGO,
        payload: true
    });
    await axios.request(config)
        .then((response) => {
            console.log(response.data);
            dispatch({
                type: EDIT_BINGO_SUCCESS,
                payload: response.data
            });
        })
        .catch((error) => {
            console.log(error);
            dispatch({
                type: EDIT_BINGO_FAILED,
                payload: error
            });
        });
}
export const cleanSingleBingo = () => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: CLEAN_SINGLE_BINGO,
        payload: null
    });
}
export const cleanAllBingos = () => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: CLEAN_ALL_BINGOS,
        payload: null
    });
}
export const cleanLastBallot = () => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: CLEAN_ALL_BINGOS,
        payload: ''
    });
}
export const getGameInfo = (game_id: number) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    console.log(game_id);
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/game/get-game-info-aws/${game_id}`,
        headers: {}
    };
    await axios.request(config)
        .then((response) => {
            const gameInfo: GameStorageDataModel = {
                lastBallot: getLastBallotTs(JSON.parse(response.data.data.ballots)),
                listBallotPlayed: getListBallotPlayed(JSON.parse(response.data.data.ballots)),
                listBallotPlayedData: getListBallotPlayedData(JSON.parse(response.data.data.ballots)),
                showWinners: JSON.parse(response.data.data.list_winners),
            }
            console.log('este es el game info');
            console.log(gameInfo);
            dispatch({
                type: GET_GAME_INFO_SUCCESS,
                payload: gameInfo
            });
        })
        .catch((error) => {
            console.log(error);
        });
}
export const updateGameInfo = (gameInfoData:GameStorageDataModel) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: UPDATE_GAME_INFO,
        payload: gameInfoData
    });
}


// start game socket
export const startGameSocket = (socketBingo: any, game_id: number) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    const data = {
        game_id: game_id,
        data_Edit_game: {status: 'active'}
    }
    console.log('data para enviar al iniciar el juego', Date.now());
    console.log(data);
    socketBingo.emit('start_game', data);
    dispatch({
        type: CHANGE_STARTING_GAME,
        payload: true
    });
}
export const startGameResponseSocket = (socketBingo: any, game_id: number) => async (dispatch: (arg0: {
    type: string;
    payload: any;
}) => void) => {
    await socketBingo.on('start_game_response', (response: any) => {
        if (response.data) {
            if (response.data.id === game_id) {
                console.log('respuesta de iniciar juego del servidor websocket.................', Date.now());
                console.log(response);
                alertInfo(response.message);
                dispatch({
                    type: UPDATE_STATE_SINGLE_BINGO,
                    payload: response.data
                });
            }
        } else {
            console.error('Error en la respuesta del socket iniciar juego');
            alertError('Error al iniciar el juego');
        }
    });
}
export const launchBallotSocket = (socketBingo: any, dataLaunchBallot: LaunchBallotModel) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    console.log('data para enviar al iniciar el juego');
    console.log(dataLaunchBallot);
    const timeStart = getCurrentDateInSeconds();
    console.log('tiempo del lanzamiento inicial');
    console.log(timeStart);
    dispatch({
        type: CHANGE_LAUNCHING_BALLOT_TRUE,
        payload: true
    });
    dispatch({
        type: SWITCH_DEBUG_MESSAGES_SOCKET,
        payload: true
    });
    socketBingo.emit('launch_ballot', dataLaunchBallot);
}
// export const launchBallotResponseSocket = (socketBingo: any, game_id: number) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
//     await socketBingo.on('launch_ballot_response', (response: any) => {
//         console.log('Respuesta del lanzamiento de balota');
//         console.log(response);
//         if (response === undefined) {
//             console.error('No llego la respuesta del lanzamiento de balota.....');
//             console.error(response);
//             dispatch({
//                 type: CHANGE_LAUNCHING_BALLOT_FALSE,
//                 payload: false
//             });
//             dispatch({
//                 type: FINALIZED_PLAY_LAUNCH_BALLOT_AUTO,
//                 payload: false
//             });
//         } else {
//             const responseSocketLaunchBallot: ResLaunchBallotSocketModel = response;
//             const timeEnd = getCurrentDateInSeconds();
//             console.log('tiempo al finalizar el lanzamiento');
//             console.log(timeEnd);
//             if (responseSocketLaunchBallot.body.game_id === game_id) {
//                 console.log('respuesta de lanzar balota del servidor websocket.................');
//                 dispatch({
//                     type: COUNT_LAUNCH_BALLOT,
//                     payload: 1
//                 });
//                 updateGameInfo(game_id).then((gameData) => {
//                     console.log('este es el game info');
//                     console.log(gameData);
//                     if (gameData) {
//                         dispatch({
//                             type: UPDATE_GAME_INFO,
//                             payload: gameData
//                         });
//                         if (responseSocketLaunchBallot.status_code === 200) {
//                             if (responseSocketLaunchBallot.body.is_finalized) {
//                                 console.log(responseSocketLaunchBallot);
//                                 dispatch({
//                                     type: UPDATE_STATE_SINGLE_BINGO,
//                                     payload: responseSocketLaunchBallot.body.data
//                                 });
//                                 if (responseSocketLaunchBallot.body.list_winner.length > 0) {
//                                     dispatch({
//                                         type: CHANGE_LAUNCHING_BALLOT_FALSE,
//                                         payload: false
//                                     });
//                                     dispatch({
//                                         type: FINALIZED_PLAY_LAUNCH_BALLOT_AUTO,
//                                         payload: false
//                                     });
//                                 }
//                                 dispatch({
//                                     type: CHANGE_LAUNCHING_BALLOT_FALSE,
//                                     payload: false
//                                 });
//                                 dispatch({
//                                     type: FINALIZED_PLAY_LAUNCH_BALLOT_AUTO,
//                                     payload: false
//                                 });
//                                 alertSuccess('juego finalizado');
//                             } else {
//                                 console.log(responseSocketLaunchBallot);
//                                 if (responseSocketLaunchBallot.body.list_winner.length > 0) {
//                                     const dataWinner = JSON.stringify(responseSocketLaunchBallot.body.list_winner);
//                                     localStorage.setItem(`winners${responseSocketLaunchBallot.body.game_id.toString()}`, dataWinner);
//                                 }
//                                 dispatch({
//                                     type: CHANGE_LAUNCHING_BALLOT_FALSE,
//                                     payload: false
//                                 });
//                             }
//                         } else {
//                             console.log(responseSocketLaunchBallot);
//                             alertError('Hubo un error al lanzar balota');
//                             dispatch({
//                                 type: CHANGE_LAUNCHING_BALLOT_FALSE,
//                                 payload: false
//                             });
//                         }
//                     } else {
//                         console.error('Hubo un error al obtener al actualizar el game_in');
//                     }
//                 });
//             } else {
//                 console.error('No llego la respuesta del lanzamiento de balota.....');
//                 console.error(responseSocketLaunchBallot);
//                 dispatch({
//                     type: CHANGE_LAUNCHING_BALLOT_FALSE,
//                     payload: false
//                 });
//                 dispatch({
//                     type: FINALIZED_PLAY_LAUNCH_BALLOT_AUTO,
//                     payload: false
//                 });
//             }
//         }
//     });
// }
export const launchBallotNewMessageSocket = (socketBingo: any, dataLaunch: LaunchBallotMessageModel) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    const timeStart = getCurrentDateInSeconds();
    console.log('tiempo al activar el cronometro que envia los mensajes');
    console.log(timeStart);
    socketBingo.emit('launch_ballot_new_message', dataLaunch);
}
export const launchBallotNewMessageResponseSocket = (socketBingo: any, game_id: number) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    await socketBingo.on('launch_ballot_new_message_response', (response2: any) => {
        const timeStart = getCurrentDateInSeconds();
        console.log('tiempo al desactivar el cronometro que envia los mensajes.................');
        console.log(timeStart);
        console.log(response2);
    });
}


export const changePlayLaunchBallotAuto = (action: boolean) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: CHANGE_PLAY_LAUNCH_BALLOT_AUTO,
        payload: action
    });
}
export const changeIsAutomaticLaunch = (action: boolean) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: CHANGE_IS_AUTOMATIC_LAUNCH,
        payload: action
    });
}

export const handleSelectedClickAddImage = (typeClick: SelectedClickAddImageType) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: SELECTED_CLICK_ADD_IMAGE,
        payload: typeClick
    });
}
export const handleSelectedImageMainCreateSession = (image: string) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: SELECTED_IMAGE_MAIN_CREATE_SESSION,
        payload: image
    });
}
export const handleSelectedImageBannerCreateSession = (image: string) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: SELECTED_IMAGE_BANNER_CREATE_SESSION,
        payload: image
    });
}
export const handleSelectedImageMainCreateGame = (image: string) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: SELECTED_IMAGE_MAIN_CREATE_GAME,
        payload: image
    });
}
export const changeLaunchingBallot = (action: boolean) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: CHANGE_LAUNCHING_BALLOT_TRUE,
        payload: action
    });
}


export const createImageStorage = (image: File) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    const formData = new FormData();
    formData.append('image', image);
    const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/uploadImage`,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data: formData
    };
    dispatch({
        type: CREATE_IMAGE,
        payload: true
    });
    await axios.request(config)
        .then((response) => {
            console.log(response.data.message);
            if (response.data.status === 200) {
                alertSuccess(response.data.message);
                dispatch({
                    type: CREATE_IMAGE_SUCCESS,
                    payload: false
                });
            } else {
                alertError(response.data.message);
                dispatch({
                    type: CREATE_IMAGE_SUCCESS,
                    payload: false
                });
            }
        })
        .catch((error) => {
            console.log(error.response.data);
            alertError(error.response.data.message);
            dispatch({
                type: CREATE_IMAGE_FAILED,
                payload: false
            });
        });
}
export const getAllImageStorage = () => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/allImages`,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    };
    dispatch({
        type: GET_ALL_IMAGES,
        payload: true
    });
    await axios.request(config)
        .then((response) => {
            console.log('estas son las imagenes');
            console.log(response.data.data);
            dispatch({
                type: GET_ALL_IMAGES_SUCCESS,
                payload: response.data.data
            });
        })
        .catch((error) => {
            console.log(error.response.data.message);
            alertError(error.response.data.message);
            dispatch({
                type: GET_ALL_IMAGES_FAILED,
                payload: false
            });
        });
}
export const deleteImageStorage = (image: string) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    const config = {
        method: 'delete',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/deleteImage/${image}`,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    };
    dispatch({
        type: DELETE_IMAGE,
        payload: true
    });
    await axios.request(config)
        .then((response) => {
            console.log('imagen eliminada');
            console.log(response.data.message);
            alertSuccess(response.data.message)
            dispatch({
                type: DELETE_IMAGE_SUCCESS,
                payload: false
            });
        })
        .catch((error) => {
            console.log(error.response.data.error);
            alertError(error.response.data.error);
            dispatch({
                type: DELETE_IMAGE_FAILED,
                payload: false
            });
        });
}


// debug messages
export const debugMessage = (game_id: number, new_ballot: string) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    console.log('este es el game_id que llega a debugMessage+++++++++++++++++++++++++++++');
    console.log(game_id);
    console.log('este es el new_ballot que llega a debugMessage+++++++++++++++++++++++++++++');
    console.log(new_ballot);
    const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/messageLaunchHistory/debugMessageDataForGameId/${game_id}?new_ballot=${new_ballot}`,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    };
    dispatch({
        type: DEBUG_MESSAGES,
        payload: true
    });
    await axios.request(config)
        .then((response) => {
            console.log('RESPUESTA DEL DEBUG MESSAGES....................');
            console.log(response.data.message);
            console.log(response.data);
            dispatch({
                type: DEBUG_MESSAGES_SUCCESS,
                payload: response.data.message
            });
        })
        .catch((error) => {
            console.log(error.response.data);
            dispatch({
                type: DEBUG_MESSAGES_FAILED,
                payload: error.response.data.error
            });
        });
}
export const switchDebugMessage = (action: boolean) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: SWITCH_DEBUG_MESSAGES,
        payload: action
    });
}
export const resetLaunchingBallot = (action: boolean) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: RESET_LAUNCHING_BALLOT,
        payload: action
    });
}
export const clearDebugMessage = () => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: CLEAR_DEBUG_MESSAGES,
        payload: []
    });
}
export const getAllDebugMessages = (game_id: number) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/messageLaunchHistory/getAllMessagesDataForGameId/${game_id}`,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    };
    dispatch({
        type: GET_ALL_DEBUG_MESSAGES_DATA_BASE,
        payload: true
    });
    await axios.request(config)
        .then((response) => {
            console.log('TODOS LOS DATOS DEBUG MESSAGES....................');
            console.log(response.data.data);
            dispatch({
                type: GET_ALL_DEBUG_MESSAGES_DATA_BASE_SUCCESS,
                payload: response.data.data
            });
        })
        .catch((error) => {
            console.log(error.response.data);
            dispatch({
                type: GET_ALL_DEBUG_MESSAGES_DATA_BASE_FAILED,
                payload: [error.response.data.error]
            });
        });
}


export async function getAllBallotsMessageData(game_id: number) {
    const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/messageLaunchHistory/getAllBallotsMessagesDataForGameId/${game_id}`,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    };
    try {
        const response = await axios.request(config);
        return response.data.data as string[];
    } catch (error) {
        console.log(error);
        return [];
    }

}

export async function getAllBallotsFinishedGame(game_id: number) {
    const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/finishedGame/getFinishedGameForGameId/${game_id}`,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    };
    try {
        const response = await axios.request(config);
        return JSON.parse(response.data.data.list_ballot_played) as string[];
    } catch (error) {
        console.log(error);
        return [];
    }
}