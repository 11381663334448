import {AddBulkUserStyles} from "./AddBulkUserStyles";
import {useAppDispatch, useAppSelector} from "../../../shared/Utils/Hooks";
import {CreateUserModel} from "../../../domain/models/CreateUserModel";
import {openAndCloseModalAddBulkUsers} from "../../../store/ActionsAdmin/ModalsActions";
import {useEffect, useRef, useState} from "react";
import * as XLSX from 'xlsx';
import {BsHandThumbsUp} from "react-icons/bs";
import {FaUsers} from "react-icons/fa";
import {getCreateUsersAndUserSessionRel} from "../../../store/ActionsAdmin/UserActions";
import {getListOfList} from "../../../shared/Utils/GetListOfList";
import {getSingleSessionDatabase} from "../../../store/ActionsAdmin/SessionActions";
import {alertSuccess} from "../../../shared/Utils/AlertSuccess";
import {alertInfo} from "../../../shared/Utils/AlertInfo";
import {alertError} from "../../../shared/Utils/AlertError";

export interface IBulkUsers {
    name: string;
    email: string;
    identification_document: string;
    phone: string;
}

export interface IAddBulkUserProps {
    session_id?: number;
    card_limit?: number;
}

export default function AddBulkUser({session_id, card_limit}: IAddBulkUserProps): JSX.Element {

    const domain = window.location.origin;
    const dispatch = useAppDispatch();
    const isLoadingCreateUser = useAppSelector((state) => state.userState.isLoadingCreateUser);
    const domainActive = useAppSelector((state) => state.accountState.domainActive);
    const singleSessionDatabase = useAppSelector((state) => state.sessionState.singleSessionDatabase);
    const isLoadingCreateUsersAndUserSessionRel = useAppSelector((state) => state.userState.isLoadingCreateUsersAndUserSessionRel);
    const countCardsByBingoLimit = useAppSelector((state) => state.cardsState.countCardsByBingoLimit);
    const fileExcelBulkUsers = useRef<HTMLInputElement | null>(null);
    const [fileExcelData, setFileExcelData] = useState<CreateUserModel[]>([]);
    const [error, setError] = useState<string>('');
    const [counterCreateRelUserSession, setCounterCreateRelUserSession] = useState<number>(0);
    const [percentageLoadingCreateRelUserSession, setPercentageLoadingCreateRelUserSession] = useState<number>(0);
    const [countUserCreateSuccess, setCountUserCreateSuccess] = useState<number>(0)

    function closeModalAddBulkUsers() {
        dispatch(openAndCloseModalAddBulkUsers(false));
        setError('');
        setCounterCreateRelUserSession(0);
        setFileExcelData([]);
    }

    function handleClickInputFileBulkUsers() {
        if (fileExcelBulkUsers.current) {
            fileExcelBulkUsers.current?.click();
        }
    }

    function handleFileExcelBulkUsers(e: any) {
        const fileExcel = e.target.files[0];
        // console.log(fileExcel);
        if (fileExcel !== null && domainActive) {
            const fileExcelReader = new FileReader();
            // console.log(fileExcelReader);
            fileExcelReader.onload = (e: any) => {
                // console.log(e.target);
                if (e.target) {
                    const excelData = e.target.result;
                    const workbook = XLSX.read(excelData, {type: "binary"});
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];
                    const excelDataListObj: any[] = XLSX.utils.sheet_to_json(sheet);
                    const user_type = 'user';
                    const accountId = domainActive.id;
                    const newListBulkUsers: any[] = excelDataListObj.map((user) => ({...user, user_type, accountId}));
                    console.log(newListBulkUsers);
                    if (singleSessionDatabase && singleSessionDatabase.card_limit === 0) {
                        if (newListBulkUsers[0].cards) {
                            setFileExcelData(newListBulkUsers);
                        } else {
                            alertError('El archivo de excel debe tener: name | email | identification_document | phone | cards');
                        }
                    }
                    if (singleSessionDatabase && singleSessionDatabase.card_limit !== 0) {
                        if (newListBulkUsers[0].cards) {
                            alertError('El archivo de excel debe tener: name | email | identification_document | phone');
                        } else {
                            setFileExcelData(newListBulkUsers);
                        }
                    }
                }
            }
            fileExcelReader.readAsBinaryString(fileExcel);
        }
    }

    function handleCreateBulkUsers() {
        console.log(fileExcelData);
        if (session_id !== undefined && card_limit !== undefined && card_limit > 0 && fileExcelData.length > 0) {
            const cardsToUse = card_limit * fileExcelData.length;
            if (cardsToUse >= countCardsByBingoLimit) {
                alertError(`los tarjerones solicitados: ${cardsToUse} superan los disponibles: ${countCardsByBingoLimit}`);
                return;
            }
            const listOfListUsersExcel = getListOfList(fileExcelData, 1);
            console.log(listOfListUsersExcel);
            let index = 0;
            const createRelUsersSession = () => {
                if (index < listOfListUsersExcel.length) {
                    console.log(index);
                    const dataRelUserSession = listOfListUsersExcel[index];
                    const bulkUsersData = {
                        session_id: session_id,
                        cards: card_limit,
                        bulk_users: dataRelUserSession
                    }
                    dispatch(getCreateUsersAndUserSessionRel(bulkUsersData)).then(() => {
                        index++;
                        setError('Creando relación de usuarios con sesión ');
                        setCounterCreateRelUserSession((value) => value + dataRelUserSession.length);
                        setCountUserCreateSuccess((value) => value + dataRelUserSession.length);
                        setTimeout(createRelUsersSession, 3000);
                    });
                } else {
                    dispatch(getSingleSessionDatabase(session_id)).then(() => {
                    });
                }
            }
            createRelUsersSession();
        }
        if (session_id !== undefined && card_limit !== undefined && card_limit === 0 && fileExcelData.length > 0) {
            const dataFileExcelData = fileExcelData as any[];
            const cardsToUse = dataFileExcelData.reduce((total, user) => total + user.cards, 0) as number;
            if (cardsToUse >= countCardsByBingoLimit) {
                alertError(`los tarjerones solicitados: ${cardsToUse} superan los disponibles: ${countCardsByBingoLimit}`);
                return;
            }
            console.log(cardsToUse);
            const listOfListUsersExcel = getListOfList(fileExcelData, 1);
            console.log(listOfListUsersExcel);
            let index = 0;
            const createRelUsersSession = () => {
                if (index < listOfListUsersExcel.length) {
                    console.log(index);
                    const dataRelUserSession = listOfListUsersExcel[index];
                    if (dataRelUserSession[0].cards) {
                        const bulkUsersData = {
                            session_id: session_id,
                            cards: dataRelUserSession[0].cards,
                            bulk_users: dataRelUserSession
                        }
                        dispatch(getCreateUsersAndUserSessionRel(bulkUsersData)).then(() => {
                            index++;
                            setError('Creando relación de usuarios con sesión ');
                            setCounterCreateRelUserSession((value) => value + dataRelUserSession.length);
                            setCountUserCreateSuccess((value) => value + dataRelUserSession.length);
                            setTimeout(createRelUsersSession, 2000);
                        });
                    } else {
                        alertError(`usuario ${dataRelUserSession[0].name} no tiene cards`);
                        index++;
                        setCounterCreateRelUserSession((value) => value + dataRelUserSession.length);
                        setTimeout(createRelUsersSession, 2000);
                    }
                } else {
                    dispatch(getSingleSessionDatabase(session_id)).then(() => {});
                }
            }
            createRelUsersSession();
        } else {
            setError('Debes seleccionar un archivo de excel');
        }
    }

    useEffect(() => {
        console.log(fileExcelData.length);
        console.log(counterCreateRelUserSession);
        const hundred_percent = fileExcelData.length;
        const resultValuePercentage = 100 * counterCreateRelUserSession / hundred_percent;
        setPercentageLoadingCreateRelUserSession(resultValuePercentage);
    }, [fileExcelData, counterCreateRelUserSession]);

    return (
        <AddBulkUserStyles percentageLoadingCreateRelUserSession={percentageLoadingCreateRelUserSession}>
            <div className="container-button-close">
                <span onClick={closeModalAddBulkUsers} className="close">x</span>
            </div>
            <div className="container-title">
                <span className="title">Agregar usuarios masivos</span>
            </div>
            <form className="form-add-bulk-users">
                <input
                    ref={fileExcelBulkUsers}
                    type="file"
                    data-max-size="2"
                    accept=".xls, .xlsx"
                    className="input-img-small"
                    onChange={handleFileExcelBulkUsers}
                />
                <div className="circle-img" onClick={handleClickInputFileBulkUsers}>
                    {fileExcelData.length === 0 &&
                        <FaUsers className="options-admin-add-image"/>
                    }
                    {fileExcelData.length > 0 &&
                        <BsHandThumbsUp className="options-admin-add-image"/>
                    }
                </div>
                {fileExcelData.length === 0 && error === '' &&
                    <span className="info-add">Email, teléfono y número de celular únicos</span>
                }
                {fileExcelData.length === 0 && error !== '' &&
                    <span className="info-add">{error}</span>
                }
                {fileExcelData.length > 0 && error === '' &&
                    <span className="info-add">Lista de usuarios cargada con éxito</span>
                }
                {fileExcelData.length > 0 && error !== '' &&
                    <div className="container-loading-create-rel-user-session">
                        <div className="percentage-loading"></div>
                    </div>
                }
                <span>{countUserCreateSuccess}/{fileExcelData.length}</span>
                <button onClick={handleCreateBulkUsers} type="button" className="button-form">
                    {!isLoadingCreateUsersAndUserSessionRel ? 'Crear Usuarios' : 'Creando...'}
                </button>
            </form>
        </AddBulkUserStyles>
    );
}
