import {EditGameStyles} from "./EditGameStyles";
import {useForm} from "react-hook-form";
import {useAppDispatch, useAppSelector} from "../../../shared/Utils/Hooks";
import {useEffect, useRef, useState} from "react";
import {openAndCloseModalEditGame} from "../../../store/ActionsAdmin/ModalsActions";
import {gameModes} from "../../../shared/Utils/GameModes";
import {editBingo, getSingleBingo} from "../../../store/ActionsAdmin/BingosActions";
import {EditBingoModel} from "../../../domain/models/EditBingoModel";
import {alertSuccess} from "../../../shared/Utils/AlertSuccess";
import {uploadFile} from "../../../../../Firebase/Firebase";

export default function EditGame(): JSX.Element {

    const dispatch = useAppDispatch();
    const {register, handleSubmit, reset, formState: {errors}} = useForm<EditBingoModel>();
    const loadingEditBingo = useAppSelector((state) => state.bingoState.loadingEditBingo);
    const singleSession = useAppSelector((state) => state.sessionState.singleSession);
    const fileInputImgSmall = useRef<HTMLInputElement | null>(null);
    const fileInputImgBanner = useRef<HTMLInputElement | null>(null);
    const singleBingo = useAppSelector((state) => state.bingoState.singleBingo);
    const [img, setImg] = useState<string|undefined>(singleBingo?.main_image);
    const [errorImgEditGame, setErrorImgEditGame] = useState('');


    function handleEditBingo(data: EditBingoModel) {
        console.log(data);
        if (img === null) {
            setErrorImgEditGame('Imagen requerida');
        }
        if (singleBingo && img) {
            const dataEdit: EditBingoModel = {
                id: singleBingo.id,
                // new_bingo_name: data.new_bingo_name,
                // new_game_mode: data.new_game_mode,
                // new_prize: data.new_prize,
                // new_winners_number: data.new_winners_number,
                main_image: img
            }
            dispatch(editBingo(dataEdit)).then(() => {
                dispatch(getSingleBingo(singleBingo.id)).then(() => {
                    alertSuccess('Bingo editado con éxito');
                    reset();
                    handleCloseModal();
                });
            });
        }
    }

    const onSubmit = (data: EditBingoModel) => handleEditBingo(data);

    function handleClickImgSmall() {
        if (fileInputImgSmall.current) {
            fileInputImgSmall.current?.click()
        }
    }

    async function handleMainImageEditGame(e:any) {
        console.log(e.target.files[0].size);
        if (e.target.files[0].size < 100000) {
            const fileMinImage = e.target.files[0];
            console.log(fileMinImage);
            if (fileMinImage && img) {
                const fileFirebase = await uploadFile(fileMinImage, img);
                setErrorImgEditGame('');
                setImg(fileFirebase);
            }
        } else {
            setErrorImgEditGame('La imagen no puede superar los 100K');
        }
    }

    useEffect(() => {
        console.log(img)
    },[img]);

    function handleCloseModal() {
        dispatch(openAndCloseModalEditGame(false));
    }

    return (
        <EditGameStyles>
            <div className="container-title">
                <span className="title">Editar Juego</span>
                <span onClick={handleCloseModal} className="close">x</span>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="form">
                <div className="container-img-inputs">
                    <div className="container-img">
                        <input
                            ref={fileInputImgSmall}
                            type="file"
                            accept="image/*"
                            className="input-img-small"
                            onChange={handleMainImageEditGame}
                        />
                        <div onClick={handleClickImgSmall} className="circle-img">
                            <img
                                className="options-admin-add-image"
                                alt="optionsAdminAddImage"
                                src={img ? img : "/img/newImage/optionsAdmin/optionsAdminAddImage.png"}
                            />
                        </div>
                        {errorImgEditGame !== '' &&
                            <span className="error-img">{errorImgEditGame}</span>
                        }
                        {errorImgEditGame === '' &&
                            <span className="info-add">Agregar/editar miniatura</span>
                        }
                        {/*<span className="info-add">Agregar/editar miniatura</span>*/}
                    </div>
                    <div className="container-inputs">
                        <div className="container-input">
                            <input
                                // defaultValue={singleBingo?.bingo_name}
                                // {...register('new_bingo_name', {
                                //     validate: bingoNameValidate
                                // })}
                                className="input-session-name"
                                type="text"
                                placeholder="Campo no editable"
                                disabled={true}
                            />
                            {/*{errors.new_bingo_name?.type === 'validate' && <span className='error'>{errors.new_bingo_name.message}</span>}*/}
                        </div>
                        <div className="container-input">
                            <select
                                // defaultValue={singleBingo?.game_mode}
                                // {...register('new_game_mode', {
                                //     validate: bingoGameMode
                                // })}
                                className="mode-game"
                                disabled={true}
                            >
                                <option value=''>Campo no editable</option>
                                {gameModes.map((mode, index) => (
                                    <option key={index} value={mode.ref}>{mode.translationRef}</option>
                                ))}
                            </select>
                            {/*{errors.new_game_mode?.type === 'validate' && <span className='error'>{errors.new_game_mode.message}</span>}*/}
                        </div>
                        <div className="container-input">
                            <input
                                // defaultValue={singleBingo?.prize}
                                // {...register('new_prize', {
                                //     validate: bingoNamePrize
                                // })}
                                className="game-prize"
                                type="text"
                                placeholder="Campo no editable"
                                disabled={true}
                            />
                            {/*{errors.new_prize?.type === 'validate' && <span className='error'>{errors.new_prize.message}</span>}*/}
                        </div>
                        <div className="container-input">
                            <input
                                // defaultValue={singleBingo?.winner_number}
                                // {...register('new_winners_number', {
                                //     validate: winnerNumber
                                // })}
                                className="game-prize"
                                type="text"
                                placeholder="Campo no editbale"
                                disabled={true}
                            />
                            {/*{errors.new_winners_number?.type === 'validate' && <span className='error'>{errors.new_winners_number.message}</span>}*/}
                        </div>
                    </div>
                </div>
                <div className="container-button">
                    <button disabled={loadingEditBingo} type="submit" className="button-form">{!loadingEditBingo ? 'Editar Juego' : 'Editando...' }</button>
                </div>
            </form>
        </EditGameStyles>
    );
}
