import styled from "styled-components";
import {Device} from "../../../shared/Utils/devices";

export const ContentCardBingoStyled = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    align-content: start;
    justify-items: center;
    width: 100%;
    height: 100%;
    gap: 1vw;
    padding-right: 1vw;
    box-sizing: border-box;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: 1vw;
    }

    &::-webkit-scrollbar-track {
        background-color: silver;
        border-radius: 10vw;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #ffffff;
        border-radius: 10vw;
    }

    @media ${Device.tablet} {
        grid-template-columns: 1fr 1fr;
        height: 90%;
        gap: 2vw;
        margin-top: 2vh;

        &::-webkit-scrollbar {
            width: 3vw;
        }
        &::-webkit-scrollbar-track {
            background-color: silver;
            border-radius: 5vw;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #ffffff;
            border-radius: 10vw;
        }
    }
    
    @media ${Device.mobileL} {
        grid-template-columns: 1fr 1fr;
        height: 90%;
        gap: 2vw;
        margin-top: 2vh;

        &::-webkit-scrollbar {
            width: 3vw;
        }
        &::-webkit-scrollbar-track {
            background-color: silver;
            border-radius: 5vw;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #ffffff;
            border-radius: 10vw;
        }
    }
    @media ${Device.mobileM} {
        grid-template-columns: 1fr 1fr;
        height: 90%;
        gap: 2vw;
        margin-top: 2vh;

        &::-webkit-scrollbar {
            width: 3vw;
        }

        &::-webkit-scrollbar-track {
            background-color: silver;
            border-radius: 5vw;

        }

        &::-webkit-scrollbar-thumb {
            background-color: #ffffff;
            border-radius: 10vw;
        }
    }
    @media ${Device.mobileS} {
        grid-template-columns: 1fr 1fr;
        height: 90%;
        gap: 2vw;
        margin-top: 2vh;

        &::-webkit-scrollbar {
            width: 3vw;
        }

        &::-webkit-scrollbar-track {
            background-color: silver;
            border-radius: 5vw;

        }

        &::-webkit-scrollbar-thumb {
            background-color: #ffffff;
            border-radius: 10vw;
        }
    }
`
