import {
    CREATE_USER_SESSION_REL,
    CREATE_USER_SESSION_REL_FAILED,
    CREATE_USER_SESSION_REL_SUCCESS
} from "../TypesAdmin/UserSessionRelType";

interface IStateUserSessionRel {
    isLoadingCreateUserSessionRel: boolean;
    message: string;
    error: string;
}
const initialStateUserSessionRel: IStateUserSessionRel = {
    isLoadingCreateUserSessionRel: false,
    message: '',
    error: ''
}
const UserSessionRelReducer = function (state = initialStateUserSessionRel, action: {type:any; payload:any}):IStateUserSessionRel {
    switch (action.type) {
        case CREATE_USER_SESSION_REL:
            return {
                isLoadingCreateUserSessionRel: action.payload,
                message: '',
                error: ''
            }
        case CREATE_USER_SESSION_REL_SUCCESS:
            return {
                isLoadingCreateUserSessionRel: action.payload,
                message: '',
                error: ''
            }
        case CREATE_USER_SESSION_REL_FAILED:
            return {
                isLoadingCreateUserSessionRel: action.payload,
                message: '',
                error: ''
            }
        default:
            return state;
    }
}
export default UserSessionRelReducer;