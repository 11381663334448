import styled from "styled-components";

interface IConfigurationOptionsStylesProps {
    buttonColor:string;
    buttonColorShadow:string;
}
export const ConfigurationOptionsStyles = styled.div<IConfigurationOptionsStylesProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 1vw;
  width: 100%;
  height: 93.5vh;
  overflow-y: scroll;

  .icon-back-container {
    display: flex;
    position: absolute;
    justify-content: start;
    align-items: center;
    align-self: start;
    margin-top: 0.5vw;
    left: 16vw;

    .icon-back {
      width: 1.4vw;
      cursor: pointer;
    }
  }

  .title-configuration {
    width: 100%;
    text-align: center;
  }

  .container-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1vw;
    width: 100%;

    .text-buttons {
      font-size: 1.2vw;
    }

    .buttons {
      border: none;
      font-size: 1.5vw;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 10vw;
      height: 2.5vw;
      background: ${({buttonColor}) => buttonColor};
      border-radius: 33px;
      opacity: 1;
      cursor: pointer;
      transition: box-shadow 0.3s ease;
      font-family: Anton, sans-serif;
      font-weight: 400;
    }

    .buttons:hover {
      box-shadow: ${({buttonColorShadow}) => buttonColorShadow};
    }
  }
  .container-switch {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1vw;
    margin-top: 2vw;

    .buttons {
      border: none;
      font-size: 1.5vw;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 10vw;
      height: 2.5vw;
      background: ${({buttonColor}) => buttonColor};
      border-radius: 33px;
      opacity: 1;
      cursor: pointer;
      transition: box-shadow 0.3s ease;
      font-family: Anton, sans-serif;
      font-weight: 400;
    }

    .buttons:hover {
      box-shadow: ${({buttonColorShadow}) => buttonColorShadow};
    }
  }
`

