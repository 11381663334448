import styled from "styled-components";
import {Device} from "../../../shared/Utils/devices";

export const HeaderStyled = styled.div`
    width: 100%;
    height: 3vw;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0 2px 2px #00000038;
    opacity: 1;
    z-index: 10;

    .options-admin-title-header {
        width: 8vw;
        margin-left: 2vw;
        cursor: pointer;
    }

    .container-user-session {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: .5vw;
        margin-right: 2vw;
        cursor: pointer;

        .container-icon-session {
            display: flex;
            align-items: center;
            justify-content: center;
            position: revert;
            border-radius: 100%;

            .img-user-session-circle {
                width: 2vw;
            }

            .img-user-session-avatar {
                width: 1vw;
                position: absolute;
            }
        }

        .name-user {
            text-align: center;
            letter-spacing: 0;
            color: #2E304E;
            opacity: 1;
            font-size: .9vw;
            font-family: 'Poppins-SemiBold', sans-serif;
            font-weight: 600;
            font-style: normal;
        }
    }

    @media ${Device.tablet} {
        width: 100%;
        height: 8vw;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0 2px 2px #00000038;
        opacity: 1;
        z-index: 10;

        .options-admin-title-header {
            width: 14vw;
            margin-left: 2vw;
            cursor: pointer;
        }

        .container-user-session {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: .5vw;
            margin-right: 2vw;
            cursor: pointer;

            .container-icon-session {
                display: flex;
                align-items: center;
                justify-content: center;
                position: revert;
                border-radius: 100%;

                .img-user-session-circle {
                    width: 4vw;
                }

                .img-user-session-avatar {
                    width: 2vw;
                    position: absolute;
                }
            }

            .name-user {
                text-align: center;
                letter-spacing: 0;
                color: #2E304E;
                opacity: 1;
                font-size: 2vw;
                font-family: 'Poppins-SemiBold', sans-serif;
                font-weight: 600;
                font-style: normal;
            }
        }
    }
    @media ${Device.mobileL} {
        height: 10vw;
        position: unset;
        background: transparent;
        box-shadow: none;
        box-sizing: border-box;
        margin-top: 6vw;

        .options-admin-title-header {
            width: 30vw;
            margin-left: 2vw;
        }

        .container-user-session {
            margin-right: 5vw;

            .container-icon-session {
                position: relative;

                .img-user-session-circle {
                    width: 8vw;
                }

                .img-user-session-avatar {
                    width: 4vw;
                }
            }

            .name-user {
                display: none;
            }
        }
    }
    @media ${Device.mobileM} {
        height: 10vw;
        position: unset;
        background: transparent;
        box-shadow: none;
        box-sizing: border-box;
        margin-top: 6vw;

        .options-admin-title-header {
            width: 30vw;
            margin-left: 2vw;
        }

        .container-user-session {
            margin-right: 5vw;

            .container-icon-session {
                position: relative;

                .img-user-session-circle {
                    width: 8vw;
                }

                .img-user-session-avatar {
                    width: 4vw;
                }
            }

            .name-user {
                display: none;
            }
        }
    }
    @media ${Device.mobileS} {
        height: 10vw;
        position: unset;
        background: transparent;
        box-shadow: none;
        box-sizing: border-box;
        margin-top: 6vw;

        .options-admin-title-header {
            width: 30vw;
            margin-left: 2vw;
        }

        .container-user-session {
            margin-right: 5vw;

            .container-icon-session {
                position: relative;

                .img-user-session-circle {
                    width: 8vw;
                }

                .img-user-session-avatar {
                    width: 4vw;
                }
            }

            .name-user {
                display: none;
            }
        }
    }
`
