import styled from "styled-components";
import {Device} from "../../../shared/Utils/devices";

export const CardBingoStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: #78509890;
    padding: .3vw .3vw .8vw .3vw;
    box-sizing: border-box;
    border-radius: .5vw;

    .container-serial {
        display: flex;
        align-items: center;
        justify-content: start;
        width: 100%;
        color: #ffffff;
        box-sizing: border-box;
        padding: .5vw;
        font-size: .7vw;
    }

    .container-number-bingo {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        align-content: center;
        justify-items: center;
        width: 100%;

        .number {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #9e7bb2;
            width: 100%;
            text-align: center;
            padding: .3vw 0;
            color: #ffffff;

            &:not(:nth-child(5n)) {
                border-right-width: 0;
            }

            &:not(:nth-last-child(-n+5)) {
                border-bottom-width: 0;
            }
        }

        .number-selected {
            color: #FF3A4F;
            font-weight: bold;
            text-decoration: line-through;
        }
    }

    @media ${Device.tablet} {

        .container-serial {
            font-size: 3vw;
        }
        .container-number-bingo {

            .number {
                font-size: 3.4vw;
                height: 6vw;

                &:not(:nth-child(5n)) {
                    border-right-width: 0;
                }

                &:not(:nth-last-child(-n+5)) {
                    border-bottom-width: 0;
                }
            }
        }
    }
    
    @media ${Device.mobileL} {

        .container-serial {
            font-size: 3vw;
        }
        .container-number-bingo {

            .number {
                font-size: 3.4vw;
                height: 6vw;

                &:not(:nth-child(5n)) {
                    border-right-width: 0;
                }

                &:not(:nth-last-child(-n+5)) {
                    border-bottom-width: 0;
                }
            }
        }
    }
    @media ${Device.mobileM} {

        .container-serial {
            font-size: 3vw;
        }
        .container-number-bingo {

            .number {
                font-size: 3.4vw;
                height: 6vw;

                &:not(:nth-child(5n)) {
                    border-right-width: 0;
                }

                &:not(:nth-last-child(-n+5)) {
                    border-bottom-width: 0;
                }
            }
        }
    }
    @media ${Device.mobileS} {

        .container-serial {
            font-size: 3vw;
        }
        .container-number-bingo {

            .number {
                font-size: 3.4vw;
                height: 6vw;

                &:not(:nth-child(5n)) {
                    border-right-width: 0;
                }

                &:not(:nth-last-child(-n+5)) {
                    border-bottom-width: 0;
                }
            }
        }
    }
`
