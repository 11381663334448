import axios from "axios";
import {myDBDomain} from "../../shared/Utils/TestingApi/MyDBDomain";
import {ConfigurationThemeFormModel} from "../../domain/models/ConfigurationThemeFormModel";
import {
    EDIT_ACCOUNT_CONFIG,
    EDIT_ACCOUNT_CONFIG_FAILED,
    EDIT_ACCOUNT_CONFIG_SUCCESS
} from "../TypesAdmin/AccountConfigTypes";

export const editAccountConfig = (data:ConfigurationThemeFormModel, id: number) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    console.log(data);
    console.log(id);
    let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `${myDBDomain}/api/v1/accountConfig/update/${id}`,
        headers: {
            'Content-Type': 'application/json'
        },
        data : data
    };
    dispatch({
        type: EDIT_ACCOUNT_CONFIG,
        payload: true
    });
    axios.request(config)
        .then((response) => {
            console.log(response.data);
            dispatch({
                type: EDIT_ACCOUNT_CONFIG_SUCCESS,
                payload: response.data.data
            });
        })
        .catch((error) => {
            console.log(error);
            dispatch({
                type: EDIT_ACCOUNT_CONFIG_FAILED,
                payload: error.response.data.error
            });
        });
}