import {FooterStyled} from "./FooterStyled";

export default function Footer():JSX.Element {

    function redirectLinkRemaikia() {
        const url = 'https://remakia.co/pages/tyc';
        window.open(url, '_blank');
    }

    return (
        <FooterStyled>
            <span onClick={redirectLinkRemaikia}>Términos y Condiciones</span>
            <span onClick={redirectLinkRemaikia}>Reglas del Bingo</span>
        </FooterStyled>
    );
}