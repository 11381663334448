import styled from "styled-components";
import {Device} from "../../../shared/Utils/devices";

export const WelcomeMessageStyled = styled.div`
    width: 90%;
    //border: .3vw solid #FFFFFF;
    //border-radius: 2.5vw;
    margin-top: 2.5vw;
    display: flex;
    justify-content: center;
    align-items: center;

    .text-container {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        width: 100%;
        height: 7vw;
        border-radius: 1.8vw;
        background-image: url("/img/new-img-change/img-banner-info-session.png");
        background-repeat: no-repeat;
        background-size: cover;
        left: 2vw;

        .welcome-text {
            font-size: 1.5vw;
            color: #ffff;
            font-weight: lighter;
            margin-left: 1.5vw;
        }

        .description-text {
            font-size: 1.5vw;
            color: #ffff;
            margin-left: 1.8vw;
            font-weight: lighter;
        }
    }

    @media ${Device.desktopL} {
        .text-container {
            height: 10vw;

            .welcome-text {
                font-size: 4vw;
            }

            .description-text {
                font-size: 2vw;
            }
        }
    }
    @media ${Device.desktop} {
        margin-top: 4vw;

        .text-container {
            height: 10vw;

            .welcome-text {
                font-size: 3vw;
            }

            .description-text {
                font-size: 3.1vw;
            }
        }
    }
    @media ${Device.laptopL} {
        margin-top: 4vw;

        .text-container {
            height: 10vw;

            .welcome-text {
                font-size: 3.2vw;
            }

            .description-text {
                font-size: 3.4vw;
            }
        }
    }

    @media ${Device.tablet} {
        margin-top: 15vw;
        height: 20vw;


        .text-container {
            height: 18vw;

            .welcome-text {
                font-size: 4.3vw;
            }

            .description-text {
                font-size: 4.5vw;
            }
        }
    }
    @media ${Device.mobileL} {
        width: 95%;
        height: 21.9vw;
        border: 1.5vw solid #FFFFFF;
        border-radius: 8vw;
        margin-top: 15vw;

        .text-container {
            justify-content: center;
            align-items: start;
            width: 90vw;
            height: 21vw;
            border-radius: 5vw;

            .welcome-text {
                font-size: 6.2vw;
            }

            .description-text {
                font-size: 4.5vw;
            }

        }
    }
    @media ${Device.mobileM} {
        width: 95%;
        height: 21.9vw;
        border: 1.5vw solid #FFFFFF;
        border-radius: 8vw;
        margin-top: 15vw;

        .text-container {
            justify-content: center;
            align-items: start;
            width: 90vw;
            height: 21vw;
            border-radius: 5vw;

            .welcome-text {
                font-size: 6.2vw;
            }

            .description-text {
                font-size: 4.5vw;
            }

        }
    }
    @media ${Device.mobileS} {
        width: 95%;
        height: 21.9vw;
        border: 1.5vw solid #FFFFFF;
        border-radius: 8vw;
        margin-top: 15vw;

        .text-container {
            justify-content: center;
            align-items: start;
            width: 90vw;
            height: 21vw;
            border-radius: 5vw;

            .welcome-text {
                font-size: 6.2vw;
            }

            .description-text {
                font-size: 4.5vw;
            }
        }
    }



`
