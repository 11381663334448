import Swal from 'sweetalert2';

export function alertInfoSessionUserRel(number:string) {
    Swal.fire({
        title: 'No estás registrado',
        text: `Escríbenos a nuestro chat bot ${number}`,
        icon: 'info',
        confirmButtonText: 'Aceptar',
    });
}
