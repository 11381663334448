import styled from "styled-components";
import {Device} from "../../../shared/Utils/devices";

interface IGameStateStyledProps {
    border: boolean;
}

export const GameStateStyled = styled.div<IGameStateStyledProps>`
    display: flex;
    justify-content: left;
    align-items: center;
    width: 17vw;
    padding: .2vw 0;
    border: ${({border}) => border ? '.1vw solid #ffffff' : 'none'};
    border-radius: 10vw;
    margin-left: 2vw;
    margin-top: 1vw;
    gap: .5vw;
    direction: ltr;
    cursor: pointer;
    box-sizing: border-box;

    .game-finish {
        width: 1.6vw;
        margin-left: .5vw;
    }

    .game-name {
        color: #ffffff;
        font-weight: lighter;
        font-size: 1vw;
    }

    @media ${Device.tablet} {
        border: none;
        gap: 2.5vw;
        height: 8vw;
        width: 77vw;

        .game-finish {
            width: 7vw;
            margin-left: .5vw;
        }

        .game-name {
            color: #ffffff;
            font-weight: lighter;
            font-size: 6vw;
        }
    }
    
    @media ${Device.mobileL} {
        border: none;
        gap: 2.5vw;
        height: 8vw;
        width: 77vw;

        .game-finish {
            width: 7vw;
            margin-left: .5vw;
        }

        .game-name {
            color: #ffffff;
            font-weight: lighter;
            font-size: 6vw;
        }
    }
    @media ${Device.mobileM} {
        border: none;
        gap: 2.5vw;
        height: 8vw;
        width: 77vw;

        .game-finish {
            width: 7vw;
            margin-left: .5vw;
        }

        .game-name {
            color: #ffffff;
            font-weight: lighter;
            font-size: 6vw;
        }
    }
    @media ${Device.mobileS} {
        border: none;
        gap: 2.5vw;
        height: 8vw;
        width: 77vw;

        .game-finish {
            width: 7vw;
            margin-left: .5vw;
        }

        .game-name {
            color: #ffffff;
            font-weight: lighter;
            font-size: 6vw;
        }
    }




`
