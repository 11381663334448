import {
    CLOSE_MODAL_CREATE_SESSION,
    CLOSE_MODAL_EDIT_SESSION,
    CLOSE_MODAL_USER_GAME_INFO,
    CLOSE_MODAL_USER_INFO,
    OPEN_AND_CLOSE_MODAL_ADD_BULK_USERS,
    OPEN_AND_CLOSE_MODAL_ADD_BULK_USERS_IN_CREATE_SESSION,
    OPEN_AND_CLOSE_MODAL_CARDS_WINNERS_USER_AND_GAME,
    OPEN_AND_CLOSE_MODAL_CONFIRM_OTP,
    OPEN_AND_CLOSE_MODAL_CREATE_GAME,
    OPEN_AND_CLOSE_MODAL_CREATE_USER,
    OPEN_AND_CLOSE_MODAL_CREATE_USER_REL_ACCOUNT_AND_SESSION,
    OPEN_AND_CLOSE_MODAL_CURRENT_GAME_DATA, OPEN_AND_CLOSE_MODAL_DEBUG_MESSAGE,
    OPEN_AND_CLOSE_MODAL_EDIT_GAME, OPEN_AND_CLOSE_MODAL_IMAGES_STORAGE, OPEN_AND_CLOSE_MODAL_IMAGES_STORAGE_GAME,
    OPEN_AND_CLOSE_MODAL_LAUNCH_BALLOT_MANUAL,
    OPEN_AND_CLOSE_MODAL_REGISTER,
    OPEN_AND_CLOSE_MODAL_SIGN_IN,
    OPEN_AND_CLOSE_MODAL_SINGLE_SESSION_USER_END,
    OPEN_AND_CLOSE_MODAL_USERS_REL_SESSION_AND_CARDS,
    OPEN_AND_CLOSE_MODAL_USERS_SESSION,
    OPEN_MODAL_CREATE_SESSION,
    OPEN_MODAL_EDIT_SESSION,
    OPEN_MODAL_USER_GAME_INFO,
    OPEN_MODAL_USER_INFO
} from "../TypesAdmin/ModalsType";

interface IStateModal {
    showModalCreateSession: boolean;
    showModalEditSession: boolean;
    showModalUserInfo: boolean;
    showModalUserGameInfo: boolean;
    showModalCreateGame: boolean;
    showModalEditGame: boolean;
    showModalCreateUser: boolean;
    showModalSignIn: boolean;
    showModalConfirmOTP: boolean;
    showModalRegister: boolean;
    showModalUsersSession: boolean;
    showModalLaunchBallotManual: string;
    showModalSingleSessionUserEnd: boolean;
    showModalAddBulkUsers: boolean;
    showModalAddBulkUsersInCreateSession: boolean;
    showModalUsersRelSessionAndCards: boolean;
    showModalCardsWinnerUserAndGame: boolean;
    showModalCreateUserRelAccountAndSession: boolean;
    showModalCurrentGameData: boolean;
    showModalImagesStorage: boolean;
    showModalImagesStorageGame: boolean;
    showModalDebugMessage: boolean;
}

const initialStateModal: IStateModal = {
    showModalCreateSession: false,
    showModalEditSession: false,
    showModalUserInfo: false,
    showModalUserGameInfo: false,
    showModalCreateGame: false,
    showModalEditGame: false,
    showModalCreateUser: false,
    showModalSignIn: true,
    showModalConfirmOTP: false,
    showModalRegister: false,
    showModalUsersSession: false,
    showModalLaunchBallotManual: '',
    showModalSingleSessionUserEnd: false,
    showModalAddBulkUsers: false,
    showModalAddBulkUsersInCreateSession: false,
    showModalUsersRelSessionAndCards: false,
    showModalCardsWinnerUserAndGame: false,
    showModalCreateUserRelAccountAndSession: false,
    showModalCurrentGameData: false,
    showModalImagesStorage: false,
    showModalImagesStorageGame: false,
    showModalDebugMessage: false,
}

const ModalReducer = function (state = initialStateModal, action: { type: any; payload: any }): IStateModal {
    switch (action.type) {
        case OPEN_MODAL_CREATE_SESSION:
            return {
                showModalCreateSession: action.payload,
                showModalEditSession: state.showModalEditSession,
                showModalUserInfo: state.showModalUserInfo,
                showModalUserGameInfo: state.showModalUserGameInfo,
                showModalCreateGame: state.showModalCreateGame,
                showModalEditGame: state.showModalEditGame,
                showModalCreateUser: state.showModalCreateUser,
                showModalSignIn: state.showModalSignIn,
                showModalConfirmOTP: state.showModalConfirmOTP,
                showModalRegister: state.showModalRegister,
                showModalUsersSession: state.showModalUsersSession,
                showModalLaunchBallotManual: state.showModalLaunchBallotManual,
                showModalSingleSessionUserEnd: state.showModalSingleSessionUserEnd,
                showModalAddBulkUsers: state.showModalAddBulkUsers,
                showModalAddBulkUsersInCreateSession: state.showModalAddBulkUsersInCreateSession,
                showModalUsersRelSessionAndCards: state.showModalUsersRelSessionAndCards,
                showModalCardsWinnerUserAndGame: state.showModalCardsWinnerUserAndGame,
                showModalCreateUserRelAccountAndSession: state.showModalCreateUserRelAccountAndSession,
                showModalCurrentGameData: state.showModalCurrentGameData,
                showModalImagesStorage: state.showModalImagesStorage,
                showModalImagesStorageGame: state.showModalImagesStorageGame,
                showModalDebugMessage: state.showModalDebugMessage,
            }
        case CLOSE_MODAL_CREATE_SESSION:
            return {
                showModalCreateSession: action.payload,
                showModalEditSession: state.showModalEditSession,
                showModalUserInfo: state.showModalUserInfo,
                showModalUserGameInfo: state.showModalUserGameInfo,
                showModalCreateGame: state.showModalCreateGame,
                showModalEditGame: state.showModalEditGame,
                showModalCreateUser: state.showModalCreateUser,
                showModalSignIn: state.showModalSignIn,
                showModalConfirmOTP: state.showModalConfirmOTP,
                showModalRegister: state.showModalRegister,
                showModalUsersSession: state.showModalUsersSession,
                showModalLaunchBallotManual: state.showModalLaunchBallotManual,
                showModalSingleSessionUserEnd: state.showModalSingleSessionUserEnd,
                showModalAddBulkUsers: state.showModalAddBulkUsers,
                showModalAddBulkUsersInCreateSession: state.showModalAddBulkUsersInCreateSession,
                showModalUsersRelSessionAndCards: state.showModalUsersRelSessionAndCards,
                showModalCardsWinnerUserAndGame: state.showModalCardsWinnerUserAndGame,
                showModalCreateUserRelAccountAndSession: state.showModalCreateUserRelAccountAndSession,
                showModalCurrentGameData: state.showModalCurrentGameData,
                showModalImagesStorage: state.showModalImagesStorage,
                showModalImagesStorageGame: state.showModalImagesStorageGame,
                showModalDebugMessage: state.showModalDebugMessage,
            }
        case OPEN_MODAL_EDIT_SESSION:
            return {
                showModalCreateSession: state.showModalCreateSession,
                showModalEditSession: action.payload,
                showModalUserInfo: state.showModalUserInfo,
                showModalUserGameInfo: state.showModalUserGameInfo,
                showModalCreateGame: state.showModalCreateGame,
                showModalEditGame: state.showModalEditGame,
                showModalCreateUser: state.showModalCreateUser,
                showModalSignIn: state.showModalSignIn,
                showModalConfirmOTP: state.showModalConfirmOTP,
                showModalRegister: state.showModalRegister,
                showModalUsersSession: state.showModalUsersSession,
                showModalLaunchBallotManual: state.showModalLaunchBallotManual,
                showModalSingleSessionUserEnd: state.showModalSingleSessionUserEnd,
                showModalAddBulkUsers: state.showModalAddBulkUsers,
                showModalAddBulkUsersInCreateSession: state.showModalAddBulkUsersInCreateSession,
                showModalUsersRelSessionAndCards: state.showModalUsersRelSessionAndCards,
                showModalCardsWinnerUserAndGame: state.showModalCardsWinnerUserAndGame,
                showModalCreateUserRelAccountAndSession: state.showModalCreateUserRelAccountAndSession,
                showModalCurrentGameData: state.showModalCurrentGameData,
                showModalImagesStorage: state.showModalImagesStorage,
                showModalImagesStorageGame: state.showModalImagesStorageGame,
                showModalDebugMessage: state.showModalDebugMessage,
            }
        case CLOSE_MODAL_EDIT_SESSION:
            return {
                showModalCreateSession: state.showModalCreateSession,
                showModalEditSession: action.payload,
                showModalUserInfo: state.showModalUserInfo,
                showModalUserGameInfo: state.showModalUserGameInfo,
                showModalCreateGame: state.showModalCreateGame,
                showModalEditGame: state.showModalEditGame,
                showModalCreateUser: state.showModalCreateUser,
                showModalSignIn: state.showModalSignIn,
                showModalConfirmOTP: state.showModalConfirmOTP,
                showModalRegister: state.showModalRegister,
                showModalUsersSession: state.showModalUsersSession,
                showModalLaunchBallotManual: state.showModalLaunchBallotManual,
                showModalSingleSessionUserEnd: state.showModalSingleSessionUserEnd,
                showModalAddBulkUsers: state.showModalAddBulkUsers,
                showModalAddBulkUsersInCreateSession: state.showModalAddBulkUsersInCreateSession,
                showModalUsersRelSessionAndCards: state.showModalUsersRelSessionAndCards,
                showModalCardsWinnerUserAndGame: state.showModalCardsWinnerUserAndGame,
                showModalCreateUserRelAccountAndSession: state.showModalCreateUserRelAccountAndSession,
                showModalCurrentGameData: state.showModalCurrentGameData,
                showModalImagesStorage: state.showModalImagesStorage,
                showModalImagesStorageGame: state.showModalImagesStorageGame,
                showModalDebugMessage: state.showModalDebugMessage,
            }
        case OPEN_MODAL_USER_INFO:
            return {
                showModalCreateSession: state.showModalCreateSession,
                showModalEditSession: state.showModalEditSession,
                showModalUserInfo: action.payload,
                showModalUserGameInfo: state.showModalUserGameInfo,
                showModalCreateGame: state.showModalCreateGame,
                showModalEditGame: state.showModalEditGame,
                showModalCreateUser: state.showModalCreateUser,
                showModalSignIn: state.showModalSignIn,
                showModalConfirmOTP: state.showModalConfirmOTP,
                showModalRegister: state.showModalRegister,
                showModalUsersSession: state.showModalUsersSession,
                showModalLaunchBallotManual: state.showModalLaunchBallotManual,
                showModalSingleSessionUserEnd: state.showModalSingleSessionUserEnd,
                showModalAddBulkUsers: state.showModalAddBulkUsers,
                showModalAddBulkUsersInCreateSession: state.showModalAddBulkUsersInCreateSession,
                showModalUsersRelSessionAndCards: state.showModalUsersRelSessionAndCards,
                showModalCardsWinnerUserAndGame: state.showModalCardsWinnerUserAndGame,
                showModalCreateUserRelAccountAndSession: state.showModalCreateUserRelAccountAndSession,
                showModalCurrentGameData: state.showModalCurrentGameData,
                showModalImagesStorage: state.showModalImagesStorage,
                showModalImagesStorageGame: state.showModalImagesStorageGame,
                showModalDebugMessage: state.showModalDebugMessage,
            }
        case CLOSE_MODAL_USER_INFO:
            return {
                showModalCreateSession: state.showModalCreateSession,
                showModalEditSession: state.showModalEditSession,
                showModalUserInfo: action.payload,
                showModalUserGameInfo: state.showModalUserGameInfo,
                showModalCreateGame: state.showModalCreateGame,
                showModalEditGame: state.showModalEditGame,
                showModalCreateUser: state.showModalCreateUser,
                showModalSignIn: state.showModalSignIn,
                showModalConfirmOTP: state.showModalConfirmOTP,
                showModalRegister: state.showModalRegister,
                showModalUsersSession: state.showModalUsersSession,
                showModalLaunchBallotManual: state.showModalLaunchBallotManual,
                showModalSingleSessionUserEnd: state.showModalSingleSessionUserEnd,
                showModalAddBulkUsers: state.showModalAddBulkUsers,
                showModalAddBulkUsersInCreateSession: state.showModalAddBulkUsersInCreateSession,
                showModalUsersRelSessionAndCards: state.showModalUsersRelSessionAndCards,
                showModalCardsWinnerUserAndGame: state.showModalCardsWinnerUserAndGame,
                showModalCreateUserRelAccountAndSession: state.showModalCreateUserRelAccountAndSession,
                showModalCurrentGameData: state.showModalCurrentGameData,
                showModalImagesStorage: state.showModalImagesStorage,
                showModalImagesStorageGame: state.showModalImagesStorageGame,
                showModalDebugMessage: state.showModalDebugMessage,
            }
        case OPEN_MODAL_USER_GAME_INFO:
            return {
                showModalCreateSession: state.showModalCreateSession,
                showModalEditSession: state.showModalEditSession,
                showModalUserInfo: state.showModalUserInfo,
                showModalUserGameInfo: action.payload,
                showModalCreateGame: state.showModalCreateGame,
                showModalEditGame: state.showModalEditGame,
                showModalCreateUser: state.showModalCreateUser,
                showModalSignIn: state.showModalSignIn,
                showModalConfirmOTP: state.showModalConfirmOTP,
                showModalRegister: state.showModalRegister,
                showModalUsersSession: state.showModalUsersSession,
                showModalLaunchBallotManual: state.showModalLaunchBallotManual,
                showModalSingleSessionUserEnd: state.showModalSingleSessionUserEnd,
                showModalAddBulkUsers: state.showModalAddBulkUsers,
                showModalAddBulkUsersInCreateSession: state.showModalAddBulkUsersInCreateSession,
                showModalUsersRelSessionAndCards: state.showModalUsersRelSessionAndCards,
                showModalCardsWinnerUserAndGame: state.showModalCardsWinnerUserAndGame,
                showModalCreateUserRelAccountAndSession: state.showModalCreateUserRelAccountAndSession,
                showModalCurrentGameData: state.showModalCurrentGameData,
                showModalImagesStorage: state.showModalImagesStorage,
                showModalImagesStorageGame: state.showModalImagesStorageGame,
                showModalDebugMessage: state.showModalDebugMessage,
            }
            case CLOSE_MODAL_USER_GAME_INFO:
                return {
                    showModalCreateSession: state.showModalCreateSession,
                    showModalEditSession: state.showModalEditSession,
                    showModalUserInfo: state.showModalUserInfo,
                    showModalUserGameInfo: action.payload,
                    showModalCreateGame: state.showModalCreateGame,
                    showModalEditGame: state.showModalEditGame,
                    showModalCreateUser: state.showModalCreateUser,
                    showModalSignIn: state.showModalSignIn,
                    showModalConfirmOTP: state.showModalConfirmOTP,
                    showModalRegister: state.showModalRegister,
                    showModalUsersSession: state.showModalUsersSession,
                    showModalLaunchBallotManual: state.showModalLaunchBallotManual,
                    showModalSingleSessionUserEnd: state.showModalSingleSessionUserEnd,
                    showModalAddBulkUsers: state.showModalAddBulkUsers,
                    showModalAddBulkUsersInCreateSession: state.showModalAddBulkUsersInCreateSession,
                    showModalUsersRelSessionAndCards: state.showModalUsersRelSessionAndCards,
                    showModalCardsWinnerUserAndGame: state.showModalCardsWinnerUserAndGame,
                    showModalCreateUserRelAccountAndSession: state.showModalCreateUserRelAccountAndSession,
                    showModalCurrentGameData: state.showModalCurrentGameData,
                    showModalImagesStorage: state.showModalImagesStorage,
                    showModalImagesStorageGame: state.showModalImagesStorageGame,
                    showModalDebugMessage: state.showModalDebugMessage,
                }
        case OPEN_AND_CLOSE_MODAL_CREATE_GAME:
            return {
                showModalCreateSession: state.showModalCreateSession,
                showModalEditSession: state.showModalEditSession,
                showModalUserInfo: state.showModalUserInfo,
                showModalUserGameInfo: state.showModalUserGameInfo,
                showModalCreateGame: action.payload,
                showModalEditGame: state.showModalEditGame,
                showModalCreateUser: state.showModalCreateUser,
                showModalSignIn: state.showModalSignIn,
                showModalConfirmOTP: state.showModalConfirmOTP,
                showModalRegister: state.showModalRegister,
                showModalUsersSession: state.showModalUsersSession,
                showModalLaunchBallotManual: state.showModalLaunchBallotManual,
                showModalSingleSessionUserEnd: state.showModalSingleSessionUserEnd,
                showModalAddBulkUsers: state.showModalAddBulkUsers,
                showModalAddBulkUsersInCreateSession: state.showModalAddBulkUsersInCreateSession,
                showModalUsersRelSessionAndCards: state.showModalUsersRelSessionAndCards,
                showModalCardsWinnerUserAndGame: state.showModalCardsWinnerUserAndGame,
                showModalCreateUserRelAccountAndSession: state.showModalCreateUserRelAccountAndSession,
                showModalCurrentGameData: state.showModalCurrentGameData,
                showModalImagesStorage: state.showModalImagesStorage,
                showModalImagesStorageGame: state.showModalImagesStorageGame,
                showModalDebugMessage: state.showModalDebugMessage,
            }
        case OPEN_AND_CLOSE_MODAL_EDIT_GAME:
            return {
                showModalCreateSession: state.showModalCreateSession,
                showModalEditSession: state.showModalEditSession,
                showModalUserInfo: state.showModalUserInfo,
                showModalUserGameInfo: state.showModalUserGameInfo,
                showModalCreateGame: state.showModalCreateGame,
                showModalEditGame: action.payload,
                showModalCreateUser: state.showModalCreateUser,
                showModalSignIn: state.showModalSignIn,
                showModalConfirmOTP: state.showModalConfirmOTP,
                showModalRegister: state.showModalRegister,
                showModalUsersSession: state.showModalUsersSession,
                showModalLaunchBallotManual: state.showModalLaunchBallotManual,
                showModalSingleSessionUserEnd: state.showModalSingleSessionUserEnd,
                showModalAddBulkUsers: state.showModalAddBulkUsers,
                showModalAddBulkUsersInCreateSession: state.showModalAddBulkUsersInCreateSession,
                showModalUsersRelSessionAndCards: state.showModalUsersRelSessionAndCards,
                showModalCardsWinnerUserAndGame: state.showModalCardsWinnerUserAndGame,
                showModalCreateUserRelAccountAndSession: state.showModalCreateUserRelAccountAndSession,
                showModalCurrentGameData: state.showModalCurrentGameData,
                showModalImagesStorage: state.showModalImagesStorage,
                showModalImagesStorageGame: state.showModalImagesStorageGame,
                showModalDebugMessage: state.showModalDebugMessage,
            }
        case OPEN_AND_CLOSE_MODAL_CREATE_USER:
            return {
                showModalCreateSession: state.showModalCreateSession,
                showModalEditSession: state.showModalEditSession,
                showModalUserInfo: state.showModalUserInfo,
                showModalUserGameInfo: state.showModalUserGameInfo,
                showModalCreateGame: state.showModalCreateGame,
                showModalEditGame: state.showModalEditGame,
                showModalCreateUser: action.payload,
                showModalSignIn: state.showModalSignIn,
                showModalConfirmOTP: state.showModalConfirmOTP,
                showModalRegister: state.showModalRegister,
                showModalUsersSession: state.showModalUsersSession,
                showModalLaunchBallotManual: state.showModalLaunchBallotManual,
                showModalSingleSessionUserEnd: state.showModalSingleSessionUserEnd,
                showModalAddBulkUsers: state.showModalAddBulkUsers,
                showModalAddBulkUsersInCreateSession: state.showModalAddBulkUsersInCreateSession,
                showModalUsersRelSessionAndCards: state.showModalUsersRelSessionAndCards,
                showModalCardsWinnerUserAndGame: state.showModalCardsWinnerUserAndGame,
                showModalCreateUserRelAccountAndSession: state.showModalCreateUserRelAccountAndSession,
                showModalCurrentGameData: state.showModalCurrentGameData,
                showModalImagesStorage: state.showModalImagesStorage,
                showModalImagesStorageGame: state.showModalImagesStorageGame,
                showModalDebugMessage: state.showModalDebugMessage,
            }
        case OPEN_AND_CLOSE_MODAL_SIGN_IN:
            return {
                showModalCreateSession: state.showModalCreateSession,
                showModalEditSession: state.showModalEditSession,
                showModalUserInfo: state.showModalUserInfo,
                showModalUserGameInfo: state.showModalUserGameInfo,
                showModalCreateGame: state.showModalCreateGame,
                showModalEditGame: state.showModalEditGame,
                showModalCreateUser: state.showModalCreateUser,
                showModalSignIn: action.payload,
                showModalConfirmOTP: state.showModalConfirmOTP,
                showModalRegister: state.showModalRegister,
                showModalUsersSession: state.showModalUsersSession,
                showModalLaunchBallotManual: state.showModalLaunchBallotManual,
                showModalSingleSessionUserEnd: state.showModalSingleSessionUserEnd,
                showModalAddBulkUsers: state.showModalAddBulkUsers,
                showModalAddBulkUsersInCreateSession: state.showModalAddBulkUsersInCreateSession,
                showModalUsersRelSessionAndCards: state.showModalUsersRelSessionAndCards,
                showModalCardsWinnerUserAndGame: state.showModalCardsWinnerUserAndGame,
                showModalCreateUserRelAccountAndSession: state.showModalCreateUserRelAccountAndSession,
                showModalCurrentGameData: state.showModalCurrentGameData,
                showModalImagesStorage: state.showModalImagesStorage,
                showModalImagesStorageGame: state.showModalImagesStorageGame,
                showModalDebugMessage: state.showModalDebugMessage,
            }
        case OPEN_AND_CLOSE_MODAL_CONFIRM_OTP:
            return {
                showModalCreateSession: state.showModalCreateSession,
                showModalEditSession: state.showModalEditSession,
                showModalUserInfo: state.showModalUserInfo,
                showModalUserGameInfo: state.showModalUserGameInfo,
                showModalCreateGame: state.showModalCreateGame,
                showModalEditGame: state.showModalEditGame,
                showModalCreateUser: state.showModalCreateUser,
                showModalSignIn: state.showModalSignIn,
                showModalConfirmOTP: action.payload,
                showModalRegister: state.showModalRegister,
                showModalUsersSession: state.showModalUsersSession,
                showModalLaunchBallotManual: state.showModalLaunchBallotManual,
                showModalSingleSessionUserEnd: state.showModalSingleSessionUserEnd,
                showModalAddBulkUsers: state.showModalAddBulkUsers,
                showModalAddBulkUsersInCreateSession: state.showModalAddBulkUsersInCreateSession,
                showModalUsersRelSessionAndCards: state.showModalUsersRelSessionAndCards,
                showModalCardsWinnerUserAndGame: state.showModalCardsWinnerUserAndGame,
                showModalCreateUserRelAccountAndSession: state.showModalCreateUserRelAccountAndSession,
                showModalCurrentGameData: state.showModalCurrentGameData,
                showModalImagesStorage: state.showModalImagesStorage,
                showModalImagesStorageGame: state.showModalImagesStorageGame,
                showModalDebugMessage: state.showModalDebugMessage,
            }
        case OPEN_AND_CLOSE_MODAL_REGISTER:
            return {
                showModalCreateSession: state.showModalCreateSession,
                showModalEditSession: state.showModalEditSession,
                showModalUserInfo: state.showModalUserInfo,
                showModalUserGameInfo: state.showModalUserGameInfo,
                showModalCreateGame: state.showModalCreateGame,
                showModalEditGame: state.showModalEditGame,
                showModalCreateUser: state.showModalCreateUser,
                showModalSignIn: state.showModalSignIn,
                showModalConfirmOTP: state.showModalConfirmOTP,
                showModalRegister: action.payload,
                showModalUsersSession: state.showModalUsersSession,
                showModalLaunchBallotManual: state.showModalLaunchBallotManual,
                showModalSingleSessionUserEnd: state.showModalSingleSessionUserEnd,
                showModalAddBulkUsers: state.showModalAddBulkUsers,
                showModalAddBulkUsersInCreateSession: state.showModalAddBulkUsersInCreateSession,
                showModalUsersRelSessionAndCards: state.showModalUsersRelSessionAndCards,
                showModalCardsWinnerUserAndGame: state.showModalCardsWinnerUserAndGame,
                showModalCreateUserRelAccountAndSession: state.showModalCreateUserRelAccountAndSession,
                showModalCurrentGameData: state.showModalCurrentGameData,
                showModalImagesStorage: state.showModalImagesStorage,
                showModalImagesStorageGame: state.showModalImagesStorageGame,
                showModalDebugMessage: state.showModalDebugMessage,
            }
        case OPEN_AND_CLOSE_MODAL_USERS_SESSION:
            return {
                showModalCreateSession: state.showModalCreateSession,
                showModalEditSession: state.showModalEditSession,
                showModalUserInfo: state.showModalUserInfo,
                showModalUserGameInfo: state.showModalUserGameInfo,
                showModalCreateGame: state.showModalCreateGame,
                showModalEditGame: state.showModalEditGame,
                showModalCreateUser: state.showModalCreateUser,
                showModalSignIn: state.showModalSignIn,
                showModalConfirmOTP: state.showModalConfirmOTP,
                showModalRegister: state.showModalRegister,
                showModalUsersSession: action.payload,
                showModalLaunchBallotManual: state.showModalLaunchBallotManual,
                showModalSingleSessionUserEnd: state.showModalSingleSessionUserEnd,
                showModalAddBulkUsers: state.showModalAddBulkUsers,
                showModalAddBulkUsersInCreateSession: state.showModalAddBulkUsersInCreateSession,
                showModalUsersRelSessionAndCards: state.showModalUsersRelSessionAndCards,
                showModalCardsWinnerUserAndGame: state.showModalCardsWinnerUserAndGame,
                showModalCreateUserRelAccountAndSession: state.showModalCreateUserRelAccountAndSession,
                showModalCurrentGameData: state.showModalCurrentGameData,
                showModalImagesStorage: state.showModalImagesStorage,
                showModalImagesStorageGame: state.showModalImagesStorageGame,
                showModalDebugMessage: state.showModalDebugMessage,
            }
        case OPEN_AND_CLOSE_MODAL_LAUNCH_BALLOT_MANUAL:
            return {
                showModalCreateSession: state.showModalCreateSession,
                showModalEditSession: state.showModalEditSession,
                showModalUserInfo: state.showModalUserInfo,
                showModalUserGameInfo: state.showModalUserGameInfo,
                showModalCreateGame: state.showModalCreateGame,
                showModalEditGame: state.showModalEditGame,
                showModalCreateUser: state.showModalCreateUser,
                showModalSignIn: state.showModalSignIn,
                showModalConfirmOTP: state.showModalConfirmOTP,
                showModalRegister: state.showModalRegister,
                showModalUsersSession: state.showModalUsersSession,
                showModalLaunchBallotManual: action.payload,
                showModalSingleSessionUserEnd: state.showModalSingleSessionUserEnd,
                showModalAddBulkUsers: state.showModalAddBulkUsers,
                showModalAddBulkUsersInCreateSession: state.showModalAddBulkUsersInCreateSession,
                showModalUsersRelSessionAndCards: state.showModalUsersRelSessionAndCards,
                showModalCardsWinnerUserAndGame: state.showModalCardsWinnerUserAndGame,
                showModalCreateUserRelAccountAndSession: state.showModalCreateUserRelAccountAndSession,
                showModalCurrentGameData: state.showModalCurrentGameData,
                showModalImagesStorage: state.showModalImagesStorage,
                showModalImagesStorageGame: state.showModalImagesStorageGame,
                showModalDebugMessage: state.showModalDebugMessage,
            }
        case OPEN_AND_CLOSE_MODAL_SINGLE_SESSION_USER_END:
            return {
                showModalCreateSession: state.showModalCreateSession,
                showModalEditSession: state.showModalEditSession,
                showModalUserInfo: state.showModalUserInfo,
                showModalUserGameInfo: state.showModalUserGameInfo,
                showModalCreateGame: state.showModalCreateGame,
                showModalEditGame: state.showModalEditGame,
                showModalCreateUser: state.showModalCreateUser,
                showModalSignIn: state.showModalSignIn,
                showModalConfirmOTP: state.showModalConfirmOTP,
                showModalRegister: state.showModalRegister,
                showModalUsersSession: state.showModalUsersSession,
                showModalLaunchBallotManual: state.showModalLaunchBallotManual,
                showModalSingleSessionUserEnd: action.payload,
                showModalAddBulkUsers: state.showModalAddBulkUsers,
                showModalAddBulkUsersInCreateSession: state.showModalAddBulkUsersInCreateSession,
                showModalUsersRelSessionAndCards: state.showModalUsersRelSessionAndCards,
                showModalCardsWinnerUserAndGame: state.showModalCardsWinnerUserAndGame,
                showModalCreateUserRelAccountAndSession: state.showModalCreateUserRelAccountAndSession,
                showModalCurrentGameData: state.showModalCurrentGameData,
                showModalImagesStorage: state.showModalImagesStorage,
                showModalImagesStorageGame: state.showModalImagesStorageGame,
                showModalDebugMessage: state.showModalDebugMessage,
            }
        case OPEN_AND_CLOSE_MODAL_ADD_BULK_USERS:
            return {
                showModalCreateSession: state.showModalCreateSession,
                showModalEditSession: state.showModalEditSession,
                showModalUserInfo: state.showModalUserInfo,
                showModalUserGameInfo: state.showModalUserGameInfo,
                showModalCreateGame: state.showModalCreateGame,
                showModalEditGame: state.showModalEditGame,
                showModalCreateUser: state.showModalCreateUser,
                showModalSignIn: state.showModalSignIn,
                showModalConfirmOTP: state.showModalConfirmOTP,
                showModalRegister: state.showModalRegister,
                showModalUsersSession: state.showModalUsersSession,
                showModalLaunchBallotManual: state.showModalLaunchBallotManual,
                showModalSingleSessionUserEnd: state.showModalSingleSessionUserEnd,
                showModalAddBulkUsers: action.payload,
                showModalAddBulkUsersInCreateSession: state.showModalAddBulkUsersInCreateSession,
                showModalUsersRelSessionAndCards: state.showModalUsersRelSessionAndCards,
                showModalCardsWinnerUserAndGame: state.showModalCardsWinnerUserAndGame,
                showModalCreateUserRelAccountAndSession: state.showModalCreateUserRelAccountAndSession,
                showModalCurrentGameData: state.showModalCurrentGameData,
                showModalImagesStorage: state.showModalImagesStorage,
                showModalImagesStorageGame: state.showModalImagesStorageGame,
                showModalDebugMessage: state.showModalDebugMessage,
            }
        case OPEN_AND_CLOSE_MODAL_ADD_BULK_USERS_IN_CREATE_SESSION:
            return {
                showModalCreateSession: state.showModalCreateSession,
                showModalEditSession: state.showModalEditSession,
                showModalUserInfo: state.showModalUserInfo,
                showModalUserGameInfo: state.showModalUserGameInfo,
                showModalCreateGame: state.showModalCreateGame,
                showModalEditGame: state.showModalEditGame,
                showModalCreateUser: state.showModalCreateUser,
                showModalSignIn: state.showModalSignIn,
                showModalConfirmOTP: state.showModalConfirmOTP,
                showModalRegister: state.showModalRegister,
                showModalUsersSession: state.showModalUsersSession,
                showModalLaunchBallotManual: state.showModalLaunchBallotManual,
                showModalSingleSessionUserEnd: state.showModalSingleSessionUserEnd,
                showModalAddBulkUsers: state.showModalAddBulkUsers,
                showModalAddBulkUsersInCreateSession: action.payload,
                showModalUsersRelSessionAndCards: state.showModalUsersRelSessionAndCards,
                showModalCardsWinnerUserAndGame: state.showModalCardsWinnerUserAndGame,
                showModalCreateUserRelAccountAndSession: state.showModalCreateUserRelAccountAndSession,
                showModalCurrentGameData: state.showModalCurrentGameData,
                showModalImagesStorage: state.showModalImagesStorage,
                showModalImagesStorageGame: state.showModalImagesStorageGame,
                showModalDebugMessage: state.showModalDebugMessage,
            }
        case OPEN_AND_CLOSE_MODAL_USERS_REL_SESSION_AND_CARDS:
            return {
                showModalCreateSession: state.showModalCreateSession,
                showModalEditSession: state.showModalEditSession,
                showModalUserInfo: state.showModalUserInfo,
                showModalUserGameInfo: state.showModalUserGameInfo,
                showModalCreateGame: state.showModalCreateGame,
                showModalEditGame: state.showModalEditGame,
                showModalCreateUser: state.showModalCreateUser,
                showModalSignIn: state.showModalSignIn,
                showModalConfirmOTP: state.showModalConfirmOTP,
                showModalRegister: state.showModalRegister,
                showModalUsersSession: state.showModalUsersSession,
                showModalLaunchBallotManual: state.showModalLaunchBallotManual,
                showModalSingleSessionUserEnd: state.showModalSingleSessionUserEnd,
                showModalAddBulkUsers: state.showModalAddBulkUsers,
                showModalAddBulkUsersInCreateSession: state.showModalAddBulkUsersInCreateSession,
                showModalUsersRelSessionAndCards: action.payload,
                showModalCardsWinnerUserAndGame: state.showModalCardsWinnerUserAndGame,
                showModalCreateUserRelAccountAndSession: state.showModalCreateUserRelAccountAndSession,
                showModalCurrentGameData: state.showModalCurrentGameData,
                showModalImagesStorage: state.showModalImagesStorage,
                showModalImagesStorageGame: state.showModalImagesStorageGame,
                showModalDebugMessage: state.showModalDebugMessage,
            }
        case OPEN_AND_CLOSE_MODAL_CARDS_WINNERS_USER_AND_GAME:
            return {
                showModalCreateSession: state.showModalCreateSession,
                showModalEditSession: state.showModalEditSession,
                showModalUserInfo: state.showModalUserInfo,
                showModalUserGameInfo: state.showModalUserGameInfo,
                showModalCreateGame: state.showModalCreateGame,
                showModalEditGame: state.showModalEditGame,
                showModalCreateUser: state.showModalCreateUser,
                showModalSignIn: state.showModalSignIn,
                showModalConfirmOTP: state.showModalConfirmOTP,
                showModalRegister: state.showModalRegister,
                showModalUsersSession: state.showModalUsersSession,
                showModalLaunchBallotManual: state.showModalLaunchBallotManual,
                showModalSingleSessionUserEnd: state.showModalSingleSessionUserEnd,
                showModalAddBulkUsers: state.showModalAddBulkUsers,
                showModalAddBulkUsersInCreateSession: state.showModalAddBulkUsersInCreateSession,
                showModalUsersRelSessionAndCards: state.showModalUsersRelSessionAndCards,
                showModalCardsWinnerUserAndGame: action.payload,
                showModalCreateUserRelAccountAndSession: state.showModalCreateUserRelAccountAndSession,
                showModalCurrentGameData: state.showModalCurrentGameData,
                showModalImagesStorage: state.showModalImagesStorage,
                showModalImagesStorageGame: state.showModalImagesStorageGame,
                showModalDebugMessage: state.showModalDebugMessage,
            }
        case OPEN_AND_CLOSE_MODAL_CREATE_USER_REL_ACCOUNT_AND_SESSION:
            return {
                showModalCreateSession: state.showModalCreateSession,
                showModalEditSession: state.showModalEditSession,
                showModalUserInfo: state.showModalUserInfo,
                showModalUserGameInfo: state.showModalUserGameInfo,
                showModalCreateGame: state.showModalCreateGame,
                showModalEditGame: state.showModalEditGame,
                showModalCreateUser: state.showModalCreateUser,
                showModalSignIn: state.showModalSignIn,
                showModalConfirmOTP: state.showModalConfirmOTP,
                showModalRegister: state.showModalRegister,
                showModalUsersSession: state.showModalUsersSession,
                showModalLaunchBallotManual: state.showModalLaunchBallotManual,
                showModalSingleSessionUserEnd: state.showModalSingleSessionUserEnd,
                showModalAddBulkUsers: state.showModalAddBulkUsers,
                showModalAddBulkUsersInCreateSession: state.showModalAddBulkUsersInCreateSession,
                showModalUsersRelSessionAndCards: state.showModalUsersRelSessionAndCards,
                showModalCardsWinnerUserAndGame: state.showModalCardsWinnerUserAndGame,
                showModalCreateUserRelAccountAndSession: action.payload,
                showModalCurrentGameData: state.showModalCurrentGameData,
                showModalImagesStorage: state.showModalImagesStorage,
                showModalImagesStorageGame: state.showModalImagesStorageGame,
                showModalDebugMessage: state.showModalDebugMessage,
            }
        case OPEN_AND_CLOSE_MODAL_CURRENT_GAME_DATA:
            return {
                showModalCreateSession: state.showModalCreateSession,
                showModalEditSession: state.showModalEditSession,
                showModalUserInfo: state.showModalUserInfo,
                showModalUserGameInfo: state.showModalUserGameInfo,
                showModalCreateGame: state.showModalCreateGame,
                showModalEditGame: state.showModalEditGame,
                showModalCreateUser: state.showModalCreateUser,
                showModalSignIn: state.showModalSignIn,
                showModalConfirmOTP: state.showModalConfirmOTP,
                showModalRegister: state.showModalRegister,
                showModalUsersSession: state.showModalUsersSession,
                showModalLaunchBallotManual: state.showModalLaunchBallotManual,
                showModalSingleSessionUserEnd: state.showModalSingleSessionUserEnd,
                showModalAddBulkUsers: state.showModalAddBulkUsers,
                showModalAddBulkUsersInCreateSession: state.showModalAddBulkUsersInCreateSession,
                showModalUsersRelSessionAndCards: state.showModalUsersRelSessionAndCards,
                showModalCardsWinnerUserAndGame: state.showModalCardsWinnerUserAndGame,
                showModalCreateUserRelAccountAndSession: state.showModalCreateUserRelAccountAndSession,
                showModalCurrentGameData: action.payload,
                showModalImagesStorage: state.showModalImagesStorage,
                showModalImagesStorageGame: state.showModalImagesStorageGame,
                showModalDebugMessage: state.showModalDebugMessage,
            }
        case OPEN_AND_CLOSE_MODAL_IMAGES_STORAGE:
            return {
                showModalCreateSession: state.showModalCreateSession,
                showModalEditSession: state.showModalEditSession,
                showModalUserInfo: state.showModalUserInfo,
                showModalUserGameInfo: state.showModalUserGameInfo,
                showModalCreateGame: state.showModalCreateGame,
                showModalEditGame: state.showModalEditGame,
                showModalCreateUser: state.showModalCreateUser,
                showModalSignIn: state.showModalSignIn,
                showModalConfirmOTP: state.showModalConfirmOTP,
                showModalRegister: state.showModalRegister,
                showModalUsersSession: state.showModalUsersSession,
                showModalLaunchBallotManual: state.showModalLaunchBallotManual,
                showModalSingleSessionUserEnd: state.showModalSingleSessionUserEnd,
                showModalAddBulkUsers: state.showModalAddBulkUsers,
                showModalAddBulkUsersInCreateSession: state.showModalAddBulkUsersInCreateSession,
                showModalUsersRelSessionAndCards: state.showModalUsersRelSessionAndCards,
                showModalCardsWinnerUserAndGame: state.showModalCardsWinnerUserAndGame,
                showModalCreateUserRelAccountAndSession: state.showModalCreateUserRelAccountAndSession,
                showModalCurrentGameData: state.showModalCurrentGameData,
                showModalImagesStorage: action.payload,
                showModalImagesStorageGame: state.showModalImagesStorageGame,
                showModalDebugMessage: state.showModalDebugMessage,
            }
        case OPEN_AND_CLOSE_MODAL_IMAGES_STORAGE_GAME:
            return {
                showModalCreateSession: state.showModalCreateSession,
                showModalEditSession: state.showModalEditSession,
                showModalUserInfo: state.showModalUserInfo,
                showModalUserGameInfo: state.showModalUserGameInfo,
                showModalCreateGame: state.showModalCreateGame,
                showModalEditGame: state.showModalEditGame,
                showModalCreateUser: state.showModalCreateUser,
                showModalSignIn: state.showModalSignIn,
                showModalConfirmOTP: state.showModalConfirmOTP,
                showModalRegister: state.showModalRegister,
                showModalUsersSession: state.showModalUsersSession,
                showModalLaunchBallotManual: state.showModalLaunchBallotManual,
                showModalSingleSessionUserEnd: state.showModalSingleSessionUserEnd,
                showModalAddBulkUsers: state.showModalAddBulkUsers,
                showModalAddBulkUsersInCreateSession: state.showModalAddBulkUsersInCreateSession,
                showModalUsersRelSessionAndCards: state.showModalUsersRelSessionAndCards,
                showModalCardsWinnerUserAndGame: state.showModalCardsWinnerUserAndGame,
                showModalCreateUserRelAccountAndSession: state.showModalCreateUserRelAccountAndSession,
                showModalCurrentGameData: state.showModalCurrentGameData,
                showModalImagesStorage: state.showModalImagesStorage,
                showModalImagesStorageGame: action.payload,
                showModalDebugMessage: state.showModalDebugMessage,
            }
        case OPEN_AND_CLOSE_MODAL_DEBUG_MESSAGE:
            return {
                showModalCreateSession: state.showModalCreateSession,
                showModalEditSession: state.showModalEditSession,
                showModalUserInfo: state.showModalUserInfo,
                showModalUserGameInfo: state.showModalUserGameInfo,
                showModalCreateGame: state.showModalCreateGame,
                showModalEditGame: state.showModalEditGame,
                showModalCreateUser: state.showModalCreateUser,
                showModalSignIn: state.showModalSignIn,
                showModalConfirmOTP: state.showModalConfirmOTP,
                showModalRegister: state.showModalRegister,
                showModalUsersSession: state.showModalUsersSession,
                showModalLaunchBallotManual: state.showModalLaunchBallotManual,
                showModalSingleSessionUserEnd: state.showModalSingleSessionUserEnd,
                showModalAddBulkUsers: state.showModalAddBulkUsers,
                showModalAddBulkUsersInCreateSession: state.showModalAddBulkUsersInCreateSession,
                showModalUsersRelSessionAndCards: state.showModalUsersRelSessionAndCards,
                showModalCardsWinnerUserAndGame: state.showModalCardsWinnerUserAndGame,
                showModalCreateUserRelAccountAndSession: state.showModalCreateUserRelAccountAndSession,
                showModalCurrentGameData: state.showModalCurrentGameData,
                showModalImagesStorage: state.showModalImagesStorage,
                showModalImagesStorageGame: state.showModalImagesStorageGame,
                showModalDebugMessage: action.payload,
            }
        default:
            return state;
    }
}
export default ModalReducer;
