import styled from "styled-components";

export const AppHeaderStyled = styled.div `
  width: 100%;
  height: 3vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0 2px 2px #00000038;
  opacity: 1;
  
  .options-admin-title-header {
    width: 8vw;
    margin-left: 2vw;
    cursor: pointer;
  }
  .container-user-session {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5vw;
    margin-right: 2vw;
    cursor: pointer;
    
    .container-icon-session {
      display: flex;
      align-items: center;
      justify-content: center;
      position: revert;
      border-radius: 100%;
      
      .img-user-session-circle {
        width: 2vw;
      }
      .img-user-session-avatar {
        width: 1vw;
        position: absolute;
      }
    }
    .name-user {
      text-align: center;
      letter-spacing: 0;
      color: #2E304E;
      opacity: 1; 
      font-size: .9vw;
      font-family: 'Poppins-SemiBold', sans-serif;
      font-weight: 600;
      font-style: normal;
    }
  }
`