import Swal from 'sweetalert2';

export function alertInfo(info:string) {
    Swal.fire({
        title: 'Info',
        text: info,
        icon: 'info',
        confirmButtonText: 'Aceptar',
    });
}
