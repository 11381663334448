import {getListBallotToValidateNumberComponent} from "./GetListBallotToValidateNumberComponent";

export function getRemainingBallots(game_mode: string | undefined, bingo_limit: number | undefined) {
    if (game_mode && bingo_limit) {
        const remainingBallots = getListBallotToValidateNumberComponent(game_mode, bingo_limit);
        if (remainingBallots) {
            return remainingBallots.length;
        } else  {
            return 0;
        }
    } else {
        return 0
    }
}