import {RegisterUserStyles} from "./RegisterUserStyles"
import React from "react";
import {themeDefault} from "../../../shared/Utils/ThemeDefault";
import {useForm} from "react-hook-form";
import {useAppDispatch, useAppSelector} from "../../../shared/Utils/Hooks";
import {CreateUserModel} from "../../../domain/models/CreateUserModel";
import {alertError} from "../../../shared/Utils/AlertError";
import {createUserLocal} from "../../../store/ActionsAdmin/UserActions";
import {openAndCloseModalRegister, openAndCloseModalSignIn} from "../../../store/ActionsAdmin/ModalsActions";
import {validateNameRegister} from "../../../shared/Utils/ValidateNameRegister";
import {validateEmailRegister} from "../../../shared/Utils/ValidateEmailRegister";
import {validateIdentificationDocumentRegister} from "../../../shared/Utils/ValidateIdentificationDocumentRegister";
import {validateTermsAndConditions} from "../../../shared/Utils/ValidateTermsAndConditions";

export interface registerUserModel {
    name: string;
    email: string;
    identificationDocument: string;
    termsAndConditions: boolean;
}

export default function RegisterUser(): JSX.Element {

    const {register, handleSubmit, formState: {errors}} = useForm<registerUserModel>();
    const dispatch = useAppDispatch();
    const numberPhoneLogin = useAppSelector((state) => state.authUserState.numberPhoneLogin);
    const domainActive = useAppSelector((state) => state.accountState.domainActive);
    const isLoadingCreateUserLocal = useAppSelector((state) => state.userState.isLoadingCreateUserLocal);

    function handleRegister(data: registerUserModel) {
        console.log(data);
        if (domainActive) {
            const dataRegister: CreateUserModel = {
                name: data.name,
                phone: numberPhoneLogin,
                email: data.email,
                identification_document: data.identificationDocument,
                user_type: 'user',
                accountId: domainActive.id
            }
            console.log(dataRegister);
            dispatch(createUserLocal(dataRegister)).then(() => {
                dispatch(openAndCloseModalRegister(false));
                dispatch(openAndCloseModalSignIn(true));
            }).catch((e) => {
                console.log(e);
                alertError('Hubo un error al crear la sesión');
            });
        } else {
            alertError('Dominio no se encuentra registrado');
        }
    }

    const submitFormRegister = (data: registerUserModel) => handleRegister(data);


    return (
        <RegisterUserStyles
            button_color={themeDefault.button_color}
            button_color_shadow={themeDefault.button_color_shadow}
        >
            <form onSubmit={handleSubmit(submitFormRegister)} className="form-login">
                <div className="title-form">
                    Regístrate
                </div>
                <div className="container-text-2">
                        <span className="text-2">
                            Completa tu información personal
                        </span>
                </div>
                <div className="container-input">
                    <input
                        placeholder="Nombre"
                        type="text"
                        {...register('name', {
                            validate: validateNameRegister
                        })}
                    />
                </div>
                {errors.name?.type === 'validate' &&
                    <span className="container-error">{errors.name.message}</span>
                }
                {/*<span>alguna mierda</span>*/}
                <div className="container-input">
                    <input
                        placeholder="Correo electrónico"
                        type="email"
                        {...register('email', {
                            validate: validateEmailRegister
                        })}
                    />
                </div>
                {errors.email?.type === 'validate' &&
                    <span className="container-error">{errors.email.message}</span>
                }
                <div className="container-input">
                    <input
                        // value={identificationDocument}
                        // onChange={(event) => handleIdentificationRegister(event.target.value)}
                        placeholder="Documento de identidad"
                        type="text"
                        {...register('identificationDocument', {
                            validate: validateIdentificationDocumentRegister
                        })}
                    />
                </div>
                {errors.identificationDocument?.type === 'validate' &&
                    <span className="container-error">{errors.identificationDocument.message}</span>
                }
                <div className="container-input-check">
                    <div className="input-check">
                        <input
                            type="checkbox"
                            {...register('termsAndConditions', {
                                validate: validateTermsAndConditions
                            })}
                        />
                        <label>Acepto los términos y condiciones</label>
                        {errors.termsAndConditions?.type === 'validate' &&
                            <span className="container-error-massages">{errors.termsAndConditions.message}</span>
                        }
                    </div>
                </div>
                <div className="container-error-massages">
                    {/*{errors}*/}
                    {/*aqui van los errores*/}
                </div>
                <div className="container-button">
                    <button
                        disabled={isLoadingCreateUserLocal}
                        type="submit"
                        className="button-form"
                    >
                        {!isLoadingCreateUserLocal ? 'Registrarme' : 'Cargando...'}
                    </button>
                </div>
            </form>
        </RegisterUserStyles>
    );
}
