import styled from "styled-components";

export const AdminHomeStyles = styled.div`
  width: 100%;
  height: 100vh; /* Establece la altura al 100% de la ventana */
  display: flex;
  flex-direction: row;
  overflow: hidden;

  .selectSession {
    width: 16%;
  }
  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 5px;
    background: #F1F1F150;
  }

`