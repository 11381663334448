import {SessionUserStyled} from "./SessionUserStyled";
import {useHistory} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../admin/shared/Utils/Hooks";
import {useEffect, useState} from "react";
import {getSessionStorageObj} from "../../../../admin/shared/Utils/GetSessionStorageObject";
import {alertConfirmUserSessionRel} from "../../../../admin/shared/Utils/TestingApi/AlertConfirmUserSessionRel";
import {createUserSessionRel} from "../../../../admin/store/ActionsAdmin/UserSessionRelActions";
import {getUserActive} from "../../../../admin/store/ActionsAdmin/UserActions";
import {getSingleSessionEndUser} from "../../../../admin/store/ActionsAdmin/SessionEndUserActions";
import {getGameInfo} from "../../../../admin/store/ActionsAdmin/BingosActions";
import {getCardsAws} from "../../../../admin/store/ActionsAdmin/CardActions";
import {chatBotNumbers} from "../../../../admin/shared/Utils/ChatBotNumbers";
import {alertInfoSessionUserRel} from "../../../../admin/shared/Utils/AlertInfoSessionUserRel";
import Loading from "../../../../admin/presentation/Components/Loading/Loading";
import {openAndCloseModalSingleSessionUserEnd, openUserInfo} from "../../../../admin/store/ActionsAdmin/ModalsActions";

interface ISessionUserProps {
    main_image: string;
    session_name: string;
    session_info: string;
    session_id: number;
}

export default function SessionUser({
                                        main_image,
                                        session_name,
                                        session_info,
                                        session_id,
                                    }: ISessionUserProps): JSX.Element {

    const history = useHistory();
    const dispatch = useAppDispatch();
    const userSessionStorage = getSessionStorageObj('user');
    const userEndActive = useAppSelector((state) => state.userState.userEndActive);
    const domainActive = useAppSelector((state) => state.accountState.domainActive);
    const [confirmButton, setConfirmButton] = useState<boolean>(false);
    const [chatBotNumberSelected, setChatBotNumberSelected] = useState('');
    const [isLoadingInSession, setIsLoadingInSession] = useState(false);

    function handleRedirectUserSession() {
        if (userEndActive) {
            dispatch(openAndCloseModalSingleSessionUserEnd(true));
            dispatch(getSingleSessionEndUser(session_id)).then(() => {
                dispatch(getCardsAws(session_id.toString(), userEndActive.phone)).then(() => {
                    dispatch(openAndCloseModalSingleSessionUserEnd(false));
                    dispatch(openUserInfo(false));
                    history.push(`/user/session/${session_id}`);
                });
            });
        }
    }

    function handleSessionRelUser() {
        if (userEndActive) {
            const sessionsRel = userEndActive?.sessions;
            const confirm = sessionsRel?.some(session => session.id === session_id);
            return !!confirm;
        }
        return false;
    }

    async function handleCreateUserSessionRel() {
        // alertConfirmUserSessionRel(session_name).then((confirm) => {
        //     if (confirm && userEndActive) {
        //         dispatch(createUserSessionRel(session_id, userEndActive.id)).then(() => {
        //             dispatch(getUserActive(userSessionStorage.id)).then(() => {});
        //         });
        //     }
        // });
        alertInfoSessionUserRel(chatBotNumberSelected);
    }

    useEffect(() => {
        if (domainActive) {
            const data = chatBotNumbers.filter((chatBotSelected) => chatBotSelected.id === domainActive.id);
            console.log(data);
            setChatBotNumberSelected(data[0].number);
        }
    }, [domainActive]);

    useEffect(() => {
        const conf = handleSessionRelUser();
        setConfirmButton(conf);
    }, [userEndActive]);

    return (
        <SessionUserStyled>
            <div className="container-circle-img">
                <img
                    className="img-session-small"
                    alt="img-session-small"
                    // src="/img/imgUser/imgHome/Rectángulo 343.png"
                    src={main_image}
                />
            </div>
            <div className="container-card-ext">
                <div className="container-card-int">
                    <div className="container-text-card">
                        <span className="text-session-name">
                            {/*La tercera es la vencida*/}
                            {session_name}
                        </span>
                        <span className="text-session-info">
                            {/*esta es la info*/}
                            {session_info}
                        </span>
                    </div>
                </div>
                {confirmButton &&
                    <div onClick={handleRedirectUserSession} className="button-session-card">
                        Entrar
                    </div>
                }
                {!confirmButton &&
                    <div onClick={handleCreateUserSessionRel} className="button-session-card">
                        Registrase
                    </div>
                }
            </div>
        </SessionUserStyled>
    );
}
