import {HomeGamesUserStyled} from "./HomeGamesUserStyled";
import React, {useEffect} from "react";
import SessionGames from "../SessionGames/SessionGames";
import LastBallotsEndUser from "../LastBallotsEndUser/LastBallotsEndUser";
import GameMode from "../../../../admin/presentation/Components/GameMode/GameMode";
import {useAppDispatch, useAppSelector} from "../../../../admin/shared/Utils/Hooks";
import Winners from "../Winners/Winners";
import ContentCardBingo from "../ContentCardBingo/ContentCardBingo";
import {useHistory, useParams} from "react-router-dom";
import {getGameInfo} from "../../../../admin/store/ActionsAdmin/BingosActions";
import SessionInfo from "../../../../admin/presentation/Components/SessionInfo/SessionInfo";
import UserSessionInfo from "../UserSessionInfo/UserSessionInfo";
import {openUserInfo} from "../../../../admin/store/ActionsAdmin/ModalsActions";
import {IParams} from "../../../../admin/presentation/Components/ShowSession/ShowSession";
import {getSingleSessionEndUser} from "../../../../admin/store/ActionsAdmin/SessionEndUserActions";


export default function HomeGamesUser(): JSX.Element {

    const id: IParams = useParams();
    const singleGameEndUser = useAppSelector((state) => state.gameEndUserState.singleGameEndUser);
    const gameInfo = useAppSelector((state) => state.bingoState.gameInfo);
    const singleSessionEndUser = useAppSelector((state) => state.sessionEndUserState.singleSessionEndUser);
    const history = useHistory();
    const dispatch = useAppDispatch()
    const showModalUserInfo = useAppSelector((state) => state.modalState.showModalUserInfo);

    function handleRedirectHome() {
        dispatch(openUserInfo(false));
        history.push('/user/home');
    }

    useEffect(() => {
        const timerGameInfo = setInterval(() => {
            if (singleGameEndUser) {
                dispatch(getGameInfo(singleGameEndUser.id)).then(() => {

                });
            }
        }, 5000);
        return () => clearInterval(timerGameInfo);
    }, [singleGameEndUser]);

    useEffect(() => {
        console.log(gameInfo);
    }, []);

    return (
        <HomeGamesUserStyled>
            <div className="sidebar-content">
                <div className="container-img">
                    <img
                        onClick={handleRedirectHome}
                        className="return-icon"
                        alt="return-icon"
                        src="/img/imgUser/imgGame/icon-return.png"
                    />
                </div>
                {showModalUserInfo &&
                    <div className="modal-user-session-info">
                        <UserSessionInfo/>
                    </div>
                }
                <SessionGames/>
                <Winners/>
            </div>

            <div className="game-content">
                <div className="container-games-cards">
                    <div className="container-games-cards-int">
                        <div className="container-int-background">
                            <img className="img-background" alt="img-background"
                                 src="/img/imgUser/imgGame/Rectángulo 253.png"
                            />
                            <div className="container-info-game">
                                {singleGameEndUser ? singleGameEndUser?.bingo_name : 'No hay juegos'} - Premio: {singleGameEndUser ? singleGameEndUser?.prize : 'No hay premios'}
                            </div>
                            <div className="container-info-game-mobile">
                                <span className="name-game">
                                    {singleGameEndUser ? singleGameEndUser?.bingo_name : 'No hay juegos'}
                                </span>
                                <span className="prize-game">
                                    {singleGameEndUser ? singleGameEndUser?.prize : 'No hay premios'}
                                </span>
                            </div>
                            <LastBallotsEndUser/>
                        </div>
                        <div className="container-mode-game">
                            <GameMode gameMode={singleGameEndUser?.game_mode}/>
                        </div>
                    </div>
                    <div className="container-cards">
                        <ContentCardBingo/>
                    </div>
                </div>
                <div className="container-social-networks">
                    <img className="live-img" alt="img" src="/img/imgUser/imgGame/Grupo 5267.svg"/>
                    <div className="social-networks">
                        <img className="img-telegram-whatsApp" alt="img"
                             src="/img/imgUser/imgGame/Grupo 5298@2x.png"/>
                        <img className="img-telegram-whatsApp" alt="img" src="/img/imgUser/imgGame/Grupo 5296.svg"/>
                    </div>
                </div>
            </div>
        </HomeGamesUserStyled>
    );
}

