import {ShowSessionAwardsStyled} from "./ShowSessionAwardsStyled";
import {SetStateAction, useEffect, useState} from "react";
import 'react-quill/dist/quill.snow.css';
import QuillShowSession from "../QuillShowSession/QuillShowSession";
import {useAppDispatch, useAppSelector} from "../../../shared/Utils/Hooks";
import {SessionConfigSponsorsModel} from "../../../domain/models/SessionConfigSponsorsModel";
import {SessionConfigAwardsModel} from "../../../domain/models/SessionConfigAwardsModel";
import {editSessionConfigAwards} from "../../../store/ActionsAdmin/SessionConfigActions";
import {getSingleSessionDatabase} from "../../../store/ActionsAdmin/SessionActions";

export default function ShowSessionAwards(): JSX.Element {

    const dispatch = useAppDispatch();
    const [awardsContent, setAwardsContent] = useState('');
    const singleSessionDatabase = useAppSelector((state) => state.sessionState.singleSessionDatabase);
    const isLoadingSessionConfigAwards = useAppSelector((state) => state.sessionConfigState.isLoadingSessionConfigAwards);
    const [dataAwards, setDataAwards] = useState<string>('');


    const awardsHandleChange = (content: SetStateAction<string>) => {
        setAwardsContent(content);
    };


    function handleOnClickButton() {
        console.log(awardsContent)
        const awardsString = JSON.stringify(awardsContent);
        console.log(awardsString);
        const data: SessionConfigAwardsModel = {
            prizes_data: awardsString,
        }

        if (singleSessionDatabase) {
            dispatch(editSessionConfigAwards(data, singleSessionDatabase.session_config_id)).then(() => {
                dispatch(getSingleSessionDatabase(singleSessionDatabase.id)).then(() => {});
            });
        }
    }

    useEffect(() => {
        if (singleSessionDatabase && singleSessionDatabase.session_config.prizes_data) {
            const dataAwards: string | null = JSON.parse(singleSessionDatabase.session_config.prizes_data);
            console.log(dataAwards);
            if (dataAwards !== null) {
                setDataAwards(dataAwards);
            } else {
                setDataAwards('');
            }
        }
    }, [singleSessionDatabase]);

    return (
        <ShowSessionAwardsStyled>
            <QuillShowSession
                value={dataAwards}
                handleChange={awardsHandleChange}
                onClickButton={handleOnClickButton}
                loading={isLoadingSessionConfigAwards}
            />
        </ShowSessionAwardsStyled>
    );
}
