import {WinnersStyled} from "./WinnersStyled";
import {useAppSelector} from "../../../../admin/shared/Utils/Hooks";

export default function Winners(): JSX.Element {

    const showWinners = useAppSelector((state) => state.bingoState.showWinners);



    return (
        <WinnersStyled>
            <div className="container-sup-winner">
                <div className="container-inf-winner">
                    <div className="header-card-winner">
                        <span className="players">Jugadores</span>
                        <span className="cards">Tarjetones</span>
                        <span className="winners">Ganadores</span>
                    </div>
                    <div className="body-card-winner">
                        <div className="card-winners">
                            {showWinners && showWinners.map((winner, index) => (
                                <div key={index} className="content-winner">
                                    <span className="name-winner">{winner.name}</span>
                                    <div className="container-serial">
                                        {winner.serial}
                                    </div>
                                    <img
                                        className="start-winner"
                                        alt="img-start-winner"
                                        src="/img/newImage/img-start-winner.svg"
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </WinnersStyled>
    );
}
