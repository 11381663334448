export const GET_ALL_BINGOS = 'GET_ALL_BINGOS';
export const GET_ALL_BINGOS_SUCCESS = 'GET_ALL_BINGOS_SUCCESS';
export const GET_ALL_BINGOS_FAILED = 'GET_ALL_BINGOS_FAILED';
export const GET_SINGLE_BINGO = 'GET_SINGLE_BINGO';
export const GET_SINGLE_BINGO_SUCCESS = 'GET_SINGLE_BINGO_SUCCESS';
export const GET_SINGLE_BINGO_FAILED = 'GET_SINGLE_BINGO_FAILED';
export const CREATE_BINGO = 'CREATE_BINGO';
export const CREATE_BINGO_SUCCESS = 'CREATE_BINGO_SUCCESS';
export const CREATE_BINGO_FAILED = 'CREATE_BINGO_FAILED';
export const DELETE_BINGO = 'DELETE_BINGO';
export const DELETE_BINGO_SUCCESS = 'DELETE_BINGO_SUCCESS';
export const DELETE_BINGO_FAILED = 'DELETE_BINGO_FAILED';
export const LAUNCH_BALLOT = 'LAUNCH_BALLOT';
export const LAUNCH_BALLOT_SUCCESS = 'LAUNCH_BALLOT_SUCCESS';
export const LAUNCH_BALLOT_FAILED = 'LAUNCH_BALLOT_FAILED';
export const START_GAME = 'START_GAME';
export const START_GAME_SUCCESS = 'START_GAME_SUCCESS';
export const START_GAME_FAILED = 'START_GAME_FAILED';
export const EDIT_BINGO = 'EDIT_BINGO';
export const EDIT_BINGO_SUCCESS = 'EDIT_BINGO_SUCCESS';
export const EDIT_BINGO_FAILED = 'EDIT_BINGO_FAILED';
export const CLEAN_SINGLE_BINGO = 'CLEAN_SINGLE_BINGO';
export const CLEAN_ALL_BINGOS = 'CLEAN_ALL_BINGOS';
export const UPDATE_LAST_BALLOT = 'UPDATE_LAST_BALLOT';
export const CLEAN_LAST_BALLOT = 'CLEAN_LAST_BALLOT';
export const GET_SHOW_WINNERS = 'GET_SHOW_WINNERS';
export const GET_GAME_INFO = 'GET_GAME_INFO';
export const GET_GAME_INFO_SUCCESS = 'GET_GAME_INFO_SUCCESS';
export const GET_GAME_INFO_FAILED = 'GET_GAME_INFO_FAILED';
export const UPDATE_GAME_INFO = 'UPDATE_GAME_INFO';
export const UPDATE_LAST_BALLOT_LAUNCH = 'UPDATE_LAST_BALLOT_LAUNCH';
export const ALL_BALLOTS_PLAYED = 'ALL_BALLOTS_PLAYED';
export const ALL_BALLOTS_PLAYED_IN_LAUNCH_BALLOT = 'ALL_BALLOTS_PLAYED_IN_LAUNCH_BALLOT';
export const CHANGE_STARTING_GAME = 'CHANGE_STARTING_GAME';
export const UPDATE_STATE_SINGLE_BINGO = 'UPDATE_STATE_SINGLE_BINGO';
export const CHANGE_LAUNCHING_BALLOT_TRUE = 'CHANGE_LAUNCHING_BALLOT_TRUE';
export const CHANGE_LAUNCHING_BALLOT_FALSE = 'CHANGE_LAUNCHING_BALLOT_FALSE';
export const COUNT_LAUNCH_BALLOT = 'COUNT_LAUNCH_BALLOT';
export const CHANGE_PLAY_LAUNCH_BALLOT_AUTO = 'CHANGE_PLAY_LAUNCH_BALLOT_AUTO';
export const FINALIZED_PLAY_LAUNCH_BALLOT_AUTO = 'FINALIZED_PLAY_LAUNCH_BALLOT_AUTO';
export const CHANGE_IS_AUTOMATIC_LAUNCH = 'CHANGE_IS_AUTOMATIC_LAUNCH';
export const SELECTED_CLICK_ADD_IMAGE = 'SELECTED_CLICK_ADD_IMAGE';
export const SELECTED_IMAGE_MAIN_CREATE_SESSION = 'SELECTED_IMAGE_MAIN_CREATE_SESSION';
export const SELECTED_IMAGE_BANNER_CREATE_SESSION = 'SELECTED_IMAGE_BANNER_CREATE_SESSION';
export const SELECTED_IMAGE_MAIN_CREATE_GAME = 'SELECTED_IMAGE_MAIN_CREATE_GAME';
export const CREATE_IMAGE = 'CREATE_IMAGE';
export const CREATE_IMAGE_SUCCESS = 'CREATE_IMAGE_SUCCESS';
export const CREATE_IMAGE_FAILED = 'CREATE_IMAGE_FAILED';
export const GET_ALL_IMAGES = 'GET_ALL_IMAGES';
export const GET_ALL_IMAGES_SUCCESS = 'GET_ALL_IMAGES_SUCCESS';
export const GET_ALL_IMAGES_FAILED = 'GET_ALL_IMAGES_FAILED';
export const DELETE_IMAGE = 'DELETE_IMAGE';
export const DELETE_IMAGE_SUCCESS = 'DELETE_IMAGE_SUCCESS';
export const DELETE_IMAGE_FAILED = 'DELETE_IMAGE_FAILED';
export const SWITCH_DEBUG_MESSAGES = 'SWITCH_DEBUG_MESSAGES';
export const SWITCH_DEBUG_MESSAGES_SOCKET = 'SWITCH_DEBUG_MESSAGES_SOCKET';
export const DEBUG_MESSAGES = 'DEBUG_MESSAGES';
export const DEBUG_MESSAGES_SUCCESS = 'DEBUG_MESSAGES_SUCCESS';
export const DEBUG_MESSAGES_FAILED = 'DEBUG_MESSAGES_FAILED';
export const CLEAR_DEBUG_MESSAGES = 'CLEAR_DEBUG_MESSAGES';
export const GET_ALL_DEBUG_MESSAGES_DATA_BASE = 'GET_ALL_DEBUG_MESSAGES_DATA_BASE';
export const GET_ALL_DEBUG_MESSAGES_DATA_BASE_SUCCESS = 'GET_ALL_DEBUG_MESSAGES_DATA_BASE_SUCCESS';
export const GET_ALL_DEBUG_MESSAGES_DATA_BASE_FAILED = 'GET_ALL_DEBUG_MESSAGES_DATA_BASE_FAILED';
export const RESET_LAUNCHING_BALLOT = 'RESET_LAUNCHING_BALLOT';